<template>
  <div class="sticker-sheet">
    <action-sheet
      v-if="$device.isMobile"
      ref="action-sheet"
      title=""
      sheet-height=""
      @close="close"
    >
      <div class="pt-3 pb-2 px-2 w-100 h-100">
        <b-tabs
          fill
          class="sticker-tabs"
          nav-class="sticker-tab"
          nav-wrapper-class="wrapper-sticker-tab"
        >
          <b-tab
            v-for="stickerType in stickersTypes"
            :key="stickerType.value"
            :title="stickerType.title"
          >
            <div class="d-flex row mx-0 px-1">
              <div
                v-for="sticker in stickerType.count"
                :key="sticker"
                class="col-4 mb-2 px-1"
              >
                <div
                  class="sticker-wrapper rounded-10 text-center"
                  @click="select(stickerType.value + '-' + sticker)"
                >
                  <img
                    v-i18n-src="
                      '@/assets/img/locales/{locale}/sticker/' +
                      stickerType.value +
                      '-' +
                      sticker +
                      '.png'
                    "
                    class="w-100"
                  />
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </action-sheet>
    <div v-if="!$device.isMobile" class="web-sticker-sheet">
      <div class="wrap-stickers-close text-primary text-16" @click.stop="close">
        <span> {{ $t('收起') }}</span>
        <span class="icon-Chevron-down"></span>
      </div>
      <b-tabs
        fill
        class="sticker-tabs"
        nav-class="sticker-tab"
        nav-wrapper-class="wrapper-sticker-tab"
      >
        <b-tab
          v-for="stickerType in stickersTypes"
          :key="stickerType.value"
          :title="stickerType.title"
          ><div class="pb-md-3 px-md-3">
            <div class="pt-2 pb-0 px-1 w-100 wrap-sticker-content scroll-bar">
              <div class="d-flex row mx-0 w-100">
                <div
                  v-for="sticker in stickerType.count"
                  :key="sticker"
                  class="sticker mb-2 px-1"
                  :class="{'col-4': $device.isMobile}"
                >
                  <div
                    class="sticker-wrapper rounded-10 text-center"
                    @click.stop="select(stickerType.value + '-' + sticker)"
                  >
                    <img
                      v-i18n-src="
                        '@/assets/img/locales/{locale}/sticker/' +
                        stickerType.value +
                        '-' +
                        sticker +
                        '.png'
                      "
                      class="w-100"
                    />
                  </div>
                </div>
              </div>
            </div></div
        ></b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n/index.js';
import ActionSheet from './ActionSheet.vue';
export default {
  components: {
    ActionSheet,
  },
  data() {
    return {
      stickersTypes: [
        {value: 'hjj', title: i18n.t('黑嘉嘉'), count: 9},
        {value: 'bubu', title: 'BUBU', count: 9},
      ],
    };
  },
  methods: {
    select(value) {
      this.$emit('select', value);
      this.$device.isMobile
        ? this.$refs['action-sheet'].closeSheet()
        : this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .sticker-wrapper {
  border: 1px solid $line-2;

  @media screen and (min-width: 768px) {
    background: white;
    cursor: pointer;
  }
}
.web-sticker-sheet {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  border-radius: 10px;
  left: 0;
  .wrap-sticker-content {
    background: $grayscale-5;
    border-radius: 6px;
    width: 100%;
    height: 253px;
    max-height: 253px;
    overflow: overlay;
  }
  .sticker {
    @media screen and (min-width: 768px) {
      min-width: 120px;
      max-width: 50%;
    }
    @media screen and (min-width: 1200px) {
      min-width: 120px;
      max-width: 33.333%;
    }
  }
}
.wrap-stickers-close {
  line-height: 28px;
  box-shadow: 0px -4px 4px 0px #0000000a;
  text-align: center;
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  .icon-Chevron-down {
    font-size: 24px;
    position: relative;
    top: 4px;
  }
}
:deep() .sticker-tabs {
  .wrapper-sticker-tab {
    margin: 0 8px 8px;
    @media screen and (min-width: 768px) {
      margin: 0 16px 8px;
    }
    .sticker-tab {
      border: 1px solid $secondary;
      border-radius: 4px;
      .nav-item .nav-link {
        padding: 5px;
        color: $secondary;
        font-weight: 400;
        border: none;
        &.active {
          background: $secondary;
          color: white;
          border-radius: 0;
          margin: 0;
        }
        &:hover {
          border: none;
        }
      }
    }
  }
}
</style>
