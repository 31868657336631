import axios from 'axios';
import url from '@/lib/api/url';
import {stage} from '@/constant/env';
const cdn = url.cdn;

/**
 * 確認現在版本
 * @returns {String} 現在版本
 */
const getOriginVersion = () => {
  return process.env.VUE_APP_GIT_TAG;
};

/**
 * 取得最新版本
 */
const getNewestVersion = async () => {
  try {
    const url = `${cdn}dist/${stage}/version.json?${new Date().getTime()}`;
    const version = (await axios.get(url)).data;
    return version;
  } catch {
    return '1.0.0';
  }
};

/**
 * 判斷是否有新版本
 * @param {String} originVersion originVersion
 * @param {String} newVersion newVersion
 * @returns {Boolean} 是否有新版本
 */
const isHaveNewVersion = (originVersion, newVersion) => {
  if (originVersion && !newVersion) return false;
  if (!originVersion && newVersion) return true;
  const originVersionArray = originVersion.split('.');
  const newestVersionArray = newVersion.split('.');
  const maxLength = Math.max(
    originVersionArray.length,
    newestVersionArray.length
  );
  for (let i = 0; i < maxLength; i++) {
    const versionReq = /([0-9]+)([a-zA-Z]*)/;
    const [, originVersionNumber, originVersionABC] =
      originVersionArray[i].match(versionReq);
    const [, newVersionNumber, newVersionABC] =
      newestVersionArray[i].match(versionReq);
    const delta = Number(newVersionNumber) - Number(originVersionNumber);
    if (delta > 0) return true;
    if (delta < 0) return false;
    if (originVersionABC === '' && newVersionABC) return true;
    if (originVersionABC && newVersionABC === '') return false;
    if (originVersionABC !== newVersionABC) {
      const delta = newVersionABC.charCodeAt() - originVersionABC.charCodeAt();
      if (delta > 0) return true;
      if (delta < 0) return false;
    }
    if (i === maxLength - 1) return false;
  }
};

export {getOriginVersion, getNewestVersion, isHaveNewVersion};
