import WgoHelper from 'wgo-helper';
import {numberToSgf} from 'goer-utils/function/parser';
import i18n from '@/i18n/index.js';
import delay from '@/lib/base/delay';
import Message from '@/lib/base/message';
import _ from 'lodash';

class GameHelper extends WgoHelper {
  constructor(mode, elemId, sgf, options) {
    const {action, isEndGame} = options;
    super(mode, elemId, sgf, options);

    this.isGameOver = false;
    this.isAiThinking = false;
    this.originSgf = false;
    this.mode = mode;
    this.action = action;
    this.isEndGame = isEndGame;
  }

  get userColor() {
    return this.action.getUserColor();
  }
  call(...args) {
    super.call(...args);
  }
  gameUpdate(event) {
    const {node, op} = event;
    if (this.editable && this.editable.player) {
      if (op != 'init') {
        this.removeAllMoveOption();
        this.clearInfluenceMarkup();
        this.showStep();
        if (
          !this.originSgf &&
          node.move &&
          !this.isGameOver &&
          (!node.children || node.children.length == 0)
        ) {
          // 落子
          this.setTurn();
          this.action.setSgf(this.sgf);

          /* 使用者下完，輪到AI時才存 */
          if (this.turnColor != this.userColor) {
            /* 目前不開放pass，因此不多做判定 */
            const move = node.move.pass
              ? 'pass'
              : numberToSgf(node.move.x, node.move.y);
            this.action
              .updateGame({move})
              .then(({data}) => {
                const game = data?.game;
                if (game?.winner) {
                  return this.call('fuseki-over', {
                    winner: game.winner,
                    score: game.score,
                  });
                }
                this.call('UserMove', data.game);
                this.aiPlay();
              })
              .catch((e) => this.refreshErrorHandler(e));
          }
          this.call('play');
        }
      }
    }
  }
  setTurn() {
    if (this.turnColor === this.userColor) {
      this.setFrozen(false);
    } else {
      this.setFrozen(true);
    }
  }
  aiPlay() {
    if (this.isAiThinking) return;
    this.isAiThinking = true;
    this.call('AiThinkStart');
    const delayMs =
      _.sample([2, 2, 2, 2, 2, 2, 3, 3, 3, 4]) * 1000 + _.random(0, 9) * 100;
    return Promise.all([this.action.nextMove(), delay(delayMs)])
      .then((result) => {
        this.call('play');
        const data = result[0].data;
        const aiMove = data.move;
        const game = data.game;
        this.isAiThinking = false;
        this.call('AiThinkOver');
        if (this.isGameOver) {
          return;
        }

        if (data.hasOwnProperty('isWin')) {
          if (data.isWin) {
            this.call('AiResign');
            let winningWay = '';
            if (this.mode === 'capture') {
              winningWay =
                this.userColor == 'black' ? i18n.t('黑勝') : i18n.t('白勝');
            } else {
              winningWay =
                this.userColor == 'black'
                  ? i18n.t('黑中盤勝')
                  : i18n.t('白中盤勝');
            }
            this.endGame(winningWay, data);
          } else {
            this.call('resign');
            let winningWay = '';
            if (this.mode === 'capture') {
              winningWay =
                this.userColor == 'white' ? i18n.t('黑勝') : i18n.t('白勝');
            } else {
              winningWay =
                this.userColor == 'white'
                  ? i18n.t('黑中盤勝')
                  : i18n.t('白中盤勝');
            }
            this.endGame(winningWay, data);
          }
        } else {
          this.setFrozen(false);

          if (aiMove.toLowerCase() == 'pass') {
            if (!this.isTestPlay) this.pass();
            this.call('AiPass');
          } else {
            if (!this.isTestPlay) this.play(aiMove);
            else this.originSgf = game.sgf;
            this.call('AiMove', game);
          }
        }
        if (game?.moveOptions) {
          this.setMoveOptions(game.moveOptions);
        }
      })
      .catch((e) => this.refreshErrorHandler(e));
  }
  resign() {
    this.call('AiThinkStart');
    this.action
      .resign()
      .then((result) => {
        this.call('resign');
        let winningWay = '';
        if (this.mode === 'capture') {
          winningWay =
            this.userColor == 'white' ? i18n.t('黑勝') : i18n.t('白勝');
        } else {
          winningWay =
            this.userColor == 'white' ? i18n.t('黑中盤勝') : i18n.t('白中盤勝');
        }
        this.endGame(winningWay, result.data);
      })
      .catch((e) => this.refreshErrorHandler(e));
  }
  endGame(winningWay, {isWin, isRestartable, item}) {
    this.call('AiThinkOver');
    this.isGameOver = true;
    this.setFrozen(true);

    this.action.overGameData();

    this.call('gameOverAndGetItem', {
      winningWay,
      isWin,
      dropItems: item,
      isRestartable,
    });
  }
  gameStart() {
    this.isGameOver = false;
    this.call('gameStart');
    this.setEditable(true);
    this.setFrozen(true);
    this.setTurn();
    if (this.turnColor != this.userColor) {
      this.aiPlay();
    }
  }
  getJudgement({isShowRedFilter = true} = {}) {
    this.call('AiThinkStart');
    return this.action.getJudgement().then((apiResult) => {
      const data = apiResult.data;
      let resultError;
      let scoreInformation = '';
      let blackScore = 0;
      if (!data.influence) {
        resultError = i18n.t('棋局還沒結束哦！');
      } else {
        if (!data.result) {
          if (this.isEndGame) {
            resultError = i18n.t('有地方沒下完，還不能完成官子');
          } else {
            resultError = i18n.t('有地方沒下完，還不能申請算輸贏');
          }
        }
        const influenceArray = getInfluenceArray(data.influence);
        influenceArray.forEach((row, y) => {
          row.forEach((mark, x) => {
            if (mark === 'o' || mark === 'b') {
              if (data.result) {
                this.editable.board.addObject({type: 'mini', x, y, c: -1});
              }
            } else if (mark === 'x' || mark === 'w') {
              if (data.result) {
                this.editable.board.addObject({type: 'mini', x, y, c: 1});
              }
              blackScore++;
            } else if (mark === 'X') {
              blackScore++;
            } else if (mark === '*') {
              // 雙活共氣
              blackScore += 0.5;
            } else if (mark === '.') {
              if (isShowRedFilter)
                this.editable.board.addObject({type: 'RedFilter', x, y});
              blackScore += 0.5;
            }
          });
        });

        scoreInformation = i18n.t('（黑{blackScore}子）', {
          blackScore,
        });
      }

      this.call('AiThinkOver');
      return {
        resultError,
        winningWay: data.result,
        scoreInformation,
      };
    });
  }
  confirmJudgement({winningWay}) {
    this.call('AiThinkStart');
    return this.action
      .confirmJudgement()
      .then((result) => {
        return this.endGame(winningWay, result.data);
      })
      .catch((e) => this.refreshErrorHandler(e));
  }
  clearInfluenceMarkup() {
    this.editable.board.obj_arr.forEach((row) => {
      row.forEach((items) => {
        items.forEach((item) => {
          if (item.type == 'mini' || item.type == 'RedFilter') {
            this.editable.board.removeObject({
              type: item.type,
              x: item.x,
              y: item.y,
            });
          }
        });
      });
    });
  }
  refreshErrorHandler(error) {
    if (error?.message?.includes('timeout')) {
      Message.error('請確認網路連線的狀態後再次嘗試', 5000);
    } else {
      const message = error?.response?.data?.message || error?.message;
      Message.error(message);
    }
    this.call('AiThinkOver');
  }
}

/**
 * 將influence字串轉換為陣列儲存
 * @param {String} influence - Leela influence API計算後回傳的字串
 * @param {Object} boardWidth - [可選] 棋盤大小
 * @returns {Array} - 儲存influence的二維陣列
 */
function getInfluenceArray(influence) {
  const boardWidth = Math.sqrt(influence.length);

  if (boardWidth % 1 != 0) {
    throw new Error('board must be square');
  }

  const influenceArray = [];
  for (const index in influence) {
    const mark = influence[index];
    const x = index % boardWidth;
    const y = Math.floor(index / boardWidth);
    if (x == 0) {
      influenceArray.push([]);
    }
    influenceArray[y].push(mark);
  }
  return influenceArray;
}

export default GameHelper;
