export default [
  {
    path: '/practice',
    name: 'practice',
    component: () => import('@/views/Practice/index.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
    },
  },
  {
    path: '/practice/pvp-home',
    name: 'pvp-home',
    component: () => import('@/views/Pvp/PvpHome.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 1,
    },
  },
  {
    path: '/practice/pvp-menu',
    name: 'pvp-menu',
    component: () => import('@/views/Pvp/PvpMenu.vue'),
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      requireAuth: true,
      transitionDepth: 1,
    },
  },
  {
    path: '/practice/gamelist',
    name: 'gamelist',
    component: () => import('@/views/Practice/AiGame.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
    },
  },
  {
    path: '/practice/tsumelist',
    name: 'tsumelist',
    component: () => import('@/views/Practice/TsumePracticeList.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
    },
  },
  {
    path: '/practice/game',
    name: 'practiceGame',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: 'practiceGame'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/practice/tsume/ranks/:rank/types/:type',
    name: 'practiceTsume',
    component: () => import('@/views/Room/TsumeRoom.vue'),
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'tsume',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
];
