import {getLevelConfig, getUserStatus, getLevelInfo} from '@/services/ladder';
import {LADDER_TYPES} from '@/constant/ladder.js';
import _ from 'lodash';
import Vue from 'vue';
import dayjs from 'dayjs';
import gRankList from '@/json/gRank.json';
import {
  saveLocalData,
  getLocalData,
  removeLocalData,
} from '@/lib/base/localData';

const specialLevels = _.range(0, 1001, 50);
specialLevels[0] = 10;

const state = {
  energy: 0,
  energyRestoreHour: 4,
  energyRestoreAt: null,
  type: [
    {key: LADDER_TYPES.GAME, title: '下棋'},
    {key: LADDER_TYPES.TSUME, title: '做題'},
    {key: LADDER_TYPES.FUSEKI, title: '佈局'},
    {key: LADDER_TYPES.ENDGAME, title: '官子'},
  ],
  maxLevel: {},
  starLevels: {},
  rankMapping: {},
  userStatus: {},
  specialLevels,
};

const getters = {
  getMaxLevelByType: (state) => (type) => {
    return state.maxLevel[type];
  },
  getStarLevelsByType: (state) => (type) => {
    return state.starLevels[type] || {};
  },
  getRankMappingByType: (state) => (type) => {
    return state.rankMapping[type] || {};
  },
  getRankByTypeAndStage: (state, getters) => (type, stage) => {
    const rankMapping = getters.getRankMappingByType(type);
    return Object.keys(rankMapping).find((rank) => {
      const {start, end} = rankMapping[rank];
      return start <= stage && end >= stage;
    });
  },
  getUserStage: (state) => (type) => {
    switch (type) {
      case LADDER_TYPES.GAME:
        return state.userStatus.gameLevel;
      case LADDER_TYPES.TSUME:
        return state.userStatus.tsumeLevel;
      case LADDER_TYPES.ENDGAME:
        return state.userStatus.endgameLevel;
      case LADDER_TYPES.FUSEKI:
        return state.userStatus.fusekiLevel;
      default:
        return 0;
    }
  },
  getCongratulationStage: (state, getters) => (type) => {
    const nextStageText = getLocalData(`nextStage_${type}`);
    if (!nextStageText) return {};
    const nextStage = parseInt(nextStageText);
    const maxStage = getters.getMaxLevelByType(type);
    const userStage = getters.getUserStage(type);
    if (userStage === nextStage) {
      // 多過了一關
      if (userStage === maxStage) {
        // 過完所有關卡
        return {
          star: null,
          special: maxStage,
        };
      } else {
        const starLevels =
          Object.values(getters.getStarLevelsByType(type)) || [];
        const specialLevels = state.specialLevels;
        // 檢查是否通過星星關卡
        const star = starLevels.find((level) => level === nextStage);
        // 檢查是否通過特殊關卡
        const special = specialLevels.find((level) => level === nextStage);
        return {star, special};
      }
    } else {
      return {star: null, special: null};
    }
  },
};

const actions = {
  async getAllStageConfig({dispatch, state}) {
    await Promise.all(
      state.types.map((type) => dispatch('getStageConfig', type.key))
    );
  },

  async getStageConfig({commit, state}, type) {
    if (state.maxLevel[type]) return;
    const levelConfig = await getLevelConfig(type.toUpperCase());
    commit('setStageConfig', {type, ...levelConfig});
  },

  async getUserStatus({commit}) {
    const data = await getUserStatus();
    commit('setUserStatus', data);
  },

  async getStageInfo({}, {type, stage}) {
    const data = await getLevelInfo({level: stage, type: type.toUpperCase()});
    return data;
  },

  setNextStage({state}, {type}) {
    const userStage = getters.getUserStage(state)(type);
    const nextStage = getLocalData(`nextStage_${type}`);
    if (!nextStage && userStage >= 0) {
      saveLocalData(`nextStage_${type}`, userStage + 1);
    }
  },
  resetNextStage({dispatch}, {type}) {
    removeLocalData(`nextStage_${type}`);
    dispatch('setNextStage', {type});
  },
  addEnergy({state}) {
    state.energyRestoreAt = dayjs().add(state.energyRestoreHour, 'hour');
    state.energy = state.energy + 1;
  },
};

const mutations = {
  setStageConfig(state, {type, maxLevel, config}) {
    Vue.set(state.maxLevel, type, maxLevel);
    const starLevels = {};
    const rankMapping = {};
    let startLevel = 0;
    gRankList.forEach(({value: rank}) => {
      const rankConfig = config[rank];
      if (!rankConfig) return;
      let endLevel = 0;
      rankConfig.starLevels.forEach((level) => {
        starLevels[level] = level;
        if (level > endLevel) endLevel = level;
      });
      rankMapping[rank] = {start: startLevel, end: endLevel};
      startLevel = endLevel + 1;
    });
    Vue.set(state.starLevels, type, starLevels);
    Vue.set(state.rankMapping, type, rankMapping);
  },
  setUserStatus(
    state,
    {
      energy,
      energyRestoreHour,
      energyRestoreAt,
      gameLevel = 0,
      tsumeLevel = 0,
      endgameLevel = 0,
      fusekiLevel = 0,
    }
  ) {
    state.energy = energy;
    state.energyRestoreHour = energyRestoreHour;
    state.energyRestoreAt = energyRestoreAt && dayjs(energyRestoreAt);
    state.userStatus = {
      gameLevel,
      tsumeLevel,
      endgameLevel,
      fusekiLevel,
    };
  },
  clearUserStatus(state) {
    state.energy = 0;
    state.energyRestoreHour = 4;
    state.energyRestoreAt = null;
    state.userStatus = {};
    Object.values(LADDER_TYPES).forEach((type) =>
      removeLocalData(`nextStage_${type}`)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
