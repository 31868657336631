<template>
  <mounting-portal mount-to="#modal-wrapper" append>
    <div
      class="modal-wrapper position-fixed d-flex justify-content-center align-items-center"
      :class="{'wrapper-transparent': isShowTransition}"
      :style="wrapperBackground"
      @click="closeWrapper"
    >
      <slot></slot>
    </div>
  </mounting-portal>
</template>

<script>
export default {
  props: {
    lockScroll: {
      type: Boolean,
      default: true,
    },
    clickToClose: {
      type: Boolean,
      default: true,
    },
    isShowTransition: {
      type: Boolean,
      default: false,
    },
    isModalWrapperShow: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      wrapperBackground: 'rgba(0, 0, 0, 0.5)',
    };
  },
  watch: {
    isModalWrapperShow: {
      handler() {
        if (this.isModalWrapperShow) {
          if (this.isShowTransition) {
            this.openWrapperTransition();
          }
        } else {
          this.closeWrapper();
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.lockScroll) {
      document.querySelector('body').style.overflow = 'hidden';
    }
  },
  beforeDestroy() {
    if (this.lockScroll) {
      document.querySelector('body').style.overflow = '';
    }
  },
  methods: {
    openWrapperTransition() {
      this.wrapperBackground = {
        background: 'rgba(0, 0, 0, 0.5)',
      };
    },
    closeWrapper() {
      if (this.clickToClose) {
        if (this.isShowTransition) {
          this.wrapperBackground = {
            background: 'rgba(0, 0, 0, 0)',
          };
          this.$emit('close');
        } else {
          this.$emit('close');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  z-index: 1040;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  &.wrapper-transparent {
    background: rgba(0, 0, 0, 0);
  }
}
</style>
