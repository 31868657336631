import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';

/**
 * 取得黑嘉嘉ai對弈列表
 * @param {String} planId 課程方案 ID
 * @returns {Promise} promise
 */
function getAiGameCourseConfigs(planId) {
  return callLambda({
    category: 'aiGame',
    apiName: `hjjAiGameCourseConfigs?coursePlan=${planId}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得使用者某課程的ai對弈紀錄
 * @param {String} planId 課程方案 ID
 * @param {String} rank 難度(G級)
 * @returns {Promise} promise
 */
function getAiGameUserRecord(planId, rank) {
  return callLambda({
    category: 'aiGame',
    apiName: `hjjAiGameUserRecord?coursePlan=${planId}&rank=${rank}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * ai對弈落點提示功能
 * @param {Number} id game id
 * @returns {Promise} promise
 */
function supportGame(id) {
  const data = {
    id,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'supportGame',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 創建新下棋練習/ai對弈（注意aiId是列表中的lv，aiLevel則是ai的棋力）
 * @param {String} userColor 玩家擇色
 * @param {Number} aiLevel ai等級
 * @param {Number} boardSize 棋盤尺寸
 * @param {String} style 棋風
 * @param {String} opponentName ai名字
 * @param {String} coursePlan coursePlan
 * @param {String} aiId ai的lv
 * @returns {Promise} promise
 */
function createPracticeGame(
  userColor,
  aiLevel,
  boardSize,
  style,
  opponentName,
  coursePlan,
  aiId
) {
  const data = {
    userColor,
    boardSize,
    aiLevel,
    style,
    opponentName,
    isAdaptive: false,
    coursePlan,
    aiId,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'practiceGame',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 創建黑嘉嘉課程的對局
 * @param {String} courseUniqueKey 課程唯一鍵
 * @param {String} coursePlan coursePlan
 * @returns {Promise} promise
 */
function createCourseGame(courseUniqueKey, coursePlan) {
  const data = {
    courseUniqueKey,
    coursePlan,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'hjjCourseGame',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 創建黑嘉嘉檢定的對局
 * @param {Number} rank 棋力
 * @param {String} verificationId 檢定id
 * @param {String} gameId 下棋id
 * @param {String} coursePlan coursePlan
 * @returns {Promise} promise
 */
function createVerificationGame(rank, verificationId, gameId, coursePlan) {
  const data = {
    rank,
    gameId,
    id: verificationId,
    coursePlan,
  };
  return callLambda({
    category: 'verification',
    apiName: 'createAiGame',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * createLadderGame
 */
async function createLadderGame({level}) {
  const res = await callLambda({
    category: 'aiGame',
    method: 'post',
    apiName: 'ladderGame',
    data: {level},
  });
  return res.data;
}

/**
 * createLadderEndgame
 */
async function createLadderEndgame({level}) {
  const res = await callLambda({
    category: 'aiGame',
    method: 'post',
    apiName: 'ladderEndgame',
    data: {level},
  });
  return res.data;
}

/**
 * createLadderFuseki
 */
async function createLadderFuseki({level}) {
  const res = await callLambda({
    category: 'aiGame',
    method: 'post',
    apiName: 'ladderFuseki',
    data: {level},
  });
  return res.data;
}

/**
 * 取得尚未完成的下棋練習
 * @param {String} gameMode 下棋模式(practice/ladder....)
 * @returns {Promise} promise
 */
function getUnfinishedGame(gameMode = 'practice') {
  return callLambda({
    category: 'aiGame',
    apiName: 'unfinishedGame',
    params: {gameMode},
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得黑嘉嘉課程下棋紀錄
 * @param {String} courseUniqueKey 課程唯一鍵
 * @returns {Promise} promise
 */
function getUnfinishedCourseGame(courseUniqueKey) {
  return callLambda({
    category: 'aiGame',
    apiName: `hjjCourseGame/${encodeURIComponent(courseUniqueKey)}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得黑嘉嘉檢定的對局
 * @param {String} verificationId 檢定id
 * @param {String} gameId 下棋id
 * @param {String} gameCreatedTime 下棋id
 * @returns {Promise} promise
 */
function getUnfinishedVerificationGame(
  verificationId,
  gameId,
  gameCreatedTime
) {
  const params = {
    gameId,
    gameCreatedTime,
    id: verificationId,
  };
  return callLambda({
    category: 'verification',
    apiName: 'unfinishedGame',
    params,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 更新目前下棋練習對局
 * @param {Number} createdTime 對局創建時間
 * @param {Object} move 落子位置
 * @returns {Promise} promise
 */
function updateGame({createdTime, move, time, byoYomiTime, byoYomiAmount}) {
  return callLambda({
    category: 'aiGame',
    apiName: 'gameKifu',
    data: {createdTime, move, time, byoYomiTime, byoYomiAmount},
    method: 'put',
    timeout: 8 * 1000,
  });
}

/**
 * ai下棋
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function nextMove(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'nextMove',
    data: {
      createdTime,
    },
    method: 'put',
  });
}

/**
 * 算輸贏數地
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function getJudgement(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'judgement',
    params: {
      createdTime,
    },
    method: 'get',
  });
}

/**
 * 確認算輸贏數地結果
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function confirmJudgement(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'judgement',
    data: {createdTime},
    method: 'put',
  });
}

/**
 * 取消算輸贏數地結果(重新計時)
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function cancelJudgement(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'judgement',
    data: {createdTime},
    method: 'delete',
  });
}

/**
 * 認輸、結束對局
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function resign(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'resignation',
    data: {
      createdTime,
    },
    method: 'put',
  }).then((result) => {
    return result;
  });
}

/**
 * 模擬考/檢定-下棋超時
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function checkTimeout({createdTime, time, byoYomiTime, byoYomiAmount}) {
  const data = {createdTime, time, byoYomiTime, byoYomiAmount};
  return callLambda({
    category: 'aiGame',
    apiName: 'checkTimeout',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得棋譜列表資料
 * @param {Number} amount 取得資料數量
 * @param {String} username 使用者名稱
 * @param {Array} gameMode 取得資料的類型
 * @param {String} pvpLastKey pvp 最後的 key
 * @param {String} pveLastKey pve 最後的 key
 * @returns {Promise} promise
 */
function getKifus(amount, username, gameMode, pvpLastKey, pveLastKey) {
  return callLambda({
    category: 'aiGame',
    apiName: 'getKifuList',
    method: 'post',
    data: {
      amount,
      username,
      gameMode,
      pvpLastKey,
      pveLastKey,
    },
  }).then((result) => {
    return result;
  });
}

/**
 * 取得單一棋譜資料
 * @param {Number} createdTime id
 * @param {Number} username username
 * @returns {Promise} promise
 */
const getKifu = _.memoize(
  function getKifu(createdTime, username) {
    return callLambda({
      category: 'aiGame',
      apiName: '',
      method: 'get',
      params: {
        createdTime,
        username,
      },
    }).then((result) => {
      return result.data;
    });
  },
  (createdTime) => createdTime
);

/**
 * 棋譜覆盤
 * @param {Number} data.id 此棋譜id
 * @param {Number} data.gameMode 此棋譜下期模式(ex.practice)
 * @param {Number} data.coursePlan 用戶當下使用的課程方案
 * @param {Number} data.username 棋譜用戶
 * @param {Number} data.needNotification 是否通知
 * @returns {Promise} promise
 */
const reviewGame = _.memoize(
  function reviewGame(data) {
    return callLambda({
      category: 'aiGame',
      apiName: 'reviewGame',
      method: 'post',
      data,
    }).then((result) => {
      return result.data;
    });
  },
  (data) => data.id
);

/**
 * 棋譜變化圖(限19路)
 * @param {Number} sgf 棋譜sgf
 * @param {Number} gameId 棋譜id
 * @param {Number} step 手數
 * @returns {Promise} promise
 */
function variation(sgf, gameId, step) {
  const data = {
    sgf,
    gameId,
    step,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'variation',
    method: 'post',
    data,
  }).then((result) => {
    return result.data;
  });
}

/**
 * 強制勝利
 * @param {Number} createdTime id
 * @returns {Promise} promise
 */
async function forceWin(createdTime) {
  const res = await callLambda({
    category: 'aiGame',
    apiName: 'forceWin',
    method: 'put',
    data: {
      createdTime,
    },
  });
  return res.data;
}

/**
 * photoInfluence
 * @param {String} picture picture
 * @param {Number} boardSize boardSize
 * @returns {Promise} promise
 */
async function photoInfluence({picture, boardSize}) {
  const res = await callLambda({
    category: 'aiGame',
    apiName: 'photoInfluence',
    method: 'post',
    data: {
      picture,
      boardSize,
    },
  });
  return res.data;
}

export default {
  createPracticeGame,
  createCourseGame,
  createVerificationGame,
  createLadderGame,
  createLadderEndgame,
  createLadderFuseki,
  getUnfinishedGame,
  getUnfinishedCourseGame,
  getUnfinishedVerificationGame,
  updateGame,
  nextMove,
  getJudgement,
  confirmJudgement,
  cancelJudgement,
  resign,
  getKifu,
  getKifus,
  reviewGame,
  getAiGameCourseConfigs,
  getAiGameUserRecord,
  supportGame,
  variation,
  checkTimeout,
  forceWin,
  photoInfluence,
};
