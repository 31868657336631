export const MODE = {
  BASE: 'base',
  ADVANCED: 'advanced',
  BOSS: 'boss',
};

export const MODE_LABEL = {
  BASE: '基礎關',
  ADVANCED: '進階關',
  BOSS: '魔王關',
};

export const ADD_COUNT = {
  BASE: 500,
  ADVANCED: 200,
  BOSS: 0,
  HOME: 1000,
};

const modeToLabelMapping = {};
const modeToAddCountMapping = {};
const labelToModeMapping = {};

for (const mode in MODE) {
  modeToLabelMapping[MODE[mode]] = MODE_LABEL[mode];
  modeToAddCountMapping[MODE[mode]] = ADD_COUNT[mode];
  labelToModeMapping[MODE_LABEL[mode]] = MODE[mode];
}

/**
 * ModeToLabel
 * @param {String} mode mode
 * @return {String} label
 */
export const modeToLabel = (mode) => {
  return modeToLabelMapping[mode];
};

/**
 * labelToMode
 * @param {String} label mode
 * @return {String} mode
 */
export const labelToMode = (label) => {
  return labelToModeMapping[label];
};

/**
 * modeToAddCount
 * @param {String} mode mode
 * @return {String} mode
 */
export const modeToAddCount = (mode) => {
  return modeToAddCountMapping[mode] || 0;
};

export default {
  MODE,
  MODE_LABEL,
  modeToLabel,
  labelToMode,
  modeToAddCount,
};
