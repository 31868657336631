export default [
  {
    path: '/ai-course/:aiCourseId/course-uniqueKey/:courseUniqueKey/day/:day',
    name: 'hjj-ai-course',
    component: () => import('@/views/AiCourse/index.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/ai-course/:aiCourseId',
    name: 'hjj-ai-course-preview',
    component: () => import('@/views/AiCourse/index.vue'),
    meta: {
      requireAuth: true,
    },
  },
];
