const state = {
  lobby: null,
};

const getters = {};

const actions = {};

const mutations = {
  setLobby(state, value) {
    state.lobby = value;
  },
  setLobbyDetails(state, data) {
    for (const [key, value] of Object.entries(data)) {
      state.lobby[key] = value;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
