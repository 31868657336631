import getDeviceInfo from '@/lib/base/getDeviceInfo';
import {TSUME_MODES} from '@/constant/chessboard';
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

export default [
  {
    path: '/personal',
    name: 'personal',
    component: () => import('@/views/Personal/PersonalRewrite.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      depth: 1,
    },
    beforeEnter: (to, _from, next) => {
      const {fullPath} = to;
      if (!isMobile && fullPath === '/personal') {
        // Redirect to the home page
        next('/');
      } else {
        // Proceed as normal
        next();
      }
    },
    children: [
      {
        path: '/web-certificate',
        name: 'web-certificate',
        component: () => import('@/views/Personal/Certificate.vue'),
        meta: {
          hasNavigation: true,
          requireAuth: true,
        },
      },
      {
        path: '/web-kifus',
        name: 'web-kifus',
        component: () => import('@/views/Kifu/Kifus.vue'),
        meta: {
          hasNavigation: true,
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: '/personal/edit',
    name: 'personal-edit',
    component: () => import('@/views/Personal/InfoEdit.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/edit/nickname',
    name: 'nickname',
    component: () => import('@/views/Personal/NickName.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/edit/avatar',
    name: 'avatar',
    component: () => import('@/views/Personal/Avatar.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/rank-intro',
    name: 'rank-intro',
    component: () => import('@/views/Personal/RankIntro.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/growth',
    name: 'growth',
    component: () => import('@/views/Personal/GrowthRecord.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/contact',
    name: 'contact',
    component: () => import('@/views/Personal/Contact.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/coupon',
    name: 'coupon',
    component: () => import('@/views/Personal/Coupon.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/course-serial-number',
    name: 'course-serial-number',
    component: () => import('@/views/Personal/CourseSerialNumber.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/course-status',
    name: 'course-status',
    component: () => import('@/views/Personal/CourseStatus.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/certificates',
    name: 'certificates',
    component: () => import('@/views/Personal/Certificates.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/certificate/:id',
    name: 'certificate',
    component: () => import('@/views/Personal/Certificate.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/kifus',
    name: 'kifus',
    component: () => import('@/views/Kifu/Kifus.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/live-course-record',
    name: 'live-course-record',
    component: () => import('@/views/Personal/LiveCourseRecords.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/kifu/:createdTime/:gameMode',
    name: 'kifu-preview',
    component: () => import('@/views/Kifu/KifuPreview.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      chessboardRoom: true,
    },
  },
  {
    path: '/personal/setting',
    name: 'setting',
    component: () => import('@/views/Personal/Setting.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 2,
    },
  },
  {
    path: '/personal/notification-setting',
    name: 'notification-setting',
    component: () => import('@/views/Personal/NotificationSetting.vue'),
    beforeEnter: (_to, _from, next) => {
      if (!isMobile) {
        next('/personal/setting'); // 如果不是手機，重定向到個人設定頁
      } else {
        next(); // 如果是手機，繼續訪問
      }
    },
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 2,
    },
  },
  {
    path: '/personal/setting/password',
    name: 'password',
    component: () => import('@/views/Personal/Password.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/academy',
    name: 'academy',
    component: () => import('@/components/Personal/Academy.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/bindEmail',
    name: 'bindEmail',
    component: () => import('@/views/Personal/BindEmail.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/bindAccount',
    name: 'bindAccount',
    component: () => import('@/views/Personal/BindThirdPartyAccount.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/bindStatus',
    name: 'bindStatus',
    component: () => import('@/views/Personal/BindStatus.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/bindMobile',
    name: 'bindMobile',
    component: () => import('@/views/Personal/BindMobile.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/removeAccount',
    name: 'removeAccount',
    component: () => import('@/views/Personal/RemoveAccount.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/personal/nfts',
    name: 'nfts',
    component: () => import('@/views/Personal/Nfts.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/nft/:uuid/transfer',
    name: 'nft-transfer',
    component: () => import('@/views/Personal/NftTransfer.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/nft/:uuid',
    name: 'nft',
    component: () => import('@/views/Personal/Nft.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/exchange-course-code',
    name: 'exchange-course-code',
    component: () => import('@/views/Personal/ExchangeCourseCode.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/exchange-course-confirm',
    name: 'exchange-course-confirm',
    component: () => import('@/views/Personal/ExchangeCourseConfirm.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/exchange-success',
    name: 'exchange-success',
    component: () => import('@/views/Personal/ExchangeCourseResult.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/vip-training-plan',
    name: 'vip-training-plan',
    component: () => import('@/views/Personal/VIPPlanIntro.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/rank-test',
    name: 'rank-test',
    component: () => import('@/views/Personal/RankTest.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/rank-test/tsume',
    name: 'rank-test-tsume',
    component: () => import('@/views/Room/TsumeRoom.vue'),
    props: {mode: TSUME_MODES.RANK_TEST},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'tsume',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/personal/rank-test/result',
    name: 'rank-test-result',
    component: () => import('@/views/Personal/RankTestResult.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
];
