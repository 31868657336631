import maintenanceService from '@/services/maintenance';

const state = {
  systemState: null,
};

const getters = {
  systemState(state) {
    return state.systemState;
  },
};

const actions = {
  async getSystemState({commit}) {
    try {
      const result = await maintenanceService.getSystemState();
      commit('setMaintenance', result);
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

const mutations = {
  setMaintenance(state, data) {
    state.systemState = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
