export default [
  {
    path: '/course/game/:courseUniqueKey',
    name: 'courseGame',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: 'courseGame'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/course/tsume/:courseUniqueKey/types/:type',
    name: 'courseTsume',
    component: () => import('@/views/Room/TsumeRoom.vue'),
    props: {mode: 'courseTsume'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'tsume',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
];
