import academyService from '@/services/academy.js';
import _ from 'lodash';

const state = {
  subAcademies: [],
  classes: [],
  studentsByClass: {},
  allStudents: [],
  managedUser: {},
};

const getters = {
  subAcademies(state) {
    return state.subAcademies;
  },
  classes(state) {
    return state.classes;
  },
  studentsByClass(state) {
    return state.studentsByClass;
  },
  allStudents(state) {
    return state.allStudents;
  },
  managedUser(state) {
    return state.managedUser;
  },
};

const actions = {
  async getClasses({commit}) {
    const res = await academyService.getClasses();
    const subAcademies = res.map((clazz) => {
      return {
        subAcademyId: clazz.subAcademyId,
        subAcademyName: clazz.subAcademyName,
      };
    });

    commit('setSubAcademies', _.uniqBy(subAcademies, 'subAcademyId'));
    commit('setClasses', res);
  },
  async getStudentsByClass({commit, state}) {
    const promise = state.classes.map((clazz) =>
      academyService.getStudentsByClass(clazz.classId)
    );

    const res = await Promise.all(promise);
    const data = {};
    for (let i = 0; i < res.length; i++) {
      const students = res[i];
      const processData = students.map((student) => {
        return {
          ...student,
          classId: state.classes[i].classId,
          subAcademyId: state.classes[i].subAcademyId,
        };
      });
      data[state.classes[i].classId] = processData;
    }
    const flattenStudents = _.flatten(Object.values(data));

    commit('setStudentsByClass', data);
    commit('setAllStudents', flattenStudents);
  },
};

const mutations = {
  setSubAcademies(state, value) {
    state.subAcademies = value;
  },
  setClasses(state, value) {
    state.classes = value;
  },
  setStudentsByClass(state, value) {
    state.studentsByClass = value;
  },
  setAllStudents(state, value) {
    state.allStudents = value;
  },
  setManagedUser(state, value) {
    state.managedUser = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
