import tsumeGame from '@/services/tsumeGame';
import {LADDER_TYPES} from '@/constant/ladder';
import {TSUME_MODES} from '@/constant/chessboard';

const state = {
  tsume: null,
  tsumeStatistics: {},
  verificationTsume: {},
  practiceTsumeList: null,
};

const getters = {
  tsumeStatistics(state) {
    return state.tsumeStatistics;
  },
  verificationTsume(state) {
    return state.verificationTsume;
  },
  practiceTsumeList(state) {
    return state.practiceTsumeList;
  },
  getCreatedTime(state) {
    return state.tsume?.[0].createdAt || state.tsume?.[0].createdTime;
  },
};

const actions = {
  getTsumeStatistics({commit}) {
    return tsumeGame.getTsumeStatistics().then((response) => {
      commit('setTsumeUserData', response);
    });
  },
  getPracticeTsumeList({commit}, currentPlanId) {
    return tsumeGame.getTsumePracticeList(currentPlanId).then((tsumeList) => {
      commit('setPracticeTsumeList', tsumeList);
    });
  },
  async createLadderTsume({dispatch, commit}, {stage}) {
    await tsumeGame.createLadderTsume(stage);
    dispatch('setLadderNextStage');
    commit('resetTsume');
  },
  async startRankTest({commit}) {
    await tsumeGame.startRankTest();
    commit('resetTsume');
  },
  async getUnfinishedTsume({commit, dispatch}, tsumeMode) {
    const result = await tsumeGame.getUnfinishedTsume(tsumeMode);
    commit('setTsume', result);
    if (result && tsumeMode === TSUME_MODES.LADDER)
      dispatch('setLadderNextStage');

    return result;
  },
  async quickTsume({commit, getters}) {
    const createdTime = getters.getCreatedTime;
    await tsumeGame.postLadderResult(createdTime, true);
    commit('resetTsume');
  },
  async postLadderResult({commit, getters}) {
    const createdTime = getters.getCreatedTime;
    await tsumeGame.postLadderResult(createdTime, true);
    commit('resetTsume');
  },
  setLadderNextStage({dispatch}) {
    return dispatch(
      'ladder/setNextStage',
      {type: LADDER_TYPES.TSUME},
      {root: true}
    );
  },
};

const mutations = {
  setTsume(state, tsume) {
    state.tsume = tsume;
  },
  resetTsume() {
    state.tsume = null;
  },
  setTsumeUserData(state, value) {
    state.tsumeStatistics = value;
  },
  setVerificationTsume(state, tsume) {
    state.verificationTsume = tsume;
  },
  setPracticeTsumeList(state, tsumeList) {
    state.practiceTsumeList = tsumeList;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
