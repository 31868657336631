import store from '@/store';

/**
 * 取得頭像圖檔，主要用於判斷是否為兒童版頭像
 * @param {String} fileName 圖檔名稱
 * @param {String} coursePlan 課程方案
 * @returns {Object} 圖檔路徑
 */
function getAvatarUrl(fileName, coursePlan) {
  const currentPlan = coursePlan || store.getters['course/currentPlan'];
  if (
    (currentPlan.name && currentPlan.name.includes('兒童')) ||
    (currentPlan.id && currentPlan.id.includes('kid'))
  ) {
    let imgSrc = '';
    try {
      imgSrc = require(`@/assets/img/personal/avatar/${fileName}-kid.png?srcset`);
    } catch {
      imgSrc = require(`@/assets/img/personal/avatar/${fileName}.png?srcset`);
    }
    return imgSrc;
  } else {
    return require(`@/assets/img/personal/avatar/${fileName}.png?srcset`);
  }
}

export default getAvatarUrl;
