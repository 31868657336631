module.exports = {
  'clientVersion': 'hjjtw',
  'stage': 'prod',
  'appName': '黑嘉嘉圍棋',
  'locale': 'zh-TW',
  'regionCode': 'TW',
  'infraRegion': 'GLOBAL',
  'manifest': {
    'name': '歡迎來到黑嘉嘉圍棋',
    'contactUrl': 'https://line.me/R/ti/p/%40753mueeo',
    'homePageUrl': 'https://www.heijiajia.com.tw',
    'discord': 'http://discord.gg/hjj',
    'accupass': 'https://pse.is/4klhe2',
    'store': 'https://www.heijiajiastore.com.tw/',
    'appUrl': 'https://app.heijiajia.com.tw/',
    'fandoraShop': 'https://go.fandora.co/omt6g',
    'igqe': 'https://tw.igqe.org/#/',
  },
  'googleAnalyticsId': 'G-Z7JQGVV34L',
  'shareJwt': true,
  'academyId': {
    'dev': '1908eadc-e8de-4c40-a24a-ce14e530acf2',
    'beta': '909ffbe8-8531-4383-9fd7-e86c38a4787c',
    'stg': 'ef8f1baa-fe3b-4c71-9bfc-680bf95234f5',
    'prod': 'ef8f1baa-fe3b-4c71-9bfc-680bf95234f5',
  },
  'aiGame': {
    'victoryCondition': { 'black': { '9': 44, '13': 88, '19': 185 }, 'white': { '9': 38, '13': 82, '19': 177 } },
  },
  'appConfigs': {
    'ios': { 'store': 'itms-apps://itunes.apple.com/tw/app/黑嘉嘉圍棋/id1581621835', 'build': 1 },
    'android': { 'store': 'market://details?id=com.heijiajia.tw', 'build': 1 },
  },
  'awsConfigs': {
    'cdnBucket': 'hei-jia-jia-tw.weiqi.study-cdn',
    'region': 'ap-northeast-2',
    'accessKeyId': 'AKIA2UQ4LSEADDIWABGT',
    'secretAccessKey': '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',
  },
  'loginMethods': [ 'phone', 'email', 'activity', 'google', 'apple' ],
  'avatarsNumber': 15,
  'liveCourseReplayDays': 365,
  'gtm': 'GTM-5CCZMRP',
  'fbPixel': '323315989434184',
  'googleClientId': {
    'web': '1004999832610-065b2hlvrtsagpv7fadbogfmb3fhfujd.apps.googleusercontent.com',
    'android': '1004999832610-e32mdfdn08lacnfkb9pfkebud60cm85i.apps.googleusercontent.com',
    'ios': '1004999832610-7nbski0r0qlotjfsok945a6dl61s6d0m.apps.googleusercontent.com',
  },
  'appleClientId': { 'web': 'com.heijiajia.tw-web', 'ios': 'com.heijiajia.tw' },
  'videoCdn': 'https://video-cdn.heijiajia.com.tw/',
  'appUrl': 'https://app.heijiajia.com.tw/',
  'recaptchaSiteKey': '6Le-fJwpAAAAANZPb1VLwhDEs-FlNaTMXz-wc3GD',
  'abVersion': 'a',
};