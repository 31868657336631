import Vue from 'vue';
import Vuex from 'vuex';
import env from './modules/env';
import user from './modules/user';
import aiGame from './modules/aiGame';
import tsumeGame from './modules/tsumeGame';
import verification from './modules/verification';
import course from './modules/course';
import aiCourse from './modules/aiCourse';
import pvp from './modules/pvp';
import liveCourse from './modules/liveCourse';
import notification from './modules/notification';
import maintenance from './modules/maintenance';
import teacherManagement from './modules/teacherManagement';
import aiTutor from './modules/aiTutor';
import multimediaClassroom from './modules/multimediaClassroom';
import ladder from './modules/ladder';
import weeklyTsume from './modules/weeklyTsume';
import popup from './modules/popup';
import product from './modules/product';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    env,
    user,
    aiGame,
    tsumeGame,
    verification,
    course,
    aiCourse,
    pvp,
    liveCourse,
    notification,
    maintenance,
    teacherManagement,
    aiTutor,
    multimediaClassroom,
    ladder,
    weeklyTsume,
    popup,
    product,
  },
});
