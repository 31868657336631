import userServices from '@/services/user';

const state = {
  popup: {},
};

const getters = {
  getIsDone: (state) => (popupId) => {
    return state.popup[popupId] === true;
  },
};

const actions = {
  async getPopupIsDone({commit}, idList) {
    if (!Array.isArray(idList)) idList = [idList];
    const result = await userServices.getPopup(idList);
    commit('setPopupIsDone', result);
    return result;
  },
  async setPopupIsDone({commit}, id) {
    userServices.setPopup({id, isDone: true});
    commit('setPopupIsDone', {[id]: true});
  },
};

const mutations = {
  setPopupIsDone(state, value) {
    state.popup = {...state.popup, ...value};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
