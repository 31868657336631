import i18n from '@/i18n/index';
const images = require.context(
  '@/assets/img/locales/',
  true,
  /\.(png|jpg|jpeg|svg)$/
);

/**
 * 若 player 姓名是 AI 名稱，則翻譯成對應語系
 * @param {String} name 姓名
 * @return {String}
 * todo: 優化空間，根據不同應用場景的 userData 資料判斷身份若為 AI 才翻譯
 */
export const translateNameIfIsAI = (name) => {
  let result = name;
  if (['黑小嘉', '白小嘉'].includes(name)) {
    result = i18n.t(name);
  }
  return result;
};

/**
 * 根據 i18n locale 設定圖片來源
 * @param {*} filepath 圖片路徑
 * @param {*} locale 指定 locale
 * @return {String} 圖片來源
 */
export const getI18nImage = (filepath, locale) => {
  const localePath = locale ? locale : i18n.locale;
  if (filepath.indexOf('https://') === 0) {
    return filepath.replace('{locale}', localePath);
  }
  let result;
  const localizedPath = `./${localePath}${filepath.split('{locale}').pop()}`;

  try {
    result = images(localizedPath);
  } catch (err) {
    console.error('images', err);
    // const defaultPath = `./zh-TW${filepath.split('{locale}').pop()}`;
    // result = images(defaultPath);
    result = '';
  }
  return result;
};

/**
 * 根據 i18n locale 設定日期顯示文字
 * @param {*} dateString 日期字串
 * @return {String} 日期顯示文字
 */
export const formatI18NDate = (dateString) => {
  let result = dateString;
  if (i18n.locale !== 'zh-TW') {
    result = result.replace('年', '/').replace('月', '/').replace('日', '');
  }

  if (result.endsWith('/')) {
    result = result.slice(0, -1);
  }

  return result;
};

export const translateByLocale = {
  install(Vue) {
    Vue.prototype.$translateByLocale = function (key, locale, replacements) {
      const i18nInstance = this.$i18n;
      return replacements
        ? i18nInstance.t(key, locale, replacements)
        : i18nInstance.t(key, locale);
    };
  },
};
