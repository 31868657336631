<template>
  <action-sheet
    v-if="isChangeLocaleModalShow && $device.isMobile"
    :title="$t('語言')"
    :touch-move-to-close="true"
    show-close-button
    sheet-height="200"
    @close="closeChangeLocaleModal"
  >
    <ul class="list-group">
      <li
        v-for="localeItem in localeList"
        :key="localeItem.code"
        class="locale-item list-group-item-action d-flex align-items-center px-3"
        @click="handleOnChangeLocale(localeItem.code)"
      >
        <span>{{ localeItem.name }}</span>
        <i v-if="localeItem.code === locale" class="icon-Check"></i>
      </li>
    </ul>
  </action-sheet>
</template>

<script>
import ActionSheet from '@/components/Base/ActionSheet.vue';
import {mapActions, mapState} from 'vuex';

export default {
  components: {
    ActionSheet,
  },
  computed: {
    ...mapState('env', ['locale', 'isChangeLocaleModalShow', 'localeList']),
  },
  methods: {
    ...mapActions('env', ['closeChangeLocaleModal', 'changeLocale']),
    handleOnChangeLocale(locale) {
      this.changeLocale(locale);
      this.closeChangeLocaleModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.locale-item {
  display: flex;
  justify-content: space-between;
  .icon-Check {
    font-size: 32px;
    color: #ff855e;
  }
}
</style>
