export default [
  {
    path: '/multimedia-classroom',
    name: 'multimedia-classroom',
    component: () => import('@/views/MultimediaClassroom/index.vue'),
    meta: {
      requireDesktop: true,
      requireAuth: true,
      requireTeacher: true,
    },
  },
  {
    path: '/multimedia-classroom/edit/:id',
    name: 'multimedia-classroom-edit',
    component: () => import('@/views/MultimediaClassroom/index.vue'),
    props: {isEdit: true},
    meta: {
      requireDesktop: true,
      requireAuth: true,
      requireTeacher: true,
    },
  },
  {
    path: '/multimedia-classroom/show/:id',
    name: 'multimedia-classroom-show',
    component: () => import('@/views/MultimediaClassroom/index.vue'),
    props: {isReadOnly: true},
    meta: {
      requireDesktop: true,
      requireAuth: true,
    },
  },
];
