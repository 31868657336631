import {callLambda} from '@/lib/api/lambda';

/**
 * 取得商品列表
 * @returns {Promise} promise
 */
export async function getProducts() {
  const res = await callLambda({
    category: 'ec',
    method: 'get',
    apiName: 'products',
  });
  return res.data;
}

/**
 * 取得商品
 * @param {String} id id
 * @param {String} code 優惠碼
 * @param {String} currency 幣值
 * @returns {Promise} promise
 */
export async function getProduct(id, {code, currency}) {
  const res = await callLambda({
    category: 'ec',
    method: 'get',
    apiName: `products/${id}`,
    params: {
      code,
      currency,
    },
  });
  return res.data;
}

/**
 * 檢查是否可以建立訂單
 * @param {Object} data request payload
 * @returns {Promise} promise
 */
export async function checkOrderViability(data) {
  const res = await callLambda({
    category: 'ec',
    apiName: 'checkOrderViability',
    method: 'post',
    data,
  });
  return res.data;
}

/**
 * 創建訂單
 * @param {Object} data request payload
 * @returns {Promise} promise
 */
export async function createOrder(data) {
  const res = await callLambda({
    category: 'ec',
    apiName: 'order',
    method: 'post',
    data,
  });
  return res.data;
}

/**
 * 檢查訂單狀態
 * @returns {Promise} promise
 */
export async function checkOrderState({id, platform, detail = false}) {
  const res = await callLambda({
    category: 'ec',
    apiName: 'checkOrderState',
    method: 'post',
    data: {
      id,
      platform,
      detail,
    },
  });
  return res.data;
}

export default {
  getProducts,
  getProduct,
  createOrder,
  checkOrderState,
  checkOrderViability,
};
