import {stage} from '@/constant/env.js';
import getDeviceInfo from '@/lib/base/getDeviceInfo';
import {removeLocalData, saveLocalData} from '@/lib/base/localData';
import Message from '@/lib/base/message';
import store from '@/store/index.js';
import i18n from '@/i18n/index.js';
import userService from '@/services/user';
import regionCodes from '@/config/regionCodes';
import url from '@/lib/api/url';

const state = {
  cdn: url.cdn,
  viewMode: '',
  device: '',
  orientation: '',
  width: 0,
  height: 0,
  platform: 'web',
  isLoading: false,
  isReviewGameLoading: false,
  isSwitchCourseSheetShow: false,
  isSidebarShow: false,
  isModalPostQuestionShow: false,
  isShowVipModal: false,
  hasVerificationNotification: false,
  isCheatMode: false,
  locale: 'zh-TW',
  localeAlias: '繁中',
  localeName: '繁體中文',
  isChangeLocaleModalShow: false,
  localeList: [],
  localePriceList: [
    {
      code: 'zh-TW',
      currency: 'NTD',
    },
    {
      code: 'zh-CN',
      currency: 'USD',
    },
    {
      code: 'ja-JP',
      currency: 'USD',
    },
    {
      code: 'en-US',
      currency: 'USD',
    },
  ],
};
const isProd = stage === 'prod';
if (isProd) {
  state.localeList = [
    {
      code: 'zh-TW',
      name: '繁體中文',
    },
    {
      code: 'zh-CN',
      name: '简体中文',
    },
    // {
    //   code: 'ja-JP',
    //   name: '日本語',
    // },
    {
      code: 'en-US',
      name: 'English',
    },
  ];
} else {
  state.localeList = [
    {
      code: 'zh-TW',
      name: '繁體中文',
    },
    {
      code: 'zh-CN',
      name: '简体中文',
    },
    // {
    //   code: 'ja-JP',
    //   name: '日本語',
    // },
    {
      code: 'en-US',
      name: 'English',
    },
  ];
}

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  isReviewGameLoading(state) {
    return state.isReviewGameLoading;
  },
  isShowVipModal(state) {
    return state.isShowVipModal;
  },
  viewMode(state) {
    return state.viewMode;
  },
  device(state) {
    return state.device;
  },
  permission() {
    return store.getters['course/currentPlan']?.contents || {};
  },
  regionCodes() {
    const local = state.locale;
    return regionCodes.sort((a, b) => {
      return (a.sort[local] || 9999) - (b.sort[local] || 9999);
    });
  },
  isIos(state) {
    return state.platform === 'ios';
  },
  isAndroid(state) {
    return state.platform === 'android';
  },
  isChinese(state) {
    console.log(state.locale, state.locale.includes('zh'));
    return state.locale.includes('zh');
  },
};

const actions = {
  openModalPostQuestion({commit}) {
    commit('openModalPostQuestion');
  },
  closeModalPostQuestion({commit}) {
    commit('closeModalPostQuestion');
  },
  openChangeLocaleModal({commit}) {
    commit('openChangeLocaleModal');
  },
  closeChangeLocaleModal({commit}) {
    commit('closeChangeLocaleModal');
  },
  async changeLocale({commit}, locale) {
    if (store.getters['user/isLogin']) {
      await userService.updateUser({language: locale});
    }

    removeLocalData('videoTextTrack'); // 影片字幕語言
    removeLocalData('videoAudioTrack'); // 影片語音語言
    commit('changeLocale', locale);
  },
  async initLocale({commit}, locale) {
    if (store.getters['user/isLogin']) {
      await userService.updateUser({language: locale});
    }
    commit('changeLocale', locale);
  },
  init({commit}) {
    const data = getDeviceInfo();
    commit('setEnv', data);
  },
  changePlanInit({rootGetters}, plan) {
    const currentPlan = rootGetters['course/currentPlan'];
    if (currentPlan.id === plan.id) {
      return;
    }

    saveLocalData('plan-info', JSON.stringify(plan));
    if (plan.id === 'kid2week') {
      store.dispatch('liveCourse/getKid2WeekLiveCourses');
    } else {
      store.commit('liveCourse/setDailyLiveList', []);
    }
    store.commit('course/setCurrentPlan', plan);
    store.dispatch('verification/getVerification', plan.id);
    store.dispatch('verification/getVerificationList', plan.id);
    store.dispatch('tsumeGame/getPracticeTsumeList', plan.id);
    store.dispatch('course/getCourses', plan.id);
    Message.success(i18n.t('已切換課程'), 3000);
  },
};

const mutations = {
  openModalPostQuestion(state) {
    state.isModalPostQuestionShow = true;
  },
  closeModalPostQuestion(state) {
    state.isModalPostQuestionShow = false;
  },
  openChangeLocaleModal(state) {
    state.isChangeLocaleModalShow = true;
  },
  closeChangeLocaleModal(state) {
    state.isChangeLocaleModalShow = false;
  },
  changeLocale(state, locale) {
    state.locale = locale;
    state.localeName = state.localeList.find(
      (item) => item.code === locale
    ).name;
    saveLocalData('locale', locale);
  },
  setEnv(state, data) {
    const {viewMode, device, orientation, height, width} = data;
    state.viewMode = viewMode;
    state.device = device;
    state.orientation = orientation;
    state.width = width;
    state.height = height;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setIsReviewGameLoading(state, value) {
    state.isReviewGameLoading = value;
  },
  setIsSwitchCourseSheetShow(state, value) {
    state.isSwitchCourseSheetShow = value;
  },
  setIsSidebarShow(state, value) {
    state.isSidebarShow = value;
  },
  setHasVerificationNotification(state, value) {
    state.hasVerificationNotification = value;
  },
  toggleCheatMode(state) {
    state.isCheatMode = !state.isCheatMode;
  },
  setIsShowVipModal(state, value) {
    state.isShowVipModal = value;
  },
  setPlatform(state, value) {
    state.platform = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
