import {callLambda} from '@/lib/api/lambda';

/**
 * 取得使用者下棋資訊
 * @returns {Promise} promise
 */
export function getTsumeStatistics() {
  return callLambda({
    category: 'tsumeGame',
    apiName: 'statistics',
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得做題練習列表
 * @param {String} planId 課程方案 ID
 * @returns {Promise} promise
 */
export function getTsumePracticeList(planId) {
  return callLambda({
    category: 'tsumeGame',
    apiName: `specialTraining?coursePlan=${planId}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/** 隨機取得做題練習題目
 * @param {String} rank 難度
 * @param {String} type 分類
 * @param {String} count 題目的數量(沒輸入的話預設 1 題)
 * @returns {Promise} promise
 */
export function getRandomTsumePractice(rank, type) {
  const params = {
    rank,
    type,
    count: 10,
  };
  return callLambda({
    category: 'tsumeGame',
    apiName: 'randomSpecialTraining',
    params,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 儲存做題練習的紀錄
 * @param {String} tsumeId 題目id
 * @param {String} type 類型
 * @param {String} rank 難度
 * @param {String} userAnswer 使用者答案
 * @param {String} isCorrect 對錯
 * @param {String} coursePlan 方案
 * @param {String} isEnd 結束
 * @returns {Promise} promise
 */
export function updateTsumePractice({
  tsumeId,
  type,
  rank,
  userAnswer,
  isCorrect,
  coursePlan,
  isEnd,
}) {
  const data = {
    tsumeId,
    type,
    rank,
    userAnswer,
    isCorrect,
    coursePlan,
    isEnd,
  };
  return callLambda({
    category: 'tsumeGame',
    apiName: 'specialTraining',
    method: 'put',
    data,
  });
}

/**
 * 創建黑嘉嘉課程的詰棋
 * @param {String} courseUniqueKey 課程唯一鍵
 * @returns {Promise} promise
 */
export function createCourseTsume(courseUniqueKey) {
  const data = {
    courseUniqueKey,
  };
  return callLambda({
    category: 'tsumeGame',
    apiName: 'hjjCourseTsume',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得黑嘉嘉課程詰棋紀錄
 * @param {String} courseUniqueKey 課程唯一鍵
 * @returns {Promise} promise
 */
export function getUnfinishedCourseTsume(courseUniqueKey) {
  return callLambda({
    category: 'tsumeGame',
    apiName: `hjjCourseTsume/${encodeURIComponent(courseUniqueKey)}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得未完成的詰棋
 * @param {Number} level level
 * @returns {Promise} promise
 */
export function createLadderTsume(level) {
  return callLambda({
    category: 'tsumeGame',
    apiName: 'ladderTsume',
    method: 'post',
    data: {level},
  }).then((result) => {
    return result.data;
  });
}

/**
 * postLadderResult
 * @param {Number} createdTime createdTime
 * @param {Boolean} isQuit isQuit
 * @returns {Promise} promise
 */
export function postLadderResult(createdTime, isQuit = false) {
  return callLambda({
    category: 'tsumeGame',
    apiName: 'ladderResult',
    method: 'post',
    data: {createdTime, isQuit},
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得未完成的詰棋
 * @param {String} tsumeMode tsumeMode
 * @returns {Promise} promise
 */
export function getUnfinishedTsume(tsumeMode) {
  return callLambda({
    category: 'tsumeGame',
    apiName: 'unfinishedTsume',
    method: 'get',
    params: {
      tsumeMode,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 結算黑嘉嘉課程詰棋結果
 * @param {String} courseUniqueKey 課程唯一鍵
 * @returns {Promise} promise
 */
export function getCourseTsumeResult(courseUniqueKey) {
  return callLambda({
    category: 'tsumeGame',
    apiName: 'hjjCourseResult',
    params: {
      courseUniqueKey,
    },
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 儲存詰棋課程的紀錄
 * @param {String} tsumeId 題目id
 * @param {String} tsumeMode 詰棋模式
 * @param {String} userAnswer 使用者答案
 * @param {String} isCorrect 對錯
 * @param {String} courseUniqueKey 課程sk
 * @param {String} isEnd 對錯
 * @returns {Promise} promise
 */
export function updateTsumeRecord({
  tsumeId,
  tsumeMode,
  userAnswer,
  isCorrect,
  courseUniqueKey,
  isEnd,
}) {
  const data = {
    id: tsumeId,
    tsumeMode,
    userAnswer,
    isCorrect,
    courseUniqueKey,
    isEnd,
  };
  return callLambda({
    category: 'tsumeGame',
    apiName: 'tsumeRecord',
    method: 'put',
    data,
  });
}

/**
 * @param {string} mode
 * getWeeklyTsume
 */
export async function getWeeklyTsume({mode}) {
  const res = await callLambda({
    category: 'tsumeGame',
    method: 'get',
    apiName: 'weeklyTsume',
    params: {mode},
  });
  return res.data;
}

/**
 * @param {string} mode
 * getWeeklyTsumeCount
 */
export async function getWeeklyTsumeCount({mode}) {
  const res = await callLambda({
    category: 'tsumeGame',
    method: 'get',
    apiName: 'weeklyTsume/count',
    params: {mode},
  });
  return res.data;
}

/**
 * @param {string} mode node
 * @param {boolean} isWin isWin
 * addWeeklyTsumeCount
 */
export async function addWeeklyTsumeCount({mode, isWin}) {
  const res = await callLambda({
    category: 'tsumeGame',
    method: 'post',
    apiName: 'weeklyTsume/count',
    data: {mode, isWin},
  });
  return res.data;
}

/**
 * getWeeklyTsumeTimeRange
 */
export async function getWeeklyTsumeTimeRange() {
  const res = await callLambda({
    category: 'tsumeGame',
    method: 'get',
    apiName: 'weeklyTsume/timeRange',
  });
  return res.data;
}

/**
 * getWeeklyTsumeTimeRange
 */
export async function startRankTest() {
  const res = await callLambda({
    category: 'tsumeGame',
    method: 'post',
    apiName: 'rankTest/start',
  });
  return res.data;
}

export default {
  getTsumePracticeList,
  getRandomTsumePractice,
  updateTsumePractice,
  createCourseTsume,
  getUnfinishedCourseTsume,
  createLadderTsume,
  postLadderResult,
  getUnfinishedTsume,
  getCourseTsumeResult,
  updateTsumeRecord,
  getTsumeStatistics,
  getWeeklyTsume,
  getWeeklyTsumeCount,
  addWeeklyTsumeCount,
  getWeeklyTsumeTimeRange,
  startRankTest,
};
