<template>
  <div class="modal-result-wrapper">
    <modal-wrapper :is-modal-wrapper-show="true">
      <div
        class="modal-result position-relative d-flex flex-column justify-content-center align-items-center"
        :class="{'is-hidden-result': hiddenResultContent}"
        @click.stop
      >
        <div class="position-relative" :class="{'mb-3': hiddenResultContent}">
          <b-img
            class="img-result mt-4"
            :srcset="resultImages"
            alt="result"
          ></b-img>
          <span
            class="result-state text-white font-weight-bold position-absolute"
          >
            {{ resultState }}
          </span>
        </div>
        <p
          v-if="!hiddenResultContent"
          class="result-content mt-3 mb-2 text-primary font-weight-bold text-center"
        >
          {{ winningWayText ? winningWayText : resultContent | winningWayText }}
        </p>
        <p
          v-if="eloText"
          class="text-18 mb-2 text-font-grayscale-1 font-weight-bold text-center"
        >
          {{ eloText }}
        </p>
        <div
          v-if="
            mode === TSUME_MODES.QUICK_TEST || mode === TSUME_MODES.WEEKLY_TSUME
          "
          class="wrap-btn"
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="primary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="return"
              @click="onItemClick('return')"
            >
              {{ $t('查看結果') }}
            </b-button>
          </div>
        </div>
        <div v-else-if="mode === TSUME_MODES.AI_TUTOR" class="wrap-btn">
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="secondary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="mr-3 return"
              @click="onItemClick('return')"
            >
              {{ $t('關閉') }}
            </b-button>
          </div>
        </div>
        <div v-else-if="mode === GAME_MODES.PRACTICE_GAME" class="wrap-btn">
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="secondary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="mr-3 return"
              @click="onItemClick('return')"
            >
              {{ $t('返回') }}
            </b-button>
            <b-button
              variant="infoAndLink"
              :size="$device.isMobile ? 'md' : 'lg'"
              :disabled="!hasReviewed"
              class="ml-3 text-white"
              @click="onItemClick('reviewGame')"
            >
              {{ $t('覆盤') }}
            </b-button>
          </div>
          <p v-if="!hasReviewed" class="notice-reviewed mt-2 mb-0 text-center">
            {{ $t('此局手數未達{reviewLimit}無法進行覆盤', {reviewLimit}) }}
          </p>
        </div>
        <div v-else-if="mode === GAME_MODES.PVP" class="wrap-btn">
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="secondary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="return"
              @click="onItemClick('return')"
            >
              {{ $t('返回') }}
            </b-button>
            <b-button
              v-if="!isTournament"
              variant="primary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="ml-3"
              @click="onItemClick('rematch')"
            >
              {{ $t('邀請再一局') }}
            </b-button>
            <b-button
              v-if="!isTournament"
              variant="infoAndLink"
              :size="$device.isMobile ? 'md' : 'lg'"
              :disabled="!hasReviewed"
              class="ml-3 text-white"
              @click="onItemClick('reviewGame')"
            >
              {{ $t('覆盤') }}
            </b-button>
          </div>
          <p
            v-if="!hasReviewed && !isTournament"
            class="notice-reviewed mt-2 mb-0 text-center"
          >
            {{ $t('此局手數未達{reviewLimit}無法進行覆盤', {reviewLimit}) }}
          </p>
        </div>
        <div v-else-if="isCourse" class="wrap-btn">
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="secondary"
              :size="$device.isMobile ? 'md' : 'lg'"
              @click="onItemClick('return')"
            >
              {{ $t('返回') }}
            </b-button>
            <b-button
              v-if="mode === TSUME_MODES.COURSE_TSUME"
              variant="primary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="ml-3"
              @click="onItemClick('answer')"
            >
              {{ $t('看解答') }}
            </b-button>
            <b-button
              v-if="mode === GAME_MODES.COURSE_GAME"
              variant="infoAndLink"
              :size="$device.isMobile ? 'md' : 'lg'"
              :disabled="!hasReviewed"
              class="ml-3 text-white"
              @click="onItemClick('reviewGame')"
            >
              {{ $t('覆盤') }}
            </b-button>
          </div>
          <p
            v-if="!hasReviewed && mode === 'courseGame'"
            class="notice-reviewed mt-2 mb-0 text-center"
          >
            {{ $t('此局手數未達{reviewLimit}無法進行覆盤', {reviewLimit}) }}
          </p>
        </div>
        <div
          v-else-if="mode === TSUME_MODES.PRACTICE_TSUME"
          class="wrap-btn d-flex justify-content-center align-items-center"
        >
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'md' : 'lg'"
            class="mr-3"
            @click="onItemClick('return')"
          >
            {{ $t('返回') }}
          </b-button>
          <b-button
            variant="primary"
            :size="$device.isMobile ? 'md' : 'lg'"
            class="mr-3"
            @click="onItemClick('answer')"
          >
            {{ $t('看解答') }}
          </b-button>
        </div>
        <div
          v-else-if="isVerification"
          class="wrap-btn d-flex justify-content-center align-items-center"
        >
          <b-button
            variant="primary"
            :size="$device.isMobile ? 'md' : 'lg'"
            @click="onItemClick('confirm')"
          >
            {{ $t('確定') }}
          </b-button>
        </div>
        <div
          v-else-if="isLadder"
          class="wrap-btn d-flex justify-content-center align-items-center"
        >
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'md' : 'lg'"
            class="mr-3 return"
            @click="onItemClick('return')"
          >
            {{ $t('返回') }}
          </b-button>
          <b-button
            v-if="isSuccess && hasNext"
            variant="primary"
            :size="$device.isMobile ? 'md' : 'lg'"
            @click="onItemClick('next')"
          >
            {{ $t('下一關') }}
          </b-button>
          <b-button
            v-else-if="!isSuccess"
            variant="primary"
            :size="$device.isMobile ? 'md' : 'lg'"
            @click="onItemClick('restart')"
          >
            {{ $t('再次挑戰') }}
          </b-button>
          <b-button
            v-if="hasReviewButton"
            variant="infoAndLink"
            :size="$device.isMobile ? 'md' : 'lg'"
            :disabled="!hasReviewed"
            class="ml-3 text-white"
            @click="onItemClick('reviewGame')"
          >
            {{ $t('覆盤') }}
          </b-button>
        </div>
        <div
          v-else
          class="wrap-btn d-flex justify-content-center align-items-center"
        >
          <b-button
            variant="primary"
            :size="$device.isMobile ? 'md' : 'lg'"
            @click="onItemClick('confirm')"
          >
            {{ $t('確定') }}
          </b-button>
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
import {GAME_MODES, TSUME_MODES} from '@/constant/chessboard';

export default {
  components: {
    ModalWrapper,
  },
  props: {
    mode: {
      type: String,
      default: '',
      validator: (val) => {
        return [
          ...Object.values(GAME_MODES),
          ...Object.values(TSUME_MODES),
          '',
        ].includes(val);
        // 下棋練習、做題練習、檢定、課程練習、課程做題、人人對弈
      },
    },
    hiddenResultContent: {
      type: Boolean,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isOverTime: {
      type: Boolean,
      default: false,
    },
    isTournament: {
      type: Boolean,
      default: false,
    },
    resultContent: {
      type: String,
      default: '',
    },
    winningWayText: {
      type: String,
      default: null,
    },
    hasReviewed: {
      type: Boolean,
      default: false,
    },
    reviewLimit: {
      type: Number,
      default: 20,
    },
    eloText: {
      type: String,
      default: null,
    },
    hasNext: {
      type: Boolean,
      default: true,
    },
    isGame: {
      type: Boolean,
      default: false,
    },
    isTsume: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalShow: false,
      TSUME_MODES,
      GAME_MODES,
    };
  },
  computed: {
    isVerification() {
      return [
        TSUME_MODES.VERIFICATION_TSUME,
        GAME_MODES.VERIFICATION_GAME,
      ].includes(this.mode);
    },
    isCourse() {
      return [TSUME_MODES.COURSE_TSUME, GAME_MODES.COURSE_GAME].includes(
        this.mode
      );
    },
    isLadder() {
      return [
        GAME_MODES.LADDER,
        GAME_MODES.LADDER_ENDGAME,
        GAME_MODES.LADDER_FUSEKI,
        TSUME_MODES.LADDER,
      ].includes(this.mode);
    },
    resultImages() {
      const completeMode = [
        TSUME_MODES.QUICK_TEST,
        TSUME_MODES.WEEKLY_TSUME,
        TSUME_MODES.VERIFICATION_TSUME,
        TSUME_MODES.RANK_TEST,
        GAME_MODES.VERIFICATION_GAME,
      ];
      if (completeMode.includes(this.mode)) {
        return require('@/assets/img/modal/complete.png?srcset');
      } else if (this.isSuccess) {
        return require('@/assets/img/modal/awesome.png?srcset');
      } else {
        return require('@/assets/img/modal/frustration.png?srcset');
      }
    },
    resultState() {
      let result = '';
      switch (this.mode) {
        case GAME_MODES.LADDER:
        case GAME_MODES.LADDER_ENDGAME:
        case GAME_MODES.LADDER_FUSEKI:
        case TSUME_MODES.LADDER:
          result = this.isSuccess ? this.$t('通過') : this.$t('未通過');
          break;

        case TSUME_MODES.VERIFICATION_TSUME:
        case GAME_MODES.VERIFICATION_GAME:
          result = this.isOverTime ? this.$t('作答結束') : this.$t('完成');
          break;
        case GAME_MODES.PRACTICE_GAME:
        case GAME_MODES.COURSE_GAME:
        case GAME_MODES.PVP:
          result = this.isSuccess ? this.$t('勝利') : this.$t('失敗');
          break;
        case TSUME_MODES.AI_TUTOR:
          result = this.$t('達到目標');
          break;
        case TSUME_MODES.WEEKLY_TSUME:
        case TSUME_MODES.QUICK_TEST:
        case TSUME_MODES.RANK_TEST:
          result = this.$t('完成');
          break;
        default:
          result = this.isSuccess ? this.$t('很熟練喔') : this.$t('要再加強');
          break;
      }
      return result;
    },
    hasReviewButton() {
      const list = [
        GAME_MODES.PVP,
        GAME_MODES.PRACTICE_GAME,
        GAME_MODES.COURSE_GAME,
        GAME_MODES.LADDER,
      ];
      return this.isGame && list.includes(this.mode);
    },
  },
  methods: {
    onItemClick(value) {
      this.$emit('item-click', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-result {
  background: $bgsection;
  border-radius: 30px;
  width: 300px;
  min-height: 374px;
  &.is-hidden-result {
    min-height: 332px;
  }
  @media screen and (min-width: 768px) {
    width: 390px;
  }
  .result-content {
    font-size: 20px;
    line-height: 34px;
    @media screen and (min-width: 768px) {
      font-size: 28px;
      line-height: 44px;
    }
  }
  .wrap-btn {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background: white;
    padding: 16px 0;
    .btn-infoAndLink:disabled,
    .btn-infoAndLink.disabled {
      background: #ced4da;
      border-color: #ced4da;
    }
    .notice-reviewed {
      font-size: 12px;
      color: $font-grayscale-1;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .img-result {
    width: 230px;
    height: 230px;
    @media screen and (min-width: 768px) {
      width: 320px;
      height: 320px;
    }
  }
  .result-state {
    bottom: 4.5%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 28px;
    @media screen and (min-width: 768px) {
      font-size: 38px;
    }
  }
}
</style>
