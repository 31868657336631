/* eslint-disable require-jsdoc */
import {callLambda} from '@/lib/api/lambda';

/**
 * 取得課件內容
 * @param {String} courseId 課件id
 * @returns {Promise} promise
 */
export function getCourseDetail(courseId) {
  return callLambda({
    category: 'multimediaClassroom',
    method: 'GET',
    apiName: `course/${courseId}`,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得課件分類
 * @param {Boolean} isPublic 是否公開
 * @returns {Promise} promise
 */
export function getTypes(isPublic) {
  return callLambda({
    category: 'multimediaClassroom',
    method: 'GET',
    apiName: 'type',
    params: {isPublic},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 建立課件分類
 * @param {Boolean} isPublic 是否公開
 * @param {String} name typeName
 * @returns {Promise} promise
 */
export function createType({name, isPublic}) {
  return callLambda({
    category: 'multimediaClassroom',
    method: 'POST',
    apiName: 'type',
    data: {isPublic, typeName: name},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 更新課件分類
 * @param {String} id typeId
 * @param {String} name typeName
 * @returns {Promise} promise
 */
export function updateType({id, name}) {
  return callLambda({
    category: 'multimediaClassroom',
    method: 'PUT',
    apiName: `type/${id}`,
    data: {typeName: name},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 更新課件分類
 * @param {String} id typeId
 * @returns {Promise} promise
 */
export function deleteType(id) {
  return callLambda({
    category: 'multimediaClassroom',
    method: 'DELETE',
    apiName: `type/${id}`,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得課件
 * @param {Boolean} isPublic 是否公開
 * @param {String} typeId typeId
 * @returns {Promise} promise
 */
export function getCourses({isPublic, typeId}) {
  const limit = 300;
  return callLambda({
    category: 'multimediaClassroom',
    method: 'GET',
    apiName: 'course',
    params: {isPublic, limit, typeId},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 新增課件
 * @param {Boolean} isPublic 是否公開
 * @param {String} typeId typeId
 * @param {String} courseName courseName
 * @param {Any} courseContent courseContent
 * @returns {Promise} promise
 */
export function createCourse({isPublic, typeId, courseName, courseContent}) {
  return callLambda({
    category: 'multimediaClassroom',
    method: 'POST',
    apiName: 'course',
    data: {isPublic, typeId, courseName, courseContent},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 更新課件
 * @param {Boolean} courseId courseId
 * @param {String} typeId typeId
 * @param {String} courseName courseName
 * @param {Any} courseContent courseContent
 * @returns {Promise} promise
 */
export function updateCourse({typeId, courseId, courseName, courseContent}) {
  return callLambda({
    category: 'multimediaClassroom',
    method: 'PUT',
    apiName: `course/${courseId}`,
    data: {typeId, courseName, courseContent},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 刪除課件
 * @param {String} courseId courseId
 * @returns {Promise} promise
 */
export function deleteCourse(courseId) {
  return callLambda({
    category: 'multimediaClassroom',
    method: 'DELETE',
    apiName: `course/${courseId}`,
  }).then((res) => {
    return res.data;
  });
}

export default {
  getCourseDetail,
  createType,
  getTypes,
  updateType,
  deleteType,
  createCourse,
  getCourses,
  updateCourse,
  deleteCourse,
};
