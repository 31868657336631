export const LADDER_TYPES = {
  GAME: 'game',
  TSUME: 'tsume',
  FUSEKI: 'fuseki',
  ENDGAME: 'endgame',
};

export const LADDER_TYPE_LABELS = {
  [LADDER_TYPES.GAME]: '下棋',
  [LADDER_TYPES.TSUME]: '做題',
  [LADDER_TYPES.FUSEKI]: '佈局',
  [LADDER_TYPES.ENDGAME]: '官子',
};
