// hjjtw prod
const url = {
  lambda: {
    /* 專案後端api */
    academy:
      'https://zpeq2tkj1a.execute-api.ap-northeast-2.amazonaws.com/prod/academy/',
    subAcademy:
      'https://zpeq2tkj1a.execute-api.ap-northeast-2.amazonaws.com/prod/subacademy/',
    academy2:
      'https://4b4ohaov8d.execute-api.ap-northeast-2.amazonaws.com/prod/academy2/',
    aiCourse:
      'https://6thir7o56e.execute-api.ap-northeast-2.amazonaws.com/prod/aiCourse/',
    aiGame:
      'https://445t1jkfve.execute-api.ap-northeast-2.amazonaws.com/prod/aiGame/',
    announcement:
      'https://f4ahjhh1mc.execute-api.ap-northeast-2.amazonaws.com/prod/announcement/',
    notification:
      'https://qzcmpdca79.execute-api.ap-northeast-2.amazonaws.com/prod/notification/',
    tsumeGame:
      'https://b8m80zxzg3.execute-api.ap-northeast-2.amazonaws.com/prod/tsumeGame/',
    user: 'https://ulsi1ec694.execute-api.ap-northeast-2.amazonaws.com/prod/user/',
    password:
      'https://ulsi1ec694.execute-api.ap-northeast-2.amazonaws.com/prod/password/',
    form: '',
    socket: 'wss://p8jhkg8n18.execute-api.ap-northeast-2.amazonaws.com/prod/',
    maintenance:
      'https://mdsct04jr8.execute-api.ap-northeast-2.amazonaws.com/prod/maintenance/',
    backstage:
      'https://l5t06gagzi.execute-api.ap-northeast-2.amazonaws.com/prod/backstage/',
    pvp: 'https://9p6a3w4p26.execute-api.ap-northeast-2.amazonaws.com/prod/pvp/',
    homework:
      'https://m2c8kwqsoi.execute-api.ap-northeast-2.amazonaws.com/prod/',
    liveCourse:
      'https://10odo5rkg0.execute-api.ap-northeast-2.amazonaws.com/prod/liveCourse/',
    multimediaClassroom:
      'https://db827z7oj4.execute-api.ap-northeast-2.amazonaws.com/prod/multimediaClassroom/',
    verification:
      'https://wxv30o35h8.execute-api.ap-northeast-2.amazonaws.com/prod/verification/',
    hjj: 'https://xo4imwmj46.execute-api.ap-northeast-2.amazonaws.com/prod/hjj/',
    hjjCourse:
      'https://gd25mpw3x7.execute-api.ap-northeast-2.amazonaws.com/prod/hjjCourse/',
    ec: 'https://qmcvrmi9jf.execute-api.ap-northeast-2.amazonaws.com/prod/ec/',
    ai: 'https://xlv7d9bzc3.execute-api.ap-northeast-2.amazonaws.com/prod/ai/',
    ladder:
      'https://5a6hr015q0.execute-api.ap-northeast-2.amazonaws.com/prod/ladder/',

    /* 共用、分階段api */
    leelaLZanalysis:
      'https://4qxzqdync3.execute-api.ap-northeast-1.amazonaws.com/prod/3in1-LV2/',

    /* 共用、不分階段api */
    newAiAnalysisOld:
      'https://6puqyzreh2.execute-api.ap-northeast-1.amazonaws.com/prod/',
    influence:
      'https://plohssfdy3.execute-api.ap-northeast-1.amazonaws.com/prod/',
    leelaAnalysis:
      'https://cwjfr09ae3.execute-api.ap-southeast-1.amazonaws.com/prod/',
    goRobot:
      'https://ypm5dyymk9.execute-api.ap-southeast-1.amazonaws.com/prod/lambda_bot/',
  },
  cdn: 'https://cdn.heijiajia.com.tw/',
  backstage:
    'http://backstage.hjjtw.weiqi.study.s3-website.ap-northeast-2.amazonaws.com/',
  homepage: 'http://hjjtw.weiqi.study.s3-website.ap-northeast-2.amazonaws.com/',
};

export default url;
