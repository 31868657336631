var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{staticClass:"base-header sticky-top w-100 align-items-center",class:[
    _vm.mode === 'primary'
      ? 'primary-header justify-content-between text-dark'
      : 'secondary-header justify-content-center text-white',
  ],attrs:{"id":"base-header"}},[((_vm.leftArrow || _vm.leftText || _vm.leftIcon) && _vm.mode === 'secondary')?_c('a',{staticClass:"btn-left position-absolute h-100 d-block text-white d-flex align-items-center justify-content-center position-relative",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onClickLeft.apply(null, arguments)}}},[(_vm.leftText)?_c('div',{staticClass:"left-text text-16"},[_vm._v(_vm._s(_vm.$t(_vm.leftText)))]):(_vm.leftIcon)?_c('i',{staticClass:"left-icon text-32",class:`icon-${_vm.leftIcon}`}):_c('i',{staticClass:"left-icon text-32 icon-Chevron-left"})]):_vm._e(),(_vm.hasLogo)?_c('div',[_c('img',{staticClass:"img-logo",attrs:{"src":require('@/assets/img/logo-white-transparent.svg')}})]):_c('h4',{staticClass:"base-header-title d-flex align-items-center",class:[
      [
        {'font-weight-bold': _vm.isTitleBold || _vm.mode === 'primary'},
        _vm.mode === 'primary' ? 'text-20' : 'text-18',
      ],
    ]},[(_vm.breadcrumb.length !== 0 && _vm.mode === 'primary')?_vm._l((_vm.breadcrumb),function(bread,index){return _c('div',{key:bread.router,staticClass:"breadcrumb-container d-flex align-items-center"},[_c('span',{staticClass:"bread-title font-weight-bold",class:{clickable: bread.enable},style:(index + 1 === _vm.breadcrumb.length
              ? 'color: #303133; pointer-events: none'
              : 'color: #606266'),on:{"click":function($event){return _vm.onClickBread(bread)}}},[_vm._v(_vm._s(_vm.$t(bread.name)))]),(index + 1 !== _vm.breadcrumb.length)?_c('i',{staticClass:"icon-Chevron-right"}):_vm._e()])}):_c('span',{staticClass:"bread-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])],2),((_vm.rightText || _vm.rightIcon) && _vm.mode === 'secondary')?_c('a',{staticClass:"btn-right text-32 position-absolute h-100 d-block text-white d-flex align-items-center justify-content-center",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onClickRight.apply(null, arguments)}}},[(_vm.rightText)?_c('div',{staticClass:"right-text text-16 font-weight-bold",attrs:{"id":"right-text"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.rightText))+" ")]):_c('i',{staticClass:"right-icon",class:`icon-${_vm.rightIcon}`}),_c('div',{staticClass:"locate-point position-absolute text-12",staticStyle:{"right":"30px","opacity":"0"},attrs:{"id":"locate-point"}},[_vm._v(" - ")]),(
        _vm.$device.isMobile &&
        _vm.canShowPopover &&
        (_vm.$route.name === 'verificationList' || _vm.$route.name === 'verification')
      )?_c('b-popover',{attrs:{"triggers":"click","target":"locate-point","placement":"bottom","custom-class":"hjj-popover base-header-scores-popover","container":"main","show":_vm.hasNewScore}},[_vm._v(_vm._s(_vm.$t('你有新的成績'))+" "),_c('i',{staticClass:"icon-X text-16 ml-2",on:{"click":_vm.onClick2ClosePop}})]):_vm._e()],1):_vm._e(),(_vm.mode === 'primary')?_c('div',{staticClass:"d-flex align-items-center"},[(_vm.allowShowAiTutor)?_c('div',{staticClass:"ai-tutor mr-4",class:{'is-mobile': _vm.$device.isMobile},on:{"click":_vm.openAiTutor}},[_c('img',{staticClass:"ai-tutor-img",attrs:{"srcset":require('@/assets/img/chatbot/ai-tutor-button.png')}})]):_vm._e(),_c('div',{staticClass:"avatar-wrap",on:{"click":_vm.toggleAvatar}},[_c('b-dropdown',{staticStyle:{"position":"relative","border-radius":"10px"},attrs:{"right":"","size":"sm","variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"avatar-wrapper",staticStyle:{"position":"relative"}},[_c('img',{staticClass:"img-fluid w-100 rounded-circle",attrs:{"srcset":require(`@/assets/img/personal/avatar/${
                  _vm.userData.avatar ? _vm.userData.avatar : '0000'
                }.png?srcset`)}}),(_vm.hasUnreadNotifications)?_c('div',{staticClass:"red-dot"}):_vm._e()])]},proxy:true}],null,false,3865405705)},[_c('div',{staticClass:"personal-menu-wrapper"},[_c('PersonalMenu',{attrs:{"is-desktop":true}})],1)])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }