<template>
  <div class="w-100 h-100 d-flex flex-column align-items-center page-container">
    <base-header
      v-if="!$device.isMobile"
      :title="$t('學習計畫')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>
    <i
      v-if="$device.isMobile"
      class="icon-X close-button"
      @click="handleBack"
    ></i>
    <div
      class="main-container d-flex flex-column justify-content-center align-items-center"
      :class="[
        'step-' + currStep,
        {'shadow-sm rounded-md': !$device.isMobile},
        $device.isMobile ? 'is-mobile' : 'is-web',
      ]"
    >
      <template v-if="currStep === stepMap['介紹頁']">
        <img
          class="entry-img mb-5"
          :src="require('@/assets/img/learn-plan/entry-1.png')"
        />
        <p class="main-title my-0">
          {{ $t('嗨，準備好有計畫地學習圍棋了嗎？') }}
        </p>
        <p class="main-text my-0">{{ $t('點擊下方按鈕，展開你的學習計畫') }}</p>
        <b-button
          class="main-button mt-4"
          variant="primary"
          @click="nextStep"
          >{{ $t('開始') }}</b-button
        >
      </template>
      <template v-if="currStep === stepMap['選擇強度']">
        <div class="main-section">
          <p class="main-title my-0">
            {{ $t('請選擇強度') }}
          </p>
          <p class="main-text mt-2 mb-5">
            {{
              $t(
                '我們根據你的棋力 {rank} 與目前的課程方案《{course}》為你制定了兩種強度的學習計畫。',
                {
                  rank: convertGRank(userData.rank),
                  course: $t(currentPlan.name),
                }
              )
            }}
          </p>
          <div class="d-flex card-container">
            <template v-for="plan in planList">
              <div
                :key="plan.title"
                :class="{selected: plan.key === selectPlan.key}"
                class="chat-card chat-card-md"
                @click="selectPlan = plan"
              >
                <div class="card-icon">
                  <img
                    class="card-icon-img"
                    draggable="false"
                    :srcset="require(`@/assets/img/chatbot/${plan.key}.png`)"
                  />
                </div>
                <div class="card-content">
                  <p class="card-title">{{ $t(plan.title) }}</p>
                  <p class="card-text my-0">{{ $t(plan.text) }}</p>
                </div>
              </div>
            </template>
          </div>
          <div class="button-container">
            <b-button
              class="main-button"
              :class="{'mt-4': !$device.isMobile}"
              variant="primary"
              @click="nextStep"
              >{{ $t('確定') }}</b-button
            >
          </div>
        </div>
      </template>
      <template v-if="currStep === stepMap['確認計畫']">
        <div class="main-section">
          <p class="main-title my-5">
            {{ $t('計劃制定完成！') }}
          </p>
          <div class="bg-white shadow-sm rounded-md">
            <div class="d-flex goal-container flex-column">
              <div class="goal-card section-container">
                <div class="ml-3">
                  <p class="text">{{ $t('學習目標') }}</p>
                  <p class="rank">{{ convertGRank(currProgressPlan.rank) }}</p>
                </div>
                <div class="divide"></div>
                <div>
                  <i18n
                    path="時間：共{week}週"
                    tag="p"
                    class="text-lg week-text"
                  >
                    <span place="week" class="week-num">{{
                      currProgressPlan.week
                    }}</span>
                  </i18n>
                  <p class="text-sm">
                    {{ currProgressPlan.startTime }}
                    ～
                    {{ currProgressPlan.endTime }}
                  </p>
                </div>
              </div>
              <div class="goal-detail">
                <p class="goal-text">
                  <span>{{ $t('目標：') }}</span>
                  <span class="highlight">
                    {{ $t('通過{rank}檢定', {rank: currProgressPlan.rank}) }}
                  </span>
                </p>
                <p class="goal-text">
                  <span>{{ $t('強度：') }}</span>
                  <span class="highlight">
                    {{ currProgressPlan.level }}
                  </span>
                </p>
                <p class="goal-text">
                  <span>{{ $t('時間：') }}</span>
                  <span class="highlight">
                    {{ $t('{week}週', {week: currProgressPlan.week}) }}</span
                  >
                </p>
                <p class="goal-text">
                  <span>{{ $t('開始日：') }}</span>
                  <span class="highlight">
                    {{ currProgressPlan.startTime }}
                  </span>
                </p>
                <p class="goal-text">
                  <span>{{ $t('結束日：') }}</span>
                  <span class="highlight">{{ currProgressPlan.endTime }}</span>
                </p>
              </div>
            </div>
          </div>
          <p class="main-notice-text mt-5 mb-0">
            {{
              $t(
                '請注意，學習計畫僅提供建議學習順序，請根據自己的節奏進行練習，你隨時可重新安排新的學習計畫。'
              )
            }}
          </p>
          <div class="button-container">
            <b-button
              class="main-button"
              :class="{'my-5': !$device.isMobile}"
              variant="primary"
              @click="handleOnRedirectToLearnPage"
              >{{ $t('查看學習計畫') }}</b-button
            >
          </div>
        </div>
      </template>
      <template v-if="currStep === stepMap['適用學習計畫']">
        <AiTutorActivePlan :error-message="errorMessage" />
      </template>
    </div>
  </div>
</template>
<script>
import userService from '@/services/user';
import BaseHeader from '@/components/Base/BaseHeader.vue';
import AiTutorActivePlan from '@/views/AiTutor/AiTutorActivePlan.vue';
import {convertGRank} from '@/lib/base/ranking.js';

export default {
  name: 'AiTutorMakePlan',
  components: {
    BaseHeader,
    AiTutorActivePlan,
  },
  computed: {
    userData() {
      return this.$store.state.user.userData;
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    planRank() {
      return this.$store.state['aiTutor'].studyPlan.rank;
    },
    planCreatedTime() {
      return this.$store.state['aiTutor'].studyPlan.createdAt;
    },
    planTotalWeek() {
      return this.$store.state['aiTutor'].studyPlan.weekCount;
    },
    planWeekTimes() {
      return this.$store.state['aiTutor'].studyPlan.weekTimes;
    },
  },
  data() {
    return {
      currStep: 0,
      stepMap: {
        介紹頁: 0,
        選擇強度: 1,
        確認計畫: 2,
        適用學習計畫: 9,
      },
      errorMessage: null,
      selectPlan: {},
      currProgressPlan: {},
      planList: [],
      breadcrumb: [
        {
          name: this.$t('學習'),
          enable: true,
          router: 'learningPage',
        },
        {
          name: this.$t('制定學習計畫'),
          enable: true,
          router: 'aiTutorMakePlan',
        },
      ],
    };
  },
  mounted() {
    this.initStep();
    this.runStepAction();
  },
  methods: {
    convertGRank,
    initStep() {
      if (this.$route.query.step) {
        this.currStep = Number(this.$route.query.step);
      }
    },
    runStepAction() {
      const stepMap = this.stepMap;
      switch (this.currStep) {
        case stepMap['介紹頁']:
          break;
        case stepMap['選擇強度']:
          this.getLearnPlanList();
          break;
        case stepMap['確認計畫']:
          this.createLearnPlan();
          break;
      }
    },
    nextStep() {
      this.currStep++;
      this.runStepAction();
    },
    handleBack() {
      this.$router.push('/learning');
    },
    async getLearnPlanList() {
      try {
        const res = await userService.getStudyPlanMenu({
          coursePlan: this.currentPlan.id,
        });
        const planList = [];
        if (res.weekCounts.normal) {
          planList.push({
            key: 'moderate-intensity',
            title: this.$t('一般（共{week}週）', {
              week: res.weekCounts.normal,
            }),
            text: this.$t(
              '只要充分利用生活中的零碎時間進行學習，循序漸進的完成規定任務，就可以達成你想要的棋力成長！'
            ),
          });
        }
        if (res.weekCounts.hard) {
          planList.push({
            key: 'high-intensity',
            title: this.$t('困難 (共{week}週)', {
              week: res.weekCounts.hard,
            }),
            text: this.$t(
              '除了日常生活的零碎時間外，你可能還需要額外撥出一些時間來進行學習．學習任務的安排會比較密集，但只要能夠努力完成，你的棋力進步速度將會非常驚人！'
            ),
          });
        }

        this.planList = planList;
        this.selectPlan = this.planList[0];
      } catch ({response}) {
        const errorMessage = response?.data?.message;
        const courseName = this.$t(this.currentPlan.name);
        const userRank = convertGRank(this.userData.rank);

        switch (errorMessage) {
          case 'you have the plans':
            this.$store.dispatch('aiTutor/getCurrentStudyPlan');
            if (this.$device.isMobile) {
              this.$router.push({name: 'aiTutorLearnPlan'});
            } else {
              this.$router.go(-1);
              await this.$store.dispatch(
                'aiTutor/openAiTutorLearnPlanModalShow'
              );
              break;
            }
          case 'the coursePlan has no plans':
            this.currStep = this.stepMap['適用學習計畫'];
            this.errorMessage = this.$t(
              '目前的課程方案《{courseName}》不支援學習計劃的制定請切換或購買其他課程方案。',
              {
                courseName,
              }
            );
            break;
          case 'your rank is gte max rank of the coursePlan':
            this.currStep = this.stepMap['適用學習計畫'];
            this.errorMessage = this.$t(
              '不好意思！你的棋力（{userRank}）已達目前課程方案《{courseName}》的上限，請切換或購買其他課程方案。',
              {
                userRank,
                courseName,
              }
            );
            break;
          default:
            this.$Message.error(this.$t('請確認網路連線的狀態後再次嘗試'));
        }
      }
    },
    async createLearnPlan() {
      const selectPlanKey = this.selectPlan.key;
      let res;
      this.currProgressPlan = {};
      switch (selectPlanKey) {
        case 'moderate-intensity':
          res = await userService.postStudyPlan({
            coursePlan: this.currentPlan.id,
            mode: 'normal',
          });
          this.currProgressPlan.level = this.$t('一般');
          break;
        case 'high-intensity':
          res = await userService.postStudyPlan({
            coursePlan: this.currentPlan.id,
            mode: 'hard',
          });
          this.currProgressPlan.level = this.$t('困難');
          break;
      }

      if (!res?.error) {
        this.currProgressPlan = {
          ...this.currProgressPlan,
          rank: convertGRank(res.rank),
          week: res.weekCount,
          startTime: this.formatDate(res.startTime),
          endTime: this.formatDate(res.endTime),
        };
      } else {
        this.$Message.error(this.$t('請確認網路連線的狀態後再次嘗試'));
      }
    },
    formatDate(date) {
      return new Date(date).toISOString().split('T')[0].split('-').join('/');
    },
    async handleOnRedirectToLearnPage() {
      await this.$store.dispatch('aiTutor/getCurrentStudyPlan');
      if (this.$device.isMobile) {
        this.$router.push({name: 'aiTutorLearnPlan'});
      } else {
        this.$router.go(-1);
        await this.$store.dispatch('aiTutor/openAiTutorLearnPlanModalShow');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .main-container {
  width: 100%;
  padding: 16px;
  background: white;
  &.step-0 {
    height: 600px;
  }
  &.step-1 {
    .main-section {
      height: auto;
    }
  }
  .main-section {
    width: 100%;
    height: 568px;
    top: 136px;
    left: 252px;
    padding: 40px 16px 40px 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f8f9fb;
  }
  .main-title {
    color: #303133;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
  }
  .main-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }
  .main-notice-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  .main-button {
    width: 200px;
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
  }
  &.is-web {
    max-width: 1100px;
    margin: 40px 0;
  }
  &.is-mobile {
    height: 100%;
    background: #f8f9fb;
    padding: 40px 32px 80px;
    overflow: scroll;
    &.step-0 {
      padding-bottom: 40px;
    }
    &.step-9 {
      padding: 0;
    }
    .main-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
    }
    .main-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: center;
    }
    .main-section {
      width: 100%;
      height: auto;
      padding: 40px 0 40px 16px;
    }
    .card-container {
      flex-wrap: wrap;
    }
    .card-icon {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      margin-right: 16px;
    }
    .chat-card {
      display: flex;
      flex-direction: row;
      padding: 16px;
      height: auto;
      width: 100%;
      margin: 0 0 16px;
    }
    .card-title {
      width: 100%;
      margin: 0 0 8px;
    }
    .button-container {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      background: white;
      border-top: 1px solid #dcdfe5;
      padding: 16px;
    }
    .main-button {
      width: 100%;
      margin: 0;
    }
    .goal-card {
      min-width: 0;
    }
    .week-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
    .week-num {
      font-size: 20px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

.chat-card {
  width: 250px;
  padding: 16px 16px 28px;
  margin-right: 16px;
  background: $color-gray-white;
  box-shadow: $card-box-shadow;
  border-radius: $rounded-md;
  margin-right: 16x;
  color: $font-grayscale-1;
  cursor: pointer;
  &.selected {
    outline: 5px solid #ff855e;
  }
  &.chat-card-md {
    width: 250px;
    padding: 16px 16px 28px;
  }
  .card-icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: $rounded-sm;
  }
  .card-icon-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-title {
    color: $primary;
    font-weight: 700;
    margin: 16px 0 8px;
  }
  .card-button {
    color: $font-grayscale-1;
    font-weight: 400;
  }
  .card-text-button {
    color: $font-grayscale-1;
    font-weight: 400;
  }
  .card-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.goal-container {
  position: relative;
  transition: 0.4s;
  padding: 16px 25px;
  .goal-card {
    display: flex;
    align-items: center;
    min-width: 274px;
    width: 100%;
    height: 80px;
    color: #606266;
    padding-bottom: 16px;
    border-bottom: 1px solid #c7c7c7;
    .divide {
      width: 1px;
      height: 100%;
      background: #c7c7c7;
      margin: 0 16px;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 4px;
      &-lg {
        font-size: 16px;
        margin-bottom: 4px;
      }
      &-sm {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
    .week-num {
      font-size: 20px;
      font-weight: bold;
      color: #303133;
    }
    .rank {
      width: 64px;
      height: 32px;
      font-size: 20px;
      font-weight: bold;
      background: #303133;
      color: #fff;
      text-align: center;
      border-radius: $rounded-sm;
      margin-bottom: 0;
    }
  }
  .goal-detail {
    padding: 16px 0 22px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    .goal-text {
      margin: 0;
    }
    .highlight {
      color: #ff855e;
    }
  }
  .goal-setting {
    position: absolute;
    right: 32px;
    font-size: 32px;
    color: #ff855e;
    line-height: 32px;
  }
}
.entry-img {
  width: 100%;
  max-width: 280px;
}
.close-button {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 0;
  font-size: 32px;
  color: #ff855e;
}
</style>
