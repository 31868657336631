<template>
  <div class="all-plans-collapse rounded-10 overflow-hidden">
    <div
      class="head d-flex align-items-center cursor-pointer"
      @click="$emit('toggleVisible', !toggleAllPlansVisible)"
    >
      <i class="icon-Book text-24 mr-2"></i>
      <span class="font-weight-bold"> {{ $t('所有已購買的課程') }}</span>
      <i
        class="text-24 ml-auto"
        :class="toggleAllPlansVisible ? 'icon-Chevron-up' : 'icon-Chevron-down'"
      ></i>
    </div>
    <b-collapse v-model="toggleAllPlansVisible">
      <div class="p-2">
        <template v-for="(courseType, index) in courseTypes">
          <CourseTypeCollapse
            :key="courseType.type"
            :course-type="courseType"
            :courses="plans"
            :class="{'mt-2': index !== 0}"
            @click-type="clickType"
            @change-course="changeCourse"
            @favorite-course="favoriteCourse"
          ></CourseTypeCollapse>
        </template>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import CourseTypeCollapse from '@/components/Base/CourseTypeCollapse';

export default {
  components: {CourseTypeCollapse},
  props: {
    toggleAllPlansVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courseTypes: [
        {type: 'isNormal', title: '一般', show: true},
        {type: 'isTopic', title: '技術專題', show: false},
        {type: 'isNFT', title: 'NFT專屬課程', show: false},
      ],
    };
  },
  computed: {
    plans() {
      return this.$store.getters['course/plans'];
    },
  },
  methods: {
    clickType(type) {
      type.show = !type.show;
    },
    changeCourse(course) {
      this.$emit('change-course', course);
    },
    favoriteCourse(course) {
      this.$emit('favorite-course', course);
    },
  },
};
</script>

<style lang="scss" scoped>
.all-plans-collapse {
  border: 1px solid $line-2;
  .head {
    color: $font-grayscale-1;
    padding: 12px 16px;
    background: $grayscale-2;
  }

  .course-item {
    &:not(:last-child) {
      border-bottom: 1px solid $secondary;
    }
  }
}
</style>
