import AiTutorChat from '@/views/AiTutor/AiTutorChat.vue';
import AiTutorMakePlan from '@/views/AiTutor/AiTutorMakePlan.vue';
import AiTutorLearnPlan from '@/views/AiTutor/AiTutorLearnPlan.vue';
import AiTutorActivePlan from '@/views/AiTutor/AiTutorActivePlan.vue';
import getDeviceInfo from '@/lib/base/getDeviceInfo';
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

export default [
  {
    path: '/ai-tutor/chat',
    name: 'aiTutorChat',
    component: AiTutorChat,
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      requireAuth: true,
    },
  },
  {
    path: '/ai-tutor/make-plan',
    name: 'aiTutorMakePlan',
    component: AiTutorMakePlan,
    meta: {
      hasNavigation: !isMobile,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      requireAuth: true,
    },
  },
  {
    path: '/ai-tutor/learning-plan',
    name: 'aiTutorLearnPlan',
    component: AiTutorLearnPlan,
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      requireAuth: true,
    },
  },
  {
    path: '/ai-tutor/active-plan',
    name: 'aiTutorActivePlan',
    component: AiTutorActivePlan,
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      requireAuth: true,
    },
  },
];
