import getDeviceInfo from '@/lib/base/getDeviceInfo';
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

export default [
  {
    path: '/tournament',
    name: 'tournament',
    component: () => import('@/views/Pvp/TournamentHome.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 1,
    },
  },
  {
    path: '/tournament/leader-board',
    name: 'leader-board',
    component: () => import('@/views/Pvp/LeaderBoard.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
];
