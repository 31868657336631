import userService from '@/services/user';
import {eventMap} from '@/views/AiTutor/chatEvents';
import i18n from '@/i18n/index.js';

const defaultStudyPlan = {
  rank: 0,
  weekCount: 0,
  weekTimes: [],
  plans: [],
  createdAt: '',
};

const state = {
  isAiTutorLearnPlanModalOpen: false,
  isAiTutorActivePlanModalOpen: false,
  isAiTutorShow: false,
  chatList: [],
  studyPlan: defaultStudyPlan,
  events: [],
};

const getters = {
  isAiTutorShow(state) {
    return state.isAiTutorShow;
  },
  lastChatIsRichMenu(state) {
    return state.chatList.at(-1)?.mode === 'rich-menu';
  },
};

const actions = {
  openAiTutor({commit}) {
    commit('openAiTutor');
  },
  closeAiTutor({commit}) {
    commit('closeAiTutor');
  },
  openAiTutorLearnPlanModalShow({commit}) {
    commit('openAiTutorLearnPlanModalShow');
  },
  openAiTutorActivePlanModalShow({commit}) {
    commit('openAiTutorActivePlanModalShow');
  },
  closeAiTutorLearnPlanModalShow({commit}) {
    commit('closeAiTutorLearnPlanModalShow');
  },
  closeAiTutorActivePlanModalShow({commit}) {
    commit('closeAiTutorActivePlanModalShow');
  },
  async getCurrentStudyPlan({commit}) {
    const response = await userService.getStudyPlan();
    commit('getCurrentStudyPlan', response);
  },
  async deleteStudyPlan({commit}) {
    const response = await userService.deleteStudyPlan();
    commit('deleteStudyPlan', response);
  },
  setEvent({commit}) {
    commit('setEvent');
  },
  resetChat({commit}) {
    commit('resetChat');
  },
  pushChat({commit}, payload) {
    commit('pushChat', payload);
  },
  removeChat({commit}, {idx}) {
    commit('removeChat', idx);
  },
  sendEvent({dispatch}, eventKey) {
    const event = state.events[eventKey];
    if (event?.user) {
      dispatch('pushChat', {
        type: 'user',
        mode: 'text',
        text: i18n.t(event.user),
      });
    }
    if (event?.bot) {
      dispatch('pushChat', {
        type: 'bot',
        mode: 'text',
        text: i18n.t(event.bot),
      });
    }
    if (event?.action) {
      event.action();
    }
  },
  sendEventFromAPI({dispatch}, eventKey) {
    const event = state.events[eventKey];
    if (event?.bot) {
      dispatch('pushChat', {
        type: 'bot',
        mode: 'text',
        text: i18n.t(event.bot),
      });
    }
    if (event?.action) {
      event.action();
    }
  },
};

const mutations = {
  openAiTutor(state) {
    state.isAiTutorShow = true;
  },
  closeAiTutor(state) {
    state.isAiTutorShow = false;
  },
  openAiTutorLearnPlanModalShow(state) {
    state.isAiTutorLearnPlanModalOpen = true;
  },
  closeAiTutorLearnPlanModalShow(state) {
    state.isAiTutorLearnPlanModalOpen = false;
  },
  openAiTutorActivePlanModalShow(state) {
    state.isAiTutorActivePlanModalOpen = true;
  },
  closeAiTutorActivePlanModalShow(state) {
    state.isAiTutorActivePlanModalOpen = false;
  },
  setEvent(state) {
    state.events = eventMap;
  },
  deleteStudyPlan(state) {
    state.studyPlan = defaultStudyPlan;
  },
  getCurrentStudyPlan(state, payload) {
    state.studyPlan = payload;
  },
  pushChat(state, payload) {
    state.chatList.push(payload);
  },
  removeChat(state, idx) {
    state.chatList.splice(idx, 1);
  },
  resetChat(state) {
    state.chatList = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
