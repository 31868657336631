import 'cordova-plugin-purchase';
import {Device} from '@capacitor/device';
import {getProducts, createOrder, checkOrderViability} from '@/services/ec';

const PLATFORM_MAPPING = {
  ios: {
    platform: 'ios-appstore',
    isActive: true,
  },
  android: {
    platform: 'android-playstore',
    isActive: false,
  },
};

class InAppPurchase {
  constructor() {
    this.platform = null;
    this.isActive = null;
    this.isReady = false;
    this.isLoading = false;
    this.isInit = false;
    this.ecProduct = null;
    this.order = null;
    this.onFinished = null;
    this.onError = console.error;
  }

  async init() {
    if (this.isReady) return;
    if (this.isInit) {
      return new Promise((resolve) => {
        const timer = setInterval(() => {
          if (this.isReady) {
            clearInterval(timer);
            resolve();
          }
        }, 100);
      });
    }
    this.isInit = true;
    try {
      const info = await Device.getInfo();
      const config = PLATFORM_MAPPING[info.platform] || {};
      this.platform = config.platform;
      this.isActive = config.isActive;
      if (this.isActive) {
        CdvPurchase.store.verbosity = CdvPurchase.LogLevel.QUIET;
        const ecProducts = await getProducts();
        const purchaseProducts = ecProducts
          .filter((product) => product.inAppPurchaseId)
          .map((product) => {
            const {inAppPurchaseId} = product;
            return {
              type: CdvPurchase.ProductType.CONSUMABLE,
              id: inAppPurchaseId,
              platform: this.platform,
            };
          });
        CdvPurchase.store.register(purchaseProducts);
        CdvPurchase.store.validator = (...args) =>
          this.validatorHandler(...args);
        CdvPurchase.store.error(
          async (error) => this.onError && (await this.onError(error))
        );
        CdvPurchase.store
          .when()
          // .productUpdated((e) => console.log('productUpdated', e))
          // .receiptsReady((e) => console.log('receiptsReady', e)) // ios 不會觸發
          // .receiptUpdated((e) => console.log('receiptUpdated', e)) // 按下購買 或取消購買 或成功購買
          .approved((...args) => this.approvedHandler(...args))
          .verified((...args) => this.verifiedHandler(...args)) // 打後端 驗證成功
          .unverified((...args) => this.unverifiedHandler(...args)) // 打後端 驗證成功
          .finished((...args) => this.finishedHandler(...args));
        CdvPurchase.store.ready(() => (this.isReady = true));
        await CdvPurchase.store.initialize([
          {
            platform: this.platform,
            options: {
              needAppReceipt: true,
            },
          },
        ]);
      } else {
        this.isReady = true;
      }
    } catch {
      this.isReady = true;
    }
  }

  getProducts() {
    if (!this.isReady) return [];
    return CdvPurchase.store.products;
  }

  getProduct(id) {
    if (!this.isReady) return undefined;
    const product = CdvPurchase.store.get(id, this.platform);
    return product;
  }

  async validatorHandler(receipt, cb) {
    const transaction = receipt.transaction;
    const transactionId = transaction.id;
    const successPayload = {
      ok: true,
      data: {
        id: receipt.transaction.id,
        latest_receipt: true,
        transaction: receipt.transaction,
      },
    };
    if (!parseInt(transactionId)) {
      const response = successPayload;
      return cb(response);
    }
    let response = successPayload;
    try {
      await this.createOrder(transactionId);
    } catch (error) {
      const errorResponse = error?.response;
      const responseMessage = errorResponse?.data?.message;
      response = {
        ok: false,
        status: responseMessage?.httpStatusCode || errorResponse.status,
        code: responseMessage?.apiError || errorResponse.status,
        message: responseMessage?.errorMessage || responseMessage,
        data: {
          latest_receipt: false,
        },
      };
    }
    cb(response);
  }
  async verifiedHandler(verifiedReceipt) {
    await verifiedReceipt.finish();
  }
  async unverifiedHandler(unverifiedReceipt) {
    const receipt = unverifiedReceipt.receipt;
    this.onError && (await this.onError(unverifiedReceipt.payload));
    await receipt.finish();
  }
  async approvedHandler(transaction) {
    if (this.isLoading) return;
    this.isLoading = true;
    if (transaction.state === 'finished') return await transaction.finish();
    await transaction.verify();
  }
  async finishedHandler(transaction) {
    this.isLoading = false;
    this.ecProduct = null;
    if (this.onFinished) await this.onFinished(transaction);
  }

  async buyProduct(ecProduct) {
    this.ecProduct = ecProduct;
    const inAppProduct = this.getProduct(ecProduct.inAppPurchaseId);
    const offer = inAppProduct?.getOffer();
    if (offer) offer.order();
  }

  async createOrder(transactionId) {
    if (!this.ecProduct) return;
    const inAppProduct = this.getProduct(this.ecProduct.inAppPurchaseId);
    if (!inAppProduct) return;
    const payload = {
      description: this.ecProduct.name,
      products: [{id: this.ecProduct.id, count: 1}],
      platform: 'apple',
      otherInfo: {
        transactionId,
      },
    };
    await createOrder(payload);
  }

  async checkOrderViability(product) {
    const payload = {products: [{id: product.id, count: 1}]};
    const result = await checkOrderViability(payload);
    if (!result.viability) {
      const errorMessage = result?.products?.[0]?.error;
      return {
        viability: result.viability,
        error: errorMessage,
      };
    }
    return {viability: true};
  }
}

export default new InAppPurchase();
