import {
  getWeeklyTsume,
  addWeeklyTsumeCount,
  getWeeklyTsumeTimeRange,
  getWeeklyTsumeCount,
} from '@/services/tsumeGame';
import {getDataWithExpiry, setTemporaryData} from '@/lib/base/localData';
import dayjs from 'dayjs';
import {MODE, ADD_COUNT, modeToAddCount} from '@/constant/weeklyTsume.js';
import _ from 'lodash';
import delay from '@/lib/base/delay.js';

const startTime = dayjs()
  .add(1, 'd')
  .startOf('week')
  .add(1, 'd')
  .startOf('day')
  .valueOf();
const endTime = dayjs(startTime).add(6, 'd').endOf('day').valueOf();

const state = {
  startTime,
  endTime,
  mode: null,
  count: 0,
  passCount: 0,
  questions: null,
  playIndex: null,
  correctCount: null,
};

const getters = {};

const actions = {
  async checkTimeRange({state, dispatch}) {
    const now = dayjs().valueOf();
    const isInTimeRange = now >= state.startTime && now <= state.endTime;
    if (!isInTimeRange) {
      await dispatch('getWeeklyTsumeTimeRange');
    }
    return isInTimeRange;
  },
  async setUserRecord(
    {commit, state},
    {playIndex, correctCount, mode, delayTime = 0}
  ) {
    const expiry = Math.min(dayjs().add(1, 'hour').valueOf(), state.endTime);
    playIndex = playIndex ?? state.playIndex;
    correctCount = correctCount ?? state.correctCount;
    mode = mode ?? state.mode;
    setTemporaryData('wt-record', {playIndex, correctCount, mode}, expiry);
    if (delayTime) await delay(delayTime);
    commit('setUserRecord', {playIndex, correctCount, mode});
    return {playIndex, correctCount};
  },
  getUserRecord({commit}) {
    const userRecord = getDataWithExpiry('wt-record');
    commit('setUserRecord', userRecord || {});
    return userRecord;
  },
  async getWeeklyTsume({commit}, mode) {
    const questions = await getWeeklyTsume({mode});
    commit('setQuestions', questions);
    commit('setMode', mode);
    return questions;
  },
  async addWeeklyTsumeCount({commit, state}, {isWin}) {
    const mode = state.mode;
    const count = await addWeeklyTsumeCount({mode, isWin});
    commit('setPassCount', count + modeToAddCount(mode));
  },
  async getPassCount({commit, state}) {
    const mode = state.mode;
    const countObject = await getWeeklyTsumeCount({mode});
    commit('setPassCount', countObject.win + modeToAddCount(mode));
  },
  async getWeeklyTsumeTimeRange({commit}) {
    const timeRange = await getWeeklyTsumeTimeRange();
    commit('setTimeRange', timeRange);
    return timeRange;
  },
  async getCount({commit}) {
    const countObjectList = await Promise.all(
      Object.values(MODE).map(async (mode) => {
        return getWeeklyTsumeCount({mode});
      })
    );
    const counts = countObjectList.map((countObject) => countObject.count);
    commit('setCount', _.sum(counts) + ADD_COUNT.HOME);
  },
  async getUnfinishedTsume({state, dispatch}, mode) {
    const userRecord = await dispatch('getUserRecord');
    const isInTimeRange = await dispatch('checkTimeRange');
    if (userRecord !== null && isInTimeRange) {
      if (!state.questions || state.mode !== mode) {
        await dispatch('getWeeklyTsume', mode);
      }
    } else {
      await dispatch('startTsume', mode);
    }
  },
  async startTsume({dispatch}, mode) {
    await dispatch('setUserRecord', {playIndex: 0, correctCount: 0, mode});
    const isInTimeRange = await dispatch('checkTimeRange');
    if (!isInTimeRange) await dispatch('getWeeklyTsumeTimeRange');
    await Promise.all([
      dispatch('getWeeklyTsume', mode),
      dispatch('addWeeklyTsumeCount', {mode}),
    ]);
  },
};

const mutations = {
  setUserRecord(
    state,
    {playIndex = null, correctCount = null, mode = null} = {}
  ) {
    state.playIndex = playIndex;
    state.correctCount = correctCount;
    state.mode = mode;
  },
  setQuestions(state, questions) {
    state.questions = questions;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setCount(state, count) {
    state.count = count;
  },
  setPassCount(state, passCount) {
    state.passCount = passCount;
  },
  setTimeRange(state, timeRange) {
    state.startTime = timeRange.start;
    state.endTime = timeRange.end;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
