import { render, staticRenderFns } from "./AllPlansCollapse.vue?vue&type=template&id=21d275d8&scoped=true"
import script from "./AllPlansCollapse.vue?vue&type=script&lang=js"
export * from "./AllPlansCollapse.vue?vue&type=script&lang=js"
import style0 from "./AllPlansCollapse.vue?vue&type=style&index=0&id=21d275d8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d275d8",
  null
  
)

export default component.exports