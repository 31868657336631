<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      :width="$device.isMobile ? '320px' : '344px'"
      bg-variant="bgcontainer"
      body-class="scroll-bar"
      no-header
      :visible="visible"
      @change="visibleChange"
    >
      <template v-if="$device.isMobile" #default>
        <div
          class="header d-flex align-items-center justify-content-between px-3 pt-3"
        >
          <img :src="Logo" alt="" width="148" class="img-fluid" />
          <i
            class="icon-Circle-X text-32 text-primary cursor-pointer"
            @click="onClickClose"
          ></i>
        </div>
        <div class="content mt-1">
          <div class="bg-white p-3 mt-3">
            <div v-for="list in mobileList" :key="list.key" class="list">
              <div v-if="list.title" class="title text-14">
                {{ list.title }}
              </div>
              <template v-for="item in list.group">
                <div
                  v-if="!item.isHidden"
                  :key="item.key"
                  class="list-item d-flex align-items-center bg-white position-relative"
                  @click="onClickEvent(item)"
                >
                  <img
                    v-if="item?.img"
                    width="32"
                    class="d-block img-fluid mr-2"
                    :src="item.img"
                    :alt="item.title"
                  />
                  <i
                    v-else
                    :class="`icon-${item.icon} ${
                      item.variant ? 'text-' + item.variant : 'text-dark'
                    }`"
                    class="text-32 mr-2"
                  ></i>
                  <div
                    v-if="
                      item.key === 'verification' && hasVerificationNotification
                    "
                    class="notification bg-accent position-absolute"
                  ></div>
                  {{ item.title }}
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex p-3">
            <a
              v-if="featureToggle['isOfficialSiteShow']"
              class="flex-grow-1"
              @click.prevent="
                onClickEvent({href: `${manifest.homePageUrl}/courses`})
              "
            >
              <img
                v-i18n-src="
                  '@/assets/img/locales/{locale}/home/side-bar-more-courses.png'
                "
                class="w-100"
                alt=""
              />
            </a>
            <a
              v-if="featureToggle['isFandoraShopShow']"
              class="ml-2"
              @click.prevent="onClickEvent({href: manifest.fandoraShop})"
            >
              <img
                v-i18n-src="
                  '@/assets/img/locales/{locale}/home/side-bar-shop.png'
                "
                width="100"
                alt=""
              />
            </a>
          </div>
        </div>
      </template>
      <template v-else #default>
        <div class="header position-relative">
          <i
            class="icon-Circle-X text-40 text-primary cursor-pointer position-absolute"
            @click="onClickClose"
          ></i>
        </div>
        <div class="sidebar-content">
          <div class="text-18 font-weight-bold mb-5">
            <div
              v-for="tab in primaryLinks"
              :key="tab.key"
              class="button p-4 d-flex align-items-center cursor-pointer shadow-sm position-relative"
              :class="{active: isActive(tab)}"
              @click="onClickEvent(tab)"
            >
              <img
                v-if="tab?.img"
                width="32"
                :src="tab.img"
                class="mr-4 img-fluid"
                :alt="tab.title"
              />
              <i
                v-if="tab?.icon"
                :class="`icon-${tab.icon}`"
                class="text-32 text-primary mr-4"
              ></i>
              {{ tab.title }}
              <div
                v-if="tab.key === 'verification' && hasVerificationNotification"
                class="notification bg-accent position-absolute"
              ></div>
            </div>
          </div>

          <h4 class="font-weight-bold text-18 mb-2">{{ $t('其他') }}</h4>
          <template v-for="link in secondaryLinks">
            <a
              v-if="!link.isHidden"
              :key="link.key"
              :href="link.href"
              class="link d-flex align-items-center justify-content-between px-4 d-block w-100 shadow-sm cursor-pointer"
              :class="link.items ? 'mb-1' : 'mb-3'"
              target="_blank"
              @click="onClickEvent(link)"
            >
              <div class="d-flex align-items-center">
                <i class="text-32 mr-4" :class="`icon-${link.icon}`"></i>
                {{ link.title }}
              </div>

              <i
                v-if="link?.items"
                class="text-32 text-primary"
                :class="link.visible ? 'icon-Chevron-up' : 'icon-Chevron-down'"
              ></i>
            </a>
            <b-collapse
              v-if="!link.isHidden && link?.items"
              :key="`${link.key}-collapse`"
              v-model="link.visible"
              class="mb-3"
            >
              <a
                v-for="(item, i) in link.items"
                :key="item.key"
                :href="item.href"
                class="link d-flex align-items-center pl-7 d-block w-100 shadow-sm cursor-pointer"
                :class="i === link.items.length - 1 ? 'mb-0' : 'mb-1'"
                target="_blank"
                @click="onClickEvent(item)"
              >
                {{ item.title }}
              </a>
            </b-collapse>
          </template>

          <div class="d-flex">
            <a
              :href="`${manifest.homePageUrl}/courses`"
              target="_blank"
              class="flex-grow-1"
            >
              <img
                v-if="featureToggle['isOfficialSiteShow']"
                v-i18n-src="
                  '@/assets/img/locales/{locale}/home/side-bar-more-courses.png'
                "
                class="w-100"
                alt=""
              />
            </a>
            <a
              v-if="featureToggle['isFandoraShopShow']"
              :href="manifest.fandoraShop"
              class="ml-2"
              target="_blank"
            >
              <img
                v-i18n-src="
                  '@/assets/img/locales/{locale}/home/side-bar-shop.png'
                "
                width="102"
                alt=""
              />
            </a>
          </div>
        </div>
      </template>
    </b-sidebar>

    <ModalConfirm
      v-if="isEmptyMessageShow"
      :hide-cancel="true"
      @confirm="isEmptyMessageShow = false"
    >
      <template>
        {{ $t('目前沒有學生') }}
        <br />
        {{ $t('請洽官方人員協助新增') }}
      </template>
    </ModalConfirm>
  </div>
</template>

<script>
import Logo from '@/assets/img/logo-black-transparent.svg?srcset';
import {getLocalData, saveLocalData} from '@/lib/base/localData';
import {Browser} from '@capacitor/browser';
import BackEvent from '@/lib/base/backEvent.js';
import {manifest} from '@/constant/env.js';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import LetsGo from '@/assets/img/icon/lets go.png';
import featureToggle from '@/constant/featureToggle.js';
import {Device} from '@capacitor/device';

export default {
  components: {ModalConfirm},
  data() {
    return {
      featureToggle,
      LetsGo,
      Logo,
      togglePlanVisible: false,
      isEmptyMessageShow: false,
      manifest,
      webLinks: [
        {
          key: 'web',
          title: this.$t('官方網站'),
          href: manifest.homePageUrl,
        },
        {
          key: 'discord',
          title: this.$t('圍棋社群（Discord）'),
          href: manifest.discord,
          isHidden: true,
        },
        {
          key: 'accupass',
          title: this.$t('實體活動（Accupass）'),
          href: manifest.accupass,
        },
        {
          key: 'store',
          title: this.$t('商城'),
          href: manifest.fandoraShop,
        },
      ],
      isCameraToScoreGameList: false,
      isShowTeacherList: false,
      platform: 'web',
    };
  },
  computed: {
    isTeacher() {
      return this.$store.getters['user/isTeacher'];
    },
    isStudent() {
      return this.$store.getters['user/isStudent'];
    },
    teacherLiveCourseTags() {
      return this.$store.state.user.teacherLiveCourseTags;
    },
    notifiable() {
      return (
        this.verificationData.state === 'ENABLE' ||
        this.verificationData.state === 'SCORE'
      );
    },
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
    visible() {
      return this.$store.state.env.isSidebarShow;
    },
    noPlans() {
      return this.$store.getters['course/noPlans'];
    },
    active() {
      return this.$route.matched[0]?.name;
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    plans() {
      return this.$store.getters['course/plans'];
    },
    primaryLinks() {
      return [
        {
          key: 'ladder',
          icon: 'Stairs',
          title: this.$t('天梯闖關'),
          event: 'ladder',
        },
        {
          key: 'tournament',
          img: LetsGo,
          title: this.$t('九路排名賽'),
          event: 'tournament',
        },
        // {
        //   key: 'pvp',
        //   icon: 'Battle',
        //   title: this.$t('好友對戰'),
        //   event: this.$device.isMobile ? 'pvp-menu' : 'pvp',
        //   toNoLessonPage: true,
        // },
      ];
    },
    secondaryLinks() {
      const teacherLinks = [
        {
          key: 'student-information',
          title: this.$t('學生資訊'),
          event: 'student-information',
        },
        // {
        //   key: 'pvp-create',
        //   title: this.$t('建立對局'),
        //   event: 'pvp-create',
        // },
        {
          key: 'multimedia-classroom',
          title: this.$t('多媒體教室'),
          event: 'multimedia-classroom',
        },
        {
          key: 'multimedia-classroom-type-list',
          title: this.$t('教學課件'),
          event: 'multimedia-classroom-type-list',
        },
      ];
      if (this.teacherLiveCourseTags.length > 0) {
        teacherLinks.splice(1, 0, {
          key: 'live-manage',
          title: this.$t('直播管理'),
          event: 'live-manage',
        });
      }
      return [
        {
          key: 'teacher',
          icon: 'school',
          title: this.$t('教師專區'),
          event: 'teacher',
          visible: this.isShowTeacherList,
          isHidden: !this.isTeacher,
          items: teacherLinks,
        },
        {
          key: 'studentManagement',
          icon: 'school',
          title: this.$t('學員專區'),
          event: 'studentManagement',
          isHidden: !this.isStudent,
        },
        {
          key: 'kifus',
          icon: 'Kifu',
          title: this.$t('我的棋譜'),
          event: 'kifus',
        },
        {
          key: 'certificates',
          icon: 'Certificate',
          title: this.$t('棋力證書'),
          event: 'certificates',
        },
        {
          key: 'nfts',
          icon: 'collect',
          title: this.$t('我的收藏'),
          event: 'nfts',
        },
        {
          key: 'cameraToScoreGame',
          icon: 'Camera',
          title: this.$t('拍照數地'),
          // isHidden: this.platform === 'web',
          isHidden: true,
          visible: this.isCameraToScoreGameList,
          event: 'cameraToScoreGame',
          items: [
            {
              key: 'camera9',
              title: this.$t('{v1}路', {v1: 9}),
              event: 'camera9',
            },
            {
              key: 'camera13',
              title: this.$t('{v1}路', {v1: 13}),
              event: 'camera13',
            },
            {
              key: 'camera19',
              title: this.$t('{v1}路', {v1: 19}),
              event: 'camera19',
            },
          ],
        },
        {
          key: 'brickAndMortarStore',
          icon: 'Location',
          title: this.$t('實體據點'),
          event: 'brickAndMortarStore',
        },

        // {
        //   key: 'learning-process',
        //   icon: 'Learning-history',
        //   title: this.$t('學習歷程'),
        //   event: 'learning-process',
        // },

        // {
        //   key: 'statistics',
        //   icon: 'Report',
        //   title: this.$t('數據記錄'),
        //   event: 'statistics',
        // },
      ];
    },
    mobileList() {
      return [
        {
          key: 'primary',
          group: this.primaryLinks,
        },
        {
          key: 'secondary',
          title: this.$t('其他'),
          group: this.secondaryLinks,
        },
      ];
    },
    allStudents() {
      return this.$store.getters['teacherManagement/allStudents'];
    },
  },
  watch: {
    verificationData() {
      if (this.currentPlan.id != null) {
        this.setVerificationNotification();
      }
    },
  },
  methods: {
    isActive(tab) {
      if (this.noPlans && tab.toNoLessonPage) {
        const type = this.$route.params.type;
        return type === tab.event;
      } else if (
        this.$device.isMobile ||
        ['kifus', 'learning-process', 'nfts', 'personal'].includes(tab.event)
      ) {
        return this.active === tab.event;
      } else {
        return (
          this.$route.path.includes(tab.event) || this.active === tab.event
        );
      }
    },
    async onClickEvent(tab) {
      if (tab.href) {
        return Browser.open({
          url: tab.href,
          presentationStyle: 'popover',
        });
      }
      switch (tab.event) {
        case 'question':
          this.closeSidebar();
          this.$eventBus.$emit('toggleModalPostQuestion');
          break;
        case 'brickAndMortarStore':
          this.closeSidebar();
          window.open(
            `${manifest.homePageUrl}/origin/#/brick-and-mortar-store`
          );
        case 'teacher':
          this.isShowTeacherList = !this.isShowTeacherList;
          break;
        case 'cameraToScoreGame':
          this.isCameraToScoreGameList = !this.isCameraToScoreGameList;
          break;
        case 'camera9':
          this.closeSidebar();
          this.isCameraToScoreGameList = !this.isCameraToScoreGameList;
          if (this.$route.name !== tab.event) {
            return this.$router.push({
              name: 'cameraToScoreGame',
              query: {size: 9},
            });
          }
          break;
        case 'camera13':
          this.closeSidebar();
          this.isCameraToScoreGameList = !this.isCameraToScoreGameList;
          if (this.$route.name !== tab.event) {
            return this.$router.push({
              name: 'cameraToScoreGame',
              query: {size: 13},
            });
          }
          break;
        case 'camera19':
          this.closeSidebar();
          if (this.$route.name !== tab.event) {
            return this.$router.push({
              name: 'cameraToScoreGame',
              query: {size: 19},
            });
          }
          break;
        case 'student-information':
          // todo[perf]: 與 TeacherContainer.vue 中判斷邏輯整併
          this.closeSidebar();
          try {
            this.$store.commit('env/setIsLoading', true);
            this.$store.commit('env/setIsSidebarShow', false);
            await this.$store.dispatch('teacherManagement/getClasses');
            await this.$store.dispatch('teacherManagement/getStudentsByClass');
            if (this.allStudents.length === 0) {
              this.isEmptyMessageShow = true;
              return;
            }
            return this.$router.push({
              name: 'student-information',
              query: {
                active: 'learning-process',
              },
            });
          } catch (error) {
            console.error(error);
            this.$Message.error(this.$t('發生未知錯誤，再試一次'));
          } finally {
            this.$store.commit('env/setIsLoading', false);
          }
        case 'tournament':
          this.closeSidebar();
          return this.$router.push({
            name: 'tournament',
            query: {
              tournamentType: 'tournament_9',
            },
          });
        case 'personal':
          this.closeSidebar();
          return this.$router.push({
            name: 'personal',
          });
        case 'practiceGame':
          this.closeSidebar();
          this.$store.commit('env/setIsLoading', true);
          await this.getUnfinishedGame();
          return this.$router.push({
            name: 'practiceGame',
          });
        case 'practiceTsume':
          this.closeSidebar();
          return this.$router.push({
            name: 'practiceTsume',
            params: {rank: 'uncast', type: 'uncast'},
          });
        case 'more':
          this.$store.commit('env/setIsSidebarShow', true);
          break;

        case 'multimedia-classroom':
          this.closeSidebar();
          return window.open('/#/multimedia-classroom');
        case 'multimedia-classroom-type-list':
          this.closeSidebar();
          return this.$router.push({
            name: 'multimedia-classroom-type-list',
          });
        case 'live-manage':
          this.closeSidebar();
          return this.$router.push({
            name: 'live-manage',
          });
        case 'ladder':
          this.closeSidebar();
          this.$router.push({name: 'ladder'});
          break;
        default:
          this.closeSidebar();
          if (this.noPlans && tab.toNoLessonPage) {
            return this.$router.push({
              name: 'no-lesson',
              params: {
                type: tab.event,
              },
            });
          } else if (this.$route.name !== tab.event) {
            return this.$router.push({name: tab.event});
          }
      }
    },
    closeSidebar() {
      this.$store.commit('env/setIsSidebarShow', false);
    },
    setVerificationNotification() {
      const plansNotifiableStatus =
        JSON.parse(getLocalData('plans-notifiable-status')) || {};
      this.$store.commit(
        'env/setHasVerificationNotification',
        this.notifiable && !plansNotifiableStatus[this.currentPlan.id]
      );

      if (!this.notifiable) {
        plansNotifiableStatus[this.currentPlan.id] = this.notifiable;
        saveLocalData(
          'plans-notifiable-status',
          JSON.stringify(plansNotifiableStatus)
        );
      }
    },
    async getUnfinishedGame() {
      await this.$store.dispatch('aiGame/getUnfinishedGame');
      this.$store.commit('env/setIsLoading', false);
    },
    visibleChange(visible) {
      this.$store.commit('env/setIsSidebarShow', visible);
      this.togglePlanVisible = false;
      if (!visible) {
        BackEvent.popEvent();
      }
    },
    onClickPlan(plan) {
      this.$store.dispatch('env/changePlanInit', plan);
      this.onClickClose();
    },
    onClickClose() {
      this.$store.commit('env/setIsSidebarShow', false);
      this.togglePlanVisible = false;
      BackEvent.popEvent();
    },
  },
  async created() {
    const {platform} = await Device.getInfo();
    this.platform = platform;
  },
};
</script>

<style lang="scss" scoped>
:deep() .b-sidebar-backdrop {
  background: $grayscale-50 !important;
}
:deep() .b-sidebar {
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
}
:deep() .b-sidebar-body {
  overflow: overlay;
}
:deep().collapse {
  margin-top: -$spacing-xxs;
  display: block !important;
  height: 0;
  overflow: hidden;
  &.show {
    margin-top: 0;
    height: auto;
  }
  &.collapsing {
    margin-top: 0;
  }
}

#sidebar-backdrop {
  .sidebar-content {
    margin-top: 4px;
  }

  .list {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
  .title {
    padding: 0 20px;
    color: $font-grayscale-2;
    margin-bottom: 8px;
    line-height: 30px;
    background: $grayscale-5;
    border-radius: 3px;
  }
  .list-item {
    padding: 9px 0px;
    &:not(:last-child) {
      border-bottom: 1px solid $line-2;
    }
  }
  .notification {
    width: 14px;
    height: 14px;
    top: 9px;
    left: 20px;
    border-radius: 50%;
  }
}

@media screen and (min-width: 768px) {
  :deep() .b-sidebar {
    left: 120px;
    &:not(.b-sidebar-right) {
      left: 120px;
    }
  }
  #sidebar-backdrop {
    .icon-Circle-X {
      top: 16px;
      right: 16px;
    }
    .sidebar-content {
      margin-top: 0;
      padding: 72px 24px;
    }
    h4 {
      color: $font-grayscale-2;
    }
    .button {
      border-radius: $rounded-md;
      line-height: 30px;
      background: $white;
      transition: all 0.3s;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      &:hover {
        background: rgba(255, 192, 105, 0.3);
      }
      &.active {
        color: $white;
        background: $secondary;
      }
    }
    .link {
      text-decoration: none;
      color: $font-grayscale-1;
      background: $white;
      border-radius: $rounded-md;
      line-height: 40px;
      &:hover {
        background: rgba(255, 192, 105, 0.3);
      }
    }
    .notification {
      width: 14px;
      height: 14px;
      top: 24px;
      left: 42px;
      border-radius: 50%;
    }
  }
}
</style>
