export default [
  {
    path: '/pvp-wait/:id',
    name: 'pvp-wait',
    component: () => import('@/views/Pvp/PvpWait.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      transitionDepth: 2,
    },
  },
  {
    path: '/pvp-join/:id',
    name: 'pvp-join',
    component: () => import('@/views/Pvp/PvpJoin.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
    },
  },
  {
    path: '/pvp',
    name: 'pvp',
    component: () => import('@/views/Pvp/Pvp.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      depth: 1,
      transitionDepth: 4,
      hasPassAnimation: true,
      chessboardRoom: true,
    },
  },
];
