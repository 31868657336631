import dayjs from 'dayjs';
import {saveLocalData, removeLocalData} from '@/lib/base/localData';
import userService from '@/services/user';
import aiGameService from '@/services/aiGame';
import verificationService from '@/services/verification';
import liveCourseService from '@/services/liveCourse';
import router from '@/router';
import socket from '@/lib/socket/socket.js';
import store from '@/store/index.js';
import _ from 'lodash';

/**
 * @returns {Object} initialize state
 */
const initialState = () => {
  return {
    userData: {},
    nickName: null,
    username: null,
    gender: null,
    verifyOptData: {},
    userStatistics: {},
    purchaseLogs: null,
    academys: [],
    classes: [],
    teacherLiveCourseTags: [],
  };
};
const state = initialState();
const getters = {
  userData(state) {
    return state.userData;
  },
  isLogin(state) {
    return !!state.userData?.username;
  },
  verifyOptData(state) {
    return state.verifyOptData;
  },
  userStatistics(state) {
    return state.userStatistics;
  },
  hasPermission(state) {
    return (permission) =>
      state.userData.currentPlanPermissions &&
      state.userData.currentPlanPermissions.includes(permission);
  },
  hasLegacyAdvancedPlanActivated(state) {
    return state.userData.isIntelligentVersionActive;
  },
  liveCourseExpiredDay() {
    const now = dayjs();
    const expired = dayjs(state.userData?.liveCourseEndAt);
    const hour = expired.diff(now, 'hour');
    const expiredDay = Math.ceil(hour / 24);
    return expiredDay;
  },
  purchaseLogs(state) {
    return state.purchaseLogs;
  },
  academys(state) {
    return state.academys;
  },
  classes(state) {
    return state.classes;
  },
  processAcademys(state) {
    if (!state.academys) return null;
    const _academys = state.academys.filter((academy) => {
      state.classes.forEach((clazz) => {
        if (academy.id === clazz.subAcademyId && !clazz.description) {
          clazz.description = academy.description;
        }
      });
      const hasAcademy = state.classes.find(
        (clazz) => academy.id === clazz.subAcademyId
      );
      if (!hasAcademy) return academy;
    });
    return _.concat(_academys, state.classes).sort((a, b) => {
      return dayjs(b.joinedAt).valueOf() - dayjs(a.joinedAt).valueOf();
    });
  },
  isTeacher(state) {
    return state.userData?.academyInfo?.role?.isTeacher;
  },
  isStudent(state) {
    return state.userData?.academyInfo?.role?.isStudent;
  },
  allUsernames(state) {
    return state.userData?.allUsernames;
  },
  usernameTypeDetails(state) {
    return (type) => {
      if (state.userData.allUsernames) {
        return state.userData.allUsernames.find(
          (username) => type === username.type
        );
      }
    };
  },
  isVip(state) {
    return dayjs().isBefore(state.userData.liveCourseEndAt);
  },
  isInteriorTeacher(state, getters) {
    return (
      getters.isTeacher &&
      state.userData.subAcademies?.some(
        (subAcademy) => subAcademy.authType === 'INTERIOR'
      )
    );
  },
  isFirstLogin(state) {
    return state.userData?.config?.isFirstLogin;
  },
};
const actions = {
  login({commit}, {username, password, language}) {
    removeLocalData('jwt');
    return userService.login(username, password, language).then((loginData) => {
      commit('setJwt', `Bearer ${loginData.data.token}`);
    });
  },
  thirdPartyLogin({}, {token, platform, options}) {
    return userService.thirdPartyLogin({token, platform, options});
  },
  setUsernameByThirdParty({}, {token, platform, options}) {
    return userService.setUsernameByThirdParty({token, platform, options});
  },
  async loginWithJwt({commit, getters}, {force = false} = {}) {
    if (force) {
      removeLocalData('user');
      userService.getUserData?.cache.clear();
    }
    const user = await userService.getUserData();
    commit('setLogin', user);
    const isTeacher = getters.isTeacher;
    if (isTeacher) {
      const tags = await liveCourseService.getTeacherTags();
      console.log(tags);
      commit('setTeacherLiveCourseTags', tags);
    }
    return user;
  },
  logout({commit}) {
    commit('setLogout');
    commit('ladder/clearUserStatus', {}, {root: true});
  },
  changePassword({}, {oldPassword, newPassword}) {
    return userService.changePassword({oldPassword, newPassword});
  },
  resetPassword(
    {},
    {language, password, verificationId, verificationCode, isEmail}
  ) {
    return userService.resetPassword({
      language,
      password,
      verificationId,
      verificationCode,
      isEmail,
    });
  },
  sendResetPasswordOtp(
    {},
    {language, internationalPhoneNumber, email, redirect, recaptchaToken}
  ) {
    return userService.sendResetPasswordOtp({
      language,
      internationalPhoneNumber,
      email,
      redirect,
      recaptchaToken,
    });
  },
  validateResetPasswordOtp({commit}, verifyOptData) {
    commit('setOpt', verifyOptData);
    return userService.validateResetPasswordOtp(verifyOptData);
  },
  sendSignupOpt({}, {language, internationalPhoneNumber, recaptchaToken}) {
    return userService.sendSignupOpt({
      language,
      internationalPhoneNumber,
      recaptchaToken,
    });
  },
  validateSignupOpt({commit}, verifyOptData) {
    commit('setOpt', verifyOptData);
    return userService.validateSignupOpt(verifyOptData);
  },
  signup(
    {},
    {language, username, academyId, password, phone, realName, nickName}
  ) {
    return userService.signup({
      language,
      username,
      academyId,
      password,
      phone,
      realName,
      nickName,
    });
  },
  sendSignupOtpEmail(
    {},
    {email, language, academyId, password, realName, nickName, redirect}
  ) {
    return userService.sendSignupOtpEmail({
      email,
      language,
      academyId,
      password,
      realName,
      nickName,
      redirect,
    });
  },
  validateSignupOtpEmail({}, {id, code}) {
    return userService.validateSignupOtpEmail(id, code);
  },
  activateByCode({}, code) {
    return userService.activateByCode(code);
  },
  getUserStatistics({commit}) {
    return userService.getUserStatistics().then((response) => {
      commit('setUserStatistics', response);
    });
  },
  async getOwnPurchaseLog({commit}) {
    const result = await userService.getOwnPurchaseLog();
    commit('setPurchaseLogs', result.data);
  },
  async getAcademySubAcademyClass({commit}) {
    const {class: classes, subAcademy} =
      await userService.getAcademySubAcademyClass();
    const academys = subAcademy.filter(
      (subAcademy) => subAcademy.name !== '無分院'
    );
    commit('setUserAcademys', academys);
    commit('setUserClasses', classes);
  },
  async getLiveCourseEndAt({commit}) {
    const result = await userService.getLiveCourseEndAt();
    commit('setUserLiveCourseTime', result);
    return result;
  },
};
const mutations = {
  setLogin(state, user) {
    state.userData = user;
  },
  setUserLiveCourseTime(
    state,
    {lastShowLiveCourseExpiredTime, liveCourseEndAt}
  ) {
    const userData = state.userData;
    let hasUpdate = false;
    if (lastShowLiveCourseExpiredTime) {
      hasUpdate = true;
      userData.lastShowLiveCourseExpiredTime = lastShowLiveCourseExpiredTime;
    }
    if (liveCourseEndAt) {
      hasUpdate = true;
      userData.liveCourseEndAt = liveCourseEndAt;
    }
    if (hasUpdate) state.userData = userData;
  },
  setJwt(state, jwt) {
    saveLocalData('jwt', jwt);
  },
  setLogout() {
    socket.destroy();
    userService.logout();
    removeLocalData('jwt');
    router.push({name: 'login'});
    removeLocalData('user');
    removeLocalData('course-types-status');
    userService.getUserData?.cache.clear();
    aiGameService.getPracticeGameList?.cache.clear();
    verificationService.getTotalScores?.cache.clear();
    verificationService.getRecords?.cache.clear();
    store.commit('user/resetState');
    store.commit('course/resetState');
    store.commit('verification/resetState');
  },
  setOpt(state, verifyOptData) {
    state.verifyOptData = verifyOptData;
  },
  setUserStatistics(state, value) {
    state.userStatistics = value;
  },
  setPurchaseLogs(state, data) {
    state.purchaseLogs = data;
  },
  setUserAcademys(state, data) {
    state.academys = data;
  },
  setUserClasses(state, data) {
    state.classes = data;
  },
  setTeacherLiveCourseTags(state, data) {
    state.teacherLiveCourseTags = data;
  },
  resetState(state) {
    const _state = initialState();
    Object.assign(state, _state);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
