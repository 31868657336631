/* eslint-disable indent */
/* eslint-disable valid-jsdoc */
/* eslint-disable camelcase */
/* eslint-disable require-jsdoc */
import {OBJECT_TYPE, FIELD_DIRECTION, EVENT_TYPE} from 'wgo-helper';
import {ArgumentHelper} from '@/lib/wgo/argumentHelper';

// ---------- Arguments ------------------------------
// 兩子之間的動畫時間差參數
const INFLUENCE_MONO_DELAY_BASE = 100;
const INFLUENCE_ONE_WAY_ROUND = false;

const DEFAULT_INFLUENCE_THRESHOLD = 0.9;

const {WGo} = window;

ArgumentHelper.set('DEFAULT_OBJECT_MASK_ALPHA_WHITE', 0.35);
ArgumentHelper.set('DEFAULT_OBJECT_MASK_ALPHA_BLACK', 0.18);
ArgumentHelper.set('DEFAULT_OBJECT_MASK_BORDER_RADIUS', 20);
ArgumentHelper.set('DEFAULT_OBJECT_DEAD_STONE_TYPE', OBJECT_TYPE.OUTLINE);
ArgumentHelper.set('DEFAULT_OBJECT_DEAD_STONE_ALPHA', 0.1);
ArgumentHelper.set('INFLUENCE_ONE_WAY_ROUND', true);

// ---------- Common Utils ------------------------------
export const round = (num, digits = 0) => {
  const base = Math.pow(10, digits);
  return Math.round(Number(num) * base) / base;
};

// ---------- Methods ------------------------------
/**
 * 計算相連範圍的中心點
 * 算法：線性中心(座標平均值)
 * @param {*} component component
 * @returns {Array} []
 */
const getComponentCenter = (component) => {
  if (component.points.length === 0) {
    return null;
  }

  const count = component.points.length;

  const [x, y] = component.points.reduce(
    (center, point) => {
      center[0] += point[0];
      center[1] += point[1];
      return center;
    },
    [0, 0]
  );

  return [Math.round(x / count), Math.round(y / count)];
};

/**
 * 檢查目標點是否位於範圍內部
 * @param {[number, number]} point 目標點座標 [y, x]
 * @param {*} component 相連範圍組合
 * @returns {Boolean} true/false
 */
const isInsideComponent = (point, component) => {
  return component.points.some(([y, x]) => y === point[0] && x === point[1]);
};

/**
 * 檢查兩個 component 是否相交
 * @param {*} component1 component1
 * @param {*} component2 component2
 * @param {*} checkColor checkColor
 * @returns {Boolean} true/false
 */
const isComponentIntersect = (component1, component2, checkColor = true) => {
  if (checkColor && component1.c !== component2.c) {
    // 顏色不同
    return false;
  }

  const map = [];
  component1.points.forEach(([x, y]) => {
    const row = map[x] || (map[x] = []);
    row[y] = true;
  });
  return component2.points.some(([x, y]) => map[x] && map[x][y]);
};

/**
 * 落子延遲計算公式
 *
 * TODO: 確定速度公式
 * 要求：延遲時間 = 與距離正相關
 *
 * 公式： delay = 距離 ^ 2 * C
 * @param {number} d2 距離平方
 * @returns {number} 延遲 ms
 */
const dynamicDelay = (d2) => {
  if (d2 === 0) {
    // 距離為零 => 落子中心 => 無延遲
    return 0;
  }

  // return TERRITORIE_MONO_DELAY_BASE * Math.log10(d2); // 1000
  return INFLUENCE_MONO_DELAY_BASE * Math.sqrt(d2); // 100
};

/**
 * 檢查是否為簡單變化組（簡單新增 or 簡單刪除）
 * @param {*} diffComponent diffComponent
 * @param {*} plainDiff plainDiff
 */
const isDiffComponentSimple = (diffComponent, plainDiff) => {
  return diffComponent.points.every(
    ([x, y]) => plainDiff[x][y] >= -1 && plainDiff[x][y] <= 1
  );
};

// ---------- Controller ------------------------------
export class InfluenceController {
  helper;
  getInfluenceAPI;

  // flags / arguments
  isOpen = false;
  _threshold = DEFAULT_INFLUENCE_THRESHOLD;

  // inner states
  removeHelperListener;
  currentInfluence;
  currentInfluenceFiltered;
  currentInfluenceComponents;
  currentInfluenceComponentsMap;
  obj_arr; // [y][x] = { obj, timer, cb }
  dead_stone_arr;
  boardGridSnapshot;

  // ---------- getter/setter ----------
  get threshold() {
    return this._threshold;
  }

  set threshold(threshold) {
    this._threshold = threshold;
    if (this.isOpen) {
      this._updateInfluence(this.currentInfluence);
    }
  }

  // ---------- constructor ----------
  constructor(helper, getInfluenceAPI) {
    this.helper = helper;
    this.getInfluenceAPI = getInfluenceAPI;
  }

  // ---------- public methods ----------
  /**
   * 開啟實時 influence 模式
   *
   * 第一次渲染使用 drawInfluence
   * 後續更新，基於監聽 helper 的 EVENT_TYPE.Update 事件，使用 udpateInfluence 更新
   */
  open() {
    this.isOpen = true;

    const boardWidth = this.helper.boardWidth;
    this.obj_arr = Array.from({length: boardWidth}, () =>
      Array.from({length: boardWidth}, () => null)
    );
    this.dead_stone_arr = Array.from({length: boardWidth}, () =>
      Array.from({length: boardWidth}, () => null)
    );

    // Init render
    this.getInfluenceAPI(this.helper.sgf).then((influence) => {
      this._drawInfluence(influence);
    });

    // Subscribe BaseHelper.Update event
    this.removeHelperListener = this.helper.on(EVENT_TYPE.UPDATE, () => {
      this.getInfluenceAPI(this.helper.sgf).then((influence) => {
        this._updateInfluence(influence);
      });
    });
  }

  /**
   * 關閉 influence 模式
   */
  close() {
    if (!this.isOpen) {
      // not open yet
      return;
    }

    // teardown
    this.removeInfluence();
    this.removeHelperListener();

    // clear state
    this.removeHelperListener = null;
    this.currentInfluence = null;
    this.currentInfluenceFiltered = null;
    this.isOpen = false;
  }

  async draw() {
    const boardWidth = this.helper.boardWidth;
    this.obj_arr = Array.from({length: boardWidth}, () =>
      Array.from({length: boardWidth}, () => null)
    );
    this.dead_stone_arr = Array.from({length: boardWidth}, () =>
      Array.from({length: boardWidth}, () => null)
    );
    const sgf = this.helper.sgf;
    const influence = await this.getInfluenceAPI(sgf);
    this._drawInfluence(influence);
  }

  /**
   * 刷新 influence
   * simple = true  : 使用 helper.redrawBoard 方法（等同於 Board.redraw）
   * simple = false : close 之後重新 open
   * @param {*} simple
   */
  refresh(simple = false) {
    if (!this.isOpen) {
      return;
    }

    if (simple) {
      this.helper.redrawBoard();
    } else {
      this.close();
      this.open();
    }
  }

  // ---------- private methods (entry methods) ----------
  /**
   * 第一次/直接覆蓋 influence
   * @param {*} influence
   */
  _drawInfluence(influence) {
    this.removeInfluence(); // 直接刪除當前 influence

    this.currentInfluence = influence;
    const influenceFiltered = this._influenceFilter(influence);
    this.currentInfluenceFiltered = influenceFiltered;

    const components = this._getConnectedComponents(influenceFiltered);
    this.currentInfluenceComponents = components;
    this.currentInfluenceComponentsMap = this._components2Map(components);
    components.forEach((component) =>
      this._drawInfluenceComponent(component, influenceFiltered)
    );
  }

  /**
   * 更新 influence
   * 計算順序：
   *   1. 計算 influence 變化
   *   2. 計算 influence 變化與舊 influence 關聯組
   *     2.1 簡單變化組合（單向變化）
   *           簡單計算變化方向
   *     2.2 複雜變化組合（轉換）
   *
   *   3. 計算 influence 變化方向(是否存在於落子點內部)
   * @param {*} newInfluence
   * @param {*} center
   */
  _updateInfluence(newInfluence) {
    this._completeDrawImmediately();

    this.boardGridSnapshot = this.helper.boardGrid;

    const newInfluenceFiltered = this._influenceFilter(newInfluence);
    const diff = this._calcInfluenceDiff(
      this.currentInfluenceFiltered,
      newInfluenceFiltered
    );

    this._checkRemainDeadStones();

    /**
     * 1. 處理簡單變化組 + 分類出複雜變化組
     */
    const {plainDiff, addBlack, removeBlack, addWhite, removeWhite} = diff;
    const white2Black = [];
    const black2White = [];
    addBlack.forEach((component) => {
      if (isDiffComponentSimple(component, plainDiff)) {
        this._drawSimpleInfluenceDiffComponent(component, newInfluenceFiltered);
      } else {
        white2Black.push(component);
      }
    });
    addWhite.forEach((component) => {
      if (isDiffComponentSimple(component, plainDiff)) {
        this._drawSimpleInfluenceDiffComponent(component, newInfluenceFiltered);
      } else {
        black2White.push(component);
      }
    });
    removeBlack.forEach((component) => {
      if (isDiffComponentSimple(component, plainDiff)) {
        this._removeSimpleInfluenceDiffComponent(
          component,
          newInfluenceFiltered
        );
      } else {
        black2White.push(component);
      }
    });
    removeWhite.forEach((component) => {
      if (isDiffComponentSimple(component, plainDiff)) {
        this._removeSimpleInfluenceDiffComponent(
          component,
          newInfluenceFiltered
        );
      } else {
        white2Black.push(component);
      }
    });

    /**
     * 2. 處理重疊變化組 addBlack + removeWhite / addWhite + removeBlack
     */
    this._drawComplexInfluenceDiffComponents(
      white2Black,
      WGo.B,
      newInfluenceFiltered
    );
    this._drawComplexInfluenceDiffComponents(
      black2White,
      WGo.W,
      newInfluenceFiltered
    );

    /**
     * 4. Update private fields
     */
    this.currentInfluence = newInfluence;
    this.currentInfluenceFiltered = newInfluenceFiltered;
    this.currentInfluenceComponents =
      this._getConnectedComponents(newInfluenceFiltered);
    this.currentInfluenceComponentsMap = this._components2Map(
      this.currentInfluenceComponents
    );

    console.groupEnd();
  }

  /**
   * 刪除所有 influence
   */
  removeInfluence() {
    this.boardGridSnapshot = this.helper.boardGrid;
    if (!this.obj_arr) return;
    this.obj_arr.forEach((row) =>
      row.forEach((record) => {
        if (record) {
          clearTimeout(record.timer);
          this._removeObject(record.obj);
        }
      })
    );
  }

  // ---------- private methods ----------
  /**
   * 計算實地內的相連範圍組
   * @param {Array<Array<number>>} influence 實地範圍
   * @returns
   */
  _getConnectedComponents(influence) {
    const boardWidth = this.helper.boardWidth;
    const hasRead = influence.map((row) => row.map(() => false));
    const components = [];

    const findComponent = (x, y, points = []) => {
      if (
        x < 0 ||
        y < 0 ||
        x >= boardWidth ||
        y >= boardWidth ||
        hasRead[x][y]
      ) {
        return;
      }

      const c = influence[x][y];
      let startPoint = false;

      if (points.length === 0) {
        // 起點
        if (c === 0) {
          // 無落子
          hasRead[x][y] = true;
          return;
        }

        startPoint = true;
      } else {
        // 延伸點
        const [x0, y0] = points[0];
        const componentC = influence[x0][y0];

        if (c !== componentC) {
          // 與當前組不相同
          return;
        }
      }

      hasRead[x][y] = true;
      points.push([x, y]);

      // 遞歸收集上下左右
      findComponent(x - 1, y, points);
      findComponent(x + 1, y, points);
      findComponent(x, y - 1, points);
      findComponent(x, y + 1, points);

      if (startPoint && points.length) {
        components.push({points, c});
      }
    };

    for (let x = 0; x < influence.length; x++) {
      for (let y = 0; y < influence[0].length; y++) {
        findComponent(x, y);
      }
    }

    return components;
  }

  /**
   * 過濾沒有圍空的 influence 範圍
   * @param {*} influence
   * @returns
   */
  _influenceFilter(influence) {
    const {threshold} = this;

    /**
     * 1. 將實地範圍歸一化
     *    超過閾值的轉為 1 / -1
     *    沒超過的下修為 0
     */
    const newInfluence = influence.map((row) =>
      row.map((guess_c) => {
        if (guess_c >= threshold || guess_c <= -threshold) {
          return guess_c > 0 ? WGo.B : -1;
        } else {
          return 0;
        }
      })
    );

    /**
     * 2. 過濾與當前落子完全貼合的 influence 組合
     */
    // TODO 確定是否可以將 sgf 直接轉換成落子 array
    const components = this._getConnectedComponents(newInfluence);
    components.forEach((component) => {
      // TODO 改成刪除範圍只有落子沒有實地的組合
      if (component.points.length === 1) {
        const [x, y] = component.points[0];
        newInfluence[x][y] = 0;
      }
    });

    return newInfluence;
  }

  /**
   * 根據 influence 計算 (x,y) 上的樣式
   * @param {*} influence
   * @param {*} x
   * @param {*} y
   * @returns
   */
  _calcInfluenceObject(influence, x, y) {
    const boardWidth = this.helper.boardWidth;

    // 周圍棋子
    const left_c = x > 0 ? influence[y][x - 1] : null;
    const top_c = y > 0 ? influence[y - 1][x] : null;
    const right_c = x < boardWidth - 1 ? influence[y][x + 1] : null;
    const bottom_c = y < boardWidth - 1 ? influence[y + 1][x] : null;

    // ------------------------------
    // 添加目標物件
    const obj = {
      x,
      y,
      type: OBJECT_TYPE.MASK,
      c: 0,
      options: {},
    };

    if (influence[y][x] !== 0) {
      /**
       * 1. influence 內部
       */
      const c = influence[y][x];
      obj.c = c;

      // ------------------------------
      // 邊框
      const border = [];

      const isOneWayRound = window.ArgumentHelper.get(
        'INFLUENCE_ONE_WAY_ROUND',
        INFLUENCE_ONE_WAY_ROUND
      );
      const top = (isOneWayRound || top_c != null) && top_c !== c;
      const bottom = (isOneWayRound || bottom_c != null) && bottom_c !== c;
      const left = (isOneWayRound || left_c != null) && left_c !== c;
      const right = (isOneWayRound || right_c != null) && right_c !== c;

      // 圓角
      left && top && border.push(FIELD_DIRECTION.LEFT_TOP);
      left && bottom && border.push(FIELD_DIRECTION.LEFT_BOTTOM);
      right && top && border.push(FIELD_DIRECTION.RIGHT_TOP);
      right && bottom && border.push(FIELD_DIRECTION.RIGHT_BOTTOM);

      if (border.length) {
        obj.options.border = border;
      }
    }

    const left_top_c = x > 0 && y > 0 ? influence[y - 1][x - 1] : null;
    const right_top_c =
      x < boardWidth - 1 && y > 0 ? influence[y - 1][x + 1] : null;
    const right_bottom_c =
      x < boardWidth - 1 && y < boardWidth - 1 ? influence[y + 1][x + 1] : null;
    const left_bottom_c =
      x > 0 && y < boardWidth - 1 ? influence[y + 1][x - 1] : null;

    /**
     * 2. influence 外部
     * 檢查外邊緣樣式
     */
    const outerBorder = {};
    left_c &&
      left_c === top_c &&
      left_c === left_top_c &&
      left_c !== obj.c &&
      (outerBorder[FIELD_DIRECTION.LEFT_TOP] = left_c);
    right_c &&
      right_c === top_c &&
      right_c === right_top_c &&
      right_c !== obj.c &&
      (outerBorder[FIELD_DIRECTION.RIGHT_TOP] = right_c);
    right_c &&
      right_c === bottom_c &&
      right_c === right_bottom_c &&
      right_c !== obj.c &&
      (outerBorder[FIELD_DIRECTION.RIGHT_BOTTOM] = right_c);
    left_c &&
      left_c === bottom_c &&
      left_c === left_bottom_c &&
      left_c !== obj.c &&
      (outerBorder[FIELD_DIRECTION.LEFT_BOTTOM] = left_c);

    if (Object.keys(outerBorder).length) {
      obj.options.outerBorder = outerBorder;
    } else if (influence[y][x] === 0) {
      // 沒有內部也沒有外圓角
      return null;
    }

    return obj;
  }

  /**
   * 以 Component 為單位進行渲染
   * @param {*} component
   * @param {*} influence
   * @param {*} center
   */
  _drawInfluenceComponent(component, influence, center) {
    // 1. 實地範圍
    const objects = component.points.map(([y, x]) =>
      this._calcInfluenceObject(influence, x, y)
    );

    // 2. 檢查邊緣 outer border
    this._getSurroundingPoints(objects.map(({x, y}) => [x, y])).forEach(
      ([x, y]) => {
        const obj = this._calcInfluenceObject(influence, x, y);
        if (obj) {
          objects.push(obj);
        }
      }
    );

    const delayMap = new Map();
    const multiCenter =
      !center || center.length === 0
        ? [getComponentCenter(component)]
        : Array.isArray(center[0])
        ? center
        : [center];
    multiCenter.forEach((center) => {
      delayMap.set(center, []);
    });

    // 3. 計算每個 object 的延遲
    objects.forEach((obj) => {
      const {x, y, c} = obj;

      // ------------------------------
      // 動畫
      // 由落子中心出發
      let minDelay = Infinity;
      let nearestCenter = null;
      multiCenter.forEach((center) => {
        const [cy, cx] = center;
        const d2 = (x - cx) ** 2 + (y - cy) ** 2; // 與中心距離
        const delay = dynamicDelay(d2);

        if (c === 0) {
          // 純粹的外圓角，跟隨最遠組合才出現
          if (!nearestCenter || delay > minDelay) {
            minDelay = delay;
            nearestCenter = center;
          }
        } else if (delay < minDelay) {
          minDelay = delay;
          nearestCenter = center;
        }
      });

      if (nearestCenter) {
        delayMap.get(nearestCenter).push({obj, delay: minDelay});
      } else {
        obj.options.delay = 0;
      }
    });

    // 4. 減去最小延遲
    multiCenter.forEach((center) => {
      const records = delayMap.get(center);

      if (records.length === 0) {
        return;
      }

      const minDelay = records.reduce(
        (res, {delay}) => (res <= delay ? res : delay),
        Infinity
      );
      records.forEach(({obj, delay}) => {
        obj.options.delay = delay - minDelay;
      });
    });

    // 5. Add
    objects.forEach((obj) => this._addObject(obj, obj.options.delay));
  }

  /**
   * 以 component 為單位移除 influence
   * 方向：外側指向 center（使用 reverse 反轉）
   * @param {*} component
   * @param {*} influence
   * @param {*} center
   * @param {*} reverse
   */
  _removeInfluenceComponent(component, influence, center, reverse) {
    /**
     * 1. 刪除 component
     */
    const objects = component.points.map(([y, x]) => ({
      x,
      y,
      type: OBJECT_TYPE.MASK,
      options: {},
    }));

    /**
     * 檢查 component 邊緣
     */
    this._getSurroundingPoints(objects.map(({x, y}) => [x, y])).forEach(
      ([x, y]) => {
        if (this.obj_arr[x][y]) {
          objects.push(this.obj_arr[x][y].obj);
        }
        // const obj = this._calcInfluenceObject(influence, x, y);
        // if (obj) {
        //   objects.push(obj);
        // }
      }
    );

    const delayMap = new Map();
    const multiCenter =
      !center || center.length === 0
        ? [getComponentCenter(component)]
        : Array.isArray(center[0])
        ? center
        : [center];
    multiCenter.forEach((center) => {
      delayMap.set(center, []);
    });

    objects.forEach((obj) => {
      const {x, y} = obj;

      // ------------------------------
      // 動畫
      // 由落子中心出發
      let minDelay = Infinity;
      let nearestCenter = null;
      multiCenter.forEach((center) => {
        const [cy, cx] = center;
        const d2 = (x - cx) ** 2 + (y - cy) ** 2; // 與中心距離
        const delay = dynamicDelay(d2);

        if (delay < minDelay) {
          minDelay = delay;
          nearestCenter = center;
        }
      });

      // 取最近的點(delay 最小)
      if (nearestCenter) {
        delayMap.get(nearestCenter).push({obj, delay: minDelay});
      } else {
        obj.options.delay = 0;
      }
    });

    multiCenter.forEach((center) => {
      const records = delayMap.get(center);

      if (records.length === 0) {
        return;
      }

      const minDelay = records.reduce(
        (res, {delay}) => Math.min(res, delay),
        Infinity
      );
      const maxDelay = records.reduce(
        (res, {delay}) => Math.max(res, delay),
        0
      );
      records.forEach(({obj, delay}) => {
        obj.options.delay = reverse
          ? delay - minDelay
          : maxDelay - (delay - minDelay);
      });
    });

    objects.forEach((obj) =>
      this._removeObject(obj, obj.options.delay, () => {
        const newObj = this._calcInfluenceObject(influence, obj.x, obj.y);
        if (newObj) {
          this._addObject(newObj);
        }
      })
    );
  }

  /**
   * 簡單 addBlack / addWhite
   * @param {*} component
   * @param {*} reverse add 為 false, remove 為 true
   */
  _drawSimpleInfluenceDiffComponent(component, influence) {
    const nearbyComponents = this._getNearbyComponents(component);

    if (nearbyComponents.length) {
      // 以最大相連組為中心
      const multiCenter = nearbyComponents.map((component) =>
        getComponentCenter(component)
      );
      this._drawInfluenceComponent(component, influence, multiCenter);
    } else {
      const {x, y} = this.helper.node.move;

      if (isInsideComponent([y, x], component)) {
        const center = [y, x];
        this._drawInfluenceComponent(component, influence, center);
      } else {
        const center = getComponentCenter(component);
        this._drawInfluenceComponent(component, influence, center);
      }
    }
  }

  /**
   * 簡單 removeBlack / removeWhite
   * @param {*} component
   * @param {*} influence
   */
  _removeSimpleInfluenceDiffComponent(component, influence) {
    const nearbyComponents = this._getNearbyComponents(component);

    if (nearbyComponents.length) {
      const multiCenter = nearbyComponents.map((component) =>
        getComponentCenter(component)
      );
      this._removeInfluenceComponent(component, influence, multiCenter);
    } else {
      const center = getComponentCenter(component);
      this._removeInfluenceComponent(component, influence, center);
    }
  }

  /**
   * 複雜 influenceDiff = 黑/白增加實地與白/黑減少實地重疊
   * @param {*} diffComponents
   * @param {*} addColor
   * @param {*} influence
   */
  _drawComplexInfluenceDiffComponents(diffComponents, addColor, influence) {
    const restComponents = diffComponents;
    while (restComponents.length) {
      // 找到第一個 add
      let i;
      for (i = 0; i < restComponents.length; i++) {
        if (restComponents[i].c === addColor) {
          break;
        }
      }

      if (i === restComponents.length) {
        // 沒有 add 了，剩下都是 remove
        restComponents.forEach((component) => {
          this._removeSimpleInfluenceDiffComponent(component, influence);
        });
        break;
      }

      // 以 add 為主要出發方向
      const addComponent = restComponents.splice(i, 1)[0];
      const removeComponents = [];

      for (i = 0; i < restComponents.length; ) {
        if (isComponentIntersect(addComponent, restComponents[i], false)) {
          removeComponents.push(restComponents.splice(i, 1)[0]);
        } else {
          i++;
        }
      }

      // check last hand as center
      let center;
      const {x, y} = this.helper.node.move;
      if (isInsideComponent([y, x], addComponent)) {
        center = [y, x];
      } else {
        center = getComponentCenter(addComponent);
      }
      removeComponents.forEach((component) =>
        this._removeInfluenceComponent(component, influence, center, true)
      );
      this._drawInfluenceComponent(addComponent, influence, center);
    }
  }

  /**
   * 從當前 influenceCompoentsMap 中找尋相鄰的組合
   * @param {*} component
   * @returns
   */
  _getNearbyComponents(component) {
    const nearbyComponents = [];

    this._getSurroundingPoints(component.points).forEach(([x, y]) => {
      const nearbyComponent =
        this.currentInfluenceComponentsMap[x] &&
        this.currentInfluenceComponentsMap[x][y];

      // 檢查顏色
      if (
        nearbyComponent &&
        !nearbyComponents.includes(nearbyComponent) &&
        nearbyComponent.c === component.c
      ) {
        nearbyComponents.push(this.currentInfluenceComponentsMap[x][y]);
      }
    });

    return nearbyComponents;
  }

  /**
   * 計算 influence 差異
   *   分為 add / remove + black / white 四種組合
   * @param {*} oldInfluence
   * @param {*} newInfluence
   * @returns
   */
  _calcInfluenceDiff(oldInfluence, newInfluence) {
    // ! No size check, assert it outside
    const boardWidth = this.helper.boardWidth;

    const plainDiff = [];
    const addBlackDiff = [];
    const removeBlackDiff = [];
    const addWhiteDiff = [];
    const removeWhiteDiff = [];

    for (let x = 0; x < boardWidth; x++) {
      plainDiff.push([]);
      addBlackDiff.push([]);
      removeBlackDiff.push([]);
      addWhiteDiff.push([]);
      removeWhiteDiff.push([]);

      for (let y = 0; y < boardWidth; y++) {
        const c1 = oldInfluence[x][y];
        const c2 = newInfluence[x][y];

        plainDiff[x][y] = c2 - c1;
        addBlackDiff[x][y] = c2 === WGo.B && c1 !== WGo.B ? WGo.B : 0;
        removeBlackDiff[x][y] = c1 === WGo.B && c2 !== WGo.B ? WGo.B : 0;
        addWhiteDiff[x][y] = c2 === WGo.W && c1 !== WGo.W ? WGo.W : 0;
        removeWhiteDiff[x][y] = c1 === WGo.W && c2 !== WGo.W ? WGo.W : 0;
      }
    }

    const addBlack = this._getConnectedComponents(addBlackDiff);
    const removeBlack = this._getConnectedComponents(removeBlackDiff);
    const addWhite = this._getConnectedComponents(addWhiteDiff);
    const removeWhite = this._getConnectedComponents(removeWhiteDiff);

    return {
      plainDiff,
      addBlack,
      removeBlack,
      addWhite,
      removeWhite,
    };
  }

  _checkRemainDeadStones() {
    const boardWidth = this.helper.boardWidth;
    for (let x = 0; x < boardWidth; x++) {
      for (let y = 0; y < boardWidth; y++) {
        const influence_c = this.currentInfluenceFiltered[y][x];
        const stone_c = this.boardGridSnapshot[y][x];

        if (stone_c === 0) {
          // Board 吃子/刪除 MONO 的時候清理 dead_stone_arr 紀錄
          this.dead_stone_arr[x][y] = null;
        }

        if (influence_c !== 0 && stone_c !== 0 && influence_c !== stone_c) {
          this._replaceWithDeadStone(x, y);
        }
      }
    }
  }

  // ----------  ----------
  /**
   * 添加 Object & 記錄到 obj_arr
   * obj_arr 內保存對象為 record 對象
   * Record {
   *   obj,      添加到 Board 的目標 object
   *   timer,    延遲執行計時器
   *   cb,       延遲執行任務體
   * }
   *
   * _addObject 會再更新的同時默認清理相同位置已經存在的節點
   * 即該方法支援重複更新的場景要求
   * @param {*} obj
   * @param {*} delay
   */
  _addObject(obj, delay) {
    const {x, y, c} = obj;

    const record = {obj, timer: null, cb: null};

    if (this.obj_arr[x][y]) {
      clearTimeout(this.obj_arr[x][y].timer);
    }
    this.obj_arr[x][y] = record;

    const updateRecord = () => {
      const recentRecord = this.obj_arr[x][y];
      recentRecord && this.helper.removeObject(recentRecord.obj);
      this.helper.addObject(obj);

      const stone_c = this.boardGridSnapshot[y][x];
      if (c !== 0 && stone_c !== 0 && c !== stone_c) {
        this._replaceWithDeadStone(x, y);
      }
    };

    if (delay) {
      record.cb = () => {
        updateRecord();
        record.timer = null;
      };
      record.timer = setTimeout(record.cb, delay);
    } else {
      updateRecord();
    }
  }

  _replaceWithDeadStone(x, y) {
    if (this.dead_stone_arr && !this.dead_stone_arr[x][y]) {
      const stone_c = this.boardGridSnapshot[y][x];
      this.helper.removeObject({x, y});
      const deadStoneObject = {
        x,
        y,
        c: stone_c,
        options: {
          [OBJECT_TYPE.DEAD_STONE]: true,
        },
      };
      this.dead_stone_arr[x][y] = deadStoneObject;
      this.helper.addObject(deadStoneObject);
    }
  }

  /**
   * 刪除 Object 紀錄
   * 1. 停止 record.timer 計時器
   * 2. helper.removeObject 清理物件
   * @param {*} obj
   * @param {*} delay
   * @param {*} cb
   * @returns
   */
  _removeObject(obj, delay, cb) {
    const {x, y} = obj;

    if (!this.obj_arr[x][y]) {
      return;
    }

    const record = this.obj_arr[x][y];
    if (record.timer) {
      clearTimeout(record.timer);
      record.cb();
    }

    const removeRecord = () => {
      this.helper.removeObject(obj);
      cb && cb();

      if (this.dead_stone_arr[x][y]) {
        const deadStoneObject = this.dead_stone_arr[x][y];
        this.dead_stone_arr[x][y] = null;

        this.helper.removeObject(deadStoneObject);

        if (this.boardGridSnapshot[y][x]) {
          const stone_c = this.boardGridSnapshot[y][x];
          this.helper.addObject({x, y, c: stone_c});
        }
      }
    };

    if (delay) {
      record.cb = () => {
        removeRecord();
        record.timer = null;
      };
      record.timer = setTimeout(record.cb, delay);
    } else {
      removeRecord();
    }
  }

  /**
   * 立即完成圖上所有 record 任務
   * 用於 updateInfluence，立即完成上一次的渲染動畫，
   * 保證下一次的動畫不會出現覆蓋
   */
  _completeDrawImmediately() {
    this.obj_arr.forEach((row) =>
      row.forEach((record) => {
        if (record && record.timer) {
          clearTimeout(record.timer);
          record.cb();
        }
      })
    );
  }

  // ---------- utils ----------
  _components2Map(components) {
    return components.reduce((map, component) => {
      component.points.forEach(
        ([x, y]) => ((map[x] || (map[x] = {}))[y] = component)
      );
      return map;
    }, {});
  }

  _component2Map(component) {
    const c = component.c != null ? component.c : true;
    return component.points.reduce((map, [x, y]) => {
      (map[x] || (map[x] = {}))[y] = c;
      return map;
    }, {});
  }

  _getSurroundingPoints(points) {
    const boardWidth = this.helper.boardWidth;
    const pointsMap = this._component2Map({points});

    const checkedPoints = {};
    const surroundingPoints = [];

    const recordSurroundingPoint = (x, y) => {
      if (
        x >= 0 &&
        y >= 0 &&
        x < boardWidth &&
        y < boardWidth &&
        !(pointsMap[x] && pointsMap[x][y]) &&
        !(checkedPoints[x] && checkedPoints[x][y])
      ) {
        surroundingPoints.push([x, y]);
        (checkedPoints[x] || (checkedPoints[x] = {}))[y] = true;
      }
    };

    const checkPoint = (x, y) => {
      recordSurroundingPoint(x - 1, y);
      recordSurroundingPoint(x + 1, y);
      recordSurroundingPoint(x, y - 1);
      recordSurroundingPoint(x, y + 1);
    };

    points.forEach((point) => checkPoint(...point));

    return surroundingPoints;
  }
}
