<template>
  <div class="w-100 h-100 d-flex flex-column align-items-center chat-container">
    <base-header
      v-if="$device.isMobile"
      :title="$t('學習助手')"
      :left-arrow="true"
      :back-on-click-left="true"
    >
    </base-header>
    <div
      ref="chatSectionGroupEl"
      class="chat-section-group hidden-scroll-style"
      :class="[$device.isMobile ? 'is-mobile' : 'is-web']"
    >
      <template v-for="(chat, chatIdx) in chatList">
        <section
          v-if="chat.type === 'bot'"
          :key="`${chatIdx}_chat`"
          class="chat-section bot"
        >
          <img
            class="chat-avatar"
            :srcset="
              require(`@/assets/img/${
                chat.icon || 'chatbot/ai-tutor-character'
              }.png`)
            "
          />
          <div
            v-if="chat.mode === 'menu'"
            v-drag-scroll
            class="chat-mask-layer hidden-scroll-style"
          >
            <div class="chat-card-group">
              <template v-for="(menu, menuIdx) in chat.menuList">
                <div :key="`${chatIdx}_menu_${menuIdx}`" class="chat-card py-0">
                  <div class="card-content">
                    <p class="card-title card-text">{{ $t(menu.title) }}</p>
                    <template v-for="(event, eventIdx) in menu.eventList">
                      <p
                        :key="`${chatIdx}_event_${eventIdx}`"
                        class="card-text-button card-text"
                        @click.capture="sendEvent(event.eventKey)"
                      >
                        {{ $t(event.text) }}
                      </p>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-if="chat.mode === 'text-button'" class="chat-bubble">
            <div v-dompurify-html="formatTextWithHighlightTag(chat.text)"></div>
            <div class="chat-button-group">
              <div
                v-for="(button, btnIdx) in chat.buttonList"
                :key="`${chatIdx}_btn_${btnIdx}`"
                class="chat-button"
                :class="button.class"
                @click="handleOnClickButton(button)"
              >
                {{ $t(button.text) }}
              </div>
            </div>
          </div>
          <div v-if="chat.mode === 'text'" class="chat-bubble">
            <div v-dompurify-html="formatTextWithHighlightTag(chat.text)"></div>
          </div>
          <div v-if="chat.mode === 'text-img'" class="chat-bubble">
            <p class="mb-0">{{ $t(chat.text) }}</p>
            <p class="mb-0 text-primary">{{ $t('點擊圖片放大查看') }}</p>
            <img
              class="chat-img my-0"
              draggable="false"
              :src="chat.imgSrc"
              @click="openImageModal(chat.imgSrc)"
            />
          </div>
          <div v-if="chat.mode === 'text-menu'" class="chat-bubble-text-menu">
            <div class="chat-bubble mb-1">
              <div
                v-dompurify-html="formatTextWithHighlightTag(chat.text)"
              ></div>
            </div>
            <div
              v-for="menu in chat.menuList"
              :key="menu.eventKey"
              class="w-100 ml-2"
            >
              <b-button
                class="mt-2"
                pill
                size="sm"
                variant="outline-primary"
                @click="handleOnClickButton(menu)"
              >
                {{ menu.text }}
              </b-button>
            </div>
          </div>
          <div
            v-if="chat.mode === 'rich-menu'"
            v-drag-scroll
            class="chat-mask-layer hidden-scroll-style"
          >
            <div class="chat-card-group">
              <template v-for="(item, itemIdx) in richMenu">
                <div :key="`${chatIdx}_item_${itemIdx}`" class="chat-card">
                  <div class="card-icon">
                    <img
                      class="card-icon-img"
                      draggable="false"
                      :srcset="require(`@/assets/img/chatbot/${item.icon}.png`)"
                    />
                  </div>
                  <div class="card-content">
                    <p class="card-title card-text">{{ $t(item.title) }}</p>
                    <template v-for="(event, eventIdx) in item.events">
                      <p
                        :key="`${chatIdx}_event_${eventIdx}`"
                        class="card-text-button card-text"
                        @click.capture="sendEvent(event.key)"
                      >
                        {{ $t(event.text) }}
                      </p>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </section>
        <section
          v-if="chat.type === 'user'"
          :key="`${chatIdx}_chat`"
          class="chat-section user"
        >
          <img
            class="chat-avatar"
            :srcset="
              require(`@/assets/img/personal/avatar/${
                userData.avatar || '0000'
              }.png?srcset`)
            "
          />
          <div v-if="chat.mode === 'img-text'" class="chat-bubble">
            <img
              class="chat-img"
              draggable="false"
              :src="chat.imgSrc"
              @click="openImageModal(chat.imgSrc)"
            />
            <p>{{ chat.text }}</p>
          </div>
          <div
            v-if="chat.mode === 'text'"
            class="chat-bubble"
            :class="{'has-error': chat.error}"
          >
            {{ chat.text }}
          </div>
          <img
            v-if="chat.error"
            class="icon-resend"
            :srcset="require(`@/assets/img/chatbot/resend.png`)"
            @click="resendChat(chat, chatIdx)"
          />
        </section>
      </template>
      <section v-show="isChatbotLoading" class="chat-section bot">
        <img
          class="chat-avatar"
          :srcset="require(`@/assets/img/chatbot/ai-tutor-character.png`)"
        />
        <div class="chat-bubble is-loading">
          <div class="chat-loading">
            <div class="circle circle1"></div>
            <div class="circle circle2"></div>
            <div class="circle circle3"></div>
          </div>
        </div>
      </section>
    </div>
    <div class="chat-field">
      <i
        class="m-2 text-32 icon-chat"
        :class="{'is-active': !lastChatIsRichMenu}"
        @click="sendMenu"
      ></i>
      <b-form-input
        ref="chatInputEl"
        v-model="chatInput"
        class="chat-input"
        name="question"
        type="text"
        :placeholder="$t('請輸入問題')"
        @compositionstart="isComposing = true"
        @compositionend="isComposing = false"
        @keydown.enter="sendChat()"
      ></b-form-input>
      <i
        class="m-2 text-28 icon-send"
        :class="{'is-active': chatInput}"
        @click="sendChat()"
      ></i>
    </div>
    <modal-wrapper
      v-if="isImageModalShow"
      :is-modal-wrapper-show="true"
      :is-show-transition="true"
      :click-to-close="true"
      @close="isImageModalShow = false"
    >
      <div
        class="modal-wrapper"
        :class="$device.isMobile ? 'is-mobile' : 'is-web'"
      >
        <img class="modal-img" draggable="false" :src="modalImageSrc" />
        <p class="modal-text">{{ $t('點畫面任何一處關閉') }}</p>
      </div>
    </modal-wrapper>
  </div>
</template>
<script>
import {
  setDefaultEvent,
  injectInstance,
  executeErrorAction,
} from './chatEvents';
import aiService from '@/services/ai';
import BaseHeader from '@/components/Base/BaseHeader';
import ModalWrapper from '@/components/Base/ModalWrapper';
import delay from '@/lib/base/delay.js';

export default {
  components: {
    BaseHeader,
    ModalWrapper,
  },
  data() {
    return {
      isComposing: false,
      mousePosition: {},
      modalImageSrc: '',
      isImageModalShow: false,
      isChatbotLoading: false,
      chatSectionGroupEl: null,
      chatInputEl: null,
      chatInput: '',
      mockResponse: {
        type: 'bot',
        mode: 'text',
        icon: 'personal/avatar/avatar_tsumego_ai_1',
        text: '功能開發中！',
      },
    };
  },
  computed: {
    richMenu() {
      return [
        {
          icon: 'course',
          title: this.$t('課程'),
          events: [
            {
              key: 'COURSE_INTRODUCTION',
              text: this.$t('課程介紹'),
            },
          ],
        },
        {
          icon: 'verification',
          title: this.$t('模擬檢定'),
          events: [
            {
              key: 'RANK_INTRODUCTION',
              text: this.$t('功能說明'),
            },
            {
              key: 'GET_EXAM_BUY_STEP',
              text: this.$t('如何購買'),
            },
            {
              key: 'IGQE_INTRODUCTION',
              text: this.$t('IGQE是什麼？'),
            },
          ],
        },
        {
          icon: 'vip',
          title: this.$t('VIP方案'),
          events: [
            {
              key: 'VIP_DETAIL',
              text: this.$t('方案說明'),
            },
            {
              key: 'GET_VIP_BUY_STEP',
              text: this.$t('如何購買'),
            },
          ],
        },
        {
          icon: 'location',
          title: this.$t('實體教室'),
          events: [
            {
              key: 'WHERE_IS_OFFICE',
              text: this.$t('哪裡有據點？'),
            },
          ],
        },
        {
          icon: 'question',
          title: this.$t('疑難雜症'),
          events: [
            {
              key: 'GET_COMMON_QUESTION',
              text: this.$t('常見問題'),
            },
          ],
        },
      ];
    },
    chatList() {
      return this.$store.state['aiTutor'].chatList;
    },
    formatTextWithHighlightTag: () => (text) => {
      return text.replace(
        /\*\*(.*?)\*\*/g,
        '<span class="is-highlight">$1</span>'
      );
    },
    lastChatIsRichMenu() {
      return this.$store.getters['aiTutor/lastChatIsRichMenu'];
    },
    userData() {
      return this.$store.state.user.userData;
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  methods: {
    resetChat() {
      this.$store.dispatch('aiTutor/resetChat');
    },
    pushChat(chat) {
      this.$store.dispatch('aiTutor/pushChat', chat);
    },
    removeChat(idx) {
      this.$store.dispatch('aiTutor/removeChat', idx);
    },
    initEvent() {
      const defaultEvent = {
        action() {
          this.pushChat(this.mockResponse);
        },
      };
      injectInstance(this);
      setDefaultEvent(defaultEvent);
      this.$store.dispatch('aiTutor/setEvent');
    },
    initChat() {
      this.resetChat();
      this.pushChat({
        type: 'bot',
        mode: 'text',
        text: this.$t(
          this.$t(
            '哈囉，{username}\n我是黑小嘉，你的學習助手，\n有什麼需要我協助的呢？',
            {
              username: this.userData.nickName,
            }
          )
        ),
      });
      this.pushChat({
        type: 'bot',
        mode: 'rich-menu',
      });
      this.$nextTick(() => {
        this.chatSectionGoToBottom();
      });
    },
    handleOnClickButton(event) {
      if (event.eventKey) {
        this.sendEvent(event.eventKey);
      } else {
        event.action(this);
      }
    },
    sendMenu() {
      if (!this.lastChatIsRichMenu) {
        this.pushChat({
          type: 'bot',
          mode: 'rich-menu',
        });
        this.chatSectionGoToBottom();
      }
    },
    async sendChat(chat) {
      const text = chat ? chat.text : this.chatInput;

      // guard clause condition: 中文輸入法 composition 議題
      if (this.isComposing) return;
      // guard clause condition: 空白字串
      if (!text) return;

      if (chat) {
        this.pushChat(chat);
      } else {
        this.pushChat({
          type: 'user',
          mode: 'text',
          text,
        });
      }
      this.chatInput = '';
      this.isChatbotLoading = true;
      this.chatSectionGoToBottom();

      try {
        const res = await this.getChatbotResponse(text);
        this.pendingMessage();
        this.isChatbotLoading = false;
        if (res.error) {
          this.pushChat({
            type: 'bot',
            mode: 'text',
            text: executeErrorAction(res.error.message),
          });
        } else if (res.key) {
          this.sendEventFromAPI(res.key);
        }
      } catch (e) {
        console.log('e', e);
      }

      this.chatSectionGoToBottom();
    },
    resendChat(lastChat, idx) {
      const chat = lastChat;
      delete chat.error;
      this.removeChat(idx);
      this.sendChat(chat);
    },
    async getChatbotResponse(text) {
      const options = {
        text,
        coursePlan: this.currentPlan.id,
      };
      const response = await aiService.getAiResponse(options);
      return response;
    },
    sendEvent(eventKey) {
      this.$store.dispatch('aiTutor/sendEvent', eventKey);
      this.chatSectionGoToBottom();
    },
    sendEventFromAPI(eventKey) {
      this.$store.dispatch('aiTutor/sendEventFromAPI', eventKey);
      this.chatSectionGoToBottom();
    },
    chatSectionGoToBottom() {
      this.$nextTick(() => {
        this.$refs.chatSectionGroupEl.scrollTop =
          this.$refs.chatSectionGroupEl.scrollHeight;
      });
    },
    openImageModal(imgSrc) {
      this.modalImageSrc = imgSrc;
      this.isImageModalShow = true;
    },
    async pendingMessage(options = {time: 3000}) {
      this.isChatbotLoading = true;
      this.chatSectionGoToBottom();
      await delay(options.time);
      this.isChatbotLoading = false;
    },
  },
  created() {
    this.initEvent();
    this.initChat();
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  background: $bgcontainer;
  overflow: hidden;

  .chat-section {
    .chat-bubble {
      .is-highlight {
        color: $primary;
      }
    }
  }
}
.chat-field {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 54px;
  border-top: 1px solid $line-2;
  background: $color-gray-white;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-chat.is-active,
  .icon-send.is-active {
    cursor: pointer;
  }
  .chat-input {
    width: 100%;
  }
}
.notice-container {
  position: sticky;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 2px 7px 0px #c7c7c74d;
  padding: 12px 16px;
  .notice-icon {
    width: 32px;
    font-size: 32px;
    color: $primary;
  }
  .notice-message {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    color: #303133;
    margin: 0 4px;
  }
  .notice-button {
    background: #ff855e;
    color: white;
    width: 69px;
    height: 30px;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    line-height: 30px;
  }
}
.chat-section-group {
  width: 100%;

  overflow: auto;
  &.is-web {
    height: 560px;
  }
  &.is-mobile {
    min-height: 560px;
    max-height: calc(100% - 54px);
    padding-bottom: 54px;
  }
}

.chat-section {
  display: flex;
  .chat-bubble-text-menu {
    display: flex;
    overflow: hidden;
    height: auto;
    flex-flow: wrap;
  }
  .chat-bubble {
    flex-shrink: 0;
    width: auto;
    max-width: calc(100% - 84px);
    line-height: 1.5;
    font-size: 16px;
    color: $font-normal;
    font-weight: 400;
    white-space: pre-wrap;
    padding: 8px 16px;
    border-radius: $rounded-md;
    text-align: left;
    margin-bottom: 0;
    overflow-wrap: break-word;
  }
  &.bot {
    padding: 16px 0 8px 16px;
    .chat-bubble {
      margin-left: 8px;
      margin-right: 40px;
      background: $color-gray-white;
      border: 1px solid $line-2;
      &.is-loading {
        width: 97px;
      }
    }
  }
  &.user {
    padding: 16px 16px 8px 0;
    flex-direction: row-reverse;
    .chat-bubble {
      margin-left: 40px;
      margin-right: 8px;
      background: $secondary;
      &.has-error {
        width: 180px;
        margin-left: 8px;
      }
    }
  }
  .chat-mask-layer {
    display: flex;
    width: calc(100vw - 60px);
    overflow: scroll;
    height: auto;
    .chat-bubble {
      margin-right: 8px;
    }
    .chat-card {
      flex-shrink: 0;
    }
  }
}

.is-web {
  .chat-section {
    .chat-bubble {
      width: auto;
      max-width: 280px;
    }
  }
  &.chat-section-group {
    &.has-notice {
      height: 506px;
    }
  }
}

.chat-card-group {
  display: flex;
  margin-left: 8px;
  margin-bottom: 0;
  align-items: flex-start;
}

.chat-card {
  width: 140px;
  padding: 8px;
  background: $color-gray-white;
  border: 1px solid $line-2;
  box-shadow: $card-box-shadow;
  border-radius: $rounded-md;
  margin-right: 8px;
  color: $font-grayscale-1;
  &.chat-card-md {
    width: 220px;
    padding: 16px;
  }
  .card-icon {
    width: 100%;
    height: 78px;
    background: #f6ede0;
    border-radius: $rounded-sm;
  }
  .card-icon-img {
    width: 100%;
    object-fit: cover;
    user-select: none;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .card-text:not(:last-child) {
      border-bottom: 1px solid $line-2;
    }
  }
  .card-title {
    color: $primary;
    font-weight: 700;
  }
  .card-button {
    color: $font-grayscale-1;
    font-weight: 400;
  }
  .card-text-button {
    color: $font-grayscale-1;
    font-weight: 400;
    cursor: pointer;
  }
  .card-text {
    width: 100%;
    text-align: center;
    padding: 8px 0;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }
}
.chat-button-group {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 10px;
}
.chat-img {
  width: 100%;
  object-fit: cover;
  margin: 8px 0;
  background: white;
  user-select: none;
}

.chat-img-rounded {
  width: 100%;
  margin: 8px 0;
  object-fit: cover;
  border-radius: $rounded-sm;
}
.chat-button {
  width: 100%;
  text-align: center;
  background: $primary;
  color: $color-gray-white;
  border-radius: $rounded-sm;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  &.bg-secondary {
    background: $secondary;
  }
}
.chat-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.icon-send {
  color: $font-grayscale-3;
  &.is-active {
    color: $primary;
  }
}
.icon-chat {
  color: $font-grayscale-3;
  &.is-active {
    color: $primary;
  }
}
.icon-resend {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.chat-loading {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  .circle {
    width: 11px;
    height: 11px;
    background: #c0c4cc;
    border-radius: 50%;
    animation: changeColor 0.8s infinite;
    animation-direction: alternate, normal;
  }
  .circle1 {
    animation-delay: 0.4s;
  }
  .circle2 {
    animation-delay: 0.8s;
  }
  .circle3 {
    animation-delay: 1.2s;
  }
}
@keyframes changeColor {
  0% {
    background: rgb(192, 196, 204);
  }
  20% {
    background: rgb(208, 210, 216);
  }
  60% {
    background: rgb(224, 226, 229);
  }
  80% {
    background: rgb(249, 251, 242);
  }
  100% {
    background: rgb(255, 255, 255);
  }
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal-img {
    margin-bottom: 8px;
    user-select: none;
  }
  &.is-web {
    .modal-img {
      width: auto;
      max-width: 720px;
      max-height: 480px;
    }
  }
  &.is-mobile {
    width: calc(100vw - 32px);
    .modal-img {
      width: 100%;
      object-fit: cover;
    }
  }
  .modal-text {
    font-size: 14px;
    line-height: 22px;
    color: white;
  }
}

.hidden-scroll-style {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
</style>
