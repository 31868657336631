import getDeviceInfo from '@/lib/base/getDeviceInfo';
import {GAME_MODES, TSUME_MODES} from '@/constant/chessboard';
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

export default [
  {
    path: '/ladder',
    name: 'ladder',
    component: () => import('@/views/Ladder/index.vue'),
    meta: {
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/ladder/rank',
    name: 'ladderRank',
    component: () => import('@/views/Ladder/rank.vue'),
    beforeEnter: (to, from, next) => {
      if (!isMobile) {
        const query = {
          ...to.query,
          isRankingModalShow: true,
        };
        return next({name: 'ladder', query});
      }
      next();
    },
    meta: {
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/ladder/info',
    name: 'ladderInfo',
    component: () => import('@/views/Ladder/info.vue'),
    beforeEnter: (to, from, next) => {
      if (!isMobile) {
        const query = {
          ...to.query,
          isInfoModalShow: true,
        };
        return next({name: 'ladder', query});
      }
      next();
    },
    meta: {
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/ladder/game',
    name: 'ladderGame',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: GAME_MODES.LADDER},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/ladder/endgame',
    name: 'ladderEndgame',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: GAME_MODES.LADDER_ENDGAME},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/ladder/fuseki',
    name: 'ladderFuseki',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: GAME_MODES.LADDER_FUSEKI},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/ladder/tsume',
    name: 'ladderTsume',
    component: () => import('@/views/Room/TsumeRoom.vue'),
    props: {mode: TSUME_MODES.LADDER},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'tsume',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
];
