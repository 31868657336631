const map = {
  零: 0,

  一: 1,
  壹: 1,

  二: 2,
  贰: 2,
  两: 2,

  三: 3,
  叁: 3,

  四: 4,
  肆: 4,

  五: 5,
  伍: 5,

  六: 6,
  陆: 6,

  七: 7,
  柒: 7,

  八: 8,
  捌: 8,

  九: 9,
  玖: 9,

  十: 10,
  拾: 10,

  百: 100,
  佰: 100,

  千: 1000,
  仟: 1000,

  萬: 10000,
  万: 10000,
  十萬: 100000,
  十万: 100000,

  百萬: 1000000,
  百万: 1000000,

  千萬: 10000000,
  千万: 10000000,

  億: 100000000,
  亿: 100000000,
};

/**
 * 中文数字转阿拉伯数字
 * @param {String} chineseNumber String
 * @returns {Promise} promise
 */
export function chinese2digits(chineseNumber) {
  let len = chineseNumber.length;
  if (len == 0) return null;
  if (len == 1) return map[chineseNumber] <= 10 ? map[chineseNumber] : null;
  let summary = 0;
  if (map[chineseNumber[0]] == 10) {
    chineseNumber = '一' + chineseNumber;
    len++;
  }
  if (len >= 3 && map[chineseNumber[len - 1]] < 10) {
    const lastSecondNum = map[chineseNumber[len - 2]];
    if (
      lastSecondNum == 100 ||
      lastSecondNum == 1000 ||
      lastSecondNum == 10000 ||
      lastSecondNum == 100000000
    ) {
      for (const key in map) {
        if (map[key] == lastSecondNum / 10) {
          chineseNumber += key;
          len += key.length;
          break;
        }
      }
    }
  }
  if (chineseNumber.match(/亿/g) && chineseNumber.match(/亿/g).length > 1)
    return -1;
  let splitList = chineseNumber.split('亿');
  if (splitList.length == 2) {
    const rest = splitList[1] == '' ? 0 : chinese2digits(splitList[1]);
    return summary + chinese2digits(splitList[0]) * 100000000 + rest;
  }
  splitList = chineseNumber.split('万');
  if (splitList.length == 2) {
    const rest = splitList[1] == '' ? 0 : chinese2digits(splitList[1]);
    return summary + chinese2digits(splitList[0]) * 10000 + rest;
  }
  let i = 0;
  while (i < len) {
    const firstCharNum = map[chineseNumber[i]];
    const secondCharNum = map[chineseNumber[i + 1]];
    if (secondCharNum > 9) summary += firstCharNum * secondCharNum;
    i++;
    if (i == len) summary += firstCharNum <= 9 ? firstCharNum : 0;
  }
  return summary;
}

/**
 * 抓出字串內所有數字
 * @param {Boolean} chTextString 是否公開
 * @returns {Array<String>} promise
 */
export function splitByAllNumber(chTextString) {
  const takingChineseDigitsMixRERules = new RegExp(
    `([${Object.keys(map).join('')}]+)|(\\d+)`,
    'g'
  );
  const list = chTextString.match(takingChineseDigitsMixRERules);
  if (!list) return [chTextString];
  let temp = chTextString;
  const result = [];
  list.forEach((item) => {
    const startIndex = temp.indexOf(item);
    const endIndex = startIndex + item.length;
    result.push(temp.substring(0, startIndex));
    const numberString = temp.substring(startIndex, endIndex);
    const number = Number.isNaN(Number(numberString))
      ? chinese2digits(item)
      : Number(item);
    result.push(number);
    temp = temp.substring(endIndex);
  });
  return result;
}
