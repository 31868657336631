import SystemUpdate from '@/views/SystemUpdate.vue';

import getDeviceInfo from '@/lib/base/getDeviceInfo';
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

import verification from './verification';
import tournament from './tournament';
import practice from './practice';
import pvp from './pvp';
import personal from './personal';
import course from './course';
import teacherManagement from './teacher-management';
import aiTutor from './ai-tutor';
import aiCourse from './ai-course';
import multimediaClassroom from './multimedia-classroom';
import ladder from './ladder';
import weeklyTsume from './weekly-tsume';
import studentManagement from './student-management';
import cardgolegends from './cardgolegends';
import products from './products';

export default [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/:locale/terms-of-service',
    name: 'terms-of-service',
    component: () => import('@/views/TermsOfService.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/Login/Signup.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/signup-status',
    name: 'signup-status',
    component: () => import('@/views/Login/SignupStatus.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
      depth: 1,
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: () => import('@/views/Login/ForgetPassword.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/Login/ResetPassword.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('@/views/Notification/NotificationList.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: false,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/serial-number',
    name: 'serial-number',
    component: () => import('@/views/Login/SerialNumber.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
    },
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/Home.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
    },
  },
  {
    path: '/language',
    name: 'language',
    component: () => import('@/views/Language/index.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 3,
    },
  },
  {
    path: '/roomSetting',
    name: 'roomSetting',
    component: () => import('@/views/Personal/RoomSetting.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/live/list',
    name: 'liveList',
    component: () => import('@/views/Live/LiveList.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
    },
  },
  {
    path: '/live/manage',
    name: 'live-manage',
    component: () => import('@/views/Live/LiveManage.vue'),
    meta: {
      hasNavigation: true,
      requireDesktop: true,
      requireAuth: true,
      requireTeacher: true,
      depth: 1,
    },
  },
  {
    path: '/no-lesson/:type',
    name: 'no-lesson',
    component: () => import('@/views/NoLesson.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
    },
  },
  {
    path: '/more-about-courses',
    name: 'more-about-courses',
    component: () => import('@/views/MoreAboutCourses/index.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/systemUpdate',
    name: 'systemUpdate',
    component: SystemUpdate,
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/more',
    name: 'more',
    component: () => import('@/views/More.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
    },
  },
  {
    path: '/learning',
    redirect: '/learning/course',
  },
  {
    path: '/learning/:mode',
    name: 'learningPage',
    component: () => import('@/views/LearnPage.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
    },
  },
  ...teacherManagement,
  ...verification,
  ...tournament,
  ...practice,
  ...pvp,
  ...personal,
  ...course,
  ...aiTutor,
  ...aiCourse,
  ...multimediaClassroom,
  ...ladder,
  ...weeklyTsume,
  ...studentManagement,
  ...cardgolegends,
  ...products,
];
