import {callLambda} from '@/lib/api/lambda';
import Cookies from 'js-cookie';

/**
 * 取得課程資料
 * @param {String} planId 課程方案 ID
 * @param {String} isAll 顯示所有課程
 * @returns {Array} Array
 */
function getCourses(planId, isAll) {
  const apiName = isAll
    ? `courses?coursePlan=${planId}&isAll=${isAll}`
    : `courses?coursePlan=${planId}`;
  return callLambda({
    category: 'hjjCourse',
    method: 'get',
    apiName,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得課程列表
 * @returns {Array} Array
 */
function getCoursePlansList() {
  return callLambda({
    category: 'hjjCourse',
    apiName: 'coursePlans',
    method: 'get',
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得課程方案
 * @param {String} username 帳號
 * @returns {Array} Array
 */
function getCoursePlans(username) {
  return callLambda({
    category: 'hjjCourse',
    apiName: 'coursePlans',
    method: 'get',
    params: {
      username,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 * 課程方案加入最愛
 * @param {String} coursePlan 方案ID
 * @param {Boolean} isFavorite 喜歡或不喜歡
 * @returns {Promise} Promise
 */
function setCourseFavorite(coursePlan, isFavorite) {
  return callLambda({
    category: 'hjjCourse',
    apiName: 'setFavorite',
    method: 'post',
    data: {
      isFavorite,
      coursePlan,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得課程的播放權限
 * @param {String} courseUniqueKey courseUniqueKey
 * @param {String} aiCourseId aiCourseId
 * @returns {Promise} Promise
 */
async function getCourseAuth({aiCourseId, courseUniqueKey}) {
  const res = await callLambda({
    category: 'hjjCourse',
    apiName: 'getCourseAuth',
    method: 'post',
    data: {aiCourseId, courseUniqueKey},
  });
  Object.keys(res.data).forEach((key) => {
    Cookies.set(key, res.data[key], {
      domain: location.hostname.substring(location.hostname.indexOf('.')),
    });
  });
  return res.data;
}

/**
 * 取得課程的播放權限
 * @param {String} courseUniqueKey courseUniqueKey
 * @returns {Promise} Promise
 */
async function getCommentaryVideoAuth(courseUniqueKey) {
  const res = await callLambda({
    category: 'hjjCourse',
    apiName: 'getCommentaryVideoAuth',
    method: 'post',
    data: {courseUniqueKey},
  });
  Object.keys(res.data).forEach((key) => {
    Cookies.set(key, res.data[key], {
      domain: location.hostname.substring(location.hostname.indexOf('.')),
    });
  });
  return res.data;
}

/**
 * 取得正在學習課程
 * @param {String} coursePlan coursePlan Id
 * @returns {Array} Array
 */
function getLastWatchCourse({coursePlan}) {
  return callLambda({
    category: 'hjjCourse',
    apiName: 'lastWatch',
    method: 'get',
    params: {
      coursePlan,
    },
  }).then((res) => {
    return res.data;
  });
}

// todo[refactor]: 確認 api import 狀況，沒必要則看是要統一使用預設匯出（default export）還是具名匯出（named export）
export default {
  getCourses,
  getCoursePlansList,
  getCoursePlans,
  setCourseFavorite,
  getCourseAuth,
  getLastWatchCourse,
};
export {
  getCourses,
  getCoursePlansList,
  getCoursePlans,
  setCourseFavorite,
  getCourseAuth,
  getCommentaryVideoAuth,
  getLastWatchCourse,
};
