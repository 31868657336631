import i18n from '@/i18n/index.js';
import dayjs from 'dayjs';

/**
 * @param {Date} date date
 * @param {String} format date format
 * @returns {String} formatted date
 */
const dateFormat = (date, format = 'YYYY/MM/DD') => dayjs(date).format(format);

/**
 * convert winningWay
 * @param {String} winningWay the kifu from database
 * @param {String} gameMode gameMode
 * @param {Number} komi komi
 * @param {Number} boardSize gameMode
 * @param {Object} options options
 * @returns {String} the winningWayText show to user
 */
const winningWayText = (
  winningWay,
  gameMode,
  komi,
  boardSize,
  options = {enableAbbreviations: false}
) => {
  /**
   * format winning result
   * @param {*} result winning result
   * @param {*} i18nOptions i18n options
   * @returns {String} formatted result
   */
  const formatResult = (result, i18nOptions = {}) => {
    let text = result;
    if (options.enableAbbreviations) {
      text = '縮寫_' + result;
    }
    return i18n.t(text, i18nOptions);
  };
  let winner = '';
  if (winningWay === '' || winningWay == null) {
    return formatResult('對局中');
  }
  if (winningWay === 'Draw') {
    return formatResult('和局');
  }
  if (winningWay === 'B+C') {
    return formatResult('黑勝');
  }
  if (winningWay === 'W+C') {
    return formatResult('白勝');
  }
  if (winningWay === 'B+T') {
    return formatResult('白超時負');
  }
  if (winningWay === 'W+T') {
    return formatResult('黑超時負');
  }
  if (winningWay.substring(0, 2) === 'B+') {
    winner = 'black';
  } else if (winningWay.substring(0, 2) === 'W+') {
    winner = 'white';
  } else {
    return winningWay;
  }
  const latterPart = winningWay.substring(2);
  if (latterPart === 'R') {
    return winner == 'black'
      ? formatResult('黑中盤勝')
      : formatResult('白中盤勝');
  } else {
    if (gameMode === 'nft') {
      const latterPart = winningWay.substring(2);

      return winner == 'black'
        ? formatResult('黑 {count} 目勝', {count: latterPart})
        : formatResult('白 {count} 目勝', {count: latterPart});
    } else if (!komi || !boardSize) {
      return winner == 'black' ? formatResult('黑勝') : formatResult('白勝');
    }

    const isBlackWin = winner == 'black';
    const result = Number(winningWay.substring(2));
    const komiNum = Number(isBlackWin ? komi / 2 : -(komi / 2));
    const scoreText = result + komiNum + (boardSize * boardSize) / 2;

    /* eslint-disable indent */
    return winner == 'black'
      ? formatResult('黑勝（黑 {count} 子）', {
          count: scoreText,
        })
      : formatResult('白勝（白 {count} 子）', {
          count: scoreText,
        });
  }
};

export default {
  winningWayText,
  dateFormat,
};
