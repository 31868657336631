<template>
  <b-navbar
    id="base-header"
    class="base-header sticky-top w-100 align-items-center"
    :class="[
      mode === 'primary'
        ? 'primary-header justify-content-between text-dark'
        : 'secondary-header justify-content-center text-white',
    ]"
  >
    <a
      v-if="(leftArrow || leftText || leftIcon) && mode === 'secondary'"
      href="#"
      class="btn-left position-absolute h-100 d-block text-white d-flex align-items-center justify-content-center position-relative"
      @click.prevent="onClickLeft"
    >
      <div v-if="leftText" class="left-text text-16">{{ $t(leftText) }}</div>
      <i
        v-else-if="leftIcon"
        class="left-icon text-32"
        :class="`icon-${leftIcon}`"
      ></i>
      <i v-else class="left-icon text-32 icon-Chevron-left"></i>
    </a>
    <div v-if="hasLogo">
      <img
        class="img-logo"
        :src="require('@/assets/img/logo-white-transparent.svg')"
      />
    </div>
    <h4
      v-else
      class="base-header-title d-flex align-items-center"
      :class="[
        [
          {'font-weight-bold': isTitleBold || mode === 'primary'},
          mode === 'primary' ? 'text-20' : 'text-18',
        ],
      ]"
    >
      <template v-if="breadcrumb.length !== 0 && mode === 'primary'">
        <div
          v-for="(bread, index) in breadcrumb"
          :key="bread.router"
          class="breadcrumb-container d-flex align-items-center"
        >
          <span
            class="bread-title font-weight-bold"
            :class="{clickable: bread.enable}"
            :style="
              index + 1 === breadcrumb.length
                ? 'color: #303133; pointer-events: none'
                : 'color: #606266'
            "
            @click="onClickBread(bread)"
            >{{ $t(bread.name) }}</span
          >
          <i v-if="index + 1 !== breadcrumb.length" class="icon-Chevron-right">
          </i>
        </div>
      </template>

      <span v-else class="bread-title"> {{ $t(title) }} </span>
    </h4>

    <a
      v-if="(rightText || rightIcon) && mode === 'secondary'"
      href="#"
      class="btn-right text-32 position-absolute h-100 d-block text-white d-flex align-items-center justify-content-center"
      @click.prevent="onClickRight"
    >
      <div
        v-if="rightText"
        id="right-text"
        class="right-text text-16 font-weight-bold"
      >
        {{ $t(rightText) }}
      </div>
      <i v-else class="right-icon" :class="`icon-${rightIcon}`"></i>
      <div
        id="locate-point"
        class="locate-point position-absolute text-12"
        style="right: 30px; opacity: 0"
      >
        -
      </div>
      <b-popover
        v-if="
          $device.isMobile &&
          canShowPopover &&
          ($route.name === 'verificationList' || $route.name === 'verification')
        "
        triggers="click"
        target="locate-point"
        placement="bottom"
        custom-class="hjj-popover base-header-scores-popover"
        container="main"
        :show="hasNewScore"
        >{{ $t('你有新的成績') }}
        <i class="icon-X text-16 ml-2" @click="onClick2ClosePop"></i>
      </b-popover>
    </a>
    <div v-if="mode === 'primary'" class="d-flex align-items-center">
      <div
        v-if="allowShowAiTutor"
        class="ai-tutor mr-4"
        :class="{'is-mobile': $device.isMobile}"
        @click="openAiTutor"
      >
        <img
          class="ai-tutor-img"
          :srcset="require('@/assets/img/chatbot/ai-tutor-button.png')"
        />
      </div>
      <div class="avatar-wrap" @click="toggleAvatar">
        <b-dropdown
          right
          size="sm"
          variant="link"
          no-caret
          style="position: relative; border-radius: 10px"
        >
          <template #button-content>
            <div class="avatar-wrapper" style="position: relative">
              <img
                class="img-fluid w-100 rounded-circle"
                :srcset="
                  require(`@/assets/img/personal/avatar/${
                    userData.avatar ? userData.avatar : '0000'
                  }.png?srcset`)
                "
              />
              <div v-if="hasUnreadNotifications" class="red-dot"></div>
            </div>
          </template>
          <div class="personal-menu-wrapper" style="">
            <PersonalMenu :is-desktop="true" />
          </div>
        </b-dropdown>
      </div>
    </div>
  </b-navbar>
</template>

<script>
import userService from '@/services/user';
import LogoImg from '@/assets/img/logo.png';

export default {
  props: {
    mode: {
      type: String,
      default: 'secondary',
    },
    title: {
      type: String,
      default: '',
    },
    breadcrumb: {
      type: Array,
      default() {
        return [];
      },
    },
    isTitleBold: {
      type: Boolean,
      default: false,
    },
    leftArrow: {
      type: Boolean,
      default: false,
    },
    leftText: {
      type: String,
      default: '',
    },
    backOnClickLeft: {
      type: Boolean,
      default: true,
    },
    rightIcon: {
      type: String,
      default: '',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    rightText: {
      type: String,
      default: '',
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    hasRedDot: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PersonalMenu: () => import('@/views/Personal/PersonalMenu.vue'),
  },
  data() {
    return {
      LogoImg,
      avatarDropdownShow: false,
      canShowPopover: true,
    };
  },
  watch: {
    '$route.name': {
      handler() {
        this.canShowPopover = false;
        setTimeout(() => {
          this.canShowPopover = true;
        }, 1000);
      },
      immediate: true,
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters['user/isLogin'];
    },
    allowShowAiTutor() {
      const enableRouteNames = [
        'home',
        'learningPage',
        'liveList',
        'personal',
        'verificationList',
        'multimedia-classroom-list',
        'multimedia-classroom-type-list',
        'studentManagement',
      ];
      return enableRouteNames.includes(this.$route.name);
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    primaryOption() {
      return [
        [
          {
            name: this.$t('個人'),
            value: 'personal',
            show: true,
          },
          {
            name: this.$t('設定'),
            value: 'setting',
          },
          {
            name: this.$t('切換語言'),
            value: 'language',
            show: true,
          },
          {
            name: this.$t('聯絡我們'),
            value: 'contact',
            show: true,
          },
        ],
        [
          {
            name: this.$t('登出'),
            value: 'logout',
            show: true,
          },
        ],
      ];
    },
    hasNewScore() {
      return this.$store.getters['verification/hasNewScore'];
    },
    hasUnreadNotifications() {
      return this.$store.getters['notification/hasUnread'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  methods: {
    async handleOnRedirectToLearnPage() {
      await this.$store.dispatch('aiTutor/getCurrentStudyPlan');
      if (this.$device.isMobile) {
        this.$router.push({name: 'aiTutorLearnPlan'});
      } else {
        await this.$store.dispatch('aiTutor/openAiTutorLearnPlanModalShow');
      }
    },
    async redirectToLearnPlan() {
      const res = await userService.postStudyPlan({
        coursePlan: this.currentPlan.id,
      });
      const errorMessage = res?.error?.message;
      const alreadyHavePlan = errorMessage === 'you have the plans';

      if (alreadyHavePlan) {
        this.handleOnRedirectToLearnPage();
      } else {
        this.$router.push({name: 'aiTutorMakePlan'});
      }
    },
    openAiTutor() {
      if (this.$device.isMobile) {
        this.$router.push({name: 'aiTutorChat'});
      } else {
        this.$store.dispatch('aiTutor/openAiTutor');
      }
    },
    toggleAvatar() {
      this.avatarDropdownShow = !this.avatarDropdownShow;
    },
    onClickBread(bread) {
      if (bread.onClick) return bread.onClick();
      this.$router.push({name: bread.router, query: bread.query});
    },
    onClickLeft() {
      if (this.backOnClickLeft) {
        return this.$router.back();
      }
      return this.$emit('on-click-left');
    },
    onClickRight() {
      return this.$emit('on-click-right');
    },
    onClickOption(action) {
      switch (action) {
        case 'personal':
          return this.$router.push({name: 'personal'});
        case 'setting':
          return this.$router.push({name: 'setting'});
        case 'password':
          return this.$router.push({name: 'password'});
        case 'language':
          return this.$router.push({name: 'language'});
        case 'contact':
          this.$router.push({name: 'contact'});
          break;
        case 'logout':
          return this.logout();
        case 'notifications':
          return this.$router.push({name: 'notification'});
        default:
          break;
      }
    },
    onClick2ClosePop() {
      this.$emit('on-click-close-pop');
    },
    logout() {
      return this.$store.dispatch('user/logout').then(() => {
        this.$JPush.updateTags();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-header {
  min-height: 56px;
  background: $secondary;
  animation: delayShow 0.2s forwards;
  padding: 0;
  @media screen and (min-width: 768px) {
    animation: none;
  }
  .btn-left {
    top: 0;
    left: 0;
    text-decoration: none;
    opacity: 0;
    animation: delayShow 0.2s forwards;
    @media screen and (min-width: 768px) {
      opacity: 1;
      animation: none;
    }
    .left-icon {
      padding: 6px 6px 6px 12px;
    }
    .red-dot {
      width: 14px;
      height: 14px;
      top: 12px;
      right: 6px;
      border-radius: 50%;
      z-index: 5;
    }
    .left-text {
      padding-left: 16px;
      @media screen and (min-width: 768px) {
        background: $accent;
        padding: 5px 12px;
        margin-left: 16px;
        border-radius: $rounded-sm;
        line-height: 28px;
      }
    }
  }
  .breadcrumb-container {
    margin-right: 8px;

    .bread-title {
      font-size: 20px;
      margin-right: 8px;
      color: #606266;
      &.clickable {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    i {
      font-size: 32px;
      color: rgba(144, 147, 153, 1);
    }
  }
  .base-header-title {
    opacity: 0;
    animation: delayShow 0.2s forwards;
    @media screen and (min-width: 768px) {
      opacity: 1;
      animation: none;
    }
  }
  .btn-right {
    right: 0;
    text-decoration: none;
    .right-icon {
      padding: 6px 12px 6px 6px;
      @media screen and (min-width: 768px) {
        background: $accent;
        padding: 5px 12px;
        margin-right: 8px;
        border-radius: $rounded-sm;
        line-height: 28px;
      }
    }
    .right-text {
      padding-right: 16px;
    }
  }
  .avatar-wrap {
    width: 40px;
    height: 40px;
    :deep(.btn) {
      padding: 0;
    }
  }
  .notification-wrap {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    .icon-Bell {
      font-size: 30px;
    }
    .notification-dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      top: 0;
      right: 0;
    }
  }
  &.primary-header {
    background: $bgcontainer;
    padding: 0 32px;
    min-height: 80px;
    border-bottom: 1px solid $line-2;
  }
}
:deep(.dropdown-menu) {
  max-width: 224px;
  width: 224px;
}
:deep().dropdown-item {
  height: 36px;
  &:hover,
  &:focus,
  &:active {
    background: $grayscale-5;
    color: $font-normal;
  }
}

@keyframes delayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.img-logo {
  width: 151px;
  height: 56px;
}
.ai-tutor {
  .ai-tutor-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}

.avatar-wrap {
  .red-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    background-color: #f35d23;
    border-radius: 100%;
  }
}

.personal-menu-wrapper {
  width: 350px;
  position: absolute;
  right: -2px;
  top: -2px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000001c;
}
</style>
