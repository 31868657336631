<template>
  <div v-if="isShow">
    <ModalInfo
      v-if="!$device.isMobile"
      :visible="isShow"
      :title="title"
      size="lg"
      @close="close"
    >
      <div class="modal-main">
        <PostQuestion
          :is-loading="isLoading"
          :error-message="modalMessage"
          @submit="submit"
        />
      </div>
    </ModalInfo>
    <div v-else class="main">
      <base-header
        :title="title"
        :left-arrow="true"
        :back-on-click-left="false"
        @on-click-left="close"
      ></base-header>
      <PostQuestion
        :is-loading="isLoading"
        :error-message="modalMessage"
        @submit="submit"
      />
    </div>
    <modal-confirm
      v-model="isModalShow"
      :confirm-text="$t('知道了')"
      :hide-cancel="true"
      @confirm="clickConfirm"
    >
      <p class="modal-title">
        {{ $t('問題已送出！') }}
      </p>
      <p class="text-left">
        {{
          $t(
            '收到問題了，老師會收集同學們的問題，並於直播講堂回答大家的問題喔！（*因直播講堂的時間有限，不一定每位同學的問題都會被回答到哦！）'
          )
        }}
      </p>
    </modal-confirm>
  </div>
</template>

<script>
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import ModalInfo from '@/components/Modal/ModalInfo.vue';
import PostQuestion from '@/components/AiTutor/PostQuestion.vue';
import BaseHeader from '@/components/Base/BaseHeader';
import userService from '@/services/user';
import dayjs from 'dayjs';
import {putObject} from '@/lib/base/uploadFile';

export default {
  components: {
    ModalInfo,
    PostQuestion,
    BaseHeader,
    ModalConfirm,
  },
  data() {
    return {
      isShow: false,
      uploadPromise: null,
      uploadResolve: null,
      isModalShow: false,
      errorMap: {
        'Invalid image file extension': this.$t(
          '*上傳格式須為圖片或 SGF 檔案格式'
        ),
      },
      modalMessage: null,
      isLoading: false,
    };
  },
  computed: {
    title() {
      return this.$t('圍棋問題');
    },
    user() {
      return this.$store.getters['user/userData'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  methods: {
    resetModalInfo() {
      this.isShow = false;
      this.isModalShow = false;
      this.modalMessage = '';
    },
    clickConfirm() {
      this.close();
    },
    close() {
      this.resetModalInfo();
      this.isShow = false;
    },
    async submit(e) {
      try {
        this.isLoading = true;
        const arrayBuffer = e.image;
        if (arrayBuffer) {
          await putObject({key: e.fileKey, arrayBuffer});
        }

        const result = await userService.createUserQuestion({
          coursePlan: this.currentPlan.id,
          text: e.text,
          image: e.fileKey,
          sgf: e.sgf,
        });
        if (result) {
          this.isModalShow = true;
        }
        this.isLoading = false;
      } catch (e) {
        const hasErrorMessage =
          e.response && e.response.data && e.response.data.message;
        if (hasErrorMessage) {
          const errorMessage = this.errorMap[e.response.data.message];
          this.modalMessage = errorMessage || this.$t('請再試一次');
        } else {
          this.modalMessage = this.$t('請再試一次');
        }
        this.isLoading = false;
      }
    },
    show() {
      if (dayjs().isAfter(this.user.liveCourseEndAt)) {
        return this.$store.commit('env/setIsShowVipModal', true);
      }
      this.isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
  background: $bgcontainer;
}
.modal-main {
  margin: 0px -22px;
  background: #f8f9fb;
  border-radius: 10px;
  border: 1px solid #ced4da;
}
.modal-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #303133;
  margin-bottom: 16px;
}
</style>
