import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';

/**
 * getLevelConfig 取得 Ladder 等級設定
 */
export const getLevelConfig = _.memoize(async (type) => {
  const res = await callLambda({
    category: 'ladder',
    method: 'get',
    apiName: 'levelConfig',
    params: {type},
  });
  return res.data;
});

/**
 * getUserStatus
 */
export const getUserStatus = async () => {
  const res = await callLambda({
    category: 'ladder',
    method: 'get',
    apiName: 'userStatus',
  });
  return res.data;
};

/**
 * getLevelInfo
 */
export const getLevelInfo = _.memoize(async ({level, type}) => {
  const res = await callLambda({
    category: 'ladder',
    method: 'get',
    apiName: 'levelInfo',
    params: {type, level},
  });
  return res.data;
});

/**
 * getRanking
 */
export const getRanking = async ({level = 1000, type}) => {
  const res = await callLambda({
    category: 'ladder',
    method: 'get',
    apiName: 'ranking',
    params: {type: type.toUpperCase(), level},
  });
  return res.data;
};

/**
 * getRankLevelConfig
 */
export const getRankLevelConfig = async ({rank}) => {
  const res = await callLambda({
    category: 'ladder',
    method: 'get',
    apiName: 'rankLevelConfig',
    params: {rank},
  });
  return {...res.data, rank};
};

/**
 * getLastReadRank
 */
export const getLastReadRank = async () => {
  const res = await callLambda({
    category: 'ladder',
    method: 'get',
    apiName: 'lastReadRank',
  });
  return res.data;
};

/**
 * updateLastReadRank
 */
export const updateLastReadRank = async () => {
  const res = await callLambda({
    category: 'ladder',
    method: 'post',
    apiName: 'lastReadRank',
  });
  return res.data;
};

/**
 * getStarLevelConfig
 */
export const getStarLevelConfig = async () => {
  const res = await callLambda({
    category: 'ladder',
    method: 'get',
    apiName: 'starLevelConfig',
  });
  return res.data;
};
