export default [
  {
    path: '/teacher-management/student-information',
    name: 'student-information',
    component: () => import('@/views/TeacherManagement/StudentInformation.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      depth: 1,
    },
  },
  {
    path: '/teacher-management/student-information/kifu/:username/:createdTime/:gameMode',
    name: 'student-information-kifu-preview',
    component: () => import('@/views/Kifu/KifuPreview.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      depth: 1,
      chessboardRoom: true,
    },
  },
  {
    path: '/teacher-management/student-information/score/:username/:scoreId/:rank/:coursePlan',
    name: 'student-information-score',
    component: () => import('@/views/TeacherManagement/StudentScore.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      depth: 1,
      chessboardRoom: true,
    },
  },
  {
    path: '/teacher-management/multimedia-classroom-type-list',
    name: 'multimedia-classroom-type-list',
    component: () =>
      import('@/views/TeacherManagement/MultimediaClassroomTypeList.vue'),
    meta: {
      hasNavigation: true,
      requireDesktop: true,
      requireAuth: true,
      requireTeacher: true,
      depth: 1,
    },
  },
  {
    path: '/teacher-management/multimedia-classroom-type-list/:typeId',
    name: 'multimedia-classroom-type',
    component: () =>
      import('@/views/TeacherManagement/MultimediaClassroomType.vue'),
    meta: {
      hasNavigation: true,
      requireDesktop: true,
      requireAuth: true,
      requireTeacher: true,
      depth: 1,
    },
  },
];
