<template>
  <div>
    <b-modal
      ref="modal"
      class="modal-info"
      content-class="rounded-10 position-relative"
      :size="size"
      hide-footer
      hide-header
      :title="title"
      :visible="visible"
      :static="isStatic"
      centered
      @hide="close"
      @close="close"
    >
      <div class="header text-18 font-weight-bold" :style="headerStyle">
        {{ title }}
        <span
          class="btn-close text-primary cursor-pointer position-absolute text-32 icon-X"
          @click="close"
        >
        </span>
      </div>
      <div class="main text-center" :style="bodyStyle">
        <slot></slot>
        {{ content }}
      </div>
      <div v-if="!hideFooter" class="footer" :style="footerStyle">
        <b-button
          variant="primary"
          :size="buttonSize"
          class="d-block mx-auto text-14"
          :style="buttonStyle"
          @click="confirm"
        >
          {{ buttonText }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    buttonStyle: {
      type: String,
      default: 'width: 150px; line-height: 18px',
    },
    title: {
      type: String,
      default: '說明',
    },
    content: {
      type: String,
      default: '',
    },
    hasButton: {
      type: Boolean,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: '知道了',
    },
    buttonSize: {
      type: String,
      default: 'md',
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    bodyStyle: {
      type: String,
      default: '',
    },
    headerStyle: {
      type: String,
      default: '',
    },
    footerStyle: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-dialog {
  width: 300px;
  margin: 0.5rem auto;
  &.modal-lg {
    min-width: 377px;
  }
  &.modal-xl {
    min-width: 600px;
  }
  .modal-body {
    padding: 0;
  }
  .btn-close {
    top: 14px;
    right: 6px;
  }
  .header {
    padding: 15px 16px;
    border-bottom: 1px solid $line-1;
    color: $font-grayscale-1;
  }
  .footer {
    padding: 8px 16px;
    border-top: 1px solid $line-1;
  }
  .main {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    padding: 16px 32px;
    line-height: 28px;
  }
}
</style>
