import Vue from 'vue';
import VueI18n from 'vue-i18n';
import numberFormats from './numberFormats';
import {locale} from '@/constant/env';

// docs: https://docs.google.com/spreadsheets/d/1uaTT4p-n18ZZnSAjd_SINV2oiZhvBVbiVRaYUmE04QU/edit#gid=0
import zhTW from './lang/zh-TW.json';
import zhCN from './lang/zh-CN.json';
import enUS from './lang/en-US.json';
// import jaJP from './lang/ja-JP.json';

Vue.use(VueI18n);

const missingKeys = {};

const i18n = new VueI18n({
  locale,
  messages: {'zh-TW': zhTW, 'zh-CN': zhCN, 'en-US': enUS, 'ja-JP': enUS},
  numberFormats,
  missing: (locale, key) => {
    console.log('missing key', JSON.stringify(missingKeys), key);
    return key;
  },
});

export default i18n;
