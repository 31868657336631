import notificationService from '@/services/notification';
import hjjServices from '@/services/hjj';

const state = {
  notifications: [],
  usableCouponCount: 0,
  usableCourseSerialNumberCount: 0,
  hasUnreadCoupon: false,
  hasUnreadCourseSerialNumber: false,
};

const getters = {
  notifications(state) {
    return state.notifications;
  },
  usableCouponCount(state) {
    return state.usableCouponCount;
  },
  usableCourseSerialNumberCount(state) {
    return state.usableCourseSerialNumberCount;
  },
  hasUnreadCoupon(state) {
    const {hasUnreadCoupon} = state;
    return hasUnreadCoupon;
  },
  hasUnreadCourseSerialNumber(state) {
    const {hasUnreadCourseSerialNumber} = state;
    return hasUnreadCourseSerialNumber;
  },
  hasUnread(state) {
    const {notifications} = state;
    return notifications.some((notification) => !notification.isRead);
  },
};

const actions = {
  async getNotifications({commit}, limit) {
    const result = await notificationService.getNotifications(limit);
    commit('setNotifications', result);
  },
  async updateNotifications({commit, state}) {
    await notificationService.updateNotifications(
      state.notifications[0].createdAt
    );
    await actions.getNotifications({commit}, 50);
  },
  async setNotificationModal({commit}, sk) {
    await notificationService.setNotificationModal({
      sk,
      hasModalShown: true,
    });
    await actions.getNotifications({commit}, 50);
  },
  async getUnreadCoupon({commit}) {
    const result = await hjjServices.getUserCouponOverview();
    commit('setHasUnreadCoupon', result?.hasNew);
    commit('setUsableCoupon', result?.counts?.usable);
  },
  async getUnreadCourseSerialNumber({commit}) {
    const result = await hjjServices.getUserCourseSerialNumberOverview();
    commit('setHasUnreadCourseSerialNumber', result?.hasNew);
    commit('setUsableCourseSerialNumber', result?.counts?.usable);
  },
};

const mutations = {
  setNotifications(state, data) {
    state.notifications = data;
  },
  setHasUnreadCoupon(state, data) {
    state.hasUnreadCoupon = data;
  },
  setHasUnreadCourseSerialNumber(state, data) {
    state.hasUnreadCourseSerialNumber = data;
  },
  setUsableCoupon(state, data) {
    state.usableCouponCount = data;
  },
  setUsableCourseSerialNumber(state, data) {
    state.usableCourseSerialNumberCount = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
