<template>
  <b-sidebar
    id="sidebar-backdrop"
    backdrop
    bg-variant="bgcontainer"
    no-header
    right
    :visible="visible"
    @hidden="$emit('close')"
  >
    <div class="switch-course w-100 d-flex flex-column" @click.stop="">
      <div class="sub-title font-weight-bold text-center">
        {{ $t('切換課程') }}
        <i
          class="icon-Circle-X text-primary position-absolute"
          @click="$emit('close')"
        ></i>
      </div>

      <div class="course-container scroll-bar p-3">
        <div class="current-plan mb-3">
          <p class="upper-title m-0 text-14">{{ $t('當前課程') }}</p>
          <p class="m-0 text-18 font-weight-bold">
            {{ $t(currentPlan?.name) || '' }}
          </p>
        </div>

        <FavoritePlansCollapse
          :toggle-favorite-visible="toggleFavoriteVisible"
          class="mb-3"
          @toggleVisible="toggleFavoriteVisible = $event"
          @change-course="changeCourse"
          @favorite-course="favoriteCourse"
        />
        <AllPlansCollapse
          :toggle-all-plans-visible="toggleAllPlansVisible"
          @toggleVisible="toggleAllPlansVisible = $event"
          @change-course="changeCourse"
          @favorite-course="favoriteCourse"
        />
      </div>
      <!-- todo: 10/25更新尚未有課程地圖，先做隱藏 -->
      <!-- <div class="wrap-btn-map bg-white">
      <b-button
        pill
        variant="primary"
        size="md"
        class="d-flex align-items-center mx-auto"
      >
        <i class="icon-Elert-info text-20 mr-1"></i>
        {{ $t('課程地圖') }}
      </b-button>
    </div> -->
    </div>
  </b-sidebar>
</template>

<script>
import BackEvent from '@/lib/base/backEvent.js';
import FavoritePlansCollapse from '@/components/Base/FavoritePlansCollapse';
import AllPlansCollapse from '@/components/Base/AllPlansCollapse';

export default {
  components: {
    FavoritePlansCollapse,
    AllPlansCollapse,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      toggleFavoriteVisible: true,
      toggleAllPlansVisible: false,
    };
  },
  computed: {
    plans() {
      return this.$store.getters['course/plans'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    favoritePlans() {
      return this.$store.getters['course/favoritePlans'] || [];
    },
  },
  watch: {
    visible: {
      handler() {
        this.init();
      },
    },
  },
  created() {
    BackEvent.addEvent(() => {
      this.$emit('close');
    });
  },
  methods: {
    init() {
      this.toggleFavoriteVisible = true;
      this.toggleAllPlansVisible = this.favoritePlans.length === 0;
    },
    clickType(type) {
      type.show = !type.show;
    },
    changeCourse(course) {
      if (course.disable) {
        return;
      }
      this.$store.dispatch('env/changePlanInit', course);
      this.$emit('close');
    },
    favoriteCourse(course) {
      this.$store.dispatch('course/toggleFavorite', course);
    },
    visibleChange() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .b-sidebar-backdrop {
  background: $grayscale-20 !important;
}
:deep() .b-sidebar {
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
}
.course-container {
  height: 100%;
  overflow: overlay;
}
.switch-course {
  max-width: 375px;
  background: $white;
  height: 100%;
  :deep() .btn-link {
    padding: 0;
    text-decoration: none;
  }

  .sub-title {
    height: 56px;
    line-height: 56px;
    position: relative;
    .icon-Circle-X {
      right: 6px;
      top: 8px;
      font-size: 40px;
      cursor: pointer;
    }
  }
  .upper-title {
    color: $font-grayscale-1;
  }
  .current-plan {
    border: 1px solid #dcdfe6;
    border-radius: 10px;
    padding: 14px 24px;
  }

  .wrap-btn-map {
    padding: 8px 0;
  }
}
</style>
