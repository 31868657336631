import rankList from '@/json/rank.json';
import gRankList from '@/json/gRank.json';
import i18n from '@/i18n/index';

/**
 * transform i18n rank text helper function
 * issue: 避免轉譯非必要文字造成不必要的翻譯提示與效能問題
 * @param {*} text rank text
 * @returns {String} i18n rank text
 */
const transformI18nRankLabel = (text) => {
  return text === '無棋力' ? i18n.t(text) : text;
};

/**
 * @param {Number} gameRank - presentation of rank of game.
 * @return {String} - e.g. '2k'.
 */
const convertRank = (gameRank) => {
  // Map from game ranking definition to Go ranking definition.
  const rank = parseInt(gameRank);
  const findResult = rankList.find((item) => {
    return item.value === rank;
  });
  if (!findResult) return gameRank;
  else return findResult.label;
};

/**
 * @param {Number} gRank - presentation of grank of game.
 * @return {String} - e.g. 'G10'.
 */
const convertGRank = (gRank) => {
  // Map from game ranking definition to Go ranking definition.
  const rank = parseInt(gRank);
  const findResult = gRankList.find((item) => {
    return item.value === rank;
  });
  if (!findResult) return gRank;
  else return transformI18nRankLabel(findResult.label);
};

/**
 * @param {Number} gameRank - parse rank to IGEF rank.
 * @return {String} - e.g. 'G10'.
 */
const rank2GRank = (gameRank) => {
  // Map from game ranking definition to Go ranking definition.
  const rank = parseInt(gameRank);
  const label = convertRank(rank);

  const findResult = gRankList.find((item) => {
    return item.rankLabel === label;
  });
  if (!findResult) return gameRank;
  else return findResult.label;
};

/**
 * @param {Number} gameRank - parse rank to IGEF rank.
 * @return {String} - e.g. 'G10'.
 */
const gRank2Rank = (gameRank) => {
  // Map from game ranking definition to Go ranking definition.
  const rank = parseInt(gameRank);
  const findResult = gRankList.find((item) => {
    return item.value === rank;
  });
  if (!findResult) return gameRank;
  else return findResult.rankLabel;
};

/**
 * @param {Number} gRank - parse rank to IGEF rank.
 * @return {String} - e.g. 'G10'.
 */
const gRank2Value = (gRank) => {
  // Map from game ranking definition to Go ranking definition.
  const findResult = gRankList.find((item) => {
    return item.label === gRank;
  });
  if (!findResult) return gRank;
  else return findResult.value;
};

const maxGRank = gRankList[gRankList.length - 1].value;

export {
  rankList,
  convertRank,
  gRankList,
  convertGRank,
  rank2GRank,
  gRank2Rank,
  gRank2Value,
  maxGRank,
};
