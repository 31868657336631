<template>
  <b-modal
    ref="modal"
    class="modal-confirm"
    content-class="rounded-10 position-relative"
    hide-footer
    hide-header
    size="sm"
    :visible="visible"
    no-close-on-backdrop
    no-close-on-esc
    :centered="true"
  >
    <div
      class="main d-block text-center text-16 my-4 d-flex align-items-center justify-content-center flex-wrap"
      style="min-height: 60px; white-space: pre-line"
    >
      {{ message }}
    </div>
    <div class="text-center mb-2">
      <b-button
        size="md"
        variant="primary"
        class="confirm"
        @click="handelConfirm"
        >{{ confirmText }}</b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      promise: null,
      message: '',
      confirmText: '確定',
    };
  },
  methods: {
    init() {
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.promise = {resolve, reject};
      });
    },
    handelCancel() {
      this.promise.reject();
      this.destroyVm();
    },
    handelConfirm() {
      this.promise.resolve();
      this.destroyVm();
    },
    destroyVm() {
      this.visible = false;
      setTimeout(() => {
        this.$destroy(true);
        this.$el && this.$el.parentNode.removeChild(this.$el);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-content {
  width: 300px;
  margin: 0 auto;

  .main {
    color: $font-normal;
  }
  .btn-close {
    top: 8px;
    right: 8px;
  }
}
</style>
