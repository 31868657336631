import rankList from '@/json/gRank.json';

const aiLevel2UserRankMapping = [
  {aiLevel: 0, userRank: 0},
  {aiLevel: 10, userRank: 1},
  {aiLevel: 40, userRank: 2},
  {aiLevel: 70, userRank: 3},
  {aiLevel: 108, userRank: 4},
  {aiLevel: 150, userRank: 5},
  {aiLevel: 210, userRank: 6},
  {aiLevel: 230, userRank: 7},
  {aiLevel: 250, userRank: 8},
  {aiLevel: 280, userRank: 9},
  {aiLevel: 320, userRank: 10},
  {aiLevel: 360, userRank: 11},
  {aiLevel: 400, userRank: 12},
  {aiLevel: 440, userRank: 13},
  {aiLevel: 480, userRank: 14},
  {aiLevel: 520, userRank: 15},
  {aiLevel: 560, userRank: 16},
  {aiLevel: 600, userRank: 17},
  {aiLevel: 650, userRank: 18},
  {aiLevel: 710, userRank: 19},
];

/**
 * aiLevel 對照 rank
 * @param {Number} aiLevel 傳來的 aiLevel
 * @returns {String} 回傳棋力
 */
function processAiLevel(aiLevel) {
  const userRank = aiLevel2UserRankMapping.find(
    (mapping) => mapping.aiLevel >= aiLevel
  )?.userRank;
  return rankList.find((item) => item.value === userRank)?.label;
}

/**
 * rank 對照 aiLevel
 * @param {Number|String} rank 傳來的棋力
 * @returns {String} 回傳aiLevel
 */
function processRank(rank) {
  if (typeof rank === 'string') {
    rank = rankList.find((item) => item.label === rank)?.value;
  }
  return aiLevel2UserRankMapping.find((mapping) => mapping.userRank === rank)
    ?.aiLevel;
}

export default {
  processAiLevel,
  processRank,
};
