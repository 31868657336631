import url from '@/lib/api/url';

/**
 * 檢查該value是否於所屬Enum的value中
 * @param {Object} Enum 常數
 * @param {Any} value 常數的物件value
 * @returns {Boolean} 是否屬於
 */
const isValueExist = (Enum, value) => Object.values(Enum).includes(value);

const DefaultEventEnum = {
  NO_PONG: 'noPong', // ping之後未收到pong回應
  DEFAULT_ERROR: 'defaultError', // 未handle的錯誤
};

const SendEventEnum = {
  PING: 'ping',
  PVP_CREATE_LOBBY: 'createLobby', // 創建pvp等待室
  PVP_DELETE_LOBBY: 'deleteLobby', // 刪除pvp等待室
  PVP_ENTER_LOBBY: 'enterLobby', // 開始pvp對局
  PVP_LEAVE_LOBBY: 'leaveLobby', // 離開pvp對局
  PVP_CREATE_AI_GAME: 'createAiGame', // 創建pvp AI對局
  PVP_MOVE: 'move', // pvp對局落子
  PVP_SEND_MESSAGE: 'sendMessage', // pvp對局發送訊息
  PVP_INVITE_JUDGEMENT: 'inviteJudgement', // pvp對局詢問是否算輸贏
  PVP_REFUSE_JUDGEMENT: 'refuseJudgement', // pvp對局拒絕算輸贏
  PVP_ACCEPT_JUDGEMENT: 'acceptJudgement', // pvp對局同意算輸贏
  PVP_REFUSE_JUDGEMENT_RESULT: 'refuseJudgementResult', // pvp對局拒絕算輸贏結果
  PVP_ACCEPT_JUDGEMENT_RESULT: 'acceptJudgementResult', // pvp對局接受算輸贏結果
  PVP_RESIGN: 'resign', // pvp對局投子
  PVP_INVITE_REMATCH: 'inviteRematch', // pvp對局要求重新對局
  PVP_ACCEPT_REMATCH: 'acceptRematch', // pvp對局同意重新對局
  PVP_REFUSE_REMATCH: 'refuseRematch', // pvp對局拒絕重新對局
  PVP_CHECK_OVERTIME: 'checkOvertime', // pvp對局檢查對手是否超時
  PVP_GET_UNFINISHED_GAME: 'getUnfinishedGame', // pvp對局查看未完成對局
  PVP_SPECTATE: 'spectate', // pvp對局觀戰
  PVP_GET_PVP_SCORES: 'getPvpScores', // pvp取得棋手戰績
  PVP_GET_LOBBY_LIST: 'getLobbyList', // 取得pvp等待室列表
  PVP_LEAVE_LOBBY_LIST: 'leaveLobbyList', // 離開pvp等待室列表
  PVP_PAUSE_GAME: 'pauseGame', // pvp對局老師/裁判暫停對局
  PVP_RESUME_PAUSED_GAME: 'resumePausedGame', // pvp對局暫停秒數歸0，向後端請求解除暫停
  PVP_JUDGE_BY_THIRD_PARTY: 'judgeByThirdParty', // pvp對局老師/裁判判決
  PVP_DISCONNECT: 'disconnect', // pvp對局者斷線
  PVP_CREATE_STUDENT_GAMES: 'createStudentGames', // 老師創建學生對局
  PVP_GET_THIRD_PARTY_LOBBIES: 'getThirdPartyLobbies',
};

// 後端event對應constant
const ResponseEventEnum = {
  REGISTER_SUCCESSFULLY: 'registerSuccessfully',
  MESSAGE: 'message',
  ASK_IF_LOGOUT_OTHER_ACCOUNT: 'askIfLogoutOtherAccount',
  LOGOUT: 'logout',
  REDIRECT_ROUTER: 'redirectRouter',
  UPDATE_SUCCESSFULLY: 'updateSuccessfully',
  UPDATE_ENV: 'updateEnv',
  ERROR: 'error',
  PONG: 'pong',
  // PVP相關
  PVP_CREATE_LOBBY_SUCCESSFULLY: 'createLobbySuccessfully', // 創建pvp等待室成功
  PVP_DELETE_LOBBY_SUCCESSFULLY: 'deleteLobbySuccessfully', // 刪除pvp等待室成功
  PVP_ENTER_LOBBY_SUCCESSFULLY: 'enterLobbySuccessfully', // 加入pvp等待室成功
  PVP_LEAVE_LOBBY_SUCCESSFULLY: 'leaveLobbySuccessfully', // 離開pvp等待室成功
  PVP_GAME_STARTED: 'gameStarted', // 開始pvp對局
  PVP_MOVE: 'move', // pvp對局落子
  PVP_RECEIVE_MESSAGE: 'receiveMessage', // pvp對局訊息接收
  PVP_RECEIVE_JUDGEMENT_INVITATION: 'receiveJudgementInvitation', // pvp對局詢問是否算輸贏
  PVP_RECEIVE_JUDGEMENT_REFUSAL: 'receiveJudgementRefusal', // pvp對局拒絕算輸贏
  PVP_RECEIVE_JUDGEMENT_CONFIRMATION: 'receiveJudgementConfirmation', // pvp對局接受算輸贏
  PVP_RECEIVE_JUDGEMENT_RESULT_REFUSAL: 'receiveJudgementResultRefusal', // pvp對局拒絕算輸贏結果
  PVP_RECEIVE_JUDGEMENT_RESULT_CONFIRMATION:
    'receiveJudgementResultConfirmation', // pvp對局接受算輸贏結果
  PVP_GAME_UNJUDGEABLE: 'gameUnjudgeable', // pvp對局棋局尚未結束，無法算輸贏
  PVP_GAME_ENDED: 'gameEnded', // pvp對局結束
  PVP_RECEIVE_REMATCH_INVITATION: 'receiveRematchInvitation', // pvp再來一局請求
  PVP_RECEIVE_REMATCH_CONFIRMATION: 'receiveRematchConfirmation', // pvp對局重新對局請求
  PVP_RECEIVE_REMATCH_REFUSAL: 'receiveRematchRefusal', // pvp對局拒絕重新對局
  PVP_EXIST_PVP_GAME: 'existPvpGame', // pvp對局未完成對局
  PVP_SPECTATE_SUCCESSFULLY: 'spectateSuccessfully', // pvp對局觀戰清單
  PVP_DISCONNECT: 'disconnect',
  PVP_GET_LOBBY_LIST_SUCCESSFULLY: 'getLobbyListSuccessfully', // 取得pvp等待室列表成功
  PVP_LOBBY_EXPIRED: 'lobbyExpired', // pvp等待室推送房間狀態
  PVP_LEAVE_LOBBY_LIST_SUCCESSFULLY: 'leaveLobbyListSuccessfully', // 離開pvp等待室列表成功
  PVP_SPECTATE: 'spectate', // pvp對局使用者進入觀戰
  PVP_PAUSE_GAME: 'pauseGame', // pvp對局老師/裁判暫停對局
  PVP_CONTINUE_GAME: 'continueGame', // pvp對局繼續對局
  PVP_SPECTATOR_ENTERED: 'spectatorEntered', // pvp對局觀戰者進入房間
  PVP_STATUS_CHANGE: 'pvpStatusChange', // pvp新舊房間狀態
  PVP_CREATE_STUDENT_GAMES_SUCCESSFULLY: 'createStudentGamesSuccessfully', // pvp老師創建學生對局成功
};

class Socket {
  constructor() {
    this.isInit = false;
    this.DefaultEventEnum = DefaultEventEnum;
    this.ResponseEventEnum = ResponseEventEnum;
    this.SendEventEnum = SendEventEnum;
  }

  init(jwt, router) {
    this.isInit = true;
    this.socketUrl = url.lambda.socket;
    this.jwt = jwt;
    this.router = router;
    this.isReconnect = true;
    this.isReady = false;
    this.keepAliveTimeout = null; // 保持連線避免idle的timeout id
    this.isPong = false; // 是否收到pong的回應
    this.pingPongTimer = null;
    this.pongTimeout = null;

    this.waitingQueue = []; // 暫存還沒socket還沒open之前就發出的payload
    this.waitingActionNames = [];
    this.waitingEvents = [];
    this.cbMap = {};
    this.onceCbMap = {};
    this.errorCbMap = {};
    this.onRouteMap = {};

    this.onOpenBind = this.register.bind(this);
    this.onMessageBind = this.socketMessageCallback.bind(this);
    this.onCloseBind = this.socketCloseCallback.bind(this);
    this.onErrorBind = this.socketErrorCallback.bind(this);

    for (const eventName in ResponseEventEnum) {
      this.cbMap[ResponseEventEnum[eventName]] = [];
      this.onceCbMap[ResponseEventEnum[eventName]] = [];
      this.errorCbMap[ResponseEventEnum[eventName]] = [];
    }
    for (const eventName in SendEventEnum) {
      this.errorCbMap[SendEventEnum[eventName]] = [];
    }
    for (const eventName in DefaultEventEnum) {
      this.errorCbMap[DefaultEventEnum[eventName]] = [];
    }

    this.connect();
  }

  connect() {
    this.socket = new WebSocket(this.socketUrl);
    this.recoverEventListener();

    this.socket.addEventListener('open', this.onOpenBind);
    this.socket.addEventListener('message', this.onMessageBind);
    this.socket.addEventListener('close', this.onCloseBind);
    this.socket.addEventListener('error', this.onErrorBind);
  }

  recoverEventListener() {
    Object.keys(this.cbMap).forEach((key) => {
      this.cbMap[key].forEach((cb) => this.socket.addEventListener(key, cb));
    });
    Object.keys(this.onceCbMap).forEach((key) => {
      this.onceCbMap[key].forEach((cb) =>
        this.socket.addEventListener(key, cb)
      );
    });
    Object.keys(this.errorCbMap).forEach((key) => {
      this.errorCbMap[key].forEach((cb) =>
        this.socket.addEventListener(key, cb)
      );
    });
  }

  register() {
    this.send(
      {
        action: 'register',
        jwt: this.jwt,
        router: this.router,
        ux: {
          userAgent: navigator.userAgent,
          innerWidth: window.innerWidth,
          innerHeight: window.innerHeight,
          outerWidth: window.outerWidth,
          outerHeight: window.outerHeight,
        },
      },
      true
    );
  }

  update(data = {router: this.router}) {
    // console.log(
    //   'socket.js -> update -> this.socket.readyState',
    //   this.socket.readyState
    // );
    if (data.router) {
      this.router = data.router;
    }

    this.send({
      action: 'update',
      jwt: this.jwt,
      data,
    });
  }

  startPingPong() {
    this.endPingPong();
    this.on(ResponseEventEnum.PONG, () => {
      this.isPong = true;
    });

    this.pingPongTimer = setInterval(() => {
      this.isPong = false;
      this.send({
        action: SendEventEnum.PING,
      });

      this.pongTimeout = setTimeout(() => {
        if (!this.isPong) {
          this.endPingPong();
          this.destroy(true);
          this.errorCbMap[DefaultEventEnum.NO_PONG].forEach((cb) => cb());
          this.connect();
        }
      }, 1000 * 2);
    }, 1000 * 10);
  }

  endPingPong() {
    clearInterval(this.pingPongTimer);
    clearTimeout(this.pongTimeout);
    this.clearEvent(this.ResponseEventEnum.PONG);
  }

  onAskIfLogoutOtherAccount(cb) {
    this.socket.addEventListener(
      ResponseEventEnum.ASK_IF_LOGOUT_OTHER_ACCOUNT,
      cb
    );
    this.cbMap[ResponseEventEnum.ASK_IF_LOGOUT_OTHER_ACCOUNT].push(cb);
  }

  onRedirectRouter(cb) {
    this.socket.addEventListener(ResponseEventEnum.REDIRECT_ROUTER, cb);
    this.cbMap[ResponseEventEnum.REDIRECT_ROUTER].push(cb);
  }

  onLogout(cb) {
    this.socket.addEventListener(ResponseEventEnum.LOGOUT, cb);
    this.cbMap[ResponseEventEnum.LOGOUT].push(cb);
  }

  onUpdateEnv(cb) {
    this.socket.addEventListener(ResponseEventEnum.UPDATE_ENV, cb);
    this.cbMap[ResponseEventEnum.UPDATE_ENV].push(cb);
  }

  on(eventName, cb) {
    if (Array.isArray(eventName)) {
      eventName.forEach((name) => {
        this.cbMap[name].push(cb);
      });
    } else {
      this.cbMap[eventName].push(cb);
    }
  }

  once(eventName, cb) {
    this.onceCbMap[eventName].push(cb);
  }

  // 限定在特定 route 才會執行的 callback，不會被 clearEvent / clearEvents 清除
  onRoute(eventName, routeName, cb) {
    if (!this.onRouteMap[routeName]) {
      this.onRouteMap[routeName] = {};
    }
    this.onRouteMap[routeName][eventName] = cb;
  }

  onError(eventName, cb) {
    this.errorCbMap[eventName].push(cb);
  }

  execRouteCb(eventName, data) {
    if (this.onRouteMap[this.router]?.[eventName]) {
      this.onRouteMap[this.router][eventName](data);
    }
  }

  sendLogoutOtherAccount() {
    this.send({
      action: 'logoutOtherAccount',
      jwt: this.jwt,
    });
  }

  send(payload, isForce) {
    if (!payload.jwt) {
      payload.jwt = this.jwt;
    }
    // console.log('payload', payload);
    if (this.isReady || isForce) {
      this.socket.send(JSON.stringify(payload));
      // console.log('- Socket - Send message to server', payload);

      // 10分鐘idle，lambda會自動斷線。所以定時ping一下，避免其idle
      clearTimeout(this.keepAliveTimeout);
      this.keepAliveTimeout = setTimeout(() => {
        this.update();
      }, 1000 * 60 * 9);
    } else if (!this.isReady) {
      const isDuplicated = this.waitingQueue.some(
        (queuePayload) =>
          payload.action == queuePayload.action &&
          queuePayload.jwt == payload.jwt &&
          queuePayload.pvpAction == payload.pvpAction
      );
      if (!isDuplicated) {
        this.waitingQueue.push(payload);
      }
      // console.log('- Socket - Preserve payload', payload);
    } else {
      console.log('- Socket - update error', payload);
    }
  }

  // 清除 socket 事件監聽，只清除 on、once、onError，不清除 onRoute
  clearEvent(event) {
    this.cbMap[event] = [];
    this.onceCbMap[event] = [];
    this.errorCbMap[event] = [];
  }

  // 批量清除 socket 事件監聽，只清除 on、once、onError，不清除 onRoute
  clearEvents(events) {
    events.forEach((event) => {
      this.cbMap[event] = [];
      this.onceCbMap[event] = [];
      this.errorCbMap[event] = [];
    });
  }

  // 清除 socket 事件監聽，只清除 onRoute
  clearRouteEvent(routeName) {
    this.onRouteMap[routeName] = {};
  }

  clearPvpEvents() {
    const responseEvents = Object.keys(ResponseEventEnum)
      .filter((key) => key.indexOf('PVP_') == 0)
      .map((key) => ResponseEventEnum[key]);
    const sendEvents = Object.keys(SendEventEnum)
      .filter((key) => key.indexOf('PVP_') == 0)
      .map((key) => SendEventEnum[key]);
    const pvpEvents = [...responseEvents, ...sendEvents];
    for (const event in this.cbMap) {
      if (pvpEvents.includes(event)) {
        this.cbMap[event] = [];
      }
    }
    for (const event in this.onceCbMap) {
      if (pvpEvents.includes(event)) {
        this.onceCbMap[event] = [];
      }
    }
    for (const event in this.errorCbMap) {
      if (pvpEvents.includes(event)) {
        this.errorCbMap[event] = [];
      }
    }
  }

  socketMessageCallback(event, isWaitingRun) {
    const data = JSON.parse(event.data);
    const {pvpAction, action} = data;
    if (
      !isWaitingRun &&
      this.waitingActionNames?.includes(pvpAction || action)
    ) {
      return this.waitingEvents.push(event);
    }
    // console.log('- Socket - Message from server ', data);

    switch (data.action) {
      case ResponseEventEnum.REGISTER_SUCCESSFULLY:
        this.isReady = true;
        for (const payload of this.waitingQueue) {
          this.send(payload);
        }
        this.waitingQueue = [];

        this.cbMap[data.action].forEach((cb) => cb(data));
        this.execRouteCb(data.action, data);
        this.onceCbMap[data.action].forEach((cb) => cb(data));
        this.onceCbMap[data.action] = [];
        break;
      case ResponseEventEnum.ASK_IF_LOGOUT_OTHER_ACCOUNT:
        if (!window.localStorage.getItem('testMode')) {
          this.cbMap[ResponseEventEnum.ASK_IF_LOGOUT_OTHER_ACCOUNT].forEach(
            (cb) => cb(data)
          );
        }
        break;
      case ResponseEventEnum.LOGOUT:
      case ResponseEventEnum.REDIRECT_ROUTER:
      case ResponseEventEnum.PONG:
      case ResponseEventEnum.UPDATE_ENV:
        this.cbMap[data.action].forEach((cb) => cb(data));
        break;
      case ResponseEventEnum.ERROR:
        if (this.errorCbMap[data.requestAction].length === 0) {
          this.errorCbMap[DefaultEventEnum.DEFAULT_ERROR].forEach((cb) =>
            cb(data)
          );
        } else {
          this.errorCbMap[data.requestAction].forEach((cb) => cb(data));
        }
        break;
      case 'pvp':
        if (isValueExist(ResponseEventEnum, data.pvpAction)) {
          this.cbMap[data.pvpAction].forEach((cb) => cb(data));
          this.execRouteCb(data.pvpAction, data);
          this.onceCbMap[data.pvpAction].forEach((cb) => cb(data));
          this.onceCbMap[data.pvpAction] = [];
        } else {
          throw new Error('Unknown event from server');
        }
        break;
      case ResponseEventEnum.UPDATE_SUCCESSFULLY:
        this.execRouteCb(data.action, data);
        this.onceCbMap[data.action].forEach((cb) => cb(data));
        this.onceCbMap[data.action] = [];
        break;
      default:
        throw new Error('Unknown event from server');
    }
  }

  socketCloseCallback() {
    this.isReady = false;
    if (this.isReconnect) {
      setTimeout(() => {
        this.connect();
      }, 1000);
    }
  }

  socketErrorCallback(err) {
    console.error('socket error', err);
  }

  destroy(isReconnect = false) {
    this.isReconnect = isReconnect;
    if (this.socket) {
      this.socket.removeEventListener('open', this.onOpenBind);
      this.socket.removeEventListener('message', this.onMessageBind);
      this.socket.removeEventListener('close', this.onCloseBind);
      this.socket.removeEventListener('error', this.onErrorBind);
      this.socket.close();
    }
    this.isInit = isReconnect;
  }
  startWaitingEvents(actionNames) {
    if (Array.isArray(actionNames)) {
      this.waitingActionNames.push(...actionNames);
    } else {
      this.waitingActionNames.push(actionNames);
    }
  }
  runWaitingEvents() {
    let index = 0;
    while (index < this.waitingEvents.length) {
      const event = this.waitingEvents[index];
      this.socketMessageCallback(event, true);
      index += 1;
    }
    this.waitingEvents = [];
  }
  stopWaitingEvents() {
    this.waitingEvents = [];
    this.waitingActionNames = [];
  }
}

export default new Socket();
