import getDeviceInfo from '@/lib/base/getDeviceInfo';
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

export default [
  {
    path: '/verification-list',
    name: 'verificationList',
    component: () => import('@/views/Verification/VerificationList.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
    },
  },
  {
    path: '/verification-list/verification/info',
    name: 'verificationInfo',
    component: () => import('@/views/Verification/Info.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/verification-list/verification',
    name: 'verification',
    component: () => import('@/views/Verification/Verification.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/verification-list/verification/past-scores',
    name: 'past-scores',
    component: () => import('@/views/Verification/PastScores.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/verification-list/verification/pass-scores/:scoreId/:rank/:coursePlan',
    name: 'score',
    component: () => import('@/views/Verification/Score.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/verification-list/verification/pass-scores/:scoreId/:rank/:coursePlan/apply-certification',
    name: 'applyCertification',
    component: () => import('@/views/Verification/ApplyCertification.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/verification/game/:verificationId/:rank',
    name: 'verificationGame',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: 'verificationGame'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/verification/tsume/id/:id/types/:type/overTime/:overTime',
    name: 'verificationTsume',
    component: () => import('@/views/Room/TsumeRoom.vue'),
    props: {mode: 'verificationTsume'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'tsume',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
];
