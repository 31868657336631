<template>
  <div v-if="show">
    <ModalInputNickname :show="!hasNickname" @close="close" />
    <ModalRankTest :show="hasNickname && !hasRank" @close="close(true)" />
  </div>
</template>

<script>
import ModalInputNickname from '@/components/Modal/ModalInputNickname.vue';
import ModalRankTest from '@/components/Modal/ModalRankTest.vue';
import _ from 'lodash';

export default {
  components: {
    ModalInputNickname,
    ModalRankTest,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    hasNickname() {
      return this.userData?.nickName && this.userData.nickName !== '-';
    },
    hasRank() {
      return this.userData?.rank;
    },
  },
  methods: {
    close(force) {
      if ((this.hasNickname && this.hasRank) || force) {
        const queryData = _.cloneDeep(this.$route.query);
        delete queryData.isFirstLogin;
        this.$router.replace({query: queryData});
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
