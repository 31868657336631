<template>
  <div>
    <b-modal
      v-if="!isWantToGetFreeVip && !isSuccess"
      ref="modal"
      class="modal-confirm"
      content-class="rounded-10 position-relative"
      hide-footer
      hide-header
      size="sm"
      :visible="!!isShow"
      :centered="true"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hide="close"
      @close="close"
    >
      <div class="modal-vip">
        <div class="header">
          <div><i :class="iconClass" /></div>
          <div>
            <h3>{{ $t(title) }}</h3>
          </div>
          <span
            v-show="!hasGotFreeVip"
            class="btn-close icon-X"
            @click.prevent="close"
          >
          </span>
        </div>
        <div class="content">
          <div>{{ $t(contents) }}</div>

          <b-button
            v-show="hasGotFreeVip"
            class="button mt-3"
            variant="primary"
            size="sm"
            @click="gotoVipHomePage"
          >
            {{ $t('前往官網購買') }}
          </b-button>
          <b-button
            v-show="hasGotFreeVip"
            class="button mt-2"
            variant="secondary"
            size="sm"
            @click="close('VipModal_CancelButton_Click')"
          >
            {{ $t('下次再說') }}
          </b-button>
          <b-button
            v-show="!hasGotFreeVip"
            class="button mt-3"
            variant="primary"
            size="sm"
            @click="wantToGetFreeVip"
          >
            {{ $t('免費體驗一個月') }}
          </b-button>
          <p
            v-show="!hasGotFreeVip"
            class="text-primary button mt-3 cursor-pointer"
            @click="gotoVipInfo"
          >
            {{ $t('了解VIP方案') }}
          </p>
        </div>
      </div>
    </b-modal>
    <ModalConfirm
      v-if="!isSuccess"
      v-model="isWantToGetFreeVip"
      :no-close="true"
      @cancel="close('VipModal_TrialCheckCancelButton_Click')"
      @confirm="getFreeVip"
    >
      <div>{{ $t('準備好體驗VIP方案了嗎？') }}</div>
      <div>{{ $t('方案結束後不會自動續訂，因此不會有任何扣款。') }}</div>
    </ModalConfirm>
    <b-modal
      v-if="isSuccess"
      ref="modal"
      class="modal-confirm"
      content-class="rounded-10 position-relative"
      hide-footer
      hide-header
      size="sm"
      :visible="isShow"
      :centered="true"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      @hide="close"
      @close="close"
    >
      <div class="modal-vip">
        <div class="header">
          <div><i class="icon-Diamond" /></div>
          <div>
            <h3>{{ $t('歡迎使用VIP方案') }}</h3>
          </div>
        </div>
        <div class="text-justify content">
          <div>
            {{ $t('您的 VIP 方案已經生效，可以開始免費使用相關功能了。') }}
            <span class="text-accent"> {{ startDate }}{{ endDate }} </span>
          </div>
          <p class="text-primary text-right cursor-pointer mt-3" @click="close">
            {{ $t('確定') }}
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {manifest} from '@/constant/env.js';
import {mapGetters} from 'vuex';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import hjjService from '@/services/hjj';
import dayjs from 'dayjs';
import {TYPES} from '@/constant/ModalVip';

export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isWantToGetFreeVip: false,
      isSuccess: false,
      startDate: '',
      endDate: '',
    };
  },
  components: {
    ModalConfirm,
  },
  computed: {
    ...mapGetters({
      user: 'user/userData',
      isLoading: 'env/isLoading',
      isShow: 'env/isShowVipModal',
    }),
    type() {
      if (TYPES[this.isShow]) return this.isShow;
      return TYPES.DEFAULT;
    },
    hasGotFreeVip() {
      return this.user?.hasGotFreeVip;
    },
    title() {
      switch (this.type) {
        case TYPES.ENERGY:
          return '需要更多體力嗎？';
        case TYPES.LIVE:
          return '與專業老師線上互動';
        case TYPES.REVIEW:
          return '開啟進階圖表分析';
        case TYPES.CAMERA_TO_SCORE_GAME:
          return '棋譜拍照計算勝負';
        default:
          return this.hasGotFreeVip ? 'VIP 方案專屬功能' : '免費體驗VIP方案';
      }
    },
    contents() {
      switch (this.type) {
        case TYPES.ENERGY:
          return this.hasGotFreeVip
            ? '購買VIP方案\n擁有無限的體力'
            : '免費體驗一個月VIP方案\n擁有無限的體力';
        case TYPES.LIVE:
          return this.hasGotFreeVip
            ? '購買VIP方案\n有效掌握自己的對局表現'
            : '免費體驗一個月VIP方案\n有效掌握自己的對局表現';
        case TYPES.REVIEW:
          return this.hasGotFreeVip
            ? '購買VIP方案\n跟著專業圍棋老師一起解開困惑'
            : '免費體驗一個月VIP方案\n跟著專業圍棋老師一起解開困惑';
        case TYPES.CAMERA_TO_SCORE_GAME:
          return this.hasGotFreeVip
            ? '購買VIP方案\n所有的棋譜都可拍照計算勝負'
            : '免費體驗一個月VIP方案\n所有的棋譜都可拍照計算勝負';
        default:
          return '即刻解鎖VIP方案\n體驗更多進階功能！';
      }
    },
    iconClass() {
      switch (this.type) {
        case TYPES.ENERGY:
          return 'icon-lightning';
        case TYPES.LIVE:
          return 'icon-school';
        case TYPES.REVIEW:
          return 'icon-Chart-line';
        case TYPES.CAMERA_TO_SCORE_GAME:
          return 'icon-Camera';
        default:
          return 'icon-Diamond';
      }
    },
  },
  methods: {
    close(eventName) {
      if (eventName) this.trackEvent(eventName);
      this.isWantToGetFreeVip = false;
      this.isSuccess = false;
      this.$store.commit('env/setIsShowVipModal', false);
      this.$emit('input', false);
    },
    async trackEvent(eventName) {
      await this.$gaTrack(eventName);
    },
    gotoVipInfo() {
      this.close('VipModal_LearnMoreButton_Click');
      this.$router.push({name: 'vip-training-plan'});
    },
    gotoVipHomePage() {
      this.close('VipModal_OfficialWebsite_Click');
      window.open(`${manifest.homePageUrl}/origin/#/shop/products/aiTutor-1`);
    },
    wantToGetFreeVip() {
      this.isWantToGetFreeVip = true;
      this.trackEvent('VipModal_LearnMoreButton_Click');
    },
    async getFreeVip() {
      if (this.isLoading) return;
      this.trackEvent('VipModal_TrialCheckConfirmButton_Click');
      this.$store.commit('env/setIsLoading', true);
      const result = await hjjService.freeVip();
      const {startedAt, endedAt} = result;

      /** 因為英文問題會用到 s 會導致直接用 format 會出問題
       * @param {date} date 日期
       * @param {String} text 格式化字串
       * @returns {String} text
       */
      const format = (date, text) => {
        const dayjsDoc = dayjs(date);
        const list = ['YYYY', 'M', 'D'];
        let result = text;
        list.forEach((key) => {
          result = result.replaceAll(key, dayjsDoc.format(key));
        });
        return result;
      };
      this.startDate = format(
        startedAt,
        this.$t('該方案將從 YYYY 年 M 月 D 日生效，')
      );
      this.endDate = format(endedAt, this.$t('持續到 YYYY 年 M 月 D 日結束。'));
      this.isSuccess = true;
      await this.$store.dispatch('user/loginWithJwt', {force: true});
      this.$store.commit('env/setIsLoading', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-vip {
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  height: 330px;
  text-align: center;
  .header {
    color: $white;
    height: 140px;
    background: $secondary;
    padding: 16px 0px;
  }
  i {
    font-size: 78px;
  }
  .content {
    padding: 24px 16px;
    color: $font-normal;
    background: $white;
    & > div {
      white-space: pre-line;
    }
  }
  .button {
    min-width: 140px;
  }
  .btn-close {
    cursor: pointer;
    position: absolute;
    font-size: 32px;
    top: 14px;
    right: 14px;
  }
}
.cursor-pointer.text-primary:hover {
  color: $hover-primary !important;
}
:deep().modal-body {
  padding: 0;
}
:deep().modal-content {
  width: 300px;
  margin: auto;
}
</style>
