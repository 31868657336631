/* eslint-disable no-unused-vars */
/**
本文件主要定義了兩類圍棋課程：ecCoursesNormal（一般課程） 和 ecCourseCombo（組合方案），
並將它們轉換成 Map 對象以及相關的課程列表。以下是各部分的具體說明。

1. ecCoursesNormal
這部分包含了標準圍棋課程的資訊，適合不同程度的學習者。每個課程都有相關的 name（課程名稱）、
description（課程描述）、以及 courseId（課程ID）。
2. ecCourseCombo
這部分涵蓋了組合圍棋課程方案的資訊，每個方案均包含 name（方案名稱）和 description（方案描述）。
*/

const ecProductsNormal = {
  'kidinspired-1': {
    name: '兒童啟蒙課',
    description: '專為10歲以下零基礎兒童設計，開啟獨立思考大門',
    courseId: 'kidinspired',
    courseCounts: 4,
    vipMonthCounts: 1,
    mockExam: [],
    teacher: ['黑嘉嘉'],
  },
  'kidbasic-1': {
    name: '兒童基礎課',
    description: '專為10歲以下兒童設計，孩子一生受用的思維禮物',
    courseId: 'kidbasic',
    courseCounts: 64,
    vipMonthCounts: 12,
    mockExam: ['G19~G15'],
    teacher: ['黑嘉嘉'],
  },
  '30k-10k-v2-1': {
    name: '基礎課',
    description: '零基礎全年齡適用！輕鬆學會圍棋，通過G15檢定',
    courseId: '30k-10k-v2',
    courseCounts: 30,
    vipMonthCounts: 3,
    mockExam: ['G19~G15'],
    teacher: ['黑嘉嘉'],
  },
  'intermediate-1': {
    name: '進階課',
    description: '適合具一定基礎、能下19路棋盤的你，目標過G10',
    courseId: 'intermediate',
    courseCounts: 56,
    vipMonthCounts: 12,
    mockExam: ['G15~G10'],
    teacher: ['黑嘉嘉', '蕭正浩'],
  },
  'advanced-1': {
    name: '高階課',
    description: '進階好手或業餘段位以上適用，目標通過G5里程碑',
    courseId: 'advanced',
    courseCounts: 40,
    vipMonthCounts: 6,
    mockExam: ['G10~G5'],
    teacher: ['蕭正浩'],
  },
  'master-1': {
    name: '大師課',
    description: '業餘高段好手適用，職業九段帶你邁向頂點通過G1',
    courseId: 'master',
    courseCounts: 32,
    vipMonthCounts: 9,
    mockExam: ['G5~G1'],
    teacher: ['蕭正浩'],
  },
};

const ecProductsPlus = {
  'goplus-01-1': {
    name: '圍棋十訣',
    description: '十場經典對弈x十個圍棋關鍵守則',
    courseId: 'goplus-01',
    courseCounts: 10,
    vipMonthCounts: 0,
    mockExam: [],
    teacher: ['蕭正浩', '林延儒'],
  },
};

const ecProductsCombo = {
  'combo-01': {
    name: '棋力放大方案 —《最強的初段》',
    description: '基礎課＋進階課，從零開始一路通過G10，輕鬆晉段',
    courseCounts: 86,
    vipMonthCounts: 15,
    mockExam: ['G19~G10'],
    teacher: ['黑嘉嘉', '蕭正浩'],
  },
  'combo-02': {
    name: '親子同樂合購方案A —《手談是我們的共同語言》',
    description: '專為家長和小朋友設計，從零開始一同進入圍棋世界',
    courseCounts: 94,
    vipMonthCounts: 15,
    mockExam: ['G19~G15'],
    teacher: ['黑嘉嘉'],
  },
  'combo-03': {
    name: '親子同樂合購方案B —《壹加壹一起過檢定》',
    description: '兒童基礎課＋進階課，和孩子創造一輩子的手談羈絆',
    courseCounts: 120,
    vipMonthCounts: 24,
    mockExam: ['G19~G10'],
    teacher: ['黑嘉嘉', '蕭正浩'],
  },
  'combo-04': {
    name: '棋力放大方案 —《推開高手之門》',
    description: 'G10-G1課程組合，適合段位以上想突破棋力界線的你',
    courseCounts: 72,
    vipMonthCounts: 15,
    mockExam: ['G10~G1'],
    teacher: ['蕭正浩'],
  },
  'combo-05': {
    name: '棋力放大方案 —《從入門到巔峰全攻略》',
    description: 'G19-G1課程組合，目標學習者最划算方案，可合購',
    courseCounts: 158,
    vipMonthCounts: 30,
    mockExam: ['G19~G1'],
    teacher: ['黑嘉嘉', '蕭正浩'],
  },
};

const defaultVerificationConfig = {
  description: '為想了解棋力程度/目標考取IGQE檢定之學員準備',
  level: '難度對標 IGQE 國際認證考試',
  report: '專屬成績單與分析報告',
  certificate: '線上數位證書',
};

const ecProductVerification = {};
for (let i = 19; i > 0; i--) {
  const name = `G${i} 模擬檢定`;
  const config = {
    ...defaultVerificationConfig,
    name,
    mockExam: [`G${i}`],
  };
  ecProductVerification[`verification-g${i}`] = config;
}

const defaultAiTutoeConfig = {
  description: '進階額外功能，為任何程度學員強化棋力',
  video: 'LIVE 直播講堂',
  diamond: '天梯闖關無限體力',
  verification: '進階 AI 覆盤分析',
};
const aiTutor = {
  1: '月',
  3: '季',
  12: '年',
};
const ecProductAiTutor = {};
Object.keys(aiTutor).forEach((num) => {
  const type = aiTutor[num];
  const name = `VIP方案—《${type}方案》`;
  const config = {
    ...defaultVerificationConfig,
    name,
  };
  ecProductAiTutor[`aiTutor-${num}`] = config;
});

const allCourseProducts = {
  ...ecProductsNormal,
  ...ecProductsPlus,
};

const allProducts = {
  ...ecProductsNormal,
  ...ecProductsPlus,
  ...ecProductsCombo,
  ...ecProductVerification,
  ...ecProductAiTutor,
};

const allProductsMap = new Map(Object.entries(allProducts));

const ecProductsNormalMap = new Map(Object.entries(ecProductsNormal));
const ecProductsNormalList = Array.from(ecProductsNormalMap.keys());

const ecProductsComboMap = new Map(Object.entries(ecProductsCombo));
const ecProductsComboList = Array.from(ecProductsComboMap.keys());

const ecProductsPlusMap = new Map(Object.entries(ecProductsPlus));
const ecProductsPlusList = Array.from(ecProductsPlusMap.keys());

const ecProductsCourseMap = new Map(Object.entries(allCourseProducts));
const ecCourseNormalList = Object.values(allCourseProducts).map(
  (product) => product.courseId
);
/**
 * Finds the product name by the given courseId in the provided map.
 *
 * @param {string} courseId - The courseId to search for
 * @return {string|null} The product name associated with the given courseId, or null if not found
 */
function findProductNameByCourseId(courseId) {
  for (const [_, value] of ecProductsCourseMap.entries()) {
    if (value.courseId === courseId) {
      return value.name;
    }
  }
  return null;
}

export {
  ecProductsNormalMap,
  ecProductsComboMap,
  ecProductsNormalList,
  ecProductsComboList,
  ecCourseNormalList,
  ecProductsPlusMap,
  ecProductsPlusList,
  ecProductsCourseMap,
  allProductsMap,
  findProductNameByCourseId,
};
