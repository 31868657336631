export default [
  {
    region: '台灣',
    callingCode: '+886',
    regionCode: 'TW',
    sort: {
      'zh-TW': 1,
      'en-US': 7,
      'zh-CN': 1,
    },
  },
  {
    region: '香港',
    callingCode: '+852',
    regionCode: 'HK',
    sort: {
      'zh-TW': 2,
      'en-US': 6,
      'zh-CN': 2,
    },
  },
  {
    region: '馬來西亞',
    callingCode: '+60',
    regionCode: 'MY',
    sort: {
      'zh-TW': 3,
      'en-US': 5,
      'zh-CN': 3,
    },
  },
  {
    region: '新加坡',
    callingCode: '+65',
    regionCode: 'SG',
    sort: {
      'zh-TW': 4,
      'en-US': 4,
      'zh-CN': 4,
    },
  },
  {
    region: '美國',
    callingCode: '+1',
    regionCode: 'US',
    sort: {
      'zh-TW': 5,
      'en-US': 1,
      'zh-CN': 5,
    },
  },
  {
    region: '加拿大',
    callingCode: '+1',
    regionCode: 'CA',
    sort: {
      'zh-TW': 6,
      'en-US': 2,
      'zh-CN': 6,
    },
  },
  {
    region: '英國',
    callingCode: '+44',
    regionCode: 'GB',
    sort: {
      'zh-TW': 7,
      'en-US': 3,
      'zh-CN': 7,
    },
  },
];
