import {callLambda} from '@/lib/api/lambda';

/**
 * getAiResponse 取得 AI 教練的回應
 * @param {Object} data 請求資料
 * @param {String} data.text 使用者輸入的文字
 * @param {String} data.coursePlan 選填，使用者選擇的課程計畫
 */
async function getAiResponse(data) {
  return callLambda({
    category: 'ai',
    method: 'post',
    apiName: 'ai-tutor',
    data,
  }).then((res) => {
    return res.data;
  });
}

export default {
  getAiResponse,
};
