import Vue from 'vue';
import {getI18nImage} from '@/i18n/utils.js';

Vue.directive('click-outside', {
  bind(element, binding, vnode) {
    element.clickOutsideEvent = function (event) {
      if (!(element === event.target || element.contains(event.target))) {
        vnode.context[binding.expression]();
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent);
  },
  unbind(element) {
    document.body.removeEventListener('click', element.clickOutsideEvent);
  },
});

/**
 * 根據 i18n locale 設定圖片來源
 * @param {*} el 綁定元素
 * @param {*} binding 綁定資料
 * @param {*} vnode Vue 節點實體
 */
const setLocalizedSrc = (el, binding) => {
  el.src = getI18nImage(binding.value);
};

Vue.directive('i18n-src', {
  bind(el, binding, vnode) {
    setLocalizedSrc(el, binding);

    const unwatch = vnode.context.$watch('$i18n.locale', () => {
      setLocalizedSrc(el, binding);
    });

    el._unwatchLocale = unwatch;
  },
  update(el, binding) {
    setLocalizedSrc(el, binding);
  },
  unbind(el) {
    if (el._unwatchLocale) {
      el._unwatchLocale();
    }
  },
});
