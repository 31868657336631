import Vue from 'vue';
import alert from '@/components/Modal/ModalAlert.vue';

const Alert = Vue.extend(alert);

const alertFun = function (options) {
  const isString = typeof options === 'string' || typeof options === 'number';
  const Instance = new Alert({
    data: {
      message: isString ? options : options.message || 'message',
      confirmText: (options && options.confirm) || '確定',
    },
  });
  const vm = Instance.$mount();
  document.body.appendChild(vm.$el);
  return vm.init();
};

export default alertFun;
