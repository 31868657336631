<template>
  <div v-if="show">
    <ModalIconBanner
      :title="$t('棋力詢問')"
      icon="icon-Question"
      :is-show="step === 'index'"
    >
      <div class="px-3 py-4 text-left">
        <h4 class="mb-3">{{ $t('{name}你好～', {name: nickname}) }}</h4>
        <h4>{{ $t('請問你對圍棋的認識有多少呢？') }}</h4>
        <b-form-radio-group
          id="radio-group-1"
          v-model="selected"
          :options="options"
          name="radio-options"
          stacked
        ></b-form-radio-group>
        <b-button
          class="mt-3"
          variant="primary"
          size="md"
          block
          @click="nextStep"
        >
          {{ $t('確定送出') }}
        </b-button>
      </div>
    </ModalIconBanner>
    <ModalIconBanner
      :title="$t('哈囉！超級新手')"
      icon="icon-Hint-active"
      :is-show="step === 'noPlay'"
    >
      <div class="px-3 py-4 text-center">
        <h4>{{ $t('你可以從體驗課程開始觀看喔！') }}</h4>

        <b-button class="mt-3" variant="primary" size="md" @click="goToLearn">
          {{ $t('前往觀看') }}
        </b-button>
        <br />
        <b-button
          class="mt-3"
          variant="secondary"
          size="md"
          @click="close(true)"
        >
          {{ $t('下次再說') }}
        </b-button>
      </div>
    </ModalIconBanner>
    <ModalIconBanner
      :title="$t('取得你的第一個棋力！')"
      icon="icon-Hint-active"
      :is-show="step === 'hasPlay'"
    >
      <div class="px-3 py-4 text-center">
        <h4 style="white-space: pre-line">
          {{ $t('棋力對於接下來的體驗很有幫助喔！\n來做個測驗吧？') }}
        </h4>

        <b-button class="mt-3" variant="primary" size="md" @click="goToTest">
          {{ $t('前往測試') }}
        </b-button>
        <br />
        <b-button class="mt-3" variant="secondary" size="md" @click="pass">
          {{ $t('下次再測') }}
        </b-button>
      </div>
    </ModalIconBanner>
    <ModalConfirm
      v-model="isPass"
      :confirm-text="$t('知道了')"
      hide-cancel
      @confirm="close"
    >
      <h4 style="white-space: pre-line">
        {{ $t('之後可以在「個人」\n完成初始棋力測驗') }}
      </h4>
    </ModalConfirm>
  </div>
</template>

<script>
import ModalIconBanner from '@/components/Modal/ModalIconBanner.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import POPUP_IDS, {getPopupId} from '@/constant/popupIds.js';

export default {
  components: {
    ModalConfirm,
    ModalIconBanner,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDone: true,
      isPass: false,
      step: null,
      selected: 'hasPlay',
    };
  },
  watch: {
    show() {
      this.init();
    },
    isDone() {
      this.init();
    },
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    nickname() {
      return this.userData?.nickName;
    },
    rank() {
      return this.userData?.rank;
    },
    options() {
      return [
        {text: '有一定棋力', value: 'hasPlay'},
        {text: '略懂圍棋規則', value: 'hasPlay-2'},
        {text: '完全不懂圍棋規則', value: 'noPlay'},
      ];
    },
  },
  async created() {
    const popupId = getPopupId(POPUP_IDS.RANK_QUESTION);
    await this.$store.dispatch('popup/getPopupIsDone', popupId);
    this.isDone = this.$store.getters['popup/getIsDone'](popupId);
  },
  methods: {
    init() {
      if (this.show && !this.isDone) {
        this.step = 'index';
      }
    },
    nextStep() {
      const popupId = getPopupId(POPUP_IDS.RANK_QUESTION);
      this.$store.dispatch('popup/setPopupIsDone', popupId);
      switch (this.selected) {
        case 'hasPlay':
        case 'hasPlay-2':
          this.step = 'hasPlay';
          break;
        case 'noPlay':
          this.step = 'noPlay';
          break;
      }
    },
    goToLearn() {
      this.close(true);
      this.$router.push({path: '/learning/course'});
    },
    goToTest() {
      this.close();
      this.$router.push({name: 'rank-test'});
    },
    pass() {
      this.step = '';
      this.isPass = true;
    },
    close(noTest = false) {
      if (noTest) {
        const popupId = getPopupId(POPUP_IDS.RANK_QUESTION_TEST);
        this.$store.dispatch('popup/setPopupIsDone', popupId);
      }
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-dialog {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  color: $font-grayscale-1;
  .modal-body {
    padding: 0;
  }
}
:deep() .custom-control.custom-radio {
  margin-top: 8px;
}
:deep() .btn.btn-md {
  min-width: 140px;
}

.header {
  padding: 14px 8px 14px 16px;
  border-bottom: 1px solid $line-1;
}
img {
  width: 300px;
}
.btn-wrap {
  border-top: 1px solid #ced4da;
  text-align: center;
}
</style>
