var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sticker-sheet"},[(_vm.$device.isMobile)?_c('action-sheet',{ref:"action-sheet",attrs:{"title":"","sheet-height":""},on:{"close":_vm.close}},[_c('div',{staticClass:"pt-3 pb-2 px-2 w-100 h-100"},[_c('b-tabs',{staticClass:"sticker-tabs",attrs:{"fill":"","nav-class":"sticker-tab","nav-wrapper-class":"wrapper-sticker-tab"}},_vm._l((_vm.stickersTypes),function(stickerType){return _c('b-tab',{key:stickerType.value,attrs:{"title":stickerType.title}},[_c('div',{staticClass:"d-flex row mx-0 px-1"},_vm._l((stickerType.count),function(sticker){return _c('div',{key:sticker,staticClass:"col-4 mb-2 px-1"},[_c('div',{staticClass:"sticker-wrapper rounded-10 text-center",on:{"click":function($event){return _vm.select(stickerType.value + '-' + sticker)}}},[_c('img',{directives:[{name:"i18n-src",rawName:"v-i18n-src",value:(
                    '@/assets/img/locales/{locale}/sticker/' +
                    stickerType.value +
                    '-' +
                    sticker +
                    '.png'
                  ),expression:"\n                    '@/assets/img/locales/{locale}/sticker/' +\n                    stickerType.value +\n                    '-' +\n                    sticker +\n                    '.png'\n                  "}],staticClass:"w-100"})])])}),0)])}),1)],1)]):_vm._e(),(!_vm.$device.isMobile)?_c('div',{staticClass:"web-sticker-sheet"},[_c('div',{staticClass:"wrap-stickers-close text-primary text-16",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('收起')))]),_c('span',{staticClass:"icon-Chevron-down"})]),_c('b-tabs',{staticClass:"sticker-tabs",attrs:{"fill":"","nav-class":"sticker-tab","nav-wrapper-class":"wrapper-sticker-tab"}},_vm._l((_vm.stickersTypes),function(stickerType){return _c('b-tab',{key:stickerType.value,attrs:{"title":stickerType.title}},[_c('div',{staticClass:"pb-md-3 px-md-3"},[_c('div',{staticClass:"pt-2 pb-0 px-1 w-100 wrap-sticker-content scroll-bar"},[_c('div',{staticClass:"d-flex row mx-0 w-100"},_vm._l((stickerType.count),function(sticker){return _c('div',{key:sticker,staticClass:"sticker mb-2 px-1",class:{'col-4': _vm.$device.isMobile}},[_c('div',{staticClass:"sticker-wrapper rounded-10 text-center",on:{"click":function($event){$event.stopPropagation();return _vm.select(stickerType.value + '-' + sticker)}}},[_c('img',{directives:[{name:"i18n-src",rawName:"v-i18n-src",value:(
                      '@/assets/img/locales/{locale}/sticker/' +
                      stickerType.value +
                      '-' +
                      sticker +
                      '.png'
                    ),expression:"\n                      '@/assets/img/locales/{locale}/sticker/' +\n                      stickerType.value +\n                      '-' +\n                      sticker +\n                      '.png'\n                    "}],staticClass:"w-100"})])])}),0)])])])}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }