import multimediaClassroomService from '@/services/multimediaClassroom';
import {v4 as uuidV4} from 'uuid';
import {splitByAllNumber} from '@/lib/base/chinese2digits';

/**
 * 排列分類名稱與課件名稱的方法
 * @param {String} aName aName
 * @param {String} bName bName
 * @returns {Array<String>} promise
 */
const sortFunction = (aName, bName) => {
  // return 0;
  const aNameList = splitByAllNumber(aName);
  const bNameList = splitByAllNumber(bName);
  const maxLength = Math.max(aNameList.length, bNameList.length);
  for (let i = 0; i < maxLength; i++) {
    const aChar = aNameList[i] || '';
    const bChar = bNameList[i] || '';

    if (aChar < bChar) return -1;
    if (aChar > bChar) return 1;
  }
  return 0;
};

const state = {
  courseId: null,
  courseContents: null,
  types: [],
  courses: {},
};

const getters = {
  courseId(state) {
    return state.courseId;
  },
  courseContents(state) {
    return state.courseContents;
  },
  courseCount: (state, getters) => (typeId) => {
    return getters['typeCourse'](typeId).length;
  },
  typeCourse: (state) => {
    return (typeId, {needSort = false} = {}) => {
      const list = Object.values(state.courses[typeId] || {});
      if (needSort) {
        list.sort((a, b) => {
          const aName = a.courseName;
          const bName = b.courseName;
          return sortFunction(aName, bName);
        });
      }
      return list;
    };
  },
  type: (state) => (typeId) => {
    return state.types.find((type) => type.typeId === typeId);
  },
};

const actions = {
  async getCourseDetail({commit}, id) {
    try {
      const result = await multimediaClassroomService.getCourseDetail(id);
      commit('setCourse', result);
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async createType({commit}, {name, isPublic = true}) {
    const result = await multimediaClassroomService.createType({
      name,
      isPublic,
    });
    commit('setTypes', result);
    return result;
  },
  async getTypes({commit}, {isPublic = true} = {}) {
    const result = await multimediaClassroomService.getTypes(isPublic);
    commit('setTypes', result);
    return result;
  },
  async updateType({commit}, {id, name}) {
    const result = await multimediaClassroomService.updateType({id, name});
    commit('setTypes', result);
    return result;
  },
  async deleteType({commit}, {id}) {
    const result = await multimediaClassroomService.deleteType(id);
    commit('setTypes', result);
    return result;
  },

  async getCourses({commit}, {isPublic = true, typeId} = {}) {
    const result = await multimediaClassroomService.getCourses({
      isPublic,
      typeId,
    });
    commit('setCourses', result);
    return result;
  },

  async createCourse(
    {commit, state},
    {isPublic = true, typeId, courseName} = {}
  ) {
    commit('setDefaultCourseContents');
    const courseContent = state.courseContents;

    const newCourse = await multimediaClassroomService.createCourse({
      isPublic,
      typeId,
      courseName,
      courseContent,
    });
    commit('setCourses', [newCourse]);
    return newCourse;
  },

  async updateCourse({commit}, {typeId, courseId, courseName, courseContent}) {
    const result = await multimediaClassroomService.getCourseDetail(courseId);
    courseContent = courseContent || result.courseContent;
    courseName = courseName || result.courseName;
    typeId = typeId || result.typeId;
    commit('setCourseContents', courseContent);
    await multimediaClassroomService.updateCourse({
      typeId,
      courseId,
      courseName,
      courseContent,
    });
    commit('removeCourses', [result]);
    commit('setCourses', [
      {
        typeId,
        courseId,
        courseName,
        courseContent,
      },
    ]);
    return result;
  },

  async deleteCourse({commit}, {typeId, courseId}) {
    const result = await multimediaClassroomService.deleteCourse(courseId);
    commit('removeCourses', [{typeId, courseId}]);
    return result;
  },
};

const mutations = {
  setDefaultCourseContents(state) {
    state.courseContents = [{filmstripId: uuidV4(), sgf: '"(;CA[big5]SZ[9])'}];
  },
  setCourse(state, {courseId, courseContent}) {
    state.courseId = courseId;
    state.courseContents = courseContent;
  },
  setCourseId(state, courseId) {
    state.courseId = courseId;
  },
  setCourseContents(state, courseContent) {
    state.courseContents = courseContent;
  },
  updateCourseContentById(state, {filmstripId, title, sgf}) {
    state.courseContents = state.courseContents.map((course) => {
      if (course.filmstripId === filmstripId) {
        return {
          ...course,
          title: title != null ? title : course.title,
          sgf: sgf != null ? sgf : course.sgf,
        };
      }
      return course;
    });
  },
  deleteCourseContentByIndex(state, index) {
    state.courseContents = state.courseContents.filter((_, i) => i !== index);
  },
  addCourseContents(state, list) {
    state.courseContents = state.courseContents.concat(
      list.map(({sgf, image}) => {
        const result = {
          filmstripId: uuidV4(),
        };
        if (sgf) {
          result.sgf = sgf;
          result.title = '';
        }
        if (image) {
          result.image = image;
        }
        return result;
      })
    );
  },

  setTypes(state, types) {
    state.types = types.sort((a, b) => {
      const aName = a.typeName;
      const bName = b.typeName;
      return sortFunction(aName, bName);
    });
  },
  removeCourses(state, courses) {
    const temp = {...(state.courses || {})};
    courses.forEach((course) => {
      const {typeId, courseId} = course;
      if (!temp[typeId]) temp[typeId] = {};
      delete temp[typeId][courseId];
    });
    state.courses = temp;
  },
  setCourses(state, courses) {
    const temp = {...(state.courses || {})};
    courses.forEach((course) => {
      const {typeId, courseId} = course;
      if (!temp[typeId]) temp[typeId] = {};
      temp[typeId][courseId] = course;
    });
    state.courses = temp;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
