<template>
  <div class="w-100 h-100 d-flex flex-column align-items-center page-container">
    <base-header
      v-if="$device.isMobile"
      left-arrow
      :title="$t('學習計畫')"
      :right-icon="'Setting'"
      :back-on-click-left="false"
      @on-click-left="redirectToLearnPage"
      @on-click-right="toggleSettingShow"
    ></base-header>

    <div class="sticky-bar">
      <div
        v-if="isSettingShow && !$device.isMobile"
        class="setting-list"
        :class="$device.isMobile ? 'is-mobile' : 'is-web'"
      >
        <div class="setting-item" @click="openFinishPlanModal">
          {{ $t('結束學習計畫') }}
        </div>
        <div class="setting-item" @click="openResetPlanModal">
          {{ $t('重定學習計畫') }}
        </div>
      </div>
      <div class="goal-container" :class="{'is-collapse': isScrollDown}">
        <div class="goal-card section-container">
          <div>
            <p class="text">{{ $t('學習目標') }}</p>
            <p class="rank">{{ convertGRank(planRank) }}</p>
          </div>
          <div class="divide"></div>
          <div>
            <i18n path="時間：共{week}週" tag="p" class="text-lg">
              <span place="week" class="num">{{ planTotalWeek }}</span>
            </i18n>
            <p class="text-sm">
              {{ formatDate(firstWeekDate) }}
              ～
              {{ formatDate(lastWeekDate) }}
            </p>
          </div>
          <div
            v-if="!$device.isMobile"
            class="goal-setting"
            @click="toggleSettingShow"
          >
            <i class="icon-Setting"></i>
          </div>
        </div>
      </div>
      <div class="week-container">
        <i
          class="week-controller-icon icon-Chevron-left"
          @click="moveToPrevWeek"
        ></i>
        <div
          ref="weekButtonGroupEl"
          class="week-button-group hidden-scroll-style"
        >
          <div
            v-for="(plan, planIdx) in planList"
            :key="planIdx"
            class="week-button"
            :class="[
              {'is-selected': currPlanListIndex === planIdx},
              {
                'is-curr': isBetweenDate(
                  planWeekTimes[planIdx][0],
                  planWeekTimes[planIdx][1]
                ),
              },
            ]"
            @click="handleOnClickWeekButton(planIdx)"
          >
            <div
              v-if="checkAllPlanFinish(planList[planIdx])"
              class="week-button-icon"
            >
              <span :class="'icon-Colorful-correct'">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </div>
            W{{ plan[0].weeks }}
          </div>
        </div>
        <i
          class="week-controller-icon icon-Chevron-right"
          @click="moveToNextWeek"
        ></i>
      </div>
    </div>
    <div
      ref="bodyEl"
      class="body-container"
      :class="[{'is-collapse': isScrollDown}, {'is-web': !$device.isMobile}]"
    >
      <div class="plan-container">
        <div class="week-bar">
          <p class="week">
            {{
              $t(`第 {week} 週`, {
                week: planList[currPlanListIndex]?.[0]?.weeks,
              })
            }}
          </p>
          <p class="date">
            {{ formatDate(planWeekTimes[currPlanListIndex][0]) }}～{{
              formatDate(planWeekTimes[currPlanListIndex][1])
            }}
          </p>
        </div>

        <div class="week-plan hidden-scroll-style">
          <template v-for="(plan, planIdx) in planList[currPlanListIndex]">
            <div
              :key="planIdx"
              class="plan-card section-container"
              @click="handleOnClickCard(plan)"
            >
              <i class="plan-icon" :class="plan.icon"></i>
              <div>
                <div class="plan-type">
                  {{ $t(typeMap[plan.type]) }}
                </div>
                <div class="plan-description">{{ $t(plan.description) }}</div>
              </div>
              <i v-if="plan.isPass" class="plan-status icon-Check"> </i>
            </div>
          </template>
        </div>
      </div>
    </div>
    <action-sheet
      v-if="isSettingShow && $device.isMobile"
      :title="$t('設定學習計畫')"
      :touch-move-to-close="true"
      sheet-height="156"
      @close="closeSetting"
    >
      <div
        class="setting-list"
        :class="$device.isMobile ? 'is-mobile' : 'is-web'"
      >
        <div class="setting-item" @click="openFinishPlanModal">
          {{ $t('結束計畫') }}
        </div>
        <div class="setting-item" @click="openResetPlanModal">
          {{ $t('重定計畫') }}
        </div>
      </div>
    </action-sheet>
    <modal-confirm
      v-model="isFinishPlanModalShow"
      :confirm-text="$t('確定')"
      :cancel-text="$t('取消')"
      @confirm="handleOnFinishPlan"
      @cancel="closeFinishPlanModal"
    >
      {{ $t('確定要結束學習計畫？') }}
    </modal-confirm>
    <modal-confirm
      v-model="isResetPlanModalShow"
      :confirm-text="$t('確定')"
      :cancel-text="$t('取消')"
      @confirm="handleOnResetPlan"
      @cancel="closeResetPlanModal"
    >
      {{ $t('確定要重設學習計畫？') }}
    </modal-confirm>
    <modal-result
      v-if="resultModalController.isShow"
      :is-success="true"
      :mode="resultModalController.mode"
      :winning-way-text="resultModalController.description"
      :elo-text="resultModalController.title"
      @item-click="resultModalController.action"
    ></modal-result>
  </div>
</template>
<script>
import BaseHeader from '@/components/Base/BaseHeader.vue';
import ActionSheet from '@/components/Base/ActionSheet.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import ModalResult from '@/components/Modal/ModalResult.vue';
import {convertGRank} from '@/lib/base/ranking';
import aiGameService from '@/services/aiGame';
import tsumeGameService from '@/services/tsumeGame';
import Egg from '@/lib/base/egg';
import _ from 'lodash';

export default {
  name: 'AiTutorLearnPlan',
  components: {
    BaseHeader,
    ActionSheet,
    ModalConfirm,
    ModalResult,
  },
  data() {
    return {
      typeMap: {
        GAME: '下棋',
        TSUME: '做題',
        LIVE_COURSE: '直播',
        COURSE_VIDEO: '課程',
        COURSE_TSUME: '課後練習',
        VERIFICATION: '檢定',
      },
      iconMap: {
        GAME: 'icon-Kifu',
        TSUME: 'icon-Kifu2',
        LIVE_COURSE: 'icon-live-stream',
        COURSE_VIDEO: 'icon-Video',
        COURSE_TSUME: 'icon-Book',
        VERIFICATION: 'icon-Verification',
      },
      resultModalController: {
        isShow: false,
        mode: 'aiTutor',
        title: '',
        description: '',
        action: () => null,
      },
      bodyEl: null,
      weekButtonGroupEl: null,
      bodyScrollTop: 0,
      latestScrollPercentage: 0,
      scrollTimeout: null,
      collapseTimeout: null,
      isScrolling: false,
      toucher: null,
      isCollapsing: null,
      isScrollDown: false,
      isSettingShow: false,
      isFinishPlanModalShow: false,
      isResetPlanModalShow: false,
      currPlanListIndex: 0,
      aiGameCourseConfigs: [],
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
    planRank() {
      return this.$store.state['aiTutor'].studyPlan.rank;
    },
    planCreatedTime() {
      return this.$store.state['aiTutor'].studyPlan.createdAt;
    },
    planTotalWeek() {
      return this.$store.state['aiTutor'].studyPlan.weekCount;
    },
    planWeekTimes() {
      return this.$store.state['aiTutor'].studyPlan.weekTimes;
    },
    planList() {
      return this.formatPlans(this.$store.state['aiTutor'].studyPlan.plans);
    },
    firstWeekDate() {
      return this.planWeekTimes.at(0)?.[0];
    },
    lastWeekDate() {
      return this.planWeekTimes.at(-1)?.[1];
    },
    coursePlan() {
      return this.$store.state['aiTutor'].studyPlan.coursePlan;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    hasUnfinishedGame() {
      return this.$store.getters['aiGame/hasUnfinishedGame'];
    },
    getAiInfo: (instance) => (aiId) => {
      const aiList = instance.aiGameCourseConfigs
        .map((list) => (list.aiList ? list.aiList.flat() : []))
        .flat();
      return aiList?.find((item) => item.id === aiId) || null;
    },
    convertGRank: () => (v) => {
      return convertGRank(v);
    },
  },
  watch: {
    isScrollDown() {
      this.isCollapsing = true;

      if (this.collapseTimeout) {
        clearTimeout(this.collapseTimeout);
      }
      const scrollbarAnimationEndTime = 1000;
      this.collapseTimeout = setTimeout(() => {
        this.isCollapsing = false;
      }, scrollbarAnimationEndTime);
    },
    isScrolling(scrolling) {
      if (scrolling) return;
      if (this.isCollapsing) return;
      const target = this.$refs.bodyEl;
      if (!target) return;
      this.currScrollTop = target.scrollTop;
      const currScrollPercentage =
        (this.currScrollTop / (target.scrollHeight - target.clientHeight)) *
        100;
      if (currScrollPercentage > this.latestScrollPercentage) {
        this.isScrollDown = true;
      } else {
        this.isScrollDown = false;
      }

      this.latestScrollPercentage = currScrollPercentage;
    },
  },
  methods: {
    redirectToLearnPage() {
      this.$router.push({
        name: 'learningPage',
      });
    },
    setWeekFromLastClick() {
      const lastWeekPlanInfo = localStorage.getItem('lastWeekPlanInfo');
      if (lastWeekPlanInfo) {
        const cache = JSON.parse(lastWeekPlanInfo);

        if (cache.createdAt === this.planCreatedTime) {
          this.currPlanListIndex = cache.lastPlanWeekIdx;
        }
      }
    },
    handleOnClickWeekButton(planIdx) {
      this.currPlanListIndex = planIdx;
      localStorage.setItem(
        'lastWeekPlanInfo',
        JSON.stringify({
          lastPlanWeekIdx: planIdx,
          createdAt: this.planCreatedTime,
        })
      );
    },
    async openAiTutorLearnPlanModalShow() {
      await this.$store.dispatch('aiTutor/openAiTutorLearnPlanModalShow');
    },
    async closeAiTutorLearnPlanModalShow() {
      await this.$store.dispatch('aiTutor/closeAiTutorLearnPlanModalShow');
    },
    async getUnfinishedGame() {
      await this.$store.dispatch('aiGame/getUnfinishedGame');
      this.$store.commit('env/setIsLoading', false);
    },
    openFinishPlanModal() {
      this.isFinishPlanModalShow = true;
    },
    openResetPlanModal() {
      this.isResetPlanModalShow = true;
    },
    toggleSettingShow() {
      this.isSettingShow = !this.isSettingShow;
    },
    closeSetting() {
      this.isSettingShow = false;
    },
    async handleOnFinishPlan() {
      try {
        this.closeFinishPlanModal();
        this.closeAiTutorLearnPlanModalShow();
        await this.deleteLearnPlan();
        this.$Message.success(this.$t('已成功結束計畫'));
        if (this.$device.isMobile) {
          this.$router.push({name: 'learningPage'});
        }
      } catch (e) {
        console.log('e', e);
      }
    },
    async handleOnResetPlan() {
      this.closeFinishPlanModal();
      this.closeAiTutorLearnPlanModalShow();
      await this.deleteLearnPlan();
      this.$Message.success(this.$t('已成功結束計畫'));
      this.$router.push({
        name: 'aiTutorMakePlan',
        query: {
          step: 1,
        },
      });
    },
    async handleOnClickCard(plan) {
      switch (plan.type) {
        // 下棋
        case 'GAME':
          this.handleOnClickGame(plan);
          break;
        // 做題
        case 'TSUME':
          this.handleOnClickTsume(plan);
          break;
        // 直播
        case 'LIVE_COURSE':
          this.handleOnClickLiveCourse(plan);
          break;
        // 課程影片
        case 'COURSE_VIDEO':
          this.handleOnCourseVideo(plan);
          break;
        // 課程題目
        case 'COURSE_TSUME':
          this.handleOnClickCourseTsume(plan);
          break;
        // 檢定
        case 'VERIFICATION':
          this.handleOnClickVerification(plan);
          break;
      }
      if (!this.$device.isMobile) {
        this.closeAiTutorLearnPlanModalShow();
      }
    },
    async handleOnClickGame(plan) {
      const aiInfo = this.getAiInfo(plan.gameAiId);
      const data = {
        userColor: _.sample(['black', 'white']),
        aiLevel: aiInfo.aiLevel,
        boardSize: aiInfo.boardSize,
        style: aiInfo.style,
        opponentName: aiInfo.opponentName,
        coursePlan: this.coursePlan,
        aiId: aiInfo.id,
      };

      this.$store.commit('env/setIsLoading', true);

      await this.getUnfinishedGame();

      if (!this.hasUnfinishedGame) {
        await this.$store.dispatch('aiGame/createPracticeGame', data);
      }

      this.$store.commit('env/setIsLoading', false);
      this.$router.push('/practice/game');
    },
    handleOnClickLiveCourse(plan) {
      // 導向直播頁面
      this.$router.push({
        name: 'liveList',
        query: {
          isAiPlanMode: true,
          search: plan.liveCourseTitle,
          startAt: plan.liveCourseTimeRange[0],
          endAt: plan.liveCourseTimeRange[1],
        },
      });
    },
    handleOnCourseVideo(plan) {
      this.$store.commit('env/setIsLoading', true);
      this.$store.commit('aiCourse/setUrlData', {
        aiCourseId: plan.courseContent.aiCourseId,
        dailyNumber: plan.courseContent.day,
        courseUniqueKey: encodeURIComponent(plan.courseContent.courseUniqueKey),
        videoId: plan.courseContent.videoId,
      });
    },
    async handleOnClickTsume(plan) {
      const params = {
        rank: plan.rank,
        type: plan.tsumeType,
      };
      await this.$router.replace({
        name: 'practiceTsume',
        params,
      });
    },
    async handleOnClickCourseTsume(plan) {
      this.$store.commit('env/setIsLoading', true);
      if (!plan.courseContent?.tsumeCreatedTime) {
        try {
          await tsumeGameService.createCourseTsume(
            plan.courseContent.courseUniqueKey
          );
        } catch (e) {
          this.$Message.error(this.$t('發生未知錯誤，再試一次'), 3000);
        }
      }
      this.$router.push({
        path: `/course/tsume/${encodeURIComponent(
          plan.courseContent.courseUniqueKey
        )}/types/${plan.courseTitle}`,
      });
      this.$store.commit('env/setIsLoading', false);
    },
    handleOnClickVerification() {
      // 跳轉到檢定頁面
      this.$router.push({
        name: 'verification',
      });
    },
    closeFinishPlanModal() {
      this.isFinishPlanModalShow = false;
      this.isSettingShow = false;
    },
    closeResetPlanModal() {
      this.isResetPlanModalShow = false;
      this.isSettingShow = false;
    },
    trackScroll() {
      this.isScrolling = true;

      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
      }
      const collapseAnimationEndTime = 500;
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
      }, collapseAnimationEndTime);
    },
    async getCurrentStudyPlan() {
      await this.$store.dispatch('aiTutor/getCurrentStudyPlan');
    },
    async deleteLearnPlan() {
      await this.$store.dispatch('aiTutor/deleteStudyPlan');
    },
    formatPlans(plans) {
      const groupMap = {};
      let groupIndex = 0;
      const result = plans.reduce((acc, plan) => {
        if (!groupMap[plan.weeks]) {
          groupIndex++;
          groupMap[plan.weeks] = groupIndex;
          acc[groupMap[plan.weeks] - 1] = [];
        }
        plan.icon = this.iconMap[plan.type];
        plan.description = this.getPlanDescription(plan);
        acc[groupMap[plan.weeks] - 1].push(plan);
        return acc;
      }, []);
      return result;
    },
    getPlanDescription(plan) {
      let result = '';
      switch (plan.type) {
        case 'GAME':
          const aiInfo = this.getAiInfo(plan.gameAiId);
          if (aiInfo) {
            result = `${this.$t('難度')}${convertGRank(aiInfo.rank)}-Lv${
              aiInfo.id
            } ${aiInfo.opponentName}`;
          } else {
            result = '';
          }
          break;
        case 'TSUME':
          result = `${this.$t('難度')}${convertGRank(plan.rank)}-${this.$t(
            plan.tsumeType
          )}`;
          break;
        case 'LIVE_COURSE':
          result = this.$t(plan.liveCourseTitle);
          break;
        case 'COURSE_VIDEO':
          result = this.$t(plan.courseTitle);
          break;
        case 'COURSE_TSUME':
          result = this.$t(plan.courseTitle);
          break;
        case 'VERIFICATION':
          result = convertGRank(this.planRank);
          break;
      }
      return result;
    },
    async getAiGameCourseConfigs() {
      const result = await aiGameService.getAiGameCourseConfigs(
        this.coursePlan
      );
      return result ?? [];
    },
    async initPlan() {
      this.$store.commit('env/setIsLoading', true);
      try {
        await this.getCurrentStudyPlan();
        this.aiGameCourseConfigs = await this.getAiGameCourseConfigs();
      } catch (e) {
        this.$Message.error(
          this.$t('因網路問題無法確認目前是否有學習計畫，請確認網路是否有異常')
        );
      }
      this.$store.commit('env/setIsLoading', false);
      this.setWeekFromLastClick();
      this.checkVerification();
    },
    isBetweenDate(startDate, endDate) {
      const today = new Date();
      return today >= startDate && today <= endDate;
    },
    formatDate(date = new Date()) {
      return new Date(date).toLocaleDateString();
    },
    checkAllPlanFinish(planList) {
      return planList.filter((plan) => !plan.isPass).length === 0;
    },
    moveToPrevWeek() {
      this.$refs.weekButtonGroupEl.scrollLeft -= 240;
    },
    moveToNextWeek() {
      this.$refs.weekButtonGroupEl.scrollLeft += 240;
    },
    trackingScrollEvent() {
      this.toucher = new Egg()
        .addCode('down', () => {
          this.latestScrollPercentage = 0;
          this.isScrollDown = false;
        })
        .listen();
      this.$refs.bodyEl.addEventListener('scroll', this.trackScroll);
    },
    checkVerification() {
      const verificationRecord =
        localStorage.getItem('verificationModalInitializedList') ?? '[]';
      const isInitialized = JSON.parse(verificationRecord).includes(
        this.planRank
      );
      const verificationPlan = this.planList
        .flat()
        .find((plan) => plan.type === 'VERIFICATION');

      if (verificationPlan.isPass && !isInitialized) {
        this.resultModalController = {
          isShow: true,
          mode: 'aiTutor',
          title: this.$t('你可自行更換或結束計畫'),
          description: this.$t('恭喜你獲得 {rank} 棋力', {
            rank: convertGRank(this.planRank),
          }),
          action: () => {
            this.resultModalController.isShow = false;
          },
        };
        localStorage.setItem(
          'verificationModalInitializedList',
          JSON.stringify([
            ...JSON.parse(
              localStorage.getItem('verificationModalInitializedList') ?? '[]'
            ),
            this.planRank,
          ])
        );
      }
    },
  },
  mounted() {
    this.trackingScrollEvent = _.throttle(this.trackingScrollEvent, 300);
    this.trackingScrollEvent();
    this.initPlan();
  },
  beforeDestroy() {
    if (this.toucher && this.toucher instanceof Egg) {
      this.toucher.removeListen();
    }
    if (this.$refs.bodyEl) {
      this.$refs.bodyEl.removeEventListener('scroll', this.trackScroll);
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: $bgcontainer;
  overflow: hidden;
}
.body-container {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 238px);
  transition: 0.4s;
  &.is-web {
    height: 100%;
    &.is-collapse {
      height: 100%;
    }
  }
  &.is-collapse {
    height: calc(100% - 142px);
  }
}

.sticky-bar {
  position: relative;
  top: 0;
  z-index: 1;
  width: 100%;
}
.goal-container {
  position: relative;
  background: #f6ede0;
  width: 100%;
  height: 96px;
  padding: 16px 16px 0;
  transition: 0.4s;
  overflow: hidden;
  &.is-collapse {
    height: 0;
    padding: 0 16px;
  }
  .goal-card {
    display: flex;
    align-items: center;
    min-width: 288px;
    width: 100%;
    height: 80px;
    padding: 8px 16px;
    color: #606266;
    .divide {
      width: 1px;
      height: 100%;
      background: #c7c7c7;
      margin: 0 16px;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 4px;
      &-lg {
        font-size: 16px;
        margin-bottom: 4px;
      }
      &-sm {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
    .num {
      font-size: 20px;
      font-weight: bold;
      color: #303133;
    }
    .rank {
      width: 64px;
      height: 32px;
      font-size: 20px;
      font-weight: bold;
      background: #303133;
      color: #fff;
      text-align: center;
      border-radius: $rounded-sm;
      margin-bottom: 0;
    }
  }
  .goal-setting {
    position: absolute;
    right: 32px;
    font-size: 32px;
    color: #ff855e;
    line-height: 32px;
    cursor: pointer;
  }
}

.week-container {
  background: #f6ede0;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .week-controller-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    font-size: 24px;
    text-align: center;
    color: #909399;
    cursor: pointer;
  }
  .week-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: calc(100% - 48px);
    overflow-y: hidden;
    overflow-x: scroll;
    transition: 0.4s;
  }

  .week-button {
    flex-shrink: 0;
    position: relative;
    margin: 0 3px 0 0;
    min-width: 57px;
    padding: 0 8px;
    height: 54px;
    border-radius: $rounded-sm;
    background: white;
    line-height: 54px;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
    cursor: pointer;
    .week-button-icon {
      position: absolute;
      top: 0;
      right: 2px;
      font-size: 12px;
      height: 12px;
      .icon-Colorful-correct {
        position: absolute;
        left: -12px;
        .path2 {
          position: absolute;
          top: 0;
          left: 0;
        }
        .path2::before {
          margin-left: 0;
        }
      }
    }
    &.is-selected {
      border: 2px solid $primary;
    }
    &.is-curr {
      background: #303133;
      color: white;
    }
  }
}

.plan-container {
  .week-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    .week {
      font-size: 20px;
      font-weight: bold;
      line-height: 34px;
      margin-bottom: 4px;
      text-align: center;
    }
    .date {
      background: #30313333;
      color: white;
      border-radius: 100px;
      text-align: center;
      margin-bottom: 0;
      padding: 0 16px;
    }
  }
  .week-plan {
    position: relative;
    padding: 0 16px;
    overflow: scroll;
    min-height: 250px;
    .plan-card {
      position: relative;
      height: 66px;
      display: flex;
      justify-content: flex-start;
      padding: 8px;
      margin-bottom: 8px;
      cursor: pointer;
    }
    .plan-icon {
      color: $primary;
      font-size: 32px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #f8f9fb;
      border-radius: $rounded-sm;
      margin-right: 8px;
    }
    .plan-type {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: black;
    }
    .plan-description {
      font-size: 12px;
      color: #909399;
      line-height: 20px;
    }
    .plan-status {
      position: absolute;
      right: 16px;
      top: 17px;
      width: 32px;
      height: 32px;
      font-size: 32px;
      z-index: 9;
      color: #5abb29;
    }
  }
}

.section-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 7px 0px #c7c7c74d;
}

.setting-list {
  &.is-web {
    position: absolute;
    right: 30px;
    top: 74px;
    z-index: 1;
  }
  &.is-mobile {
    .setting-item {
      font-size: 16px;
      line-height: 28px;
      padding: 11px 0 11px 16px;
      border-bottom: 1px solid #dcdfe5;
      cursor: pointer;
    }
  }
  &.is-web {
    width: 224px;
    height: 76px;
    padding: 8px 0px;
    border-radius: 4px;
    border: 1px solid #dcdfe5;
    background: white;
    .setting-item {
      width: 100%;
      height: 30px;
      padding: 4px 24px;
      font-size: 14px;
      line-height: 22px;
      &:hover {
        background: #0000000d;
      }
    }
  }
}

.hidden-scroll-style {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
