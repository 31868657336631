<template>
  <div class="uploadImg" :class="mainClass">
    <b-img
      v-if="!sgf"
      v-show="previewImg"
      class="img"
      :class="imageFileName"
      :src="previewImg"
    ></b-img>
    <div v-if="sgf" class="img" :class="imageFileName">
      <chessboard
        ref="chessboard"
        toolbar="checkAnswer"
        :hide-tool-bar="true"
        is-locked
        :sgf="sgf"
      ></chessboard>
    </div>
    <b-form-file
      v-model="imageFile"
      :accept="accept"
      :class="{'has-preview-img': previewImg || sgf}"
    ></b-form-file>
    <div v-show="!previewImg && !sgf" class="placeholder">
      <i class="icon-Circle-plus"></i>
      <h5>{{ $t('請點擊上傳圖片') }}</h5>
      <h6>
        {{ $t('請上傳2MB以內，比例16:9的圖片\n格式支援：jpg、png、gif') }}
      </h6>
    </div>
    <div v-show="previewImg || sgf" class="remove-img" @click="removeImg">
      <i class="icon-Circle-X"></i>
    </div>
  </div>
</template>

<script>
import {clientVersion} from '@/constant/env.js';
import Chessboard from '@/components/Chessboard/Chessboard.vue';

export default {
  props: {
    mainClass: {
      type: String,
      default: '',
    },
    imageFileName: {
      type: String,
      default: 'image-file',
    },
    breadcrumbName: {
      type: String,
      default: '預設上傳圖片',
    },
    accept: {
      type: String,
      default: '.jpg, .png, .gif',
    },
  },
  components: {
    Chessboard,
  },
  data() {
    return {
      imageFile: null,
      previewImg: null,
      resetImg: false,
      imageKye: null,
      sgf: null,
    };
  },
  watch: {
    imageFile(newValue, oldValue) {
      if (this.resetImg) {
        this.resetImg = !this.resetImg;
        return;
      }
      if (!newValue && oldValue && !this.resetImg) {
        this.imageFile = oldValue;
      }
      this.previewImg = null;
      const maxAllowedSize = 2 * 1024 * 1024;
      if (this.imageFile.size > maxAllowedSize) {
        return this.$Message.error('圖片大小超過限制');
      }

      if (/.sgf$/.test(newValue?.name)) {
        this.uploadSgf();
      } else {
        this.uploadImg();
      }
    },
  },
  methods: {
    removeImg() {
      this.$emit('remove-img', this.imageKye);
      this.resetImg = true;
      this.previewImg = null;
      this.imageFile = null;
      this.imageKye = null;
      this.sgf = null;
    },
    uploadImg() {
      const fileReader = new FileReader();
      const fileReaderBase64 = new FileReader();
      let arrayBuffer = null;
      let base64 = null;
      const fileName = this.imageFile.name;
      const prefix = `${clientVersion}/${this.breadcrumbName}`;
      const key = `${prefix}/${this.$day().valueOf()}-${fileName}`;
      this.imageKye = key;
      // eslint-disable-next-line require-jsdoc
      const sendUploadImgEvent = () => {
        if (arrayBuffer && base64) {
          this.$emit('upload-img', {
            arrayBuffer,
            base64,
            fileName,
            key,
          });
        }
      };
      fileReader.onload = (event) => {
        arrayBuffer = event.target.result;
        sendUploadImgEvent();
      };
      fileReaderBase64.onload = (event) => {
        base64 = event.target.result;
        this.previewImg = base64;
        sendUploadImgEvent();
      };
      fileReader.readAsArrayBuffer(this.imageFile);
      fileReaderBase64.readAsDataURL(this.imageFile);
    },
    uploadSgf() {
      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        const fileName = this.imageFile.name;
        const prefix = `${clientVersion}/${this.breadcrumbName}`;
        const key = `${prefix}/${this.$day().valueOf()}-${fileName}`;
        this.imageKye = key;
        const sgf = event.target.result;
        this.sgf = sgf;
        await this.$nextTick();
        this.$refs.chessboard.loadSgf(sgf);
        this.$refs.chessboard.wgoHelper.last();
        const base64 = this.$refs.chessboard.getBoardImgBase64();
        this.$emit('upload-sgf', {
          sgf,
          fileName,
          base64,
          key,
        });
      };
      fileReader.readAsText(this.imageFile);
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  display: none;
  width: 198px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xxs, 4px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
  i {
    font-size: 60px;
    color: $primary;
  }
  h5 {
    color: $font-normal;
  }
  h6 {
    color: $font-grayscale-1;
  }
}
.custom-file {
  width: 54px;
  height: 54px;
  &.has-preview-img {
    opacity: 0;
  }
  :deep() .custom-file-input {
    height: 100%;
    &.is-invalid:focus ~ .custom-file-label {
      border-color: transparent;
      box-shadow: none;
    }
    &:focus ~ .custom-file-label {
      box-shadow: none;
    }
  }
  :deep() .custom-file-label {
    border: 1px dashed transparent;
    height: 100%;
    background: transparent;
    margin-bottom: 0;
    border-radius: 3px;
    &::after {
      height: 100%;
      background: white;
      padding: 0;
      content: '\e954';
      width: 100%;
      font-family: 'icomoon' !important;
      border: 1px dashed #ced4da;
      border-radius: 3px;
      font-size: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $font-grayscale-2;
    }
    .form-file-text {
      display: none !important;
    }
  }
}
.uploadImg {
  width: 54px;
  height: 54px;
  position: relative;
  .img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 4px;
  }
  .remove-img {
    color: $primary;
    background: transparent;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    position: absolute;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-content: center;
    top: -16px;
    right: -16px;
    cursor: pointer;
    .icon-X {
      line-height: 32px;
    }
  }
}
.sgf-or-img {
  .custom-file {
    width: 200px;
    height: 200px;
    :deep() .custom-file-label {
      &::after {
        font-size: 60px;
        color: #ced4da;
      }
    }
  }
  &.uploadImg {
    width: 200px;
    height: 200px;
  }
  :deep() .chessboard-wrapper {
    width: 200px;
    height: 200px;
  }
}
.filmstrip {
  $width: 532px;
  $height: 299px;
  .custom-file {
    width: $width;
    height: $height;
    :deep() .custom-file-label {
      &::after {
        font-size: 60px;
        color: #ced4da;
      }
    }
  }
  &.uploadImg {
    width: $width;
    height: $height;
    .img {
      object-fit: contain;
      border: 1px dashed #ced4da;
    }
  }
  :deep() .chessboard-wrapper {
    width: $width;
    height: $height;
  }
  :deep() .custom-file-label {
    &::after {
      content: ' ';
    }
  }
  .placeholder {
    display: block;
  }
  .remove-img {
    color: $primary;
    background: transparent;
  }
}
</style>
