const mapping = {
  // 繁體中文
  zh: 'zh-TW',
  'zh-hant': 'zh-TW',
  'zh-tw': 'zh-TW',
  'zh-hk': 'zh-TW',
  'zh-mo': 'zh-TW',

  // 簡體
  'zh-hans': 'zh-CN',
  'zh-cn': 'zh-CN',
  'zh-sg': 'zh-CN',
  'zh-my': 'zh-CN',

  // 日文
  ja: 'ja-JP',
  'ja-jp': 'ja-JP',

  // 其他
  default: 'en-US',
};

/**
 * 取得預設的語言
 * @param {Array} supportLocaleList 支援的語言列表
 * @returns {String} 語言預設值
 */
const getDefaultLanguage = (supportLocaleList) => {
  const userLanguages = navigator.languages || [navigator.language];
  let result = null;

  for (const userLanguage of userLanguages) {
    if (userLanguage.includes('en')) {
      result = mapping.default;
      break;
    }
    const language = mapping[userLanguage.toLowerCase()];
    if (language && supportLocaleList.includes(language)) {
      result = language;
      break;
    }
  }

  if (!result) result = mapping.default;
  return result;
};

export default getDefaultLanguage;
export {mapping, getDefaultLanguage};
