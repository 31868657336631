import getDeviceInfo from '@/lib/base/getDeviceInfo';
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

export default [
  {
    path: '/products/:productId',
    name: 'productPage',
    component: () => import('@/views/Products/ProductPage.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to?.params?.productId) {
        next();
      } else {
        next(from);
      }
    },
  },
  {
    path: '/products/:productId/info',
    name: 'productInfo',
    component: () => import('@/views/Products/ProductInfo.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to?.params?.productId) {
        if (!isMobile) {
          to.name = 'productPage';
          return next(to);
        }
        next();
      } else {
        next(from);
      }
    },
  },
];
