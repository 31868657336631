import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';
import {removeLocalData} from '@/lib/base/localData';
import {Device} from '@capacitor/device';

/**
 * 取得使用者資料
 * @returns {Promise} promise
 */
const getUserData = _.memoize(function getUserData() {
  return callLambda({
    category: 'user',
    apiName: '',
    method: 'get',
  }).then((res) => {
    return res.data;
  });
});

/**
 * 登入
 * @param {String} username 帳號
 * @param {String} password 密碼
 * @param {String} language 語言
 * @returns {Promise} promise
 */
function login(username, password, language) {
  return callLambda({
    category: 'user',
    apiName: 'login',
    method: 'post',
    data: {
      username,
      password,
      language,
    },
  });
}

/**
 * 第三方登入
 * @param {String} token token
 * @param {String} platform 平台
 * @returns {Promise} promise
 */
async function thirdPartyLogin({token, platform, options = {}}) {
  const {platform: device} = await Device.getInfo();
  return callLambda({
    category: 'user',
    apiName: 'thirdPartyLogin',
    method: 'post',
    data: {token, platform, options, device},
  });
}

/**
 * 綁定第三方登入
 * @param {String} token token
 * @param {String} platform 平台
 * @returns {Promise} promise
 */
async function setUsernameByThirdParty({token, platform, options = {}}) {
  const {platform: device} = await Device.getInfo();
  return callLambda({
    category: 'user',
    apiName: 'setUsernameByThirdParty',
    method: 'post',
    data: {token, platform, options, device},
  });
}

/**
 * 登出
 * @returns {Promise} promise
 */
function logout() {
  return callLambda({
    category: 'user',
    apiName: 'logout',
    method: 'post',
  });
}

/**
 * 更新使用者資訊
 * @param {Object} user 要更新的user資料
 * @returns {Promise} promise
 */
function updateUser(user) {
  return callLambda({
    category: 'user',
    method: 'put',
    apiName: '',
    data: user,
  }).then((res) => {
    removeLocalData('user');
    getUserData?.cache.clear();
    return res.data;
  });
}

/**
 * 更改使用者密碼
 * @param {String} oldPassword 舊密碼
 * @param {String} newPassword 新密碼
 * @returns {Promise} promise
 */
function changePassword({oldPassword, newPassword}) {
  return callLambda({
    category: 'user',
    apiName: 'changePassword',
    method: 'post',
    data: {
      oldPassword,
      newPassword,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 * 重設使用者密碼
 * @param {String} language 語言
 * @param {String} password 密碼
 * @param {String} verificationId 驗證碼ID
 * @param {String} verificationCode 驗證碼
 * @param {Boolean} isEmail 是否是email發驗證信
 * @returns {Promise} promise
 */
function resetPassword({
  language,
  password,
  verificationId,
  verificationCode,
  isEmail,
}) {
  return callLambda({
    category: 'user',
    apiName: 'resetPasswordByOtp',
    method: 'post',
    data: {
      language,
      password,
      verificationId,
      verificationCode,
      isEmail,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 發送忘記密碼之驗證碼
 * @param {String} internationalPhoneNumber 手機號碼
 * @param {String} email 電子信箱
 * @param {String} redirect 驗證信轉址
 * @returns {Promise} promise
 */
function sendResetPasswordOtp({
  language,
  internationalPhoneNumber,
  email,
  redirect,
  recaptchaToken,
}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'sendResetPasswordOtp',
    data: {
      language,
      internationalPhoneNumber,
      email,
      redirect,
      recaptchaToken,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證忘記密碼之驗證碼
 * @param {String} username 帳號
 * @param {String} verificationId 驗證碼ID
 * @param {String} verificationCode 驗證碼
 * @returns {Promise} promise
 */
function validateResetPasswordOtp({
  username,
  verificationId,
  verificationCode,
}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'validateResetPasswordOtp',
    data: {
      username,
      verificationId,
      verificationCode,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 發送註冊之驗證碼
 * @param {String} internationalPhoneNumber 手機號碼
 * @returns {Promise} promise
 */
function sendSignupOpt({internationalPhoneNumber, language, recaptchaToken}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'sendSignupOtp',
    data: {
      internationalPhoneNumber,
      language,
      recaptchaToken,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證註冊之驗證碼
 * @param {String} username 使用者分類
 * @param {String} verificationId 驗證碼ID
 * @param {String} verificationCode 驗證碼
 * @returns {Promise} promise
 */
function validateSignupOpt({username, verificationId, verificationCode}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'validateSignupOtp',
    data: {
      username,
      verificationId,
      verificationCode,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 註冊
 * @param {String} username 使用者分類
 * @param {String} academyId 班級ID
 * @param {String} password 使用者密碼
 * @param {String} realName 使用者真名
 * @param {String} nickName 使用者暱稱
 * @returns {Promise} promise
 */
function signup({
  username,
  academyId,
  password,
  phone,
  realName,
  nickName,
  language,
}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'signup',
    data: {
      username,
      academyId,
      password,
      phone,
      realName,
      nickName,
      language,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 啟用序號
 * @param {String} code 班級ID
 * @param {String} academyId 班級ID
 * @returns {Promise} promise
 */
function checkCode({code}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'checkCode',
    data: {code},
  });
}

/**
 * 啟用序號
 * @param {String} code 班級ID
 * @param {String} academyId 班級ID
 * @returns {Promise} promise
 */
function activateByCode({code, academyId}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'activateByCode',
    data: {code, academyId},
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得使用者下棋資訊
 * @returns {Promise} promise
 */
function getUserStatistics() {
  return callLambda({
    category: 'user',
    apiName: 'statistics',
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得使用者消費記錄
 * @returns {Promise} promise
 */
function getOwnPurchaseLog() {
  return callLambda({
    category: 'user',
    method: 'get',
    apiName: 'getOwnPurchaseLog',
  });
}

/**
 * 取得使用者學習歷程
 * @param {Object} params 查詢資料
 * @param {String} params.username 使用者名稱
 * @param {Number} params.limit 資料筆數
 * @param {Number} params.startedAt 學習記錄起始時間
 * @param {Number} params.endedAt 學習記錄結束時間
 * @param {Boolean} params.isAscending 最新紀錄
 * @returns {Promise} promise
 */
const getLearningProcess = _.memoize(
  function getLearningProcess(params) {
    return callLambda({
      category: 'user',
      method: 'get',
      apiName: 'getLearningProcess',
      params,
    });
  },
  (params) => JSON.stringify(params)
);

/**
 * 取得使用者里程碑
 * @param {Object} params 查詢資料
 * @param {Number} params.limit 資料筆數
 * @param {Number} params.lastKey 無限滾動最後一筆
 * @param {Boolean} params.isAscending 最新紀錄
 * @returns {Promise} promise
 */
const getMilestone = _.memoize(
  function getMilestone(params) {
    return callLambda({
      category: 'user',
      method: 'get',
      apiName: 'getMilestone',
      params,
    });
  },
  (params) => JSON.stringify(params)
);

/**
 * 寄送email註冊驗證信
 * @param {String} email 使用者email
 * @param {String} language 語言
 * @param {String} academyId 班級ID
 * @param {String} [password] 使用者密碼
 * @param {String} [realName] 使用者真名
 * @param {String} [nickName] 使用者暱稱
 * @param {String} redirect 轉址
 * @returns {Promise} promise
 */
function sendSignupOtpEmail({
  email,
  language,
  academyId,
  password,
  realName,
  nickName,
  redirect,
}) {
  return callLambda({
    category: 'user',
    apiName: 'sendSignupOtpEmail',
    method: 'post',
    data: {
      email,
      language,
      academyId,
      password,
      realName,
      nickName,
      redirect,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證email註冊信
 * @param {String} id 後端回傳網址夾帶id
 * @param {String} code 後端回傳網址夾帶code
 * @returns {Promise} promise
 */
function validateSignupOtpEmail(id, code) {
  return callLambda({
    category: 'user',
    apiName: 'validateSignupOtpEmail',
    method: 'post',
    data: {
      id,
      code,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 綁定信箱/手機發送驗證碼或驗證信
 * @param {String} internationalPhoneNumber 手機號碼（含區碼）
 * @param {String} email 信箱
 * @param {String} redirect 後端回傳網址夾帶code
 * @param {String} type [EMAIL_SET || PHONE_SET]
 * @returns {Promise} promise
 */
function bindAccountSendOtp({internationalPhoneNumber, email, redirect, type}) {
  return callLambda({
    category: 'user',
    apiName: 'sendOtp',
    method: 'post',
    data: {
      internationalPhoneNumber,
      email,
      redirect,
      type,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證信箱驗證信/手機驗證碼
 * @param {String} verificationId 驗證碼id
 * @param {String} verificationCode 驗證碼code
 * @param {String} type [EMAIL_SET || PHONE_SET]
 * @returns {Promise} promise
 */
function bindAccountValidateOtp({verificationId, verificationCode, type}) {
  return callLambda({
    category: 'user',
    apiName: 'validateOtp',
    method: 'post',
    data: {
      verificationId,
      verificationCode,
      type,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證完成並綁定新的 username
 * @param {String} verificationId 驗證碼id
 * @param {String} verificationCode 驗證碼code
 * @param {String} type [EMAIL_SET || PHONE_SET]
 * @returns {Promise} promise
 */
function setUsernameByOtp({verificationId, verificationCode, type}) {
  return callLambda({
    category: 'user',
    apiName: 'setUsernameByOtp',
    method: 'post',
    data: {
      verificationId,
      verificationCode,
      type,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得 nft 列表
 * @returns {Promise} promise
 */
async function getNfts() {
  const result = await callLambda({
    category: 'user',
    apiName: 'nfts',
    method: 'get',
  });
  return result.data;
}

/**
 * 取得 nft
 * @param {Object} uuid uuid
 * @returns {Promise} promise
 */
async function getNft(uuid) {
  const result = await callLambda({
    category: 'user',
    apiName: `nft/${uuid}`,
    method: 'get',
  });
  return result.data;
}

/**
 * 轉讓 nft
 * @param {Object} uuid uuid
 * @param {Object} address address
 * @returns {Promise} promise
 */
async function transferNft(uuid, address) {
  const result = await callLambda({
    category: 'user',
    apiName: 'transferNft',
    method: 'post',
    data: {
      uuid,
      address,
    },
  });
  return result.data;
}

/**
 * 更新 nfts
 * @returns {Promise} promise
 */
async function updateNftsStatus() {
  const result = await callLambda({
    category: 'user',
    apiName: 'updateNftsStatus',
    method: 'put',
  });
  return result.data;
}

/**
 * 驗證信箱驗證信/手機驗證碼
 * @param {String} type 驗證碼id
 * @param {String} content 驗證碼code
 * @param {Array} images 圖片 -> aws sdk上傳至s3 中的 key
 * @returns {Promise} promise
 */
function createUserReport({type, content, images}) {
  return callLambda({
    category: 'user',
    apiName: 'createUserReport',
    method: 'post',
    data: {
      type,
      content,
      images,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得用戶elo
 * @param {Object} params 查詢資料
 * @param {Object} params.boardSize 棋盤大小
 * @returns {Promise} promise
 */
async function getUserElo(params) {
  const result = await callLambda({
    category: 'user',
    apiName: 'elo',
    method: 'get',
    params,
  });
  return result.data;
}

/**
 * 取得用戶elo
 * @param {Object} params 查詢資料
 * @param {Object} params.limit 棋盤大小
 * @param {Object} params.boardSize 棋盤大小
 * @param {Object} params.lastValue 待確認
 * @param {Object} params.lastUsername 待確認
 * @returns {Promise} promise
 */
async function getEloLeaderBoard(params) {
  const result = await callLambda({
    category: 'user',
    apiName: 'leaderBoard',
    method: 'get',
    params,
  });
  return result.data;
}

/**
 * 取得使用者分院及班級
 * @returns {Promise} promise
 */
function getAcademySubAcademyClass() {
  return callLambda({
    category: 'user',
    apiName: 'getUserAcademySubAcademyClassList',
    method: 'get',
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得使用者分院及班級
 * @returns {Promise} promise
 */
function createUserQuestion({coursePlan, text, image, sgf}) {
  return callLambda({
    category: 'user',
    apiName: 'userQuestion',
    method: 'post',
    data: {coursePlan, text, image, sgf},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得 是否要顯示 vip過期 modal
 * @returns {Promise} promise
 */
function getLiveCourseEndAt() {
  return callLambda({
    category: 'user',
    apiName: 'getLiveCourseEndAt',
    method: 'get',
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得所有學習計畫
 * @param {String} params courseInfo
 * @param {String} params.coursePlan coursePlan Id
 * @returns {Promise} promise
 */
function getStudyPlanMenu(params) {
  return callLambda({
    category: 'user',
    apiName: 'studyPlanMenu',
    method: 'get',
    params,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得正在進行的學習計畫
 * @returns {Promise} promise
 */
function getStudyPlan() {
  return callLambda({
    category: 'user',
    apiName: 'studyPlan',
    method: 'get',
  }).then((res) => {
    return res.data;
  });
}

/**
 * 建立學習計畫
 * @param {Object} data 學習計畫資料
 * @param {String} data.course coursePlan Id
 * @param {String} data.mode 計畫等級 'easy','normal','hard'
 * @returns {Promise} promise
 */
function postStudyPlan(data) {
  return callLambda({
    category: 'user',
    apiName: 'studyPlan',
    method: 'post',
    data,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 刪除學習計畫
 * @returns {Promise} promise
 */
function deleteStudyPlan() {
  return callLambda({
    category: 'user',
    apiName: 'studyPlan',
    method: 'delete',
  }).then((res) => {
    return res.data;
  });
}

/**
 * 解除使用者資訊綁定 ex 解除gmail綁定、解除手機號碼綁定
 * 注意：最初註冊的聯絡資訊不可以unbind！
 * @param {string} authUsername - 使用者的名稱。這是一個必要的參數。
 * @returns {Promise<Object>} Promise
 *
 * @throws {Error} 如果 API 請求失敗，則可能會拋出錯誤。
 */
async function unbindUserData(authUsername) {
  try {
    const res = await callLambda({
      category: 'user',
      apiName: 'auth',
      method: 'delete',
      data: {authUsername},
    });
    return res.data;
  } catch (error) {
    console.error('錯誤發生在 unbindUserData:', error);
    return null;
  }
}

/**
 * 取得近期使用的功能
 * @returns {Promise} promise
 */
async function getRecentUsedFeature() {
  return callLambda({
    category: 'user',
    apiName: 'recentlyUsedFeature',
    method: 'get',
  }).then((res) => {
    return res.data;
  });
}

/**
 * 新增近期使用的功能
 * @param {Object} data - 要新增的近期使用的功能
 * @param {String} data.name - 功能名稱
 * @returns {Promise} promise
 */
async function postRecentUsedFeature(data) {
  return callLambda({
    category: 'user',
    apiName: 'recentlyUsedFeature',
    method: 'post',
    data,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 更新近期使用的功能
 * @param {Object} data - 要更新的近期使用的功能
 * @param {Array} data.names - 功能名稱集合
 * @returns {Promise} promise
 */
async function putRecentUsedFeature(data) {
  return callLambda({
    category: 'user',
    apiName: 'recentlyUsedFeature',
    method: 'put',
    data,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得學習任務
 * @returns {Promise} promise
 */
async function getStudyTask() {
  const result = await callLambda({
    category: 'user',
    apiName: 'studyTask',
    method: 'get',
  });
  return result.data;
}

/**
 * 完成學習任務
 * @param {String} taskId 學習任務ID
 * @returns {Promise} promise
 */
async function completeStudyTask(taskId) {
  const result = await callLambda({
    category: 'user',
    apiName: 'completeStudyTask',
    method: 'post',
    data: {
      taskId,
    },
  });
  return result.data;
}

/**
 * 取得是否有跳過 Popup
 * @param {Array<String>} ids Popup ID 列表
 * @returns {Promise} promise
 */
async function getPopup(ids) {
  if (Array.isArray(ids) === false) ids = [ids];
  const result = await callLambda({
    category: 'user',
    apiName: 'popup',
    method: 'get',
    params: {
      ids,
    },
    paramsSerializer: {
      indexes: null, // no brackets at all
    },
  });
  const mapping = {};
  for (const item of result.data) {
    mapping[item.id] = item.isDone;
  }
  return mapping;
}

/**
 * 設定是否有跳過 Popup
 * @param {String} id Popup ID
 * @param {Boolean} isDone isDone
 * @returns {Promise} promise
 */
async function setPopup({id, isDone}) {
  const result = await callLambda({
    category: 'user',
    apiName: 'popup',
    method: 'post',
    data: {
      id,
      isDone,
    },
  });
  return result.data;
}

export default {
  getUserData,
  login,
  thirdPartyLogin,
  setUsernameByThirdParty,
  logout,
  updateUser,
  changePassword,
  resetPassword,
  sendResetPasswordOtp,
  validateResetPasswordOtp,
  sendSignupOpt,
  validateSignupOpt,
  signup,
  checkCode,
  activateByCode,
  getUserStatistics,
  getOwnPurchaseLog,
  getLearningProcess,
  sendSignupOtpEmail,
  validateSignupOtpEmail,
  getNfts,
  getNft,
  transferNft,
  updateNftsStatus,
  bindAccountSendOtp,
  bindAccountValidateOtp,
  setUsernameByOtp,
  createUserReport,
  getMilestone,
  getUserElo,
  getEloLeaderBoard,
  getAcademySubAcademyClass,
  createUserQuestion,
  getLiveCourseEndAt,
  getStudyPlan,
  getStudyPlanMenu,
  postStudyPlan,
  deleteStudyPlan,
  unbindUserData,
  getRecentUsedFeature,
  postRecentUsedFeature,
  putRecentUsedFeature,
  getStudyTask,
  completeStudyTask,
  getPopup,
  setPopup,
};
