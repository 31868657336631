<template>
  <div>
    <b-modal
      ref="modal"
      class="modal-confirm"
      content-class="rounded-10 position-relative overflow-hidden border-0"
      size="xl"
      hide-footer
      hide-header
      no-close-on-backdrop
      :visible="show"
      centered
    >
      <div
        class="header d-flex align-items-center justify-content-between font-weight-bold"
      >
        {{ $t('設定暱稱') }}
      </div>
      <div class="content">
        <div class="p-4">
          <b-form-group label-for="nickname" class="nickname-input mb-0">
            <b-form-input
              id="nicknameForPhone"
              ref="nickname"
              v-model="$v.nickname.$model"
              name="nickname"
              aria-describedby="input-nickname-feedback"
              type="text"
              :placeholder="$t('請輸入8個字以內的暱稱')"
              :state="isNicknameValid"
              @input="nicknameInputHandler"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-nickname-feedback"
              :state="isNicknameValid"
              class="mt-0"
            >
              <div v-if="!$v.nickname.required" class="error">
                {{ $t('請輸入暱稱') }}
              </div>
              <div v-else-if="!$v.nickname.maxLength" class="error">
                {{ $t('請輸入8個字以內的暱稱') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="btn-wrap p-2">
          <b-button
            variant="primary"
            size="md"
            class="font-weight-bold"
            @click="updateNickname"
          >
            {{ $t('確定送出') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {required, maxLength} from 'vuelidate/lib/validators';
import userService from '@/services/user';

export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      nickname: '',
      isNicknameValid: null,
    };
  },
  validations: {
    nickname: {
      required,
      maxLength: maxLength(8),
    },
  },
  methods: {
    nicknameInputHandler() {
      this.isNicknameValid = null;
    },
    async updateNickname() {
      if (this.isLoading) return;
      const invalid = this.$v.nickname.$invalid;
      this.isNicknameValid = null;

      if (invalid) {
        this.$v.$touch();
        // 由於b-form-input之驗證方法通過時(true)會出現預設打勾樣式，不符UI需求，故維持(null)無樣式
        this.isNicknameValid = this.validateNickName() ? null : false;
        this.focusInput();
      } else {
        this.submitUpdate();
      }
    },
    focusInput() {
      if (!this.validateNickName()) {
        this.$refs.nickname.focus();
      } else {
        false;
      }
    },
    validateNickName() {
      const {$error} = this.$v.nickname;
      return !$error;
    },
    async submitUpdate() {
      try {
        this.$store.commit('env/setIsLoading', true);

        await userService.updateUser({
          nickName: this.nickname,
          isActive: true,
        });
        await this.$store.dispatch('user/loginWithJwt');
        await this.$nextTick();
        this.$Message.success(this.$t('暱稱設定成功') + '！');
        this.$emit('close');
      } catch (error) {
        this.isNicknameValid = false;
        if (error.response?.data?.message === 'Incorrect nickName!') {
          this.backendError = '*' + this.$t('該名稱不得使用');
        } else {
          this.backendError = '*' + this.$t('暱稱設定失敗');
        }
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-dialog {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  color: $font-grayscale-1;
  .modal-body {
    padding: 0;
  }
}
.header {
  padding: 14px 8px 14px 16px;
  border-bottom: 1px solid $line-1;
}
img {
  width: 300px;
}
.btn-wrap {
  border-top: 1px solid #ced4da;
  text-align: center;
}
</style>
