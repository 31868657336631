<template>
  <div
    class="wrap-pass-animation"
    :class="[
      {
        leave: leave,
        'long-device': deviceRatio >= 1.8,
        'short-device': deviceRatio < 1.8,
      },
    ]"
  >
    <b-row
      ref="userCardAnimation"
      class="d-flex flex-column justify-content-center align-items-center h-100 position-relative wrap-user"
      :class="{leave: leave}"
    >
      <div v-show="mode === 'game'" class="p-0 card-user">
        <b-card
          bg-variant="blackChess"
          text-variant="white"
          border-variant="blackChess"
          class="position-relative"
        >
          <div class="d-flex flex-nowrap align-items-center">
            <b-img
              class="avatar ml-4 mt-4 mb-4 mr-4"
              rounded="circle"
              width="80"
              height="80"
              :srcset="blackPlayerAvatar"
              alt="avatar"
            ></b-img>
            <div
              v-if="isTournament && blackPlayer.elo"
              class="user-details w-100 m-0 text-20 font-weight-bold d-flex"
            >
              <div class="multiline-ellipsis">{{ blackPlayer.nickName }}</div>
              <div class="user-rank">{{ ' - ' + blackPlayer.elo }}</div>
            </div>
            <div
              v-else
              class="user-details w-100 m-0 text-20 font-weight-bold d-flex"
            >
              <div class="multiline-ellipsis">{{ blackPlayer.nickName }}</div>
              <div
                v-show="blackPlayer.level || blackPlayer.rankLabel"
                class="user-rank"
              >
                {{
                  ' - ' +
                  (blackPlayer.level
                    ? 'Lv.' + blackPlayer.level
                    : blackPlayer.rankLabel)
                }}
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <b-img
        v-show="mode === 'game'"
        class="text-vs"
        width="68"
        height="38"
        :srcset="require('@/assets/img/practice/vs.png?srcset')"
        alt="vs"
      ></b-img>
      <b-img
        v-show="mode === 'tsume'"
        class="text-start"
        width="194"
        height="82"
        :srcset="require('@/assets/img/practice/start.png?srcset')"
        alt="start"
      ></b-img>
      <div v-show="mode === 'game'" class="p-0 card-user">
        <b-card border-variant="white" class="card-shadow position-relative">
          <div class="d-flex flex-nowrap align-items-center">
            <b-img
              class="avatar ml-4 mt-4 mb-4 mr-4"
              rounded="circle"
              width="80"
              height="80"
              :srcset="whitePlayerAvatar"
              alt="avatar"
            ></b-img>
            <div
              v-if="isTournament && whitePlayer.elo"
              class="user-details w-100 m-0 text-20 font-weight-bold d-flex"
            >
              <div class="multiline-ellipsis">{{ whitePlayer.nickName }}</div>
              <div class="user-rank">{{ ' - ' + whitePlayer.elo }}</div>
            </div>
            <div
              v-else
              class="user-details w-100 m-0 text-20 font-weight-bold d-flex"
            >
              <div class="multiline-ellipsis">{{ whitePlayer.nickName }}</div>
              <div
                v-show="whitePlayer.level || whitePlayer.rankLabel"
                class="user-rank"
              >
                {{
                  ' - ' +
                  (whitePlayer.level
                    ? 'Lv.' + whitePlayer.level
                    : whitePlayer.rankLabel)
                }}
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </b-row>
  </div>
</template>
<script>
import aiLevelMapping from '@/lib/base/aiLevel.js';
import {convertGRank} from '@/lib/base/ranking.js';
import getAvatarUrl from '@/lib/base/avatarUrl.js';
import {translateNameIfIsAI} from '@/i18n/utils';
import {GAME_MODES} from '@/constant/chessboard';

export default {
  props: {
    leave: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      default: 'game',
    },
    gameMode: {
      type: String,
      default: null,
    },
    isTournament: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isVerification() {
      return (
        this.$route.name === 'verificationGame' ||
        this.$route.name === 'verification'
      );
    },
    user() {
      return this.$store.state.user;
    },
    userColor() {
      return this.gameData.userColor;
    },
    gRankLabel() {
      if (this.isVerification) {
        return this.$route.params.rank
          ? convertGRank(this.$route.params.rank)
          : '-';
      } else {
        return null;
      }
    },
    isLadderMode() {
      const list = [
        GAME_MODES.LADDER,
        GAME_MODES.LADDER_ENDGAME,
        GAME_MODES.LADDER_FUSEKI,
      ];
      return list.includes(this.gameMode);
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    opponentAvatar() {
      const isUserBlack = this.userColor === 'black';
      if (this.isLadderMode) {
        return isUserBlack ? 'ladder_w' : 'ladder_b';
      }
      let opponentAvatar = isUserBlack ? 'ai_w' : 'ai_b';
      const {aiId = 1, opponentName, createdTime} = this.gameData || {};
      if (
        createdTime &&
        opponentName !== '黑小嘉' &&
        opponentName !== '白小嘉'
      ) {
        if (this.currentPlan.isKid) {
          opponentAvatar = `avatar_tsumego_ai_${aiId}-kid`;
        } else {
          opponentAvatar = `avatar_tsumego_ai_${aiId}`;
        }
      }
      return opponentAvatar;
    },
    blackPlayer() {
      if (this.userColor === 'black') {
        const userData = this.user.userData;
        userData.rankLabel = convertGRank(this.user.userData.rank);
        return userData;
      } else {
        const rank = this.isLadderMode
          ? null
          : convertGRank(aiLevelMapping.processAiLevel(this.gameData.aiLevel));
        return {
          nickName: translateNameIfIsAI(this.gameData.opponentName),
          rankLabel: convertGRank(rank),
          avatar: this.opponentAvatar,
          level: this.gameData.aiId || null,
        };
      }
    },
    whitePlayer() {
      if (this.userColor === 'white') {
        const userData = this.user.userData;
        userData.rankLabel = convertGRank(this.user.userData.rank);
        return userData;
      } else {
        const rank = this.isLadderMode
          ? null
          : convertGRank(aiLevelMapping.processAiLevel(this.gameData.aiLevel));
        return {
          nickName: translateNameIfIsAI(this.gameData.opponentName),
          rankLabel: convertGRank(rank),
          avatar: this.opponentAvatar,
          level: this.gameData.aiId || null,
        };
      }
    },
    whitePlayerAvatar() {
      return getAvatarUrl(this.whitePlayer.avatar);
    },
    blackPlayerAvatar() {
      return getAvatarUrl(this.blackPlayer.avatar);
    },
    gameData() {
      return this.$store.state.aiGame;
    },
    deviceRatio() {
      const width = this.$store.state.env.width;
      const height = this.$store.state.env.height;
      return height / width;
    },
  },
  created() {
    this.$playSound('transition');
  },
  mounted() {
    this.$refs.userCardAnimation.addEventListener(
      'animationend',
      this.userCardAnimationHandler
    );
  },
  methods: {
    userCardAnimationHandler(event) {
      if (event.animationName.includes('userCardOpacity')) {
        this.$emit('animation-end');
        this.$refs.userCardAnimation.removeEventListener(
          'animationend',
          this.userCardAnimationHandler
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-pass-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1021;
  &.short-device::after {
    content: '';
    position: absolute;
    bottom: -75%;
    right: -146%;
    width: 200%;
    height: 200%;
    background: #ff855e;
    transform: rotate(45deg);
    animation: passAnimationXBottom 0.3s linear forwards;
  }
  &.leave.long-device::after,
  &.leave.short-device::after {
    animation: passAnimationXBottomLeave 0.3s linear 1s forwards;
  }
  &.short-device::before {
    content: '';
    position: absolute;
    top: -75%;
    left: -146%;
    width: 200%;
    height: 200%;
    background: $secondary;
    transform: rotate(45deg);
    animation: passAnimationXTop 0.3s linear forwards;
  }
  &.leave.long-device::before,
  &.leave.short-device::before {
    animation: passAnimationXTopLeave 0.3s linear 1s forwards;
  }
  /* iphoneX size */
  &.long-device::after {
    content: '';
    position: absolute;
    bottom: -75%;
    right: -133%;
    width: 200%;
    height: 200%;
    background: #ff855e;
    transform: rotate(45deg);
    animation: passAnimationXBottom 0.3s linear forwards;
  }
  &.long-device::before {
    content: '';
    position: absolute;
    top: -75%;
    left: -133%;
    width: 200%;
    height: 200%;
    background: $secondary;
    transform: rotate(45deg);
    animation: passAnimationXTop 0.3s linear forwards;
  }
  /* pad size */
  // @media screen and (min-device-aspect-ratio: 3/5) {
  //   &::after {
  //     bottom: -75%;
  //     right: -154%;
  //     animation: passAnimationYBottom 0.3s linear forwards;
  //   }
  //   &::before {
  //     top: -75%;
  //     left: -153%;
  //     animation: passAnimationYTop 0.3s linear forwards;
  //   }
  // }
  @keyframes passAnimationXBottom {
    from {
      transform: translateX(80%) rotate(45deg);
    }
    to {
      transform: translateX(0) rotate(45deg);
    }
  }
  @keyframes passAnimationXBottomLeave {
    from {
      transform: translateX(0%) rotate(45deg);
    }
    to {
      transform: translateX(80%) rotate(45deg);
    }
  }
  @keyframes passAnimationXTop {
    from {
      transform: translateX(-80%) rotate(45deg);
    }
    to {
      transform: translateX(0) rotate(45deg);
    }
  }
  @keyframes passAnimationXTopLeave {
    from {
      transform: translateX(0%) rotate(45deg);
    }
    to {
      transform: translateX(-80%) rotate(45deg);
    }
  }
  @keyframes passAnimationYBottom {
    from {
      transform: translateY(45%) rotate(45deg);
    }
    to {
      transform: translateX(0) rotate(45deg);
    }
  }
  @keyframes passAnimationYTop {
    from {
      transform: translateY(-45%) rotate(45deg);
    }
    to {
      transform: translateX(0) rotate(45deg);
    }
  }
  .wrap-user {
    z-index: 1;
    &.leave {
      animation: userCardOpacity 0.3s linear 1s forwards;
    }
    .text-vs {
      opacity: 0;
      margin: 34px 0;
      animation: vsMoveOn 0.1s ease 0.4s forwards;
    }
    .text-start {
      opacity: 0;
      transform: translateX(-20px);
      animation: startMoveOn 0.5s ease 0.5s forwards;
    }
    .card-user {
      width: 280px;
      &:first-of-type {
        transform: translateX(-100vw);
        animation: userCardLeftFadeIn 0.3s ease 0.5s forwards;
      }
      &:last-of-type {
        transform: translateX(100vw);
        animation: userCardRightFadeIn 0.3s ease 0.5s forwards;
      }
      @keyframes userCardLeftFadeIn {
        from {
          transform: translateX(-100vw);
        }
        to {
          transform: translateX(0);
        }
      }
      @keyframes userCardRightFadeIn {
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(0);
        }
      }
      @keyframes userCardOpacity {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
      @keyframes vsMoveOn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      @keyframes startMoveOn {
        from {
          opacity: 0;
          transform: translateX(-20px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .card {
        border-radius: 40px;
        box-shadow: -30px 30px 3px 0 $grayscale-20;
        border: none;
        .user-details {
          max-width: 144px;
          align-items: center;
          .multiline-ellipsis {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .user-rank {
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
</style>
