<template>
  <div v-if="courses.length" class="course-type-list">
    <div
      aria-controls="course"
      class="tab-type text-18 font-weight-bold text-center d-flex align-items-center justify-content-between cursor-pointer"
      :class="courseType.show ? null : 'collapsed'"
      role="tab"
      @click="clickType(courseType)"
    >
      {{ $t(courseType.title) }}
      <b-icon
        icon="Chevron-up"
        font-scale="0.8"
        class="ml-1"
        :rotate="courseType.show ? 180 : 0"
      ></b-icon>
    </div>
    <b-collapse id="course" v-model="courseType.show" role="tabpanel">
      <div class="tab-courses cursor-pointer">
        <template v-for="course in courses">
          <div
            :key="course.id"
            href="#"
            class="course-item d-flex align-items-center justify-content-between"
            @click.stop="changeCourse(course)"
          >
            {{ $t(course.name) }}
            <i
              class="icon-Heart text-24"
              :class="{favorite: course.isFavorite}"
              @click.stop="favoriteCourse(course)"
            ></i>
          </div>
        </template>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    courseType: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    plans() {
      return this.$store.getters['course/plans'];
    },
    courses() {
      if (this.courseType.type === 'isNormal') {
        return this.plans.filter((plan) => plan.isAdult || plan.isKid);
      } else {
        return this.plans.filter((plan) => plan[this.courseType.type]);
      }
    },
  },
  methods: {
    clickType(type) {
      this.$emit('click-type', type);
    },
    changeCourse(course) {
      this.$emit('change-course', course);
    },
    favoriteCourse(course) {
      this.$emit('favorite-course', course);
    },
  },
};
</script>

<style lang="scss" scoped>
.course-type-list {
  border-radius: 10px;
}
.tab-type {
  background: $grayscale-5;
  border-radius: 6px;
  line-height: 30px;
  color: $font-grayscale-1;
  padding: 6px 16px;
}
.tab-courses {
  background: white;
  border-radius: 0px 0px 10px 10px;
  .course-item {
    padding: 12px 16px;
    &:not(:last-of-type) {
      border-bottom: 1px solid #dcdfe5;
    }
  }
}
.icon-Heart {
  color: $grayscale-gray;
  &.favorite {
    color: $primary;
  }
}
</style>
