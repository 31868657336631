import courseService from '@/services/course';
import {getLocalData, saveLocalData} from '@/lib/base/localData';
import i18n from '@/i18n/index.js';
import Vue from 'vue';

/**
 * @returns {Object} initialize state
 */
const initialState = () => {
  return {
    courses: null,
    echelon: null,
    plans: [],
    anchorDay: null,
    currentPlan: {},
    plansList: [],
  };
};

const state = initialState();

const getters = {
  currentCourses(state) {
    return state.courses;
  },
  echelon(state) {
    return state.echelon;
  },
  currentPlan(state) {
    return state.currentPlan;
  },
  plans(state) {
    return state.plans;
  },
  noPlans(state) {
    return state.plans?.length === 0;
  },
  isCurrentPlan(state) {
    return (planId) => {
      return state.currentPlan.id === planId;
    };
  },
  anchorDay(state) {
    return state.anchorDay;
  },
  favoritePlans(state) {
    return (state.plans || []).filter((plan) => plan.isFavorite);
  },
  getPlanTypes: () => (options) => {
    const types = [];
    if (options.isKid) {
      types.push('kid');
    }
    switch (options.stage) {
      case 'Beginner':
        types.push('beginner');
        break;
      case 'Intermediate':
        types.push('intermediate');
        break;
      case 'Advanced':
        types.push('advanced');
        break;
      case 'Mastery':
        types.push('mastery');
        break;
    }
    return types;
  },
  getPlanTypesName: () => (planType) => {
    let typeName = '';
    if (planType.includes('kid')) {
      typeName += '兒童';
    }
    switch (true) {
      case planType.includes('beginner'):
        typeName += '基礎';
        break;
      case planType.includes('intermediate'):
        typeName += '進階';
        break;
      case planType.includes('advanced'):
        typeName += '高階';
        break;
      case planType.includes('mastery'):
        typeName += '大師';
        break;
    }
    return i18n.t(typeName);
  },
};

const actions = {
  getCourses({commit}, currentPlanId, isAll = true) {
    return courseService.getCourses(currentPlanId, isAll).then((res) => {
      commit('setCourses', res.courses);
      commit('setEchelon', res.echelon);
    });
  },
  async getPlansList({commit, getters}) {
    const plans = await courseService.getCoursePlans();

    if (plans) {
      plans.forEach((plan) => {
        plan.types = getters.getPlanTypes({
          isKid: plan.isKid,
          stage: plan.igqeStage,
        });
        plan.typeName = getters.getPlanTypesName(plan.types);
      });
      commit('setPlansList', plans);
    }
  },
  getPlans({commit}, username) {
    return courseService.getCoursePlans(username).then((plans) => {
      const localPlanInfo = JSON.parse(getLocalData('plan-info'));

      let planInfo = plans.find((plan) => {
        return (
          localPlanInfo?.id === plan.id &&
          !['trial-01', 'kidtrial-02', 'nft-01'].includes(plan.id)
        );
      });

      if (!planInfo) {
        const filterPlan = plans.find(
          (plan) => !['trial-01', 'kidtrial-02', 'nft-01'].includes(plan.id)
        );
        planInfo = filterPlan;
      }
      if (!planInfo) {
        planInfo = plans.find((plan) => {
          return localPlanInfo?.id === plan.id;
        });
      }
      if (!planInfo) {
        planInfo = plans[0] || {};
      }

      commit('setPlans', plans);
      commit('setCurrentPlan', planInfo);
      saveLocalData('plan-info', JSON.stringify(planInfo));
    });
  },
  toggleFavorite({commit}, course) {
    courseService.setCourseFavorite(course.id, !course.isFavorite);
    commit('setIsFavorite', course);
  },
};

const mutations = {
  setPlansList(state, plans) {
    state.plansList = plans;
  },
  setCourses(state, courses) {
    state.courses = courses;
  },
  setIsFavorite(state, course) {
    const mapPlan = state.plans.find((plan) => plan.id === course.id);
    Vue.set(mapPlan, 'isFavorite', !course.isFavorite);
  },
  setEchelon(state, echelon) {
    state.echelon = echelon;
  },
  setPlans(state, plans) {
    state.plans = plans;
  },
  setCurrentPlan(state, plan) {
    state.currentPlan = plan;
  },
  setAnchorDay(state, day) {
    state.anchorDay = day;
  },
  resetState(state) {
    const _state = initialState();
    Object.assign(state, _state);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
