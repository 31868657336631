/* eslint-disable require-jsdoc */
import {callLambda} from '@/lib/api/lambda';
// import _ from 'lodash';

function quickMatch({boardSize, hasHandicap}) {
  const params = {
    boardSize,
    hasHandicap,
  };
  return callLambda({
    category: 'pvp',
    method: 'get',
    apiName: 'quickMatch',
    params,
  })
    .then((res) => {
      return res.data || '';
    })
    .catch((error) => {
      return error;
    });
}

/**
 * 快速配對-積分賽
 * @param {String} boardSize 棋盤大小
 * @returns {Promise} promise
 */
function quickMatchByElo({boardSize}) {
  const data = {
    boardSize,
  };
  return callLambda({
    category: 'pvp',
    method: 'post',
    apiName: 'quickMatchByElo',
    data,
  })
    .then((res) => {
      return res.data || '';
    })
    .catch((error) => {
      return error;
    });
}

/**
 * 快速配對-停止配對
 * @param {String} boardSize 棋盤大小
 * @returns {Promise} promise
 */
function stopMatchByElo(boardSize) {
  return callLambda({
    category: 'pvp',
    method: 'post',
    apiName: 'stopMatchByElo',
    data: {boardSize},
  })
    .then((res) => {
      return res.data || '';
    })
    .catch((error) => {
      return error;
    });
}

/**
 * 快速配對-取得使用者是否正在配對中
 * @param {String} boardSize 棋盤大小
 * @returns {Promise} promise
 */
function getUserIsWating() {
  return callLambda({
    category: 'pvp',
    method: 'get',
    apiName: 'getUserIsWating',
  })
    .then((res) => {
      return res.data || '';
    })
    .catch((error) => {
      return error;
    });
}

/**
 * 取得對戰單一棋譜資料
 * @param {String} id id
 * @returns {Promise} promise
 */
function getPvpKifu(id) {
  return callLambda({
    category: 'pvp',
    apiName: id,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得房間資料
 * @param {String} lobbyId lobbyId
 * @returns {Promise} promise
 */
function getPvpLobby(lobbyId) {
  return callLambda({
    category: 'pvp',
    apiName: `lobby/${lobbyId}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

export default {
  quickMatch,
  getPvpKifu,
  getPvpLobby,
  quickMatchByElo,
  stopMatchByElo,
  getUserIsWating,
};
