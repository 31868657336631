import {Share} from '@capacitor/share';
import {clientVersion} from '@/constant/env';
import {FileSharer} from '@byteowls/capacitor-filesharer';
import {Filesystem, Directory, Encoding} from '@capacitor/filesystem';
import {stage} from '@/constant/env';
import {Media} from '@capacitor-community/media';
import {Device} from '@capacitor/device';

// keytool -v -list -keystore android.keystore
class JShare {
  constructor() {
    this.ALBUM_NAME = 'HJJ GO';
  }

  generateUrl(route, query = {}, isWebOnly = false) {
    const version = clientVersion.replace('hjj-', '').replace('hjj', '');
    const queryString = Object.keys(query)
      .map((param) => `${param}=${query[param]}`)
      .join('&');
    const queryStringWithPrefix = `?${queryString}`;

    if (stage !== 'prod') {
      return `${window.location.origin}/${route}${queryStringWithPrefix}`;
    }

    if (isWebOnly) {
      return `https://heijiajia-${version}.app.link/e/${route}${queryStringWithPrefix}&$deeplink_path=${encodeURIComponent(
        `open${queryStringWithPrefix}`
      )}`;
    }
    return `https://heijiajia-${version}.app.link/${route}${queryStringWithPrefix}&$deeplink_path=${encodeURIComponent(
      `open${queryStringWithPrefix}`
    )}`;
  }

  canShare() {
    return Share.canShare();
  }

  async shareText(text) {
    try {
      await Share.share({
        title: '',
        text,
        dialogTitle: null,
      });
    } catch (error) {
      if (error.name !== 'NotAllowedError') throw error;
    }
  }
  async sharePvp(pvpId, text) {
    const url = this.generateUrl('pvp', {pvpId});
    await this.shareText(`${text}${text ? '\n' : ''}${url}`);
  }
  async sharePvpSpectate(spectateId, text) {
    const url = this.generateUrl('pvp-spectate', {spectateId});
    await this.shareText(`${text}${text ? '\n' : ''}${url}`);
  }
  async shareUrl(url) {
    await this.shareText(url);
  }
  async shareKifu({kifuId, gameMode, username}, text) {
    const data = {kifuId, gameMode, username};
    const url = this.generateUrl('share-kifu', data, true);
    await this.shareText(`${text}${text ? '\n' : ''}${url}`);
  }
  async shareImage(base64) {
    const image = base64;
    const filename = 'share.png';
    try {
      await FileSharer.share({
        filename,
        contentType: 'image/png',
        base64Data: image.split(';base64,')[1],
      });
    } catch (error) {
      if (error.message !== 'USER_CANCELLED') throw error;
    }
  }

  async ensureDemoAlbum() {
    const [{albums}, {platform}] = await Promise.all([
      Media.getAlbums(),
      Device.getInfo(),
    ]);
    let album = null;
    if (platform === 'android') {
      const albumsPath = (await Media.getAlbumsPath()).path;
      album = albums.find(
        (a) => a.name === this.ALBUM_NAME && a.identifier.startsWith(albumsPath)
      );
    } else {
      album = albums.find((a) => a.name === this.ALBUM_NAME);
    }

    if (!album) {
      await Media.createAlbum({name: this.ALBUM_NAME});
      return this.ensureDemoAlbum();
    } else {
      return album.identifier;
    }
  }

  async savePhoto(base64, filename) {
    const albumIdentifier = await this.ensureDemoAlbum();
    await Media.savePhoto({path: base64, fileName: filename, albumIdentifier});
  }

  async saveFile(data, filename) {
    await Filesystem.writeFile({
      path: filename,
      data,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    });
  }
}

export default JShare;
