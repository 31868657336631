export default {
  /**
   * DEV-3996: toggle for IGQE link
   * https://goerlive.atlassian.net/jira/software/projects/DEV/boards/4?assignee=70121%3A3ca5f83c-92d5-4014-a14a-00740f8d96ec&selectedIssue=DEV-3996
   */
  'show-igqe-link': false,
  'show-fixed-i18n-button': false,
  // 官網 sidebar 廣告欄位
  isOfficialSiteShow: false,
  // Fandora sidebar 廣告欄位
  isFandoraShopShow: false,
};
