<template>
  <div class="modal-message-box-wrapper">
    <modal-wrapper
      :is-modal-wrapper-show="true"
      :is-show-transition="false"
      @close="closeModal"
    >
      <div
        id="modal-message-box"
        class="modal-message-box px-5 pt-5 pb-4 d-flex flex-column justify-content-between position-relative"
        @click.stop
      >
        <div class="wrap-content d-flex flex-column align-items-center">
          <p
            v-if="typeof resultContent === 'string'"
            class="message-content m-0 text-16 text-center"
          >
            {{ resultContent }}
          </p>
          <template v-else>
            <p
              v-for="(content, index) in resultContent"
              :key="index"
              class="message-content m-0 text-16 text-center"
            >
              <template>
                {{ content }}
              </template>
            </p></template
          >
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <b-button
            v-for="(button, index) in buttons"
            :key="index"
            :variant="button.variant"
            size="md"
            class="mx-2 button"
            @click="onItemClick(button.value)"
          >
            {{ $t(button.content) }}
          </b-button>
        </div>
        <span
          v-if="hasBtnClose"
          class="btn-close text-primary cursor-pointer position-absolute text-32 icon-X"
          @click.prevent="closeModal"
        >
        </span>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
export default {
  components: {
    ModalWrapper,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => {
        return [
          {
            variant: 'primary',
            value: 'confirm',
            content: '確定',
          },
        ];
      },
    },
    resultContent: {
      type: [String, Array],
      default: '',
    },
    hasBtnClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalShow: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    onItemClick(value) {
      this.$emit('on-item-click', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-message-box {
  width: 300px;
  height: 186px;
  background: white;
  border-radius: 10px;
  .wrap-content {
    min-height: 60px;
    justify-content: space-evenly;
  }
  .btn-close {
    right: 6px;
    top: 6px;
  }
}
</style>
