<template>
  <div ref="chessboard-toolbar" class="chessboard-toolbar">
    <!-- 下棋模式 -->
    <div
      v-if="mode === 'game'"
      class="game-btn-group d-flex justify-content-center align-items-center"
    >
      <b-button
        v-if="hasSupportGame"
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mr-3 mr-md-0 mb-md-2 hint"
        :disabled="supportCount <= 0"
        @click="onItemClick('supportGame')"
      >
        <span v-if="supportCount > 0" class="icon-Hint-active"></span>
        <span v-else class="icon-Hint-disabled"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{
          $t('落點提示') + ' (' + supportCount + '/10)'
        }}</span>
        <span
          v-show="$device.isMobile && supportCount > 0"
          class="hint-count"
        ></span>
        <span
          v-show="$device.isMobile && supportCount > 0"
          class="hint-count-text"
          >{{ supportCount }}</span
        >
      </b-button>
      <b-button
        v-if="hasInfluence"
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mr-3 mr-md-0 mb-md-2 influence"
        @click="onItemClick('influence')"
      >
        <span class="icon-Go-status"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{ $t('當前形勢') }}</span>
      </b-button>
      <b-button
        v-show="stepType === 'normal'"
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mr-2 stepType mr-md-1"
        @click="onItemClick('handTriangle')"
      >
        <span class="icon-Hand-triangle"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{ $t('手數顯示') }}</span>
      </b-button>
      <b-button
        v-show="stepType === 'all'"
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mr-2 stepType mr-md-1"
        @click="onItemClick('handAll')"
      >
        <span class="icon-Hand-All"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{ $t('手數隱藏') }}</span>
      </b-button>
      <b-button
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mx-2 mx-md-0 ml-md-1 flag"
        @click="onItemClick('flag')"
      >
        <span class="icon-Flag"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{ $t('認輸') }}</span>
      </b-button>
      <b-button
        v-if="isTeacher && !$device.isMobile"
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mt-md-2 mx-md-0 mx-1 try-go"
        @click="onItemClick('testPlay')"
      >
        <span class="icon-Try-go"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{
          $t('試下（限教學）')
        }}</span>
      </b-button>
      <b-button
        v-if="hasJudge"
        variant="primary"
        :pill="$device.isMobile"
        :size="$device.isMobile ? 'md' : 'lg'"
        class="ml-2 ml-md-0 mt-md-2 judge"
        @click="onItemClick('judge')"
      >
        {{ $t('算輸贏') }}
      </b-button>
    </div>
    <!-- 人人對弈 -->
    <div
      v-if="mode === 'pvp'"
      class="pvp-btn-group d-flex justify-content-center align-items-center"
    >
      <b-button
        v-show="$device.isMobile"
        variant="secondary"
        size="oval-sm"
        class="mr-2 text-center"
        @click="onItemClick('sticker')"
      >
        <i class="icon-Stickers" style="margin: 0 auto" />
      </b-button>
      <b-button
        v-show="stepType === 'normal'"
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mx-2 stepType mr-md-1 ml-md-0"
        @click="onItemClick('handTriangle')"
      >
        <span class="icon-Hand-triangle"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{ $t('手數顯示') }}</span>
      </b-button>
      <b-button
        v-show="stepType === 'all'"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mx-2 stepType mr-md-1 ml-md-0"
        @click="onItemClick('handAll')"
      >
        <span class="icon-Hand-All"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{ $t('手數隱藏') }}</span>
      </b-button>
      <b-button
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mx-2 mx-md-0 ml-md-1 flag"
        @click="onItemClick('flag')"
      >
        <span class="icon-Flag"></span>
        <span v-if="!$device.isMobile" class="ml-1">{{ $t('認輸') }}</span>
      </b-button>
      <b-button
        v-show="!$device.isMobile"
        variant="secondary"
        :size="$device.isMobile ? 'oval-sm' : 'md'"
        class="mr-2 mr-md-0 w-100 mt-md-2 text-center"
        @click="onItemClick('sticker')"
      >
        <i class="icon-Stickers" style="margin: 0 auto" />
        <span v-if="!$device.isMobile" class="ml-1">{{ $t('發表情') }}</span>
      </b-button>
      <b-button
        v-if="hasJudge"
        variant="primary"
        :pill="$device.isMobile"
        :size="$device.isMobile ? 'md' : 'lg'"
        class="ml-2 ml-md-0 mt-md-2 judge"
        @click="onItemClick('judge')"
      >
        {{ $t('算輸贏') }}
      </b-button>
    </div>
    <!-- 查看棋譜 -->
    <div
      v-else-if="mode === 'checkKifu'"
      class="checkKifu-btn-group d-flex flex-column flex-md-column-reverse justify-content-between align-items-center p-2 m-md-2"
    >
      <div v-if="$device.isMobile" class="slide-bar w-100 px-1">
        <vue-slider
          v-if="currentStep >= 0 && reviewMarks && maxStep >= 0"
          :value="currentStep || 0"
          :marks="reviewMarks"
          :min="0"
          :max="maxStep"
          :dot-size="16"
          :hide-label="true"
          tooltip="none"
          @change="stepChange"
        >
          <template v-slot:step="{}"> </template>
        </vue-slider>
      </div>
      <div
        class="slide-control d-flex justify-content-between flex-md-column-reverse w-100"
      >
        <div class="hand d-flex flex-row-reverse d-md-block">
          <b-button
            v-show="stepType === 'all'"
            :class="$device.isMobile ? 'btn btn-secondary' : 'btn btn-white'"
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            @click="onItemClick('handAll')"
          >
            <span class="icon-Hand-All"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('手數隱藏')
            }}</span>
          </b-button>
          <b-button
            v-show="stepType === 'normal' || stepType === ''"
            :class="$device.isMobile ? 'btn btn-secondary' : 'btn btn-white'"
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            @click="onItemClick('handTriangle')"
          >
            <span class="icon-Hand-triangle"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('手數顯示')
            }}</span>
          </b-button>
          <b-button
            v-show="!$device.isMobile"
            class="btn btn-white ml-1"
            size="sm"
            @click="onItemClick('kifuInfo')"
          >
            <span class="icon-Elert-info"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('棋譜資訊')
            }}</span>
          </b-button>
          <b-button
            v-if="hasTestPlay"
            :class="
              $device.isMobile
                ? 'btn btn-secondary mr-2'
                : 'btn btn-white mt-1 mr-1'
            "
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            @click="onItemClick('testPlay')"
          >
            <span class="icon-Try-go"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('開始試下')
            }}</span>
          </b-button>
          <b-button
            v-if="hasInfluence"
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            :class="
              $device.isMobile ? 'btn btn-secondary mr-2' : 'btn btn-white mt-1'
            "
            @click="onItemClick('influence')"
          >
            <span class="icon-Go-status"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('當前形勢')
            }}</span>
          </b-button>
        </div>
        <div class="check-step mb-md-1">
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            class="mr-2 mr-md-0 btn-one-step"
            :disabled="currentStep === 0"
            @click="checkStep('prev')"
          >
            <span v-if="$device.isMobile" class="icon-Rewind"></span>
            <span v-else>{{ $t('上一手') }}</span>
          </b-button>
          <span v-if="$device.isMobile" class="mx-2 currentStep">{{
            currentStep
          }}</span>
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            class="ml-2 ml-md-1 btn-one-step"
            :disabled="currentStep === maxStep"
            @click="checkStep('next')"
          >
            <span v-if="$device.isMobile" class="icon-Play"></span>
            <span v-else>{{ $t('下一手') }}</span>
          </b-button>
        </div>
        <div v-if="!$device.isMobile" class="slide-bar w-100 px-1">
          <vue-slider
            v-if="currentStep >= 0 && reviewMarks && maxStep >= 0"
            :value="currentStep || 0"
            :marks="reviewMarks"
            :min="0"
            :max="maxStep"
            :dot-size="16"
            :hide-label="true"
            tooltip="none"
            @change="stepChange"
          >
            <template v-slot:step="{}"> </template>
          </vue-slider>
        </div>
        <p v-if="!$device.isMobile" class="wrap-step text-center mb-2">
          {{ '( ' + (currentStep || '0') + ' / ' + (maxStep || '0') + ' )' }}
        </p>
      </div>
    </div>
    <!-- 查看變化圖 -->
    <div
      v-else-if="mode === 'checkVariation'"
      class="checkKifu-btn-group d-flex justify-content-between align-items-center p-2 m-md-2 position-relative"
    >
      <div
        class="questionhand-wrapper d-flex align-items-center justify-content-between w-100 flex-md-column-reverse"
      >
        <div class="d-flex" :class="{'w-100': !$device.isMobile}">
          <b-button
            :class="
              $device.isMobile ? 'btn btn-secondary mr-2' : 'btn btn-white mr-1'
            "
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            @click="onItemClick('testPlay')"
          >
            <span class="icon-Try-go"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('開始試下')
            }}</span>
          </b-button>
          <b-button
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            :class="
              $device.isMobile ? 'btn btn-secondary mr-2' : 'btn btn-white'
            "
            @click="onItemClick('influence')"
          >
            <span class="icon-Go-status"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('當前形勢')
            }}</span>
          </b-button>
        </div>
        <div class="check-question-step mb-md-1">
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            class="mr-2 mr-md-0"
            :disabled="currentStep === 0"
            @click="checkStep('prev')"
          >
            <span v-if="$device.isMobile" class="icon-Rewind"></span>
            <span v-else>{{ $t('上一手') }}</span>
          </b-button>
          <span v-if="$device.isMobile" class="step-text mx-4">{{
            currentStep
          }}</span>
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            class="ml-2 ml-md-1"
            :disabled="currentStep === maxStep"
            @click="checkStep('next')"
          >
            <span v-if="$device.isMobile" class="icon-Play"></span>
            <span v-else>{{ $t('下一手') }}</span>
          </b-button>
        </div>
        <span v-if="!$device.isMobile" class="step-text mb-md-2">{{
          $t('變化圖') + ` ( ${currentStep} / ${maxStep} )`
        }}</span>
      </div>
    </div>
    <!-- 試下 -->
    <div
      v-else-if="mode === 'testPlay' && $device.isMobile"
      class="checkKifu-btn-group d-flex justify-content-between align-items-center p-2 m-md-2 position-relative"
    >
      <div
        class="questionhand-wrapper d-flex align-items-center justify-content-between w-100 flex-md-column-reverse"
      >
        <div class="d-flex" :class="{'w-100': !$device.isMobile}">
          <b-button
            :class="$device.isMobile ? 'mr-2' : 'w-100'"
            variant="primary"
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            @click="onItemClick('testPlay')"
          >
            <span class="icon-X"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('結束試下')
            }}</span>
          </b-button>
          <b-button
            v-if="!question"
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            :class="
              $device.isMobile ? 'btn btn-secondary mr-2' : 'btn btn-white'
            "
            @click="onItemClick('influence')"
          >
            <span class="icon-Go-status"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('當前形勢')
            }}</span>
          </b-button>
        </div>
        <div class="check-question-step mb-md-1">
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            class="mr-2 mr-md-0"
            :disabled="currentStep <= 0"
            @click="checkStep('prev')"
          >
            <span v-if="$device.isMobile" class="icon-Rewind"></span>
            <span v-else>{{ $t('上一手') }}</span>
          </b-button>
          <span v-if="$device.isMobile" class="step-text mx-4">{{
            currentStep
          }}</span>
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            class="ml-2 ml-md-1"
            :disabled="maxStep <= 1 || currentStep === maxStep"
            @click="checkStep('next')"
          >
            <span v-if="$device.isMobile" class="icon-Play"></span>
            <span v-else>{{ $t('下一手') }}</span>
          </b-button>
        </div>
        <span v-if="!$device.isMobile" class="step-text mb-md-2">{{
          ` ( ${currentStep || 0} / ${maxStep || 0} )`
        }}</span>
      </div>
    </div>
    <div
      v-else-if="mode === 'testPlay' && !$device.isMobile"
      class="m-md-2 position-relative"
    >
      <div class="checkKifu-btn-group">
        <div
          class="check-question-step mb-md-2 d-flex justify-content-between align-items-center p-2"
        >
          <b-button
            variant="secondary"
            size="oval-sm"
            class="mr-2 mr-md-0"
            :disabled="currentStep <= 0"
            style="width: initial"
            @click="checkStep('prev')"
          >
            <span class="icon-Rewind"></span>
          </b-button>
          <span class="desktop-current-step step-text mx-2">{{
            currentStep || 0
          }}</span>
          <b-button
            variant="secondary"
            size="oval-sm"
            class="ml-2 ml-md-1"
            :disabled="maxStep <= 1 || currentStep === maxStep"
            style="width: initial"
            @click="checkStep('next')"
          >
            <span class="icon-Play"></span>
          </b-button>
        </div>
      </div>
      <div class="w-100">
        <b-button
          v-if="!question"
          size="md"
          class="w-100 btn mb-2"
          @click="onItemClick('influence')"
        >
          <span class="icon-Go-status"></span>
          <span v-if="!$device.isMobile" class="ml-1">{{
            $t('當前形勢')
          }}</span>
        </b-button>
        <b-button
          class="w-100"
          variant="primary"
          size="md"
          @click="onItemClick('testPlay')"
        >
          <span class="icon-X"></span>
          <span v-if="!$device.isMobile" class="ml-1">{{
            $t('結束試下')
          }}</span>
        </b-button>
      </div>
    </div>
    <!-- 查看答案 -->
    <div
      v-else-if="mode === 'checkAnswer'"
      class="checkAnswer-btn-group d-flex flex-column align-items-center justify-content-between justify-content-md-center position-relative"
    >
      <div v-if="$device.isMobile" class="d-flex mb-2 w-100">
        <b-button
          v-if="$device.isMobile"
          variant="outline-secondary"
          size="md"
          class="check-answer mr-2 mr-md-0"
          :class="{selected: selectedAnswerTab === 'question'}"
          @click="onCheckAnswerClick('question')"
        >
          {{ $t('題目') }}
        </b-button>
        <b-button
          v-if="$device.isMobile"
          variant="outline-secondary"
          size="md"
          class="check-answer mr-2 mr-md-0"
          :class="{selected: selectedAnswerTab === 'myAnswer'}"
          @click="onCheckAnswerClick('myAnswer')"
        >
          {{ $t('我的答案') }}
        </b-button>
        <b-button
          v-if="$device.isMobile"
          variant="outline-secondary"
          size="md"
          class="check-answer"
          :class="{selected: selectedAnswerTab === 'answer'}"
          @click="onCheckAnswerClick('answer')"
        >
          {{ $t('正解') }}
        </b-button>
        <b-button
          v-if="question.videoId && $device.isMobile"
          class="btn btn-answer ml-2"
          variant="outline-secondary"
          size="oval-sm"
          @click.stop="onClickVideo(question)"
        >
          <span class="icon-Circle-play"></span>
        </b-button>
      </div>
      <div
        class="d-flex flex-md-column-reverse align-items-center justify-content-between w-100"
      >
        <div :class="{'w-100': !$device.isMobile}">
          <b-button
            v-if="hasTestPlay"
            :class="{'w-100 mt-1': !$device.isMobile}"
            :variant="$device.isMobile ? 'secondary' : 'outline-secondary'"
            :size="$device.isMobile ? 'oval-sm' : 'sm'"
            @click="onItemClick('testPlay')"
          >
            <span class="icon-Try-go"></span>
            <span v-if="!$device.isMobile" class="ml-1">{{
              $t('開始試下')
            }}</span>
          </b-button>
        </div>
        <div class="d-flex step-control justify-content-between">
          <b-button
            variant="secondary"
            :disabled="
              maxStep <= 0 ||
              currentStep === 0 ||
              selectedAnswerTab === 'question'
            "
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            class="btn-prev-step"
            :class="{'mr-1': !$device.isMobile}"
            @click="checkStep('prev')"
          >
            <span v-if="$device.isMobile" class="icon-Rewind"></span>
            <span v-else>{{ $t('上一手') }}</span>
          </b-button>
          <span
            v-if="selectedAnswerTab === 'question' && $device.isMobile"
            class="step"
            >0</span
          >
          <span v-else-if="$device.isMobile" class="step">{{
            currentStep || '1'
          }}</span>
          <b-button
            variant="secondary"
            :disabled="
              maxStep <= 0 ||
              currentStep === maxStep ||
              selectedAnswerTab === 'question'
            "
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            class="btn-next-step"
            @click="checkStep('next')"
          >
            <span v-if="$device.isMobile" class="icon-Play"></span>
            <span v-else>{{ $t('下一手') }}</span>
          </b-button>
        </div>
        <div v-if="!$device.isMobile" class="step mb-2">
          {{
            selectedAnswerTab === 'question'
              ? '( 0 / 0 )'
              : '( ' + (currentStep || '0') + ' / ' + (maxStep || '0') + ' )'
          }}
        </div>
      </div>
      <!-- todo[perf]: 判斷是 featureToggle 還是不要的功能 -->
      <!-- <div
        v-if="mode === 'checkAnswer' && selectedAnswerTab === 'question'"
        class="wrap-more-answer position-absolute d-flex bg-secondary"
      >
        <b-button
          v-if="question.videoId"
          class="btn btn-answer"
          size="sm"
          @click.stop="onClickVideo(question.videoId)"
        >
          <span class="icon-Circle-play mr-1"></span>{{ $t('視頻解說') }}
        </b-button>
        <b-button
          v-for="(currentAnswer, currentAnsweIndex) in question.currentAnswer"
          :key="currentAnsweIndex"
          class="btn btn-answer"
          size="sm"
          @click.stop="onCheckAnswerClick('answer', currentAnsweIndex)"
        >
          {{ $t('解答') + (currentAnsweIndex + 1)
          }}<span
            v-show="selectedAnswerIndex === currentAnsweIndex"
            class="icon-Check ml-1"
          ></span>
        </b-button>
      </div> -->
    </div>
    <!-- 確認落子 -->
    <div
      v-show="mode === 'check2Chess'"
      class="check2Chess-btn-group justify-content-center align-items-center"
    >
      <div class="check2Chess-btn-top">
        <b-button
          variant="secondary"
          :size="$device.isMobile ? 'oval-sm' : 'md'"
          :class="$device.isMobile ? 'mx-2' : 'mr-1'"
          @click="moveChess('top')"
        >
          <span class="icon-Big-top"></span>
        </b-button>
        <b-button
          variant="secondary"
          :size="$device.isMobile ? 'oval-sm' : 'md'"
          :class="$device.isMobile ? 'mx-2' : 'mx-1'"
          @click="moveChess('down')"
        >
          <span class="icon-Big-bottom"></span>
        </b-button>
        <b-button
          variant="secondary"
          :size="$device.isMobile ? 'oval-sm' : 'md'"
          :class="$device.isMobile ? 'mx-2' : 'mx-1'"
          @click="moveChess('left')"
        >
          <span class="icon-Big-left"></span>
        </b-button>
        <b-button
          variant="secondary"
          :size="$device.isMobile ? 'oval-sm' : 'md'"
          :class="$device.isMobile ? 'mx-2' : 'ml-1'"
          @click="moveChess('right')"
        >
          <span class="icon-Big-right"></span>
        </b-button>
      </div>
      <div class="check2Chess-btn-bottom">
        <b-button
          variant="grayscale30"
          :size="$device.isMobile ? 'oval-sm' : 'md'"
          :class="$device.isMobile ? 'mx-2' : 'mr-1'"
          @click="onItemClick('moveCancle')"
        >
          <span v-if="$device.isMobile" class="icon-X"></span>
          <span v-else>{{ $t('取消') }}</span>
        </b-button>
        <b-button
          variant="positive"
          :size="$device.isMobile ? 'oval-sm' : 'md'"
          :class="$device.isMobile ? 'ml-2' : 'ml-1'"
          @click="onItemClick('moveConfirm')"
        >
          <span v-if="$device.isMobile" class="icon-Check"></span>
          <span v-else>{{ $t('確定落子') }}</span>
        </b-button>
      </div>
    </div>
    <!-- 快速測棋力 -->
    <div
      v-show="mode === 'quickTest'"
      class="check2Chess-btn-group justify-content-center align-items-center"
    >
      <template v-if="!$device.isMobile">
        <div class="check2Chess-btn-top">
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            :class="$device.isMobile ? 'mx-2' : 'mr-1'"
            @click="moveChess('top')"
          >
            <span class="icon-Big-top"></span>
          </b-button>
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            :class="$device.isMobile ? 'mx-2' : 'mx-1'"
            @click="moveChess('down')"
          >
            <span class="icon-Big-bottom"></span>
          </b-button>
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            :class="$device.isMobile ? 'mx-2' : 'mx-1'"
            @click="moveChess('left')"
          >
            <span class="icon-Big-left"></span>
          </b-button>
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            :class="$device.isMobile ? 'mx-2' : 'ml-1'"
            @click="moveChess('right')"
          >
            <span class="icon-Big-right"></span>
          </b-button>
        </div>
        <div class="check2Chess-btn-bottom">
          <b-button
            variant="grayscale30"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            :class="$device.isMobile ? 'mx-2' : 'mr-1'"
            @click="onItemClick('moveCancle')"
          >
            <span v-if="$device.isMobile" class="icon-X"></span>
            <span v-else>{{ $t('取消') }}</span>
          </b-button>
          <b-button
            variant="positive"
            :size="$device.isMobile ? 'oval-sm' : 'md'"
            :class="$device.isMobile ? 'ml-2' : 'ml-1'"
            @click="onItemClick('moveConfirm')"
          >
            <span v-if="$device.isMobile" class="icon-Check"></span>
            <span v-else>{{ $t('確定落子') }}</span>
          </b-button>
        </div>
      </template>
      <template v-else>
        <div class="check2Chess-btn-bottom">
          <b-button
            variant="secondary"
            size="lg"
            class="mr-3"
            @click="onItemClick('moveCancle')"
          >
            <span>{{ $t('取消') }}</span>
          </b-button>
          <b-button
            variant="primary"
            size="lg"
            @click="onItemClick('moveConfirm')"
          >
            <span>{{ $t('確定') }}</span>
          </b-button>
        </div>
      </template>
    </div>
    <!-- 老師檢定試下 -->
    <div
      v-show="mode === 'teacherTsume'"
      class="check2Chess-btn-group justify-content-center align-items-center"
    >
      <b-button
        v-if="isTeacher && !$device.isMobile"
        variant="secondary"
        size="md"
        style="width: 100%"
        class="mt-md-2 mx-md-0 mx-1 try-go"
        @click="onItemClick('testPlay')"
      >
        <span class="icon-Try-go"></span>
        <span class="ml-1">{{ $t('試下（限教學）') }}</span>
      </b-button>
    </div>

    <sticker-sheet
      v-if="isStickerSheetShow"
      ref="sticker-sheet"
      @select="$emit('sticker-select', $event)"
      @close="closeSticker"
    ></sticker-sheet>
  </div>
</template>

<script>
import StickerSheet from '@/components/Base/StickerSheet.vue';
import BackEvent from '@/lib/base/backEvent.js';

export default {
  components: {
    StickerSheet,
  },
  props: {
    mode: {
      type: String,
      default: '',
      validator: (val) => {
        return [
          'game',
          'pvp',
          'check2Chess',
          'checkKifu',
          'checkAnswer',
          'board',
          'checkVariation',
          'testPlay',
          'teacherTsume',
          'quickTest',
          '',
        ].includes(val);
        // 下棋、確認落子、查看棋譜、查看做題結果
      },
    },
    stepType: {
      type: String,
      default: 'normal',
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    maxStep: {
      type: Number,
      default: 0,
    },
    question: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hasSupportGame: {
      type: Boolean,
      default: false,
    },
    hasInfluence: {
      type: Boolean,
      default: false,
    },
    hasTestPlay: {
      type: Boolean,
      default: true,
    },
    hasJudge: {
      type: Boolean,
      default: true,
    },
    supportCount: {
      type: Number,
      default: 0,
    },
    kifuReviewGame: {
      type: Object,
      default: null,
    },
    webSelectedAnswerTab: {
      type: String,
      default: 'question',
    },
  },
  data() {
    return {
      isStickerSheetShow: false, // 是否顯示貼圖ActionSheet
      selectedAnswerTab: 'question',
      selectedAnswerIndex: -1,
    };
  },
  computed: {
    isTeacher() {
      return this.$store.getters['user/isTeacher'];
    },
    width() {
      return this.$store.state.env.width;
    },
    excellentHandLength() {
      return this.kifuReviewGame ? this.kifuReviewGame.excellentHand.length : 0;
    },
    questionHandLength() {
      return this.kifuReviewGame ? this.kifuReviewGame.questionHand.length : 0;
    },
    reviewMarks() {
      let _reviewMarks = [];
      if (
        this.kifuReviewGame &&
        (this.kifuReviewGame.excellentHand || this.kifuReviewGame.questionHand)
      ) {
        const excellentHandMarks = {};
        const questionHandMarks = {};
        this.kifuReviewGame.excellentHand.forEach((step) => {
          excellentHandMarks[step.stepNum] = {
            style: {
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              boxShadow: '0 0 0 3px #6CB049',
              backgroundColor: '#6CB049',
            },
          };
        });
        this.kifuReviewGame.questionHand.forEach((step) => {
          questionHandMarks[step.stepNum] = {
            style: {
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              boxShadow: '0 0 0 3px #F35D23',
              backgroundColor: '#F35D23',
            },
          };
        });
        _reviewMarks = {...excellentHandMarks, ...questionHandMarks};
      }
      return _reviewMarks;
    },
  },
  watch: {
    webSelectedAnswerTab(newValue) {
      this.selectedAnswerTab = newValue;
    },
  },
  methods: {
    onItemClick(event) {
      if (event === 'sticker') {
        BackEvent.addEvent(() => {
          this.$refs['sticker-sheet'].$refs['action-sheet'].closeSheet();
        }, 'sticker');
        this.isStickerSheetShow = true;
      }
      this.$emit('item-click', event);
    },
    moveChess(direction) {
      this.$emit('move-chess', direction);
    },
    onCheckAnswerClick(value) {
      if (value === 'answer') {
        this.selectedAnswerTab = value;
        this.$emit('check-answer-tab', 'answer');
      } else {
        this.selectedAnswerTab = value;
        this.$emit('check-answer-tab', value);
      }
    },
    onClickVideo(question) {
      this.$emit('on-click-video', question);
    },
    checkStep(event) {
      switch (event) {
        case 'prev':
          this.stepChange(this.currentStep - 1);
          break;
        case 'next':
          this.stepChange(this.currentStep + 1);
          break;
      }
    },
    stepChange(step) {
      this.$emit('step-change', step);
    },
    closeSticker() {
      BackEvent.clearEvents('sticker');
      this.isStickerSheetShow = false;
    },
    closeQuestionHand() {
      this.$emit('close-question-hand');
    },
    closeGuide() {
      localStorage.setItem('has-toolbar-guide', JSON.stringify(true));
    },
  },
};
</script>

<style lang="scss" scoped>
.game-btn-group {
  @media screen and (min-width: 768px) {
    padding: 8px;
    flex-wrap: wrap;
  }
  .hint {
    position: relative;
    @media screen and (min-width: 768px) {
      width: 100%;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (min-width: 1440px) {
      width: calc(50% - 4px);
      margin-right: 4px !important;
    }
    .hint-count {
      position: absolute;
      width: 16px;
      height: 16px;
      background: $primary;
      border-radius: 50%;
      bottom: 0;
      right: 0;
      line-height: 16px;
    }
    .hint-count-text {
      position: absolute;
      font-size: 12px;
      width: 16px;
      height: 16px;
      background: $primary;
      border-radius: 50%;
      bottom: 0;
      right: 0;
      line-height: 16px;
      transform: scale(0.833);
      font-weight: bold;
    }
    .icon-Hint-active {
      &::before {
        color: white;
      }
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
    .icon-Hint-disabled {
      &::before {
        color: white;
      }
    }
  }
  .influence {
    position: relative;
    @media screen and (min-width: 768px) {
      width: 100%;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (min-width: 1440px) {
      width: calc(50% - 4px);
      margin-left: 4px;
    }
    .icon-Go-status {
      &::before {
        color: white;
      }
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .try-go {
    @media screen and (min-width: 768px) {
      width: 100%;
    }
  }
  .stepType {
    @media screen and (min-width: 768px) {
      width: calc(50% - 4px);
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-Hand-triangle,
    .icon-Hand-All {
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .flag {
    @media screen and (min-width: 768px) {
      width: calc(50% - 4px);
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-Flag {
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .judge {
    @media screen and (min-width: 768px) {
      width: 100%;
      font-weight: 700;
    }
  }
}
.pvp-btn-group {
  @media screen and (min-width: 768px) {
    padding: 8px;
    flex-wrap: wrap;
  }
  .stepType {
    @media screen and (min-width: 768px) {
      width: calc(50% - 4px);
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-Hand-triangle,
    .icon-Hand-All {
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .flag {
    @media screen and (min-width: 768px) {
      width: calc(50% - 4px);
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-Flag {
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .icon-Stickers {
    @media screen and (min-width: 768px) {
      font-size: 20px;
      top: 3px;
      position: relative;
    }
  }
  .judge {
    @media screen and (min-width: 768px) {
      width: 100%;
      font-weight: 700;
    }
  }
}
:deep(.vue-slider) {
  height: 6px !important;
  .vue-slider-process {
    background-color: $font-grayscale-1;
  }
  .vue-slider-marks {
    .vue-slider-mark {
      width: 6px !important;
      &:first-child .vue-slider-mark-step {
        display: block;
      }
      &:last-child .vue-slider-mark-step {
        display: block;
      }
    }
  }
  .vue-slider-dot-handle {
    box-shadow: none;
    background-color: $font-grayscale-1;
  }
}
.checkKifu-btn-group {
  background: white;
  border-radius: 10px;
  .slide-bar {
    padding: 12px 0 20px;
    @media screen and (min-width: 768px) {
      padding: 12px 0 16px;
    }
    .accent {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0 0 0 2px $accent;
      background-color: $accent;
    }
    .positive {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0 0 0 3px $positive;
      background-color: $positive;
    }
  }
  .hand {
    @media screen and (min-width: 768px) {
      width: 100%;
    }
    .icon-Hand-triangle,
    .icon-Hand-All,
    .icon-Elert-info,
    .icon-Try-go,
    .icon-Go-status {
      @media screen and (min-width: 768px) {
        font-size: 16px;
        position: relative;
        top: 2px;
      }
    }
  }
  .check-step {
    align-items: center;
    @media screen and (min-width: 768px) {
      width: 100%;
    }
    span.currentStep {
      min-width: 30px;
      display: inline-block;
      line-height: 28px;
      text-align: center;
    }
  }
  .wrap-step {
    line-height: 28px;
  }
  .btn-white,
  .btn-one-step {
    @media screen and (min-width: 768px) {
      width: calc(50% - 2px);
    }
  }
  .btn-other-step {
    @media screen and (min-width: 768px) {
      width: calc(25% - 3px);
      padding: 4px 0;
    }
  }
}
.checkAnswer-btn-group {
  background: white;
  border-radius: 10px;
  padding: 8px;
  .btn-md {
    opacity: 0.54;
    white-space: nowrap;
    flex: 1 0 0;
    &.selected {
      opacity: 1;
      color: white;
      background: $secondary;
    }
    @media screen and (min-width: 768px) {
      opacity: 1;
    }
  }
  div.step {
    line-height: 28px;
  }
  .step-control {
    align-items: center;
    @media screen and (min-width: 768px) {
      width: 100%;
    }
    span.step {
      color: $font-grayscale-1;
      display: inline-block;
      width: 29px;
      line-height: 28px;
      text-align: center;
      margin: 0 16px;
    }
    .btn-prev-step,
    .btn-next-step {
      @media screen and (min-width: 768px) {
        width: calc(50% - 2px);
      }
    }
  }
  .wrap-more-answer {
    bottom: -54px;
    min-width: calc(100% + 32px);
    left: -16px;
    padding: 8px 5%;
    flex-wrap: nowrap;
    overflow: overlay;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .btn-answer {
      background: white;
      color: $secondary;
      border-color: $secondary;
      width: calc(31.25% + 16px);
      flex-shrink: 0;
      &:not(:last-of-type) {
        margin-right: 2.5%;
      }
      .icon-Check {
        position: relative;
        top: 2px;
        font-size: 16px;
      }
    }
  }
}
.check2Chess-btn-group {
  display: flex;
  @media screen and (min-width: 768px) {
    flex-direction: column;
    padding: 8px;
  }
  .check2Chess-btn-top {
    display: flex;
    @media screen and (min-width: 768px) {
      margin-bottom: 8px;
      width: 100%;
      .btn {
        width: 25%;
        height: 48px;
        padding: 8px 0;
        > span {
          font-size: 32px;
        }
      }
    }
  }
  .check2Chess-btn-bottom {
    display: flex;
    @media screen and (min-width: 768px) {
      width: 100%;
      .btn {
        width: 50%;
        height: 48px;
        > span {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}
.step-bar {
  flex: 1;
  .step-bar-btn {
    top: -15px;
  }
}
.step-text {
  text-align: center;
  @media screen and (min-width: 768px) {
    line-height: 28px;
  }
}
.check-question-step {
  @media screen and (min-width: 768px) {
    width: 100%;
    .btn-secondary {
      width: calc(50% - 2px);
    }
  }
}
.questionhand-wrapper {
  height: 38px;
  @media screen and (min-width: 768px) {
    height: auto;
    width: 100%;
  }
}
.desktop-current-step {
  background: #00000005;
  flex: 1;
  line-height: 46px;
  border-radius: 10px;
}
</style>
