import verificationService from '@/services/verification';
import Vue from 'vue';
import store from '@/store/index.js';
import _ from 'lodash';
import dayjs from 'dayjs';

/**
 * @returns {Object} initialize state
 */
const initialState = () => {
  return {
    verificationList: null,
    verificationData: null,
    currentScore: {},
    totalScores: null,
    verificationReport: null,
    records: null,
    isMilestoneShow: false,
    currentVerificationTsume: {},
    milestoneVideoCover: '',
    hasNewScore: false,
    lastSetHasNewScoreTime: 0,
  };
};

const state = initialState();

const getters = {
  verificationList(state) {
    const filterVerification = state.verificationList;
    return filterVerification.sort((a, b) => {
      return a.rank - b.rank;
    });
  },
  verificationData(state) {
    return state.verificationData;
  },
  hasNewScore(state) {
    return state.hasNewScore;
  },
  currentScore(state) {
    return state.currentScore;
  },
  totalScores(state) {
    return state.totalScores;
  },
  verificationReport(state) {
    return state.verificationReport;
  },
  pastScores(state) {
    const filterScores = (state.totalScores || []).filter((score) => {
      return score.state === 'OVER';
    });
    return filterScores.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  },
  records(state) {
    return state.records;
  },
};

const actions = {
  async getVerificationList({commit}, currentPlanId) {
    try {
      const data = await verificationService.getVerificationList(currentPlanId);
      commit('setVerificationList', data);
      return data;
    } catch (error) {
      commit('setVerificationList', {});
      throw error;
    }
  },
  async getVerification({commit}, currentPlanId) {
    try {
      const data = await verificationService.getVerification(currentPlanId);
      commit('setVerificationData', data);
      if (data?.state === 'SCORE') {
        commit('setCurrentScore', data);
        store.dispatch('notification/getNotifications', 50);
        store.dispatch('user/loginWithJwt', {force: true});
      }
      return data;
    } catch (error) {
      commit('setVerificationData', {});
      throw error;
    }
  },
  async getTotalScores({commit}) {
    try {
      const totalScores = await verificationService.getTotalScores();
      const newScores = totalScores.filter((score) => score.isNew);
      const hasNewScore = newScores.length > 0;
      const maxNewScoreCreateTime = _.max(
        newScores.map((score) => dayjs(score.createdAt).valueOf())
      );
      commit('setTotalScores', totalScores);
      commit('setHasNewScore', {hasNewScore, time: maxNewScoreCreateTime});
    } catch (error) {
      commit('setTotalScores', null);
      throw error;
    }
  },
  async getVerificationReport({commit}, {rank, id, coursePlan}) {
    try {
      const verificationReport =
        await verificationService.getVerificationReport({rank, id, coursePlan});
      commit('setVerificationReport', verificationReport);
    } catch (error) {
      commit('setVerificationReport', null);
      throw error;
    }
  },
  getRecords({commit}) {
    return verificationService.getRecords().then((records) => {
      commit('setRecords', records);
    });
  },
};

const mutations = {
  setVerificationList(state, data) {
    state.verificationList = data;
  },
  setVerificationData(state, data) {
    Vue.set(state, 'verificationData', data);
  },
  setTotalScores(state, totalScores) {
    state.totalScores = totalScores;
  },
  setVerificationReport(state, verificationReport) {
    state.verificationReport = verificationReport;
  },
  setRecords(state, records) {
    state.records = records;
  },
  setCurrentVerificationTsume(state, data) {
    state.currentVerificationTsume = data;
  },
  setCurrentScore(state, data) {
    state.currentScore = data;
  },
  setHasNewScore(state, data) {
    let hasNewScore = null;
    let time = null;
    if (typeof data === 'object') {
      ({hasNewScore, time} = data);
    } else {
      time = dayjs().valueOf();
      hasNewScore = data;
    }
    if (time > state.lastSetHasNewScoreTime) {
      state.lastSetHasNewScoreTime = time;
      state.hasNewScore = hasNewScore;
    }
  },
  setMilestoneVideoCover(state, data) {
    state.milestoneVideoCover = data;
  },
  setMilestone(state, data) {
    state.isMilestoneShow = data;
  },
  resetState(state) {
    const _state = initialState();
    Object.assign(state, _state);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
