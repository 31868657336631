export const GAME_MODES = {
  PVP: 'pvp', // 人人對弈
  PRACTICE_GAME: 'practiceGame', // 練習
  COURSE_GAME: 'courseGame', // 課程
  VERIFICATION_GAME: 'verificationGame', // 檢定
  LADDER: 'ladder', // 天梯下棋
  LADDER_ENDGAME: 'ladderEndgame', // 天梯官子
  LADDER_FUSEKI: 'ladderFuseki', // 天梯佈局
};

export const TSUME_MODES = {
  VERIFICATION_TSUME: 'verificationTsume', // 檢定
  AI_TUTOR: 'aiTutor', // AI教學
  QUICK_TEST: 'quickTest', // 快速測棋
  COURSE_TSUME: 'courseTsume', // 課程
  PRACTICE_TSUME: 'practiceTsume', // 練習
  LADDER: 'ladder', // 天梯做題
  WEEKLY_TSUME: 'weeklyTsume', // 每週做題
  RANK_TEST: 'rankTest', // 棋力測試
};

export const CHESSBOARD_MODES = {
  GAME: 'game',
  TSUME: 'tsume',
  BOARD: 'board',
  PVP: 'pvp',
  ENDGAME: 'endgame',
  FUSEKI: 'fuseki',
};
