export const dragScroll = {
  bind(el) {
    let isDragging = false;
    let lastX;
    let lastY;

    /**
     * handle mouse down event
     * @param {MouseEvent} e mouse event
     */
    el._handleOnMouseDown = (e) => {
      isDragging = true;
      lastX = e.clientX;
      lastY = e.clientY;
      el.style.userSelect = 'none';
      el.style.cursor = 'grabbing';
    };

    /**
     * handle mouse up event
     */
    el._handleOnMouseUp = () => {
      if (!isDragging) return;

      isDragging = false;
      el.style.userSelect = '';
      el.style.cursor = 'grab';
    };

    /**
     * handle mouse move event
     * @param {MouseEvent} e mouse event
     */
    el._handleOnMouseMove = (e) => {
      if (!isDragging) return;
      const dx = e.clientX - lastX;
      const dy = e.clientY - lastY;

      el.scrollLeft -= dx;
      el.scrollTop -= dy;

      lastX = e.clientX;
      lastY = e.clientY;
    };

    el.addEventListener('mousedown', el._handleOnMouseDown);
    el.addEventListener('mousemove', el._handleOnMouseMove);
    el.addEventListener('mouseup', el._handleOnMouseUp);
  },
  unbind(el) {
    el.removeEventListener('mousedown', el._handleOnMouseDown);
    el.removeEventListener('mousemove', el._handleOnMouseMove);
    el.removeEventListener('mouseup', el._handleOnMouseUp);
  },
};
