<template>
  <div>
    <modal-wrapper
      v-if="sheetShow"
      :is-modal-wrapper-show="sheetShow"
      :is-show-transition="true"
      @close="closeSheet"
    >
      <div
        class="action-sheet w-100 overflow-hidden position-absolute"
        :class="{'sheet-moving': sheetMoving}"
        :style="[sheetStyle, height]"
        @click.stop
      >
        <div
          v-if="title"
          class="sheet-title font-weight-bold text-center"
          @touchstart.stop="touchstart($event)"
          @touchmove.stop="touchmove($event)"
          @touchend.stop="touchend($event)"
        >
          <i v-if="showCloseButton" class="icon-X" @click="closeSheet"></i>
          {{ sheetTitle }}
        </div>
        <i
          v-if="shouldShowClose"
          class="icon-X close-btn"
          @click="closeCourseSheet"
        ></i>
        <div class="sheet-content">
          <slot></slot>
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/Base/ModalWrapper.vue';

export default {
  components: {
    ModalWrapper,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    parentClose: {
      type: Function,
      default: null,
    },
    touchMoveToClose: {
      type: Boolean,
      default: true,
    },
    sheetHeight: {
      type: String,
      default: 'calc(100% - 100px)',
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    shouldShowClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheetShow: false,
      initialTouchY: 0,
      sheetStyle: {},
      sheetMoving: false,
    };
  },
  computed: {
    height() {
      return {
        height: this.sheetHeight,
      };
    },
    sheetTitle() {
      return this.$t(`${this.title}`);
    },
  },
  created() {
    this.openSheet();
  },
  methods: {
    closeCourseSheet() {
      this.$emit('closeCourseSheet');
    },
    touchstart(touchEvent) {
      if (this.touchMoveToClose) {
        this.initialTouchY = touchEvent.touches[0].pageY;
        this.sheetMoving = true;
      }
    },
    touchmove(moveEvent) {
      if (
        moveEvent.touches[0].pageY - this.initialTouchY > 0 &&
        this.touchMoveToClose
      ) {
        this.sheetStyle = {
          transform: `translateY(${
            moveEvent.touches[0].pageY - this.initialTouchY
          }px)`,
        };
      }
    },
    touchend(endEvent) {
      if (
        endEvent.changedTouches[0].pageY - this.initialTouchY > 200 &&
        this.touchMoveToClose
      ) {
        this.sheetMoving = false;
        this.closeSheet();
      } else {
        this.sheetMoving = false;
        this.sheetStyle = {
          transform: 'translateY(0)',
        };
      }
    },
    closeSheet() {
      this.sheetShow = false;
      this.sheetStyle = {
        transform: 'translateY(100%)',
      };
      setTimeout(() => {
        this.$emit('close');
      }, 500);
    },
    openSheet() {
      this.sheetShow = true;
      setTimeout(() => {
        this.sheetStyle = {
          transform: 'translateY(0)',
        };
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-sheet {
  bottom: 0;
  transform: translateY(100%);
  transition: all 0.5s;
  background: $bgcontainer;
  border-radius: 20px 20px 0 0;
  position: relative;
  max-height: 85%;
  padding-bottom: env(safe-area-inset-bottom);
  .icon-X {
    position: absolute;
    right: 16px;
    top: 12px;
    font-size: 32px;
    color: #ff855e;
    cursor: pointer;
  }
  &.sheet-moving {
    transition: all 0s;
  }
  .sheet-title {
    background: $white;
    height: 56px;
    line-height: 56px;
    > i {
      position: absolute;
      right: 12px;
      top: 12px;
      font-size: 32px;
      color: $primary;
    }
  }
  .sheet-content {
    height: calc(100% - 56px);
    overflow: overlay;
  }
}
</style>
