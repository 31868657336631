<template>
  <b-modal
    ref="modal"
    class="modal-confirm"
    content-class="rounded-10 position-relative"
    hide-footer
    hide-header
    size="sm"
    :visible="value"
    :no-close-on-backdrop="noClose"
    :no-close-on-esc="noClose"
    :centered="true"
    @hide="close"
    @close="close"
  >
    <div
      class="main d-block text-center text-16 my-4 d-flex align-items-center justify-content-center flex-wrap"
      style="min-height: 60px"
    >
      <slot></slot>
    </div>
    <div class="text-center mb-2">
      <b-button
        v-if="!hideCancel"
        class="cancel"
        variant="secondary"
        size="md mr-3"
        @click="cancel"
        >{{ $t(cancelText) }}</b-button
      >
      <b-button
        size="md"
        variant="primary"
        class="confirm"
        @click="$emit('confirm')"
        >{{ $t(confirmText) }}</b-button
      >
    </div>
    <span
      v-if="hasBtnClose"
      class="btn-close text-primary cursor-pointer position-absolute text-32 icon-X"
      @click.prevent="close"
    >
    </span>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    noClose: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: '確定',
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    hasBtnClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    close() {
      this.$emit('close');
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-content {
  width: 300px;
  margin: 0 auto;

  .main {
    color: $font-normal;
  }
  .btn-close {
    top: 8px;
    right: 8px;
  }
}
</style>
