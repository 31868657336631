<template>
  <div class="favorite-plans-collapse rounded-10 overflow-hidden">
    <div
      class="head text-white d-flex align-items-center bg-secondary cursor-pointer"
      @click="$emit('toggleVisible', !toggleFavoriteVisible)"
    >
      <i class="icon-Heart-outline text-24 mr-2"></i>
      {{ $t('常用課程') }}
      <i
        class="text-24 ml-auto"
        :class="toggleFavoriteVisible ? 'icon-Chevron-up' : 'icon-Chevron-down'"
      ></i>
    </div>
    <b-collapse v-model="toggleFavoriteVisible">
      <div>
        <div v-if="favoritePlans.length === 0" class="p-3">
          <div class="empty text-center">
            <span class="font-weight-bold">{{ $t('尚未收藏任何課程') }}</span>
          </div>
        </div>
        <div v-else>
          <template v-for="course in favoritePlans">
            <div
              :key="course.id"
              href="#"
              class="course-item d-flex align-items-center justify-content-between p-3 cursor-pointer"
              @click.stop="changeCourse(course)"
            >
              {{ $t(course.name) }}
              <i
                class="icon-Heart text-24"
                :class="{favorite: course.isFavorite}"
                @click.stop="favoriteCourse(course)"
              ></i>
            </div>
          </template>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    toggleFavoriteVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    plans() {
      return this.$store.getters['course/plans'];
    },
    favoritePlans() {
      return this.$store.getters['course/favoritePlans'] || [];
    },
  },
  methods: {
    changeCourse(course) {
      this.$emit('change-course', course);
    },
    favoriteCourse(course) {
      this.$emit('favorite-course', course);
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite-plans-collapse {
  border: 1px solid $secondary;
  .head {
    padding: 12px 16px;
  }
  .empty {
    padding: 14px 16px;
    border: 1px dashed #ced4da;
    border-radius: 3px;
    color: $font-grayscale-2;
  }
  .course-item {
    &:not(:last-child) {
      border-bottom: 1px solid $secondary;
    }
  }
  .icon-Heart {
    color: $grayscale-gray;
    &.favorite {
      color: $primary;
    }
  }
}
</style>
