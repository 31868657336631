import Vue from 'vue';
import VueRouter from 'vue-router';
import {Device} from '@capacitor/device';
import {getLocalData} from '@/lib/base/localData';
import store from '@/store/index.js';
import getDeviceInfo from '@/lib/base/getDeviceInfo';
import routes from './routes/index.js';

Vue.use(VueRouter);
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let mainOriginColor = null;
/**
 * 設定 main 的背景顏色
 * @param {String} color 顏色
 */
const setMainBackground = (color = mainOriginColor) => {
  const main = document.getElementsByTagName('main')[0];
  if (main) {
    mainOriginColor = main.style.backgroundColor;
    main.style.backgroundColor = color;
  }
};

router.beforeEach(async (to, from, next) => {
  if (to?.name === 'hjj-ai-course') {
    setMainBackground('black');
  }
  if (window.isApp == null) {
    const deviceInfo = await Device.getInfo();
    window.isApp = deviceInfo.platform !== 'web';
  }
  const token = getLocalData('jwt');
  // 從維護頁跳出來，要檢查狀態
  if (from.name === 'systemUpdate') {
    const isSystemUpdate = await store.dispatch('maintenance/getSystemState');
    if (isSystemUpdate) {
      return next(false);
    }
  }
  if (token) {
    if (store.getters['user/userData'].currentPlan == null) {
      await store.dispatch('user/loginWithJwt');
    }

    if (store.getters['course/plans'].length === 0) {
      await store.dispatch(
        'course/getPlans',
        store.getters['user/userData'].username
      );
    }

    const permission = store.getters['env/permission'];

    const hasUnfinishedGame = store.getters['aiGame/hasUnfinishedGame'];
    // user logged in, prevent navigation to login page
    if (['login', 'signup'].includes(to.name)) {
      return next({
        name: 'home',
      });
      // user didn't buy any course, redirect to intro page
    } else if (
      ((['gamelist'].includes(to.name) &&
        !permission?.aiGame &&
        !hasUnfinishedGame) ||
        (['tsumelist'].includes(to.name) && !permission?.tsume)) &&
      isMobile
    ) {
      return next({
        name: 'no-lesson',
        params: {type: to.name},
      });
    } else if (
      [
        'practice',
        'pvp-home',
        'pvp-menu',
        'pvp-wait',
        'gamelist',
        'tsumelist',
      ].includes(to.name) &&
      !isMobile
    ) {
      return next({
        name: 'home',
      });
    } else if (
      to.meta.requireInteriorTeacher &&
      !store.getters['user/isInteriorTeacher']
    ) {
      return next({
        name: 'home',
      });
    } else if (to.meta.requireTeacher && !store.getters['user/isTeacher']) {
      return next({
        name: 'home',
      });
    } else if (['login', 'signup'].includes(from.name)) {
      const isFirstLogin = store.getters['user/isFirstLogin'];
      const routeData = {
        name: to.name,
        query: to.query || {},
        params: to.params || {},
      };
      if (isFirstLogin && !routeData.query.isFirstLogin) {
        routeData.query.isFirstLogin = true;
        next(routeData);
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (to.meta.requireAuth) {
      return next({
        name: 'login',
        query: {
          next: JSON.stringify({
            name: to.name,
            params: to.params,
            query: to.query,
          }),
        },
      });
    }
    if (
      to.name === 'login' &&
      from.name === 'login' &&
      !to.query.next &&
      from.query.next
    ) {
      const next = from.query.next;
      return next({
        name: 'login',
        query: {next},
      });
    }
    return next();
  }
});

router.afterEach((_to, from) => {
  if (from?.name === 'hjj-ai-course') {
    setMainBackground();
  }
  store.commit('env/setIsLoading', false);
});

export default router;
