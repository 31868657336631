import {convertGRank} from '@/lib/base/ranking.js';
import i18n from '@/i18n/index.js';
import {manifest} from '@/constant/env.js';

let instance = {
  $t: (text) => text,
};
let defaultEvent = null;

const errorActionMap = {
  default: {
    type: 'bot',
    mode: 'text',
    text: instance.$t('未知的錯誤'),
  },
  'Invalid image file extension': {
    type: 'bot',
    mode: 'text',
    text: instance.$t('上傳格式須為圖片或 SGF 檔案格式'),
  },
  'not find the coursePlan': {
    type: 'bot',
    mode: 'text',
    text: instance.$t('找不到你選擇的課程設定'),
  },
  'your rank is gte max rank of the coursePlan': {
    type: 'bot',
    mode: 'text',
    text: instance.$t(
      '不好意思！你的棋力（{userRank}）已達目前課程方案《{courseName}》的上限，請切換或購買其他課程方案。'
    ),
  },
  'the rank of the coursePlan has no plans': {
    type: 'bot',
    mode: 'text',
    text: instance.$t(
      '不好意思！你的棋力（{userRank}）已達目前課程方案《{courseName}》的上限，請切換或購買其他課程方案。'
    ),
  },
  'the plans not support the mode': {
    type: 'bot',
    mode: 'text',
    text: instance.$t(
      '不好意思！你選擇的強度已經超出課程範圍，請切換或購買其他課程方案。'
    ),
  },
};

/**
 * 替換文字中的參數
 * @param {string} text 文字
 * @returns {string} 替換後的文字
 */
const replaceText = (text = '') => {
  let result = text;
  result = result.replace('{userRank}', convertGRank(instance.userData.rank));
  result = result.replace('{courseName}', i18n.t(instance.currentPlan.name));

  return result;
};

/**
 * 根據錯誤訊息執行對應錯誤事件
 * @param {string} errorType 錯誤訊息類型
 */
export const executeErrorAction = (errorType) => {
  const ACTION = errorActionMap[errorType] || errorActionMap.default;
  if (ACTION.text) {
    ACTION.text = replaceText(i18n.t(ACTION.text));
  }

  instance.pushChat(ACTION);
};

// Server Side Response Events
const eventList = {
  '': defaultEvent,
  UNKNOWN: {
    bot: i18n.t('抱歉，我無法理解你的問題，請換個方式說明你的問題。'),
  },
  COURSE_INTRODUCTION: {
    user: i18n.t('課程介紹'),
    action: () => {
      instance.pushChat({
        type: 'bot',
        mode: 'text-button',
        text: i18n.t(
          '黑嘉嘉圍棋針對不同的棋力需求，融合理論知識與實戰經驗，提供四大系列的課程以及組合方案'
        ),
        buttonList: [
          {
            text: i18n.t('查看介紹'),
            action: (_this) => {
              _this.$router.push({path: '/more-about-courses'});
            },
          },
        ],
      });
    },
  },
  RANK_INTRODUCTION: {
    user: i18n.t('檢定說明'),
    bot: i18n.t(
      '黑嘉嘉圍棋採用IGQE（International Go Qualification Examination）做為棋力標準，並根據IGQE的標準為學員打造專屬《模擬檢定》。根據檢定的難度，涵蓋不同強度的AI對局和題目。檢定結束後，除了成績外，還會收到詳細的成績單，內容包含分析對局的佈局、中盤和官子表現，還有每手棋的勝率和目數差等數據。學員可根據成績單調整學習方向，更有效提升圍棋水平。'
    ),
  },
  GET_EXAM_BUY_STEP: {
    user: i18n.t('如何購買模擬檢定？'),
    action: () => {
      instance.pushChat({
        type: 'bot',
        mode: 'text-menu',
        text: i18n.t(
          '您可以前往官方網站單獨購買欲進行的模擬檢定，亦可選擇購買課程。每門課程均贈送相應級別的模擬檢定，以供進行檢測。'
        ),
        menuList: [
          {
            text: i18n.t('單購模擬檢定'),
            eventKey: 'EXAM_SINGLE_BUY',
          },
          {
            text: i18n.t('查看所有課程'),
            eventKey: 'COURSE_LIST',
          },
        ],
      });
    },
  },
  EXAM_SINGLE_BUY: {
    action: () => {
      window.open(
        `${manifest.homePageUrl}/origin/#/shop/products/verification-g19`
      );
    },
  },
  COURSE_LIST: {
    action: () => {
      window.open(`${manifest.homePageUrl}/courses`);
    },
  },
  IGQE_INTRODUCTION: {
    user: i18n.t('IGQE是什麼？'),
    bot: i18n.t(
      'IGQE（International Go Qualification Examination）是由國際圍棋教育聯盟（IGEF）認證，自入門至高手共分為19個等級，每個等級皆以AI做為衡量標準，提供受測者更客觀而科學的棋力檢定。'
    ),
  },
  VIP_DETAIL: {
    user: i18n.t('方案說明'),
    action: () => {
      instance.pushChat({
        type: 'bot',
        mode: 'text-button',
        text: i18n.t(
          'VIP方案裡面包含直播講堂、學習計畫、圖表覆盤等功能，點擊下方按鈕可以查看更多內容！'
        ),
        buttonList: [
          {
            text: i18n.t('查看內容'),
            action: (_this) => {
              _this.$router.push({path: '/personal/vip-training-plan'});
            },
          },
        ],
      });
    },
  },
  GET_VIP_BUY_STEP: {
    user: i18n.t('如何購買VIP方案？'),
    action: () => {
      instance.pushChat({
        type: 'bot',
        mode: 'text-button',
        text: i18n.t(
          '您可以針對個人目標與需求，選擇適合的VIP方案期間（月/季/年），前往官方網站購買。'
        ),
        buttonList: [
          {
            text: i18n.t('前往查看'),
            action: () => {
              window.open(
                `${manifest.homePageUrl}/origin/#/shop/products/aiTutor-1`
              );
            },
          },
        ],
      });
    },
  },
  GET_COMMON_QUESTION: {
    user: i18n.t('常見問題'),
    action: () => {
      instance.pushChat({
        type: 'bot',
        mode: 'text-menu',
        text: i18n.t('若以下問題沒有解答到，也可以直接輸入文字詢問喔！'),
        menuList: [
          {
            text: i18n.t('找不到購買的課程'),
            eventKey: 'COURSE_SEARCH',
          },
          {
            text: i18n.t('錯過直播講堂了'),
            eventKey: 'STREAMING_MISS',
          },
          {
            text: i18n.t('覆盤是什麼？'),
            eventKey: 'REVIEW_INTRODUCTION',
          },
          {
            text: i18n.t('檢定考不過怎麼辦？'),
            eventKey: 'EXAM_FAIL',
          },
        ],
      });
    },
  },
  COURSE_SEARCH: {
    user: i18n.t('找不到購買的課程'),
    bot: i18n.t(
      '若您購買的是一般課程，您可以在【學習】－【切換課程】切換至您想要的課程；若您購買的是組合方案，需先兌換課程，方可開始上課。'
    ),
  },
  STREAMING_MISS: {
    user: i18n.t('錯過直播講堂了'),
    bot: i18n.t(
      '直播講堂提供回放，並保留180天，即使錯過也可以觀看回放。<br/>※ 請注意：若VIP方案到期，則無法繼續使用直播講堂喔。'
    ),
  },
  REVIEW_INTRODUCTION: {
    user: i18n.t('錯過直播講堂了'),
    bot: i18n.t(
      '覆盤即為「棋局結束後，檢討對局的優劣與得失關鍵」。<br/>每個棋譜皆提供「文字解說」及「圖表分析」，學員可以視自身程度查看適合的覆盤內容。<br/>※ 請注意：若對局未達可分析手數，則無法提供覆盤喔。'
    ),
  },
  EXAM_FAIL: {
    user: i18n.t('錯過直播講堂了'),
    bot: i18n.t(
      '您可以重新練習該難度的下棋、做題，或是參加「檢定策略」的直播講堂，將會對通過檢定有所幫助喔。'
    ),
  },
  WHERE_IS_OFFICE: {
    user: i18n.t('哪裡有據點？'),
    action: () => {
      instance.pushChat({
        type: 'bot',
        mode: 'text-button',
        text: i18n.t(
          '黑嘉嘉圍棋在台北、新北、桃園、新竹和高雄皆有據點，點擊下方按鈕可以查看各據點的詳細地址及聯絡方式'
        ),
        buttonList: [
          {
            text: i18n.t('前往查看'),
            action: () => {
              window.open(
                `${manifest.homePageUrl}/origin/#/brick-and-mortar-store`
              );
            },
          },
        ],
      });
    },
  },
};

export let eventMap = null;
/**
 * setDefaultEvent
 * @param {event} event vue instance
 */
export const setDefaultEvent = (event) => {
  defaultEvent = event;
};

/**
 * injectInstance
 * @param {Vue3Instance} vm vue instance
 */
export const injectInstance = (vm) => {
  instance = vm;
  eventMap = {
    ...eventList,
  };
};
