import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';

/**
 * 將自己加入棋院(分院)
 * @param {String} code 棋院代號
 * @returns {Array} Array
 */
function addSubAcademyUserByCode(code) {
  return callLambda({
    category: 'academy',
    method: 'post',
    apiName: 'addSubAcademyUserByCode',
    data: {code},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 將自己移除棋院(分院)
 * @param {String} username 帳號
 * @param {String} subAcademyId 棋院id
 * @returns {Array} Array
 */
function deleteSubAcademyUser(username, subAcademyId) {
  return callLambda({
    category: 'subAcademy',
    apiName: 'user',
    method: 'DELETE',
    data: {
      username,
      subAcademyId,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 * 將使用者移除班級
 * @param {String} username 帳號
 * @param {String} classId 棋院id
 * @returns {Array} Array
 */
function deleteClassUser(username, classId) {
  return callLambda({
    category: 'class',
    apiName: 'user',
    method: 'DELETE',
    data: {
      username,
      classId,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得班級資料
 * @returns {Promise} promise
 */
async function getClasses() {
  const res = await callLambda({
    category: 'academy',
    apiName: 'getUserClassList',
    method: 'get',
  });
  return res.data.data;
}

/**
 * 取得班級學生資料
 * @param {Number} classId 班級id
 * @returns {Promise} promise
 */
const getStudentsByClass = _.memoize(async function getStudentsByClass(
  classId
) {
  const res = await callLambda({
    category: 'academy2',
    method: 'get',
    apiName: `getClassStudentList?classId=${classId}&isGameCountRequired=true&isTsumeCountRequired=true&isPvpCountRequired=true`,
  });

  return res.data.data;
});

/**
 * 取得棋院統計資料
 * @returns {Promise} promise
 */
async function getAcademyStatistic() {
  const res = await callLambda({
    category: 'academy',
    method: 'get',
    apiName: 'subAcademy/statistic',
  });
  return res.data;
}

/**
 * 取得老師統計資料
 * @returns {Promise} promise
 */
async function getTeacherStatistic() {
  const res = await callLambda({
    category: 'academy',
    method: 'get',
    apiName: 'teacher/statistic',
  });
  return res.data;
}

export default {
  addSubAcademyUserByCode,
  deleteSubAcademyUser,
  deleteClassUser,
  getClasses,
  getStudentsByClass,
  getAcademyStatistic,
  getTeacherStatistic,
};
