/* eslint-disable require-jsdoc */
import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';

/**
 * 取得做題解說影片網址
 * @param {String} videoId 影片ID
 * @returns {Promise} promise
 */
function getTsumeVideoPlayAuth(videoId) {
  return callLambda({
    category: 'hjj',
    method: 'post',
    apiName: 'getTsumeVideoPlayAuth',
    data: {videoId},
  }).then((res) => {
    return res.data.playAuth;
  });
}

/**
 * 取得完成影片網址
 * @param {String} videoId 影片ID
 * @returns {Promise} promise
 */
const getCourseCompletionVideoAuth = _.memoize(
  function getCourseCompletionVideoAuth(videoId) {
    return callLambda({
      category: 'hjj',
      method: 'post',
      apiName: 'getCourseCompletionVideoAuth',
      data: {videoId},
    }).then((res) => {
      return res.data.playAuth;
    });
  }
);

/**
 * 取得使用者優惠券列表
 * @returns {Promise} promise
 */
function getUserCode() {
  return callLambda({
    category: 'hjj',
    method: 'get',
    apiName: 'code/userCode',
  }).then((res) => {
    return res.data;
  });
}

/**
 * 紀錄使用者的活動
 * @param {String} evnetName 需要儲存的EVENT
 * @param {String} username 使用者
 * @returns {Promise} promise
 */
function createEventRecord({eventName, username}) {
  return callLambda({
    category: 'hjj',
    method: 'post',
    apiName: 'createEventRecord',
    data: {eventName, username},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得使用者優惠券列表
 * @returns {Promise} promise
 */
function getUserCoupon() {
  return callLambda({
    category: 'hjj',
    method: 'get',
    apiName: 'user/code/coupon',
  }).then((res) => {
    return res.data;
  });
}
/**
 * 取得使用者優惠券列表
 * @returns {Promise} promise
 */
const _getUserCouponOverview = _.debounce(function getUserCouponOverview(
  callback
) {
  return callLambda({
    category: 'hjj',
    method: 'get',
    apiName: 'user/code/coupon/overview',
  }).then((res) => {
    callback(res.data);
  });
},
100);
const getUserCouponOverview = () => {
  return new Promise((resolve, reject) => {
    try {
      _getUserCouponOverview(resolve);
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 取得使用者優惠券列表
 * @returns {Promise} promise
 */
const _getUserCourseSerialNumberOverview = _.debounce(
  function getUserCourseSerialNumberOverview(callback) {
    return callLambda({
      category: 'hjj',
      method: 'get',
      apiName: 'user/code/courseSerialNumber/overview',
    }).then((res) => {
      callback(res.data);
    });
  },
  100
);
const getUserCourseSerialNumberOverview = () => {
  return new Promise((resolve, reject) => {
    try {
      _getUserCourseSerialNumberOverview(resolve);
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 更新使用者優惠券最後看到時間
 * hjj/user/code/coupon/lastReadTime
 * @returns {Promise} promise
 */
const updateCouponLastSeenTime = _.debounce(
  function getUserCourseSerialNumberOverview() {
    return callLambda({
      category: 'hjj',
      method: 'put',
      apiName: 'user/code/coupon/lastReadTime',
    }).then((res) => {
      return res.data;
    });
  },
  100
);

/**
 * 更新使用者可兌換課程最後看到時間
 * hjj/user/code/courseSerialNumber/lastReadTime
 *
 * @returns {Promise} promise
 */
const updateCourseSerialNumberLastSeenTime = _.debounce(
  function getUserCourseSerialNumberOverview() {
    return callLambda({
      category: 'hjj',
      method: 'put',
      apiName: 'user/code/courseSerialNumber/lastReadTime',
    }).then((res) => {
      return res.data;
    });
  },
  100
);

/**
 * 取得免費 vip
 * @returns {Promise} promise
 */
function freeVip() {
  return callLambda({
    category: 'hjj',
    method: 'post',
    apiName: 'freeVip',
  }).then((res) => {
    return res.data;
  });
}

export default {
  getTsumeVideoPlayAuth,
  getCourseCompletionVideoAuth,
  getUserCode,
  createEventRecord,
  getUserCoupon,
  getUserCouponOverview,
  getUserCourseSerialNumberOverview,
  freeVip,
  updateCouponLastSeenTime,
  updateCourseSerialNumberLastSeenTime,
};
