export default [
  {
    path: '/weekly-tsume',
    name: 'weekly-tsume',
    component: () => import('@/views/WeeklyTsume/WeeklyTsume.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/weekly-tsume/room/:mode',
    name: 'weekly-tsume-room',
    component: () => import('@/views/WeeklyTsume/WeeklyTsumeRoom.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/weekly-tsume/result',
    name: 'weekly-tsume-result',
    component: () => import('@/views/WeeklyTsume/WeeklyTsumeResult.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
];
