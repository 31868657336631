<template>
  <div
    class="d-flex flex-column align-items-center page-container"
    :class="{'is-mobile': $device.isMobile}"
  >
    <base-header
      v-if="$device.isMobile"
      :title="$t('適用課程方案')"
      :left-arrow="true"
      :back-on-click-left="true"
    ></base-header>
    <div
      v-if="$device.isMobile"
      ref="bodyContainerEl"
      class="body-container px-3 position-relative"
    >
      <div ref="stickyEl" class="sticky-bar sticky-top border-bottom pt-3 pb-1">
        <div class="recommend-message" :class="{'is-mobile': $device.isMobile}">
          <i class="icon-Elert-circle"></i>
          {{ errorMessage }}
        </div>
        <p class="text-center mb-3">
          {{ $t('適用於學習計畫的課程方案如下表：') }}
        </p>
        <div class="d-flex justify-content-center flex-wrap">
          <template v-for="plans in activePlans">
            <template v-if="plans.courseList.length > 0">
              <b-button
                :key="plans.title"
                class="mr-2 mb-2 px-3"
                pill
                size="sm"
                :variant="
                  plans.title === currentTags ? 'secondary' : 'grayscale30'
                "
                @click="handleTagClick(plans.title)"
              >
                {{ plans.title }}</b-button
              >
            </template>
          </template>
        </div>
      </div>
      <div class="py-3">
        <template v-for="plans in activePlans">
          <template v-if="plans.courseList.length > 0">
            <section :key="plans.title" :ref="plans.title">
              <div class="font-weight-bold mb-2">
                <span class="header-border"></span
                ><span>{{ plans.title }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <template v-for="course in plans.courseList">
                  <div
                    :key="course.name"
                    class="course-item"
                    :class="[course.type, {'is-web': !$device.isMobile}]"
                  >
                    {{ $t(course.name) }}
                  </div>
                </template>
              </div>
            </section>
          </template>
        </template>
      </div>
    </div>
    <template v-else>
      <div class="recommend-message">
        <i class="icon-Elert-circle"></i>
        {{ errorMessage }}
      </div>
      <div class="divide-element"></div>
      <p class="recommend-title text-center mb-3">
        {{ $t('適用於學習計畫的課程方案如下表：') }}
      </p>
      <div class="recommend-plans">
        <template v-for="plans in activePlans">
          <template v-if="plans.courseList.length > 0">
            <section
              :key="plans.title"
              :ref="plans.title"
              class="recommend-plan"
            >
              <div class="font-weight-bold mb-2">
                <span class="header-border"></span
                ><span>{{ plans.title }}</span>
              </div>
              <div class="d-flex flex-column">
                <template v-for="course in plans.courseList">
                  <div
                    :key="course.name"
                    class="course-item"
                    :class="[course.type, {'is-web': !$device.isMobile}]"
                  >
                    {{ $t(course.name) }}
                  </div>
                </template>
              </div>
            </section>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import BaseHeader from '@/components/Base/BaseHeader.vue';

export default {
  components: {
    BaseHeader,
  },
  data() {
    return {
      currentTags: '',
      bodyContainerEl: null,
    };
  },
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleTagClick(tag) {
      const stickyElHeight = this.$refs.stickyEl.offsetHeight;
      this.currentTags = tag;
      this.$refs.bodyContainerEl.scrollTo(
        0,
        this.$refs[tag][0].offsetTop - stickyElHeight
      );
    },
    getPlansList() {
      this.$store.dispatch('course/getPlansList');
    },
  },
  async mounted() {
    await this.getPlansList();
    this.currentTags = this.activePlans[0].title;
  },
  computed: {
    ...mapState('course', ['plansList']),
    activePlans() {
      const stageMapping = {
        Beginner: 'is-beginner',
        Intermediate: 'is-intermediate',
        Advanced: 'is-advanced',
        Mastery: 'is-mastery',
      };
      const initialStructure = [
        {
          title: this.$t('學習計畫：兒童基礎'),
          type: this.$t('兒童基礎'),
          courseList: [],
        },
        {
          title: this.$t('學習計畫：兒童進階'),
          type: this.$t('兒童進階'),
          courseList: [],
        },
        {
          title: this.$t('學習計畫：兒童高階'),
          type: this.$t('兒童高階'),
          courseList: [],
        },
        {
          title: this.$t('學習計畫：兒童大師'),
          type: this.$t('兒童大師'),
          courseList: [],
        },
        {
          title: this.$t('學習計畫：基礎'),
          type: this.$t('基礎'),
          courseList: [],
        },
        {
          title: this.$t('學習計畫：進階'),
          type: this.$t('進階'),
          courseList: [],
        },
        {
          title: this.$t('學習計畫：高階'),
          type: this.$t('高階'),
          courseList: [],
        },
        // feat: 等大師課正式開賣後再開放
        // {title: this.$t('大師'), courseList: []},
      ];
      const activePlansList = this.plansList.filter(
        (plan) => plan.features.studyPlan
      );

      activePlansList.forEach((course) => {
        const planType = this.$t(course.typeName);

        const courseCategory = initialStructure.find(
          (item) => item.type === planType
        );
        if (courseCategory) {
          courseCategory.courseList.push({
            ...course,
            type: stageMapping[course.igqeStage] || '',
          });
        }
      });
      return initialStructure;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  padding: 16px;
  .is-mobile {
    padding: 0;
  }
}
.body-container {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.4s;
  padding-top: 0;
  scroll-behavior: smooth;
  &.is-web {
    height: 100%;
    &.is-collapse {
      height: 100%;
    }
  }
  &.is-collapse {
    height: calc(100% - 142px);
  }
}
.sticky-bar {
  background: #f8f9fb;
}

.header-border {
  display: inline-block;
  width: 8px;
  height: 16px;
  border-radius: 2px;
  background: $secondary;
  margin-right: 8px;
  line-height: 21px;
}

.course-item {
  box-sizing: border-box;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  padding: 4px 16px;
  border-radius: 6px;
  margin-bottom: 8px;
  width: 100%;
  &.is-web {
    flex-shrink: 0;
    &:nth-child(odd) {
      margin-right: 6px;
    }
  }
  &.is-beginner {
    background: #f6dbce;
    color: #703114;
  }
  &.is-intermediate {
    background: #f0ccd6;
    color: #8d2945;
  }
  &.is-advanced {
    background: #c9e5da;
    color: #1a6445;
  }
  &.is-mastery {
    background: #a7c7df;
    color: #225073;
  }
}

.recommend-message {
  background: #f5f5f5;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  background: #3031330d;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #606266;
  > i {
    color: #c0c4cc;
    font-size: 60px;
    margin-bottom: 10px;
  }
  &.is-mobile {
    padding: 16px;
    height: auto;
    margin-bottom: 16px;
    > i {
      font-size: 40px;
    }
  }
}

.recommend-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #303133;
  margin: 0 0 16px;
}

.recommend-plans {
  padding: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.divide-element {
  width: 100%;
  height: 1px;
  background: #dcdfe5;
  margin: 16px 0;
}
</style>
