export default [
  {
    path: '/cardgolegends/camera-to-score-game',
    name: 'cameraToScoreGame',
    component: () => import('@/views/Cardgolegends/CameraToScoreGame.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
    },
  },
];
