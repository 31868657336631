<template>
  <div class="post-question">
    <b-form @submit.prevent="onSubmit">
      <div class="mb-3">
        <UploadImg
          style="display: inline-block"
          main-class="sgf-or-img"
          :accept="uploadAccept"
          :width="60"
          :breadcrumb-name="breadcrumbName"
          @upload-img="uploadImgHandler"
          @upload-sgf="uploadSgfHandler"
          @remove-img="removeHandler"
        />
      </div>
      <div>
        <b-form-textarea
          v-model="formData.text"
          class="text-14"
          :placeholder="$t('請輸入問題')"
          rows="3"
          no-resize
        ></b-form-textarea>
      </div>
      <div>
        <loading-wrapper v-if="isLoading"></loading-wrapper>
        <button
          :disabled="!formData.text || (!formData.sgf && !formData.image)"
          type="submit"
          class="btn btn-primary w-100 mt-3"
          size="md"
        >
          {{ $t('上傳') }}
        </button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
      <div>
        <h6 class="mt-3">
          {{ $t('因直播講堂的時間有限，不一定每位同學的問題都會被回答到哦！') }}
        </h6>
      </div>
    </b-form>
  </div>
</template>

<script>
import UploadImg from '@/components/Base/UploadImg.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';

export default {
  components: {
    UploadImg,
    LoadingWrapper,
  },
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const defaultFormData = {
      text: null,
      sgf: null,
      image: null,
      fileKey: null,
      fileName: null,
    };
    return {
      default: Object.assign({}, defaultFormData),
      formData: Object.assign({}, defaultFormData),
    };
  },
  computed: {
    uploadAccept() {
      /** issue: 非 web 環境裝置 accept 在同時限制多個檔案時 .sgf 會失效，IANA 官方也無提供 .sgf 的 MIME type 供對應
       * IANA Official MIME type
       * https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html
       *
       * IOS: MIME types are assigned by IANA
       * https://developer.apple.com/documentation/foundation/nsxmldocument/1408633-mimetype
       *
       * Android:
       * https://android.googlesource.com/platform/external/mime-support/+/9817b71a54a2ee8b691c1dfa937c0f9b16b3473c/mime.types
       */

      let accept = '.jpg, .png, .gif, .sgf';

      if (this.$device.isMobile || this.$device.isTablet) {
        accept = '';
      }

      return accept;
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    breadcrumbName() {
      return `我要問問題/${this.userData.username}`;
    },
  },
  methods: {
    uploadImgHandler(event) {
      const {arrayBuffer, key, base64, fileName} = event;
      this.formData = Object.assign(this.formData, {
        image: arrayBuffer,
        fileKey: key,
        fileName,
        base64,
        sgf: null,
      });
    },
    uploadSgfHandler(event) {
      const {sgf, base64, fileName} = event;
      this.formData = Object.assign(this.formData, {
        image: null,
        sgf,
        base64,
        fileKey: null,
        fileName,
      });
    },
    removeHandler() {
      const defaultData = Object.assign({}, this.default);
      delete defaultData.text;
      this.formData = Object.assign(this.formData, defaultData);
    },
    onSubmit() {
      this.$emit('submit', this.formData);
    },
  },
};
</script>

<style lang="scss" scoped>
.post-question {
  width: 100%;
  padding: 16px 16px 8px;
  text-align: center;
  color: #606266;
}
h6 {
  font-family: Microsoft YaHei;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.error-message {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #dc3545;
  margin: 0;
}
</style>
