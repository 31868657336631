import InAppPurchase from '@/lib/base/inAppPurchase';
import {getProducts} from '@/services/ec';
import {allProductsMap} from '@/config/ecProducts';

const state = {
  productList: [],
};

const getters = {
  productList: (state) => {
    const productList = state.ecProducts || [];
    if (state.isInAppPurchaseActive) {
      return productList.map((product) => {
        const inAppPurchaseId = product.inAppPurchaseId;
        const inAppProduct = InAppPurchase.getProduct(inAppPurchaseId);
        product.inAppProduct = inAppProduct;
        if (!inAppProduct) product.isActive = false;
        return product;
      });
    }
    return productList;
  },
};

const actions = {
  async getProducts({commit}) {
    const [getProductsData] = await Promise.all([
      getProducts(),
      InAppPurchase.init(),
    ]);
    if (InAppPurchase.isActive) {
      commit(
        'setProductList',
        getProductsData.map((product) => {
          const inAppPurchaseId = product.inAppPurchaseId;
          const inAppProduct = InAppPurchase.getProduct(inAppPurchaseId);
          product.inAppProduct = inAppProduct;
          if (!inAppProduct) product.isActive = false;
          return {
            ...product,
            ...(allProductsMap.get(product.id) || {}),
          };
        })
      );
    } else {
      commit(
        'setProductList',
        getProductsData.map((product) => {
          return {
            ...product,
            ...(allProductsMap.get(product.id) || {}),
          };
        })
      );
    }
  },
};

const mutations = {
  setProductList(state, value) {
    state.productList = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
