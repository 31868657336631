const IDS = {
  STUDENT_MANAGEMENT_RANK_UPGRADE: 'STUDENT_MANAGEMENT_RANK_UPGRADE',
  STUDENT_MANAGEMENT_VERIFICATION_NO_PASS:
    'STUDENT_MANAGEMENT_VERIFICATION_NO_PASS',
  RANK_QUESTION: 'RANK_QUESTION',
  RANK_QUESTION_TEST: 'RANK_QUESTION_TEST',
};

/**
 * 取得是否有跳過 Popup
 * @param {String} key key
 * @param {Object} data data
 * @returns {String} id
 */
export const getPopupId = (key, data) => {
  switch (key) {
    case IDS.STUDENT_MANAGEMENT_RANK_UPGRADE:
      return `${IDS.STUDENT_MANAGEMENT_RANK_UPGRADE}_${data.rank}`;
    case IDS.STUDENT_MANAGEMENT_VERIFICATION_NO_PASS:
      return `${IDS.STUDENT_MANAGEMENT_VERIFICATION_NO_PASS}_${data.id}`;
    case IDS.RANK_QUESTION:
      return IDS.RANK_QUESTION;
    case IDS.RANK_QUESTION_TEST:
      return IDS.RANK_QUESTION_TEST;
  }
  throw new Error('not find the key');
};

export default IDS;
