<template>
  <b-modal
    ref="modal"
    class="modal-confirm"
    content-class="rounded-10 position-relative"
    hide-footer
    hide-header
    size="sm"
    :visible="isShow"
    no-close-on-backdrop
    no-close-on-esc
    :centered="true"
  >
    <div class="banner" :style="`background:${bannerColor};`">
      <i :class="icon" />
      <h3>{{ title }}</h3>
    </div>
    <div class="m-0" style="min-height: 60px">
      <slot></slot>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    bannerColor: {
      type: String,
      default: '#FF855E',
    },
    icon: {
      type: String,
      default: 'icon-Question',
    },
    title: {
      type: String,
      default: '提示',
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep() .modal-content {
  width: 300px;
  margin: 0 auto;
  overflow: hidden;
  .modal-body {
    padding: 0;
  }
  .banner {
    color: $white;
    text-align: center;
    padding: $spacing-s 0;
    white-space: pre-line;
    i {
      font-size: 79px;
    }
  }
  .main {
    color: $font-normal;
  }
  .btn-close {
    top: 8px;
    right: 8px;
  }
}
</style>
