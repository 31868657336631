<template>
  <div v-show="isShow" class="update-progress-bar">
    <div>
      <img :src="require('@/assets/img/vertical_logo.svg')" />
      <div class="bar">
        <div class="h-100" :style="`width: ${percent}%;`"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getNewestVersion,
  getOriginVersion,
  isHaveNewVersion,
} from '@/lib/base/checkVersion';
import {stage} from '@/constant/env';
import {CapacitorUpdater} from '@capgo/capacitor-updater';
import {SplashScreen} from '@capacitor/splash-screen';
import {App} from '@capacitor/app';

export default {
  data() {
    return {
      isShow: false,
      percent: 0,
      bundle: null,
    };
  },
  computed: {
    cdn() {
      return this.$store.state.env.cdn;
    },
  },
  async created() {
    setTimeout(() => {
      SplashScreen.hide();
    }, 1500);

    CapacitorUpdater.addListener('download', (e) => {
      if (this.percent < e.percent && this.isShow) {
        this.percent = e.percent;
      }
    });
    CapacitorUpdater.addListener('downloadFailed', () => {
      this.$Message.error('Error: downloadFailed');
    });
    CapacitorUpdater.notifyAppReady();
    App.addListener('appStateChange', async (state) => {
      if (state.isActive && !this.isShow) await this.checkVersion();
    });
    await this.checkVersion();
  },
  methods: {
    async getOriginVersion() {
      return getOriginVersion();
      // try { // TODO 這一版套件這方法有 bug 等之後更新再處理....
      //   const cur = await CapacitorUpdater.current();
      //   return cur?.bundle?.version || getOriginVersion();
      // } catch {
      //   return getOriginVersion();
      // }
    },
    async checkVersion() {
      const originVersion = await getOriginVersion();
      const newestVersion = await getNewestVersion();
      const isHaveNew = isHaveNewVersion(originVersion, newestVersion);
      if (isHaveNew) {
        this.isShow = true;
        const url = `${this.cdn}dist/${stage}/${newestVersion}.zip`;
        this.bundle = await CapacitorUpdater.download({
          url,
          version: newestVersion,
        });
        if (this.bundle) {
          await CapacitorUpdater.set(this.bundle);
          this.bundle = null;

          setTimeout(() => {
            this.isShow = false;
            this.percent = 0;
          }, 1000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.update-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: $bgsection;
  width: 100%;
  height: 100%;
  display: flex;
  & > * {
    margin: auto;
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: center;
    gap: $spacing-s;
  }
  img {
    width: 130px;
    height: 130px;
  }
  .bar {
    width: 160px;
    height: 10px;
    background: $font-grayscale-3;
    overflow: hidden;
    border-radius: 10px;
    & > * {
      background: $accent;
    }
  }
}
</style>
