import { render, staticRenderFns } from "./ModalPostQuestion.vue?vue&type=template&id=0844924b&scoped=true"
import script from "./ModalPostQuestion.vue?vue&type=script&lang=js"
export * from "./ModalPostQuestion.vue?vue&type=script&lang=js"
import style0 from "./ModalPostQuestion.vue?vue&type=style&index=0&id=0844924b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0844924b",
  null
  
)

export default component.exports