export const SOUND_EFFECTS = {
  CLICK: 'click',
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  WIN: 'win',
  LOSE: 'lose',
  PLAY: 'play',
  FINISH_VERIFICATION: 'finishVerification',
  RECEIVE_CERTIFICATION: 'receiveCertification',
  MESSAGE_POP: 'messagePop',
  TRANSITION: 'transition',
  CONGRATULATION: 'congratulation',
};

export const VOICES = {
  BLACK_LAST: 'black-last',
  WHITE_LAST: 'white-last',
  10: 10,
  9: 9,
  8: 8,
  7: 7,
  6: 6,
  5: 5,
  4: 4,
  3: 3,
  2: 2,
  1: 1,
};

export const PATH = {
  click: require('@/assets/sound/click.mp3'),
  correct: require('@/assets/sound/correct.mp3'),
  incorrect: require('@/assets/sound/incorrect.mp3'),
  win: require('@/assets/sound/win.mp3'),
  lose: require('@/assets/sound/lose.mp3'),
  play: require('@/assets/sound/play.mp3'),
  finishVerification: require('@/assets/sound/finish_verification.mp3'),
  receiveCertification: require('@/assets/sound/receive_certification.mp3'),
  messagePop: require('@/assets/sound/message_pop.mp3'),
  transition: require('@/assets/sound/transition.mp3'),
  congratulation: require('@/assets/sound/congratulation.mp3'),
  'black-last': require('@/assets/sound/number/black-last.mp3'),
  'white-last': require('@/assets/sound/number/white-last.mp3'),
  10: require('@/assets/sound/number/10.mp3'),
  9: require('@/assets/sound/number/09.mp3'),
  8: require('@/assets/sound/number/08.mp3'),
  7: require('@/assets/sound/number/07.mp3'),
  6: require('@/assets/sound/number/06.mp3'),
  5: require('@/assets/sound/number/05.mp3'),
  4: require('@/assets/sound/number/04.mp3'),
  3: require('@/assets/sound/number/03.mp3'),
  2: require('@/assets/sound/number/02.mp3'),
  1: require('@/assets/sound/number/01.mp3'),
};
