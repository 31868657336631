<template>
  <div
    v-if="hasNavigation"
    class="navigation overflow-hidden"
    :class="[
      $device.isMobile ? 'bg-white' : 'bg-secondary',
      {'left-navigation h-100': !$device.isMobile},
    ]"
  >
    <div
      v-if="!$device.isMobile"
      class="logo-container cursor-pointer w-100"
      @click="back2home"
    >
      <img
        :src="require('@/assets/img/login/base-logo-white.png')"
        class="img-fluid"
      />
    </div>
    <b-nav
      class="navigation-container align-items-center overflow-auto scroll-bar"
      justified
    >
      <b-nav-item
        v-for="tab of tabs"
        v-show="!tab.isHidden"
        :key="tab.key"
        class="cursor-pointer position-relative"
        :active="isActive(tab)"
        :class="[$device.isMobile ? 'text-12' : 'text-14']"
        @click="tabEvent(tab)"
      >
        <i class="text-32" :class="iconClass(tab)"></i>
        <p class="m-0">{{ $t(tab.title) }}</p>
        <div
          v-if="tab.key === 'verification' && hasVerificationNotification"
          class="notification bg-accent position-absolute"
        ></div>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import {getLocalData, saveLocalData} from '@/lib/base/localData';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('env', ['isChinese']),
    notifiable() {
      return (
        this.verificationData.state === 'ENABLE' ||
        this.verificationData.state === 'SCORE'
      );
    },
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
    isSidebarShow() {
      return this.$store.state.env.isSidebarShow;
    },
    hasNavigation() {
      if (this.$device.isMobile) {
        return this.$route.meta?.hasNavigation && !this.isMilestoneShow;
      } else {
        return this.$route.meta?.hasNavigation;
      }
    },
    isMilestoneShow() {
      return this.$store.state.verification.isMilestoneShow;
    },
    noPlans() {
      return this.$store.getters['course/noPlans'];
    },
    active() {
      return this.$route.matched[0].name;
    },
    hasUnfinishedGame() {
      return this.$store.getters['aiGame/hasUnfinishedGame'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    tabs() {
      console.log('isChinese', this.isChinese);
      let tabs = [];
      if (this.$device.isMobile) {
        tabs = [
          {
            key: 'home',
            icon: 'Home',
            title: this.$t('首頁'),
            event: 'home',
          },
          {
            key: 'learningPage',
            icon: 'Go-Kifu2',
            title: this.$t('學習'),
            event: 'learningPage',
            toNoLessonPage: true,
          },
          {
            key: 'verification',
            icon: 'Verification',
            title: this.$t('檢定'),
            event: 'verificationList',
            toNoLessonPage: true,
          },
          {
            key: 'liveList',
            icon: 'live-stream',
            title: this.$t('直播'),
            event: 'liveList',
            toNoLessonPage: true,
            isHidden: !this.isChinese,
          },
          {
            key: 'more',
            icon: 'Dots-circle-horizontal',
            title: this.$t('更多'),
            event: 'more',
          },
        ];
      } else {
        tabs = [
          {
            key: 'home',
            icon: 'Home',
            title: this.$t('首頁'),
            event: 'home',
          },
          {
            key: 'learningPage',
            icon: 'Go-Kifu2',
            title: this.$t('學習'),
            event: 'learningPage',
            toNoLessonPage: true,
          },
          {
            key: 'verification',
            icon: 'Verification',
            title: this.$t('檢定'),
            event: 'verificationList',
            toNoLessonPage: true,
          },
          {
            key: 'live',
            icon: 'live-stream',
            title: this.$t('直播'),
            event: 'liveList',
            toNoLessonPage: true,
            isHidden: !this.isChinese,
          },
          {
            key: 'more',
            icon: 'Dots-circle-horizontal',
            title: this.$t('更多'),
            event: 'more',
          },
        ];
      }
      return tabs;
    },
  },
  methods: {
    iconClass(tab) {
      return `icon-${tab.icon}`;
    },
    back2home() {
      this.$router.push({name: 'home'});
    },
    async tabEvent(tab) {
      const event = tab.event;
      switch (event) {
        case 'verificationList':
          this.$store.commit('env/setHasVerificationNotification', false);
          const plansNotifiableStatus = {
            ...JSON.parse(getLocalData('plans-notifiable-status')),
          };
          plansNotifiableStatus[this.currentPlan.id] = this.notifiable;

          saveLocalData(
            'plans-notifiable-status',
            JSON.stringify(plansNotifiableStatus)
          );
          this.$router.push({name: 'verificationList'});
          break;
        case 'practiceGame':
        case 'gamelist':
          this.$store.commit('env/setIsLoading', true);
          await this.getUnfinishedGame();
          if (this.hasUnfinishedGame && this.$device.isMobile) {
            this.$router.push('/practice/game');
          } else {
            this.$device.isMobile
              ? this.$router.push('/learning/ai-game')
              : this.$router.push({name: 'practiceGame'});
          }
          break;
        case 'practiceTsume':
          this.$router.push({
            name: 'practiceTsume',
            params: {rank: 'uncast', type: 'uncast'},
          });
          break;
        case 'more':
          if (this.$device.isMobile) {
            this.$router.push('/more');
          } else {
            this.$store.commit('env/setIsSidebarShow', !this.isSidebarShow);
            this.$store.commit('env/setIsSwitchCourseSheetShow', false);
          }
          break;
        case 'personal':
          this.$router.push({name: tab.event});
        default:
          if (this.noPlans && tab.toNoLessonPage) {
            this.$router.push({
              name: 'no-lesson',
              params: {
                type: tab.event,
              },
            });
          } else if (this.$route.name !== tab.event) {
            this.$router.push({name: tab.event});
          }
          break;
      }
    },
    async getUnfinishedGame() {
      await this.$store.dispatch('aiGame/getUnfinishedGame');
      this.$store.commit('env/setIsLoading', false);
    },
    isActive(tab) {
      if (this.$route.name === 'no-lesson') {
        const type = this.$route.params.type;
        return type === tab.event;
      } else if (this.$device.isMobile) {
        return this.active === tab.event;
      } else {
        return (
          this.$route.path.includes(tab.event) || this.active === tab.event
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  .logo-container {
    padding: 8px 10px;
    margin: 0 0 8px;
    img {
      width: 100px;
    }
  }
  .navigation-container {
    box-shadow: inset 0 1px 0 0 $line-2;
    min-height: 62px;
  }
  .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    min-height: 62px;
    color: #909399;
    border-radius: $rounded-md;
    &.active {
      color: $primary;
    }
  }

  .notification {
    width: 14px;
    height: 14px;
    top: 10px;
    right: 9px;
    border-radius: 50%;
  }

  &.left-navigation {
    position: relative;
    z-index: 1040;
    .navigation-container {
      box-shadow: none;
      flex-direction: column;
      flex-wrap: nowrap;
      max-width: 120px;
      height: calc(100% - 139px);
    }

    .nav-link {
      width: 84px;
      height: 80px;
      color: white;
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
      &.active {
        background: rgba(255, 255, 255, 0.3);
      }
    }
    .nav-item {
      margin-bottom: 8px;
      max-height: 80px;
    }
  }
}
</style>
