<template>
  <div id="app" class="h-100">
    <div
      v-if="stage !== 'prod' && featureToggle['show-fixed-i18n-button']"
      class="locale-box"
    >
      <b-dropdown
        variant="light"
        size="sm"
        no-caret
        class="font-weight-bold m-3 login-locale"
      >
        <template #button-content>
          <div class="d-flex align-items-center">
            <i class="icon-world text-24 mr-1"></i>
            {{ localeName }}
          </div>
        </template>
        <template v-for="locale in localeList">
          <b-dropdown-item
            :key="locale.code"
            @click="changeLocale(locale.code)"
          >
            {{ locale.name }}
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </div>
    <!-- <div
      class="back-btn position-absolute"
      style="bottom: 0; right: 0; z-index: 999999"
    >
      {{ BackEvent.backEventBus }}
      android測試按鈕
    </div> -->
    <div
      v-if="allowShowAiTutor && !$device.isMobile && isAiTutorShow"
      class="ai-tutor-container"
    >
      <div class="ai-tutor-container-header">
        {{ $t('學習助手') }}
      </div>
      <i
        class="icon-X ai-tutor-container-close-button"
        @click="closeAiTutor"
      ></i>
      <AiTutorChat />
    </div>
    <data-loading v-if="isLoading"></data-loading>
    <PassAnimation
      v-if="isPassAnimation && viewMode === 'phone'"
      :leave="isLeave"
      :mode="passAnimationMode"
      :game-mode="passAnimationGameMode"
      @animation-end="destoryPassAnimation"
    ></PassAnimation>

    <Sidebar v-if="isLogin"></Sidebar>

    <div
      class="h-100 overflow-hidden d-flex"
      :class="$device.isMobile ? 'flex-column' : 'flex-row-reverse'"
    >
      <main id="main">
        <transition :name="$device.isMobile ? transitionName : ''">
          <keep-alive :include="keepAliveRoute" max="1">
            <router-view></router-view>
          </keep-alive>
        </transition>
      </main>
      <navigation />
      <system-info></system-info>
    </div>

    <Offline />

    <modal-confirm
      v-model="isRedirectModalShow"
      :hide-cancel="true"
      :no-close="true"
      @confirm="
        isRedirectModalShow = false;
        $router.push('/');
      "
    >
      {{ $t('此頁面無法重複開啟，按確定後自動跳至首頁') }}
    </modal-confirm>
    <modal-confirm
      v-model="isLogoutModalShow"
      :hide-cancel="true"
      :no-close="true"
      @confirm="
        isLogoutModalShow = false;
        logout();
      "
    >
      {{ $t('已有其他使用者登入') }}
    </modal-confirm>
    <modal-confirm
      v-model="isLogoutOtherModalShow"
      confirm-text="登入其他帳號"
      cancel-text="將他人登出"
      :no-close="true"
      @confirm="
        isLogoutOtherModalShow = false;
        logout();
      "
      @cancel="
        isLogoutOtherModalShow = false;
        logoutOtherAccount();
      "
    >
      {{ $t('已有其他使用者登入本帳號') }}
    </modal-confirm>
    <modal-confirm
      v-model="isNewAppModalShow"
      :hide-cancel="true"
      :no-close="true"
      @confirm="openAppStore"
    >
      {{ $t('有新版本，請至商店下載更新') }}
    </modal-confirm>
    <!-- 觀戰對局結束modal -->
    <modal-confirm
      :value="isSpectateOverShow"
      :confirm-text="$t('知道了')"
      :hide-cancel="true"
      @confirm="isSpectateOverShow = false"
    >
      <template>
        <div>{{ $t('對局已結束') }}</div>
      </template>
    </modal-confirm>

    <!-- 首次登入，需輸入暱稱 -->
    <ModalFirstLogin :show="isFirstLogin" />

    <landscape-reminder v-if="!$device.isMobile"></landscape-reminder>
    <SwitchCourse
      :visible="isSwitchCourseSheetShow"
      @close="closeSwitchCourseSheet"
    ></SwitchCourse>

    <ModalWrapper
      v-if="isAiTutorLearnPlanModalShow"
      class="plan-modal-wrapper"
      :is-modal-wrapper-show="true"
      :is-show-transition="true"
      :click-to-close="false"
      @close="closeAiTutorLearnPlanModalShow"
    >
      <div class="plan-wrapper">
        <div class="plan-header">
          {{ $t('學習計畫') }}
          <i
            class="plan-icon icon-X"
            @click="closeAiTutorLearnPlanModalShow"
          ></i>
        </div>
        <div class="plan-body">
          <div class="plan-frame">
            <AiTutorLearnPlan />
          </div>
        </div>
      </div>
    </ModalWrapper>
    <locale-sheet></locale-sheet>
    <ModalWrapper
      v-if="isAiTutorActivePlanModalOpen"
      class="plan-modal-wrapper"
      :is-modal-wrapper-show="true"
      :is-show-transition="true"
      :click-to-close="false"
      @close="closeAiTutorActivePlanModalShow"
    >
      <div class="plan-wrapper">
        <div class="plan-header">
          {{ $t('適用課程方案') }}
          <i
            class="plan-icon icon-X"
            @click="closeAiTutorActivePlanModalShow"
          ></i>
        </div>
        <div class="plan-body">
          <div class="plan-frame">
            <AiTutorActivePlan />
          </div>
        </div>
      </div>
    </ModalWrapper>
    <ModalPostQuestion ref="modalPostQuestion" />
    <ModalVip />
    <UpdateProgressBar />
  </div>
</template>

<script>
import featureToggle from '@/constant/featureToggle.js';
import {mapActions, mapState} from 'vuex';
import watchMixin from '@/mixin/watch';
import Navigation from './components/Base/Navigation.vue';
import SystemInfo from '@/components/SystemInfo';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import {getLocalData} from '@/lib/base/localData';
import {manifest, appConfigs, stage} from '@/constant/env.js';
import url from '@/lib/api/url';
import axios from 'axios';
import DataLoading from '@/components/Base/DataLoading.vue';
import PassAnimation from '@/components/Base/PassAnimation.vue';
import Sidebar from '@/components/Base/Sidebar';
import Offline from '@/components/Base/Offline.vue';
import delay from '@/lib/base/delay.js';
import {App} from '@capacitor/app';
import {Toast} from '@capacitor/toast';
import {Device} from '@capacitor/device';
import socket from '@/lib/socket/socket.js';
import SoundService from '@/lib/base/soundService';
import AppEvent from '@/lib/base/appEvent.js';
import BackEvent from '@/lib/base/backEvent.js';
import {throttle} from 'lodash';
import fbTrack from '@/lib/base/fbTrack';
import SwitchCourse from '@/components/Base/SwitchCourse';
import ModalFirstLogin from '@/components/Modal/ModalFirstLogin.vue';
import ModalVip from '@/components/Modal/ModalVip.vue';
import Egg from '@/lib/base/egg';
import AiTutorChat from '@/views/AiTutor/AiTutorChat.vue';
import AiTutorLearnPlan from '@/views/AiTutor/AiTutorLearnPlan.vue';
import AiTutorActivePlan from '@/views/AiTutor/AiTutorActivePlan.vue';
import ModalWrapper from '@/components/Base/ModalWrapper';
import LocaleSheet from './components/Base/LocaleSheet.vue';
import i18n from '@/i18n/index.js';
import getDefaultLanguage from '@/lib/defaultLanguage.js';
import userService from '@/services/user';
import ModalPostQuestion from '@/components/Modal/ModalPostQuestion.vue';
import UpdateProgressBar from '@/components/Home/UpdateProgressBar.vue';
import dayjs from 'dayjs';
import {Browser} from '@capacitor/browser';

export default {
  metaInfo() {
    return {
      title: i18n.t(manifest.name),
    };
  },
  components: {
    AiTutorChat,
    AiTutorLearnPlan,
    AiTutorActivePlan,
    Navigation,
    SystemInfo,
    DataLoading,
    PassAnimation,
    Offline,
    ModalConfirm,
    SwitchCourse,
    Sidebar,
    ModalFirstLogin,
    ModalWrapper,
    LandscapeReminder: () => import('@/components/Base/LandscapeReminder'),
    LocaleSheet,
    ModalPostQuestion,
    ModalVip,
    UpdateProgressBar,
  },
  mixins: [watchMixin],
  data() {
    return {
      modalPostQuestion: null,
      featureToggle,
      stage,
      isRedirectModalShow: false, // 分頁重複開啟modal
      isLogoutModalShow: false, // 提示被登出的modal
      isLogoutOtherModalShow: false, // 詢問是否踢掉其他登入者的modal
      isNewAppModalShow: false, // 提示有新的 app 要去更新
      isSpectateOverShow: false, // 觀戰的對局已結束的modal
      appStoreUrl: '',
      defaultTransition: 'fade',
      transitionName: 'fade',
      isPassAnimation: false,
      passAnimationMode: 'game',
      passAnimationGameMode: null,
      isLeave: false,
      lastBack: 0,
      socket,
      checkPvpWatcher: null, // 檢查是否有對弈中人人對弈的vue watcher
      now: new Date().getTime(),
      BackEvent,
      platform: null,
    };
  },
  provide() {
    return {
      appNow: () => this.now,
    };
  },
  computed: {
    ...mapState('env', ['locale', 'localeName', 'localeList']),
    userData() {
      return this.$store.getters['user/userData'];
    },
    isAiTutorShow() {
      return this.$store.getters['aiTutor/isAiTutorShow'];
    },
    allowShowAiTutor() {
      const enableRouteNames = [
        'home',
        'learningPage',
        'liveList',
        'personal',
        'verificationList',
      ];
      return enableRouteNames.includes(this.$route.name);
    },
    keepAliveRoute() {
      const routeName = this.$route.name;
      return [
        'practiceTsume',
        'practiceGame',
        'courseTsume',
        'courseGame',
        'ladderGame',
        'ladderEndgame',
        'ladderFuseki',
        'ladderTsume',
        'rank-test-tsume',
        'verificationGame',
        'verificationTsume',
        'pvp',
        'roomSetting',
      ].includes(routeName)
        ? ['TsumeRoom', 'GameRoom', 'Pvp']
        : [];
    },
    viewMode() {
      return this.$store.state.env.viewMode;
    },
    screenHeight() {
      return this.$store.state.env.height;
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    isLogin() {
      return this.$store.getters['user/isLogin'];
    },
    plans() {
      return this.$store.getters['course/plans'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    isAiTutorLearnPlanModalShow() {
      return this.$store.state['aiTutor'].isAiTutorLearnPlanModalOpen;
    },
    isAiTutorActivePlanModalOpen() {
      return this.$store.state['aiTutor'].isAiTutorActivePlanModalOpen;
    },
    sheetHeight() {
      const sheetHeight = 50 * this.plans.length + 56;
      if (sheetHeight > this.screenHeight - 100) {
        return 'calc(100% - 100px)';
      }
      return sheetHeight + 'px';
    },
    isSwitchCourseSheetShow() {
      return this.$store.state.env.isSwitchCourseSheetShow;
    },
    isSpectate() {
      return this.$route.query.spectateId != null;
    },
    isFirstLogin() {
      return !!this.$route.query?.isFirstLogin;
    },
  },
  watch: {
    locale(newLocale) {
      this.$i18n.locale = newLocale;
    },
    $route: {
      immediate: true,
      handler() {
        if (
          socket.isInit &&
          this.$route.name !== 'roomSetting' &&
          this.$route.name !== socket.router
        ) {
          const router = this.$route.name;
          let pvpLobbyId = null;
          if (router === 'pvp-join') {
            pvpLobbyId = this.$route.params?.id;
          }
          socket.update({router, pvpLobbyId});
        }
      },
    },
    isLogin: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          if (this.checkPvpWatcher) {
            this.checkPvpWatcher();
          }
          this.checkPvpWatcher = this.waitUntil(
            {
              '$route.name': {
                $exists: true,
              },
              'socket.isInit': true,
            },
            () => {
              this.checkPvpUnfinishedGame();
            }
          );
          this.initialSocket();
          if (this.isLogin) {
            this.$store.dispatch('notification/getNotifications', 50);
          }
        }
      },
    },
    isSpectate: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.urlToPvpRoom();
        }
      },
    },
  },
  async created() {
    this.initEventBusHandler();
    const isSystemUpdate = await this.$store.dispatch(
      'maintenance/getSystemState'
    );
    if (isSystemUpdate) {
      this.$router.push({
        name: 'systemUpdate',
      });
    }

    document.documentElement.setAttribute('lang', this.locale || 'en');
    fbTrack('PageView');
    this.scheduleUpdateNow();
    const deviceInfo = await Device.getInfo();
    this.platform = deviceInfo.platform;
    window.isApp = deviceInfo.platform !== 'web';
    this.$store.commit('env/setPlatform', deviceInfo.platform);

    await this.checkAppBuildVersion();

    if (this.platform === 'android') {
      App.addListener('backButton', this.backButtonHandler);
    }

    if (this.platform === 'web') {
      window.addEventListener(
        'resize',
        throttle(this.handleWindowResize, 1000 / 60, {leading: true})
      );
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener(
          'resize',
          throttle(this.handleWindowResize, 1000 / 60, {leading: true})
        );
      });
    }

    if (this.platform === 'ios' || this.platform === 'android') {
      window.addEventListener('orientationchange', () => {
        this.handleWindowResize();
      });
      window.addEventListener(
        'resize',
        throttle(this.handleWindowResize, 1000 / 60, {leading: true})
      );
    }

    this.handleWindowResize();

    if (this.$device.isMobile) {
      this.$router.beforeEach(async (to, from, next) => {
        if (to.name === 'roomSetting' || from.name === 'roomSetting') {
          this.transitionName = to.fullPath.includes('roomSetting')
            ? 'slide-left'
            : 'slide-right';
          next();
          return;
        }
        const fromUpperTransitionType = to.meta.fromUpperTransitionType;
        const toUpperTransitionType = from.meta.toUpperTransitionType;
        const toPathLength = to.path.split('/').length;
        const fromPathLength = from.path.split('/').length;
        const transitionType =
          toPathLength >= fromPathLength
            ? fromUpperTransitionType
            : toUpperTransitionType;
        if (
          to.meta.transitionDepth != null &&
          from.meta.transitionDepth != null
        ) {
          if (to.meta.hasPassAnimation) {
            this.transitionName = this.defaultTransition;
          } else {
            this.transitionName =
              to.meta.transitionDepth < from.meta.transitionDepth
                ? 'slide-right'
                : 'slide-left';
          }
          next();
        } else if (transitionType === 'slide') {
          this.transitionName =
            toPathLength < fromPathLength ? 'slide-right' : 'slide-left';
          next();
        } else if (
          transitionType === 'passAnimation' &&
          this.$device.isMobile
        ) {
          this.passAnimationMode = to.meta.passAnimationMode;
          if (toPathLength >= fromPathLength) {
            if (this.passAnimationMode === 'game') {
              this.passAnimationGameMode =
                to.matched?.[0]?.props?.default?.mode;
            }
            this.isPassAnimation = true;
            await delay(1000);
            next();
            if (this.passAnimationMode === 'tsume') await delay(2000);
            this.isLeave = true;
          }
        } else {
          this.transitionName = this.defaultTransition;
          next();
        }
      });
    }
    if (stage !== 'prod') {
      this.toggleCheatMode();
    }

    App.addListener('appStateChange', async (state) => {
      if (state.isActive) {
        AppEvent.setAppStateActive(true);
        const isSystemUpdate = await this.$store.dispatch(
          'maintenance/getSystemState'
        );
        if (isSystemUpdate) {
          this.$router.push({
            name: 'systemUpdate',
          });
        }
      } else {
        SoundService.stopSound();
        AppEvent.setAppStateActive(false);
      }
    });
    this.initI18nLocale();
  },
  async beforeDestroy() {
    await App.removeAllListeners();
  },
  methods: {
    ...mapActions('env', ['initLocale', 'changeLocale']),
    async checkLearnPlanOrRedirect() {
      if (dayjs().isAfter(this.userData.liveCourseEndAt)) {
        this.$store.commit('env/setIsShowVipModal', true);
        return false;
      }
      this.$eventBus.$emit('postRecentUsedFeature', 'learn-plan');
      const alreadyHavePlan = await userService.getStudyPlan();
      if (alreadyHavePlan) {
        this.handleOnRedirectToLearnPage();
      } else {
        this.$router.push({name: 'aiTutorMakePlan'});
      }
    },
    async handleOnRedirectToLearnPage() {
      await this.$store.dispatch('aiTutor/getCurrentStudyPlan');
      if (this.$device.isMobile) {
        this.$router.push({name: 'aiTutorLearnPlan'});
      } else {
        await this.$store.dispatch('aiTutor/openAiTutorLearnPlanModalShow');
      }
    },
    toggleModalPostQuestion() {
      this.$refs.modalPostQuestion.show();
      this.$eventBus.$emit('postRecentUsedFeature', 'question');
    },
    /**
     * 記錄 RecentContainer 功能近期使用服務
     * @param {('tournament' | 'pvp' | 'kifus' | 'learn-ai-game' | 'learn-tsume' | 'learn-plan' | 'verification' | 'learning-process' | 'question')} eventName - 事件名稱，限定為以下幾種：
     * - 'tournament'：進入九路排名賽
     * - 'pvp'：進入好友對戰
     * - 'kifus'：進入我的棋譜
     * - 'learn-ai-game'：進入學習頁-下棋
     * - 'learn-tsume'：進入學習頁-做題
     * - 'learn-plan'：進入學習助手訓練計畫
     * - 'verification'：進入檢定頁
     * - 'learning-process'：進入學習歷程
     * - 'question'：開啟圍棋問題 modal
     */
    postRecentUsedFeature(eventName) {
      userService.postRecentUsedFeature({name: eventName});
    },
    putRecentUsedFeature(eventName) {
      userService.putRecentUsedFeature({names: eventName});
    },
    initEventBusHandler() {
      // todo[perf]: 將專案中 global 事件 e.g. tab/page 等進入前的判斷合併統一到此處避免 shotgun surgery, 未來量多時可考量 by naming, files 隔離事件

      this.$eventBus.$on(
        'toggleModalPostQuestion',
        this.toggleModalPostQuestion
      );

      this.$eventBus.$on('postRecentUsedFeature', this.postRecentUsedFeature);
      this.$eventBus.$on('putRecentUsedFeature', this.putRecentUsedFeature);
      this.$eventBus.$on(
        'checkLearnPlanOrRedirect',
        this.checkLearnPlanOrRedirect
      );
    },
    closeAiTutor() {
      this.$store.dispatch('aiTutor/closeAiTutor');
    },
    initI18nLocale() {
      const locale = getLocalData('locale');
      if (locale) {
        this.initLocale(locale);
      } else {
        const supportLocaleList = this.localeList.map(({code}) => code);
        const locale = getDefaultLanguage(supportLocaleList);
        this.initLocale(locale);
      }
    },
    closeAiTutorLearnPlanModalShow() {
      this.$store.dispatch('aiTutor/closeAiTutorLearnPlanModalShow');
    },
    closeAiTutorActivePlanModalShow() {
      this.$store.dispatch('aiTutor/closeAiTutorActivePlanModalShow');
    },
    toggleCheatMode() {
      const egg = new Egg();
      egg
        .addCode('esc,esc,esc', () => {
          this.$store.commit('env/toggleCheatMode');
        })
        .listen();
    },
    async handleWindowResize() {
      if (document.activeElement.nodeName === 'INPUT') return;
      await this.$store.dispatch('env/init');
      if (this.viewMode === 'phone') {
        if (this.screenHeight >= 500) {
          document.body.style.height = `${this.screenHeight}px`;
        } else {
          setTimeout(this.handleWindowResize, 100);
        }
      }
    },
    destoryPassAnimation() {
      this.isPassAnimation = false;
      this.isLeave = false;
    },
    async backButtonHandler() {
      if (BackEvent.backEventBus.length !== 0) {
        BackEvent.emit();
        return;
      } else if (this.$route?.meta.depth === 1) {
        if (Date.now() - this.lastBack < 2000) {
          App.exitApp();
        } else {
          await Toast.show({
            text: this.$t('再按一次離開應用程式'),
          });
          this.lastBack = Date.now();
        }
      } else {
        window.history.back();
      }
    },
    initialSocket() {
      const jwt = getLocalData('jwt');

      if (jwt && !socket.isInit) {
        socket.init(jwt, this.$route.name);
        if (this.$route.name == 'home') {
          this.urlToPvpRoom();
        }
        socket.onAskIfLogoutOtherAccount(() => {
          this.isLogoutOtherModalShow = true;
        });
        socket.onLogout(() => {
          this.$nextTick(() => {
            this.logout();
            this.isLogoutModalShow = true;
          });
        });
        socket.onRedirectRouter(() => {
          this.isRedirectModalShow = true;
        });
      }
      // 監聽產品是否更新中
      socket.onUpdateEnv((result) => {
        const data = JSON.parse(result.message).isUnderMaintenance;
        this.$store.commit('maintenance/setMaintenance', data);
        if (data) {
          this.$router.push({name: 'systemUpdate'});
        } else {
          this.$router.push({name: 'home'});
        }
      });
    },
    logout() {
      this.$store.dispatch('user/logout');
    },
    logoutOtherAccount() {
      socket.sendLogoutOtherAccount();
    },
    urlToPvpRoom() {
      if (this.$route.query.pvp) {
        this.$store.commit('env/setIsLoading', true);
        socket.send({
          action: 'pvp',
          pvpAction: socket.SendEventEnum.PVP_ENTER_LOBBY,
          data: {
            lobbyId: this.$route.query.pvp,
          },
        });
        socket.once(socket.ResponseEventEnum.PVP_GAME_STARTED, () => {
          this.$store.commit('env/setIsLoading', false);
          this.$router.push('pvp');
        });
      } else if (this.isSpectate) {
        this.$store.commit('env/setIsLoading', true);
        const sendEvent = socket.SendEventEnum.PVP_SPECTATE;
        socket.send({
          action: 'pvp',
          pvpAction: sendEvent,
          data: {
            gameId: this.$route.query.spectateId,
          },
        });
        const getGameResponseEvent =
          socket.ResponseEventEnum.PVP_SPECTATE_SUCCESSFULLY;
        socket.once(getGameResponseEvent, async (result) => {
          const game = result.data;
          this.$store.commit('env/setIsLoading', false);
          if (!game.winner) {
            this.$router.push({
              name: 'pvp',
              query: {spectateId: this.$route.query.spectateId},
            });
          } else {
            this.$router.replace({
              name: 'home',
            });
            this.isSpectateOverShow = true;
          }
        });
      }
    },
    checkPvpUnfinishedGame() {
      if (
        socket.isInit &&
        !['pvp', 'pvp-wait', 'pvp-join'].includes(this.$route.name)
      ) {
        socket.once(socket.ResponseEventEnum.PVP_EXIST_PVP_GAME, (result) => {
          const data = result.data;
          if (data) {
            this.$router.push('/pvp');
          }
        });
        socket.send({
          action: 'pvp',
          pvpAction: socket.SendEventEnum.PVP_GET_UNFINISHED_GAME,
        });
      }
    },
    scheduleUpdateNow() {
      if (!this.timmer) {
        this.timmer = setInterval(() => {
          this.now = new Date().getTime();
        }, 1000);
      }
    },
    openAppStore() {
      if (this.platform === 'ios') {
        window.open(this.appStoreUrl);
      } else {
        Browser.open({url: this.appStoreUrl});
      }
    },
    dateFormat(plan) {
      return this.$day(plan.startedAt).format('YYYY-MM-DD');
    },
    async closeSwitchCourseSheet() {
      BackEvent.popEvent();
      this.$store.commit('env/setIsSwitchCourseSheetShow', false);
    },

    async getAppInfo() {
      try {
        return await App.getInfo();
      } catch (error) {
        return {build: 0};
      }
    },
    async getAppConfigs() {
      const defaultValue = appConfigs || {};
      const appConfigUrl = `${url.cdn}appConfig.json?v=${Date.now()}`;
      try {
        return (await axios.get(appConfigUrl)).data || defaultValue;
      } catch (error) {
        return defaultValue;
      }
    },
    async checkAppBuildVersion() {
      const [appInfo, appConfigs] = await Promise.all([
        this.getAppInfo(),
        this.getAppConfigs(),
      ]);
      const appConfig = appConfigs?.[this.platform];
      this.appStoreUrl = appConfig?.store;
      if (appConfig && appInfo.build < appConfig.build) {
        this.isNewAppModalShow = true;
      }
    },
  },
};
</script>

<style lang="scss">
html {
  font-size: 16px;
}

body {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.sheet-option {
  height: 50px;
  &.disabled {
    color: $font-grayscale-3;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $line-2;
  }
  .icon-Check {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 32px;
    color: $primary;
  }
}

// DEFAULT_TRANSITION
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
// SLIDE_TRANSITION
main {
  display: grid;
  grid-template: 'main';
  flex: 1;
  position: relative;
  overflow-x: hidden;
  z-index: 6;
  background-color: $bgcontainer;
}

main > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  // flex: 1 1 auto;
  background-color: $bgcontainer;
  position: relative;
  // height: 100%; /* To be fixed */
  touch-action: manipulation;
}

main > :first-child {
  z-index: 9; /* Prevent flickering on first frame when transition classes not added yet */
}

.slide-left-leave-to {
  animation: delayHide 0.01s 300ms forwards;
  animation: leaveToLeft 300ms both ease;
  z-index: 6;
}

.slide-left-enter-to {
  animation: delayHide 0.01s forwards;
  animation: enterFromRight 300ms both ease;
  z-index: 12;
}

.slide-right-leave-to {
  animation: delayHide 0.01s forwards;
  animation: leaveToRight 300ms both ease;
  z-index: 12;
}

.slide-right-enter-to {
  animation: delayHide 0.01s 300ms forwards;
  animation: enterFromLeft 300ms both ease;
  z-index: 6;
}
@keyframes delayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes delayHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes leaveToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
    filter: brightness(0.5);
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-50%);
    filter: brightness(0.5);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes leaveToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.ai-tutor {
  &.is-mobile {
    width: 55px;
    height: 55px;
    bottom: 8px;
    right: 8px;
  }
  &-container {
    border: 2px solid $secondary;
    position: fixed;
    width: 380px;
    height: 670px;
    right: 18px;
    bottom: 13px;
    z-index: 9;
    border-radius: $rounded-md;
    background: $color-gray-white;
    overflow: hidden;
    &-header {
      background: $secondary;
      color: $color-gray-white;
      height: 56px;
      line-height: 56px;
      text-align: center;
      font-size: 18px;
    }
    &-close-button {
      position: absolute;
      z-index: 100;
      top: 13px;
      right: 13px;
      font-size: 32px;
      color: $color-gray-white;
    }
  }
}
.plan-wrapper {
  width: 600px;
  height: 500px;
  border-radius: 10px;
  background: white;
  overflow: hidden;
  .plan-icon {
    font-size: 32px;
    color: #ff855e;
    cursor: pointer;
  }
  .plan-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 12px 15px 16px;
    height: 60px;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    color: #606266;
    border-bottom: 1px solid #ced4da;
  }
  .plan-body {
    padding: 16px;
    width: 100%;
  }
  .plan-frame {
    width: 568px;
    height: 406px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    overflow: hidden;
  }
}

.list-group {
  .list-group-item-action {
    height: 51px;
    border-bottom: 1px solid rgba(199, 199, 199, 0.3);
    color: $font-normal;
  }
}

.locale-box {
  // feat: for dev/beta testing
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 10000;
}
.ai-tutor-container {
  .icon-X {
    cursor: pointer;
  }
}
</style>
