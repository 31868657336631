import store from '@/store';
import AppEvent from '@/lib/base/appEvent.js';
import {SOUND_EFFECTS, VOICES, PATH} from '@/constant/sound.js';
import _ from 'lodash';

class SoundService {
  constructor() {
    const resourcesUrlMap = {
      soundEffects: {
        ..._.chain(SOUND_EFFECTS)
          .mapKeys((value) => value)
          .mapValues((value) => PATH[value])
          .value(),
      },
      voices: {
        ..._.chain(VOICES)
          .mapKeys((value) => value)
          .mapValues((value) => PATH[value])
          .value(),
      },
    };
    this.resourcesMap = {};

    /**
     * 載入聲音資源
     */
    Object.keys(resourcesUrlMap).forEach((soundsType) => {
      const _soundsType = resourcesUrlMap[soundsType];
      this.resourcesMap[soundsType] = {};
      Object.keys(_soundsType).forEach((key) => {
        const url = _soundsType[key];
        const sound = new Audio(url);
        this.resourcesMap[soundsType][key] = sound;
      });
    });
  }

  /**
   * 播放音效
   * @param {String} soundName 音效名稱
   * @param {Number} volume 音量大小
   * @returns {String} 音效描述文字
   */
  playSound(soundName, {soundEffectSetting = true, volume = 0.5} = {}) {
    this.stopSound();
    const sound =
      this.resourcesMap.soundEffects[soundName] ||
      this.resourcesMap.voices[soundName];

    const _soundEffectSetting =
      store.getters['user/userData']?.config?.soundEffectSetting ??
      soundEffectSetting;

    if (sound && _soundEffectSetting && AppEvent.getAppStateActive()) {
      sound.volume = volume;
      sound.play();
    }
    return true;
  }

  /**
   * 停止音效
   */
  stopSound() {
    for (const soundsType in this.resourcesMap) {
      for (const key in this.resourcesMap[soundsType]) {
        const sound = this.resourcesMap[soundsType][key];
        sound.pause();
        sound.currentTime = 0;
      }
    }
  }
}

export default new SoundService();
