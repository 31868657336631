import getDeviceInfo from '@/lib/base/getDeviceInfo';
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

export default [
  {
    path: '/student-management',
    name: 'studentManagement',
    component: () => import('@/views/StudentManagement/index.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/student-management/statistics',
    name: 'statistics',
    component: () => import('@/views/StudentManagement/StatisticsDetail.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
];
