/* eslint-disable require-jsdoc */
import AWS from 'aws-sdk';
import {awsConfigs} from '@/constant/env.js';
import {Buffer} from 'buffer';

AWS.config.update(awsConfigs);
const s3 = new AWS.S3();

const getObjectUrl = async (Key) => {
  const params = {
    Bucket: 'goer-private-ap-northeast-2',
    // Key: '截圖 2022-11-23 下午12.35.43.png',
    Key,
    Expires: 60, // sec
  };
  const url = await s3.getSignedUrl('getObject', params);
  return url;
};

const putObject = async ({key, arrayBuffer}) => {
  const Body = Buffer.from(arrayBuffer);
  const params = {
    Bucket: 'goer-private-ap-northeast-2',
    Key: key,
    Body,
  };
  return s3.upload(params).promise();
};

export {getObjectUrl, putObject};
